"use strict";

/**
 * This represents a set of jobs associated with a particular node.
 */
export class JobDonut {
    /**
     * Constructs a job donut visualization for the given node element.
     *
     * @param {HTMLElement} element - The element for the node.
     * @param {Object} options - The configuration of the donut.
     */
    constructor(element, options) {
        this._options = options;

        var radius = options.radius || 30;

        var svg = document.createElementNS(JobDonut.SVGNS, "svg");
        element.appendChild(svg);

        // Line up with center of icon
        svg.setAttribute("width", svg.clientWidth);
        svg.setAttribute("height", svg.clientHeight);

        this.element = element;
        this.svg = svg;

        this._jobs = [];
        this._ids = [];
        this.redraw();
    }

    append(info) {
        if (info.id !== undefined) {
            if (this._jobs[info.id] === undefined) {
                this._ids.push(info.id);
            }
            this._jobs[info.id] = info;
        }
        else {
            throw new Error("job must have an ID");
        }
        this.redraw();
    }

    redraw() {
        let svg = this.svg;

        // Clear existing children
        svg.innerHTML = "";

        let options = this._options;

        var parts = this._ids.length;
        var thickness = options.thickness || 12;
        var padding = options.padding;
        var radius = options.radius || 23;
        var innerRadius = radius - thickness;

        var left = (parseInt(svg.getAttribute('width'))  - 2*radius) / 2;
        var top =  (parseInt(svg.getAttribute('height')) - 2*radius) / 2;

        // Calculate padding as a ratio
        padding = 2 * Math.PI * (padding / ((2 * Math.PI * radius)));

        // We will take each job, or an aggregate of jobs, and divide into
        // sections. Each section will be depicted as an arc.
        let sections = [];

        if (parts >= options.aggregate) {
            // Just show each job type in the circular graph in aggregate

            // Count the number of running/finished/queued/failed
            var total = this._ids.length;
            var arcs = {};

            this._ids.forEach( (id) => {
                let job = this._jobs[id];
                if (arcs[job.status] === undefined) {
                    arcs[job.status] = 0;
                }
                arcs[job.status] ++;
            });

            // Append these as sections
            ["queued", "started", "finished", "failed"].forEach( (type) => {
                if (arcs[type]) {
                    sections.push({ status: type, amount: arcs[type] / total });
                }
            });
        }
        else {
            // Add a section for each job
            this._ids.forEach( (id) => {
                let job = this._jobs[id];
                sections.push({
                    status: job.status,
                    id: job.id,
                    amount: 1 / this._ids.length
                });
            });
        }

        parts = sections.length;

        // If there is only one arc, there is no padding
        if (parts == 1) {
            // Very small padding lets the arcs close and reduces my stress
            // my reducing the special cases of math involved.
            padding = 0.0001;
        }

        // Calculate the padding's arc length
        var paddingLength = radius * padding;

        // Calculate the relative inner padding
        // This will make the dividing lines straight between each arc
        var innerPadding = (2 * Math.PI) * (paddingLength / (innerRadius * Math.PI * 2));

        // Determine the starting point
        // We want the first wedge to be centered, so we measure the arc length and
        // project ourselves along the curve appropriately.
        if (sections.length == 0) {
            return;
        }

        var theta = (2 * Math.PI) * sections[0].amount;

        // Rotate theta to center that arc (move it negative half its length)
        theta -= theta / 2;

        sections.forEach( (section) => {
            // Calculate the arc length as it will be drawn
            let arcLength = (2 * Math.PI) * section.amount - padding;

            // Move around the circle by the given padding
            let innerTheta = theta;
            let innerLength = arcLength + padding - innerPadding;
            theta += padding / 2;
            innerTheta += innerPadding / 2;

            // Calculate points along the arc
            var x1 = left + radius + radius * Math.cos(theta);
            var y1 = top  + radius + radius * Math.sin(theta);

            var x2 = left + radius + radius * Math.cos(theta + arcLength);
            var y2 = top  + radius + radius * Math.sin(theta + arcLength);

            var x3 = left + radius + (innerRadius) * Math.cos(innerTheta + innerLength);
            var y3 = top  + radius + (innerRadius) * Math.sin(innerTheta + innerLength);

            var x4 = left + radius + (innerRadius) * Math.cos(innerTheta);
            var y4 = top  + radius + (innerRadius) * Math.sin(innerTheta);

            // Calculate the axis of rotation the arc takes
            let sweep = 0;
            if (arcLength > Math.PI) {
                sweep = 1;
            }

            // Create an arc of the calculated length
            let path = ["M", x1, y1,
                        "A", radius, radius, 0, sweep, 1, x2, y2,
                        "L", x3, y3,
                        "A", innerRadius, innerRadius, 0, sweep, 0, x4, y4,
                        "L", x1, y1].join(" ");
            var arc = document.createElementNS(JobDonut.SVGNS, "path");
            arc.setAttribute("d", path);
            arc.setAttribute("data-job-status", section.status);
            if (section.id) {
                arc.setAttribute("data-job-id", section.id);
            }

            // Add it to our SVG diagram
            svg.appendChild(arc);

            // Reposition to the next arc
            theta += arcLength;

            // Move around the circle by padding once more
            theta += padding / 2;
        });
    }

    updateJobs(jobs) {
        jobs.forEach( (job) => {
            var arc = this.svg.querySelector('path[data-job-id="' + job.id + '"]');
            if (arc) {
                arc.setAttribute("data-job-status", job.status);
            }
        });
    }
}

JobDonut.SVGNS = "http://www.w3.org/2000/svg";

export default JobDonut;
