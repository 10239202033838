"use strict";

import EventComponent from './event_component.js';
import Util           from './util.js';
import Search         from './search.js';
import Occam          from './occam.js';

/**
 * This class represents an object list, which is used for displaying the list of dependencies or resources on the details page.
 */
class ObjectList extends EventComponent {
    constructor(element) {
        super();

        if (element.getAttribute('data-object-list-index') == null) {
            ObjectList._count++;

            element.setAttribute('data-object-list-index', ObjectList._count);
            ObjectList._loaded[element.getAttribute('data-object-list-index')] = this;
        }

        // Retain the object list container element
        this.element = element;

        // Assume we are the parent list
        this._parentList = null;

        // Find all sibling sublists
        this._subLists = [];

        // If we aren't ourselves a sub-listing, find the others
        // We use this._subLists to alter links and disable buttons during
        // asynchronous edits to the object metadata.
        let card = this.element.parentNode.parentNode;
        if (!this.element.hasAttribute('data-module')) {
            let subListQuery = ".objects[data-module]";
            let listElements = card.querySelectorAll(subListQuery);

            // Load and instantiate the ObjectList for each sublist
            this._subLists = Array.prototype.map.call(listElements, (el) => {
                return ObjectList.load(el);
            });
        }
        else {
            // Find the parent list
            let listQuery = ".objects:not([data-module])";
            let listElement = card.querySelector(listQuery);

            // Load and instantiate the ObjectList for each sublist
            if (listElement) {
                this._parentList = ObjectList.load(listElement);
            }
        }

        // Get the object template
        this.template = element.querySelector("template");

        // Bind events
        this.bindEvents();
    }

    /**
     * Returns an instance of ObjectList for the given element.
     *
     * This will create an ObjectList, if it doesn't exist, for this element.
     *
     * @param {HTMLElement} element The main element for the object list;
     *                              typically the `<ul>` element.
     */
    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element;
        if (typeof element != "number") {
            index = element.getAttribute('data-object-list-index');
        }

        if (index) {
            return ObjectList._loaded[index];
        }

        return new ObjectList(element);
    }

    /**
     * Loads all object lists found within the given element.
     */
    static loadAll(element) {
        if (!element) {
            throw new TypeError("element argument required");
        }

        if (!element.tagName) {
            throw new TypeError("element must be an HTMLElement");
        }

        var lists = element.querySelectorAll('.objects-container ul.objects');
        lists.forEach( (element) => {
            ObjectList.load(element);
        });
    }

    /**
     * Attaches events to the element.
     */
    bindEvents() {
        this.element.querySelectorAll(":scope > li.object:not(.empty)").forEach( (subElement) => {
            this.bindItemEvents(subElement);
        });
    }

    /**
     * Attaches events to a list item.
     */
    bindItemEvents(element) {
        // Bind asynchronous deletion button
        let deleteButton = element.querySelector("button.remove");
        if (deleteButton) {
            deleteButton.addEventListener("click", (event) => {
                event.stopPropagation();
                event.preventDefault();

                this.remove(element);
            });
        }

        let button = element.querySelector("a, button");
        if (button) {
            button.addEventListener("click", (event) => {
                event.stopPropagation();

                if (element.getAttribute('href') === '#') {
                    event.preventDefault();
                }

                this.trigger('click', this.infoFor(element));
            });
        }
    }

    /**
     * Populates the object listing with the result of a search.
     */
    search(options = {}) {
        // Form the query
        let query = "";

        // By default, we are searching for objects
        let searchFunction = Search.perform;

        // Special case service listing
        if (options.service === "" || options.service) {
            options.query = options.service || "";
            searchFunction = Search.services;
        }

        // Perform the object search
        searchFunction(options).then( (data) => {
            console.log("result", data);

            // Clear the listing
            this.clear();

            // Form the object listing
            if (data.services) {
                data.services.forEach( (object) => {
                    this.append({
                        "type": "service",
                        "name": object.service,
                        "iconID": object.iconID,
                        "iconGroup": object.iconGroup
                    });
                });
            }
            else if (data.objects) {
                data.objects.forEach( (object) => {
                    this.append(object);
                });
            }
        });
    }

    /**
     * Updates the item element to reflect the given information.
     */
    update(entry, options) {
        ["id", "uid", "revision", "name", "relation", "source", "type"].forEach( (key) => {
            if (options[key]) {
                let subElement = entry.querySelector('p.' + key);
                if (subElement) {
                    subElement.textContent = options[key].trim();
                }
            }
        });

        if (!options.copy) {
            let copyElement = entry.querySelector("a.copy");
            if (copyElement) {
                copyElement.remove();
            }
        }

        if (!options.download) {
            let downloadElement = entry.querySelector("a.download");
            if (downloadElement) {
                downloadElement.remove();
            }
        }

        if (true || options.loading) {
            let iconElement = entry.querySelector("a:first-child > img.icon");
            if (iconElement) {
                iconElement.src = "/images/indicators/object.svg";
            }

            entry.classList.add("loading");
        }

        if (options.iconID) {
            let iconGroup = options.iconGroup || "objects";
            entry.querySelector("svg > use").setAttribute("xlink:href", "/images/symbol/" + iconGroup + ".svg#" + options.iconID);
        }
    }

    /**
     * Removes all entries in the list.
     */
    clear() {
        // Remove all elements
        this.element.innerHTML = "";
    }

    /**
     * Appends the given item to the object list at the given location.
     *
     * @param {Object} options - The information to use to describe the new object.
     * @param {number} atIndex - The index to position the new element. Defaults to -1, which appends to the end of the list.
     */
    append(options, atIndex = -1) {
        if (!this.template) {
            throw new Error("Cannot add a new element to this object list: no template to use.");
        }

        // Clone a node
        let dummy = this.template;
        let newElement = null;
        if ('content' in dummy) {
            newElement = document.importNode(dummy.content, true);
            newElement = newElement.querySelector("li.object");
        }
        else {
            newElement = dummy.querySelector("li.object").cloneNode(true);
        }

        if (atIndex < 0) {
            atIndex = this.length;
        }

        if (atIndex >= 0) {
            atIndex = Math.min(atIndex, this.length);
        }

        let anchor = this.elementFor(atIndex);
        if (!anchor) {
            anchor = this.element.querySelector(":scope > li.object.empty");
        }

        if (!anchor) {
            // Append to the list.
            this.element.appendChild(newElement);
        }
        else {
            this.element.insertBefore(newElement, anchor);
        }

        // Bind item events
        this.bindItemEvents(newElement);

        // Bind events.
        Occam.loadAll(newElement);

        // And fill in element with the given information.
        this.update(newElement, options);

        return newElement;
    }

    /** Removes the list item by either index or element.
     */
    remove(index_or_element) {
        let subElement = this.elementFor(index_or_element);

        // Trigger remove form, if it exists (and this isn't still loading)
        let deleteButton = subElement.querySelector("button.remove");
        if (deleteButton && !subElement.classList.contains("loading")) {
            let form = Util.getParents(deleteButton, "form", "form")[0];
            if (form) {
                // Update element to indicate the removal in-progress
                subElement.classList.add("removing");

                // Disable all other delete and add buttons
                this.disable();

                // Get the item's index
                let index = this.indexFor(subElement);

                // Submit the deletion request
                Util.submitForm(form, () => {
                    // Reform all other buttons assuming the deletion occurs
                    // That is, subtract one from their index if they are greater
                    // than the one being deleted. (Also check sub-sections)
                    this.fixup(index);

                    // Remove item from document
                    subElement.remove();

                    // Re-enable the list
                    this.enable();
                });
            }
        }
    }

    /**
     * Returns the logical index for the given element.
     *
     * This is the index of the item within the actual object metadata, if
     * available. Since sub-listings might exist which filter the objects into
     * categorical sets, the index of an item may not be its position within
     * the document. That is, the second item in a list might have an index
     * greater than 1.
     *
     * It determines the index by inspecting the data key.
     *
     * @param {HTMLElement} element - The object list element.
     *
     * @returns {number} The index of the element or -1 if it is unknown.
     */
    indexFor(element) {
        let index = -1;
        element.querySelectorAll("input").forEach( (input) => {
            let name = input.getAttribute('name');
            let match = name.match(/^data\[.+?\((\d+)\)\]$/);
            if (match) {
                index = parseInt(match[1]);
            }
        });
        return index;
    }

    /**
     * Updates the index referenced by the given object list element.
     *
     * This goes through and updates the fields within the <input> tags such
     * that they now refer to the given index.
     *
     * @param {HTMLElement} element - The object list element.
     * @param {number} index - The new index.
     */
    setIndexFor(element, index) {
        element.querySelectorAll("input").forEach( (input) => {
            let name = input.getAttribute('name');
            let match = name.match(/^([a-zA-Z_]+\[.+?)\((\d+)\)\]$/);
            if (match) {
                name = match[1] + "(" + index + ")]";
                input.setAttribute('name', name);
            }
        });
    }

    /**
     * Updates URLs by decrementing the index for any items greater than the given.
     *
     * @param {number} index - The index to check against.
     * @param {bool} fromParent - When true, it is called from the parent list.
     */
    fixup(index, fromParent = false) {
        // The parent list should handle it, if it exists
        if (this._parentList && !fromParent) {
            return this._parentList.fixup(index);
        }

        // For every element, update the index.
        this.element.querySelectorAll(":scope > li.object:not(.empty)").forEach( (subElement) => {
            let current = this.indexFor(subElement);
            if (current > index) {
                this.setIndexFor(subElement, current - 1);
            }
        });

        // Also do so for the sublists
        this._subLists.forEach( (subList) => {
            subList.fixup(index, true);
        });
    }

    /**
     * Disables the object list (and any sub-list)
     *
     * @param {bool} fromParent - When true, it is called from the parent list.
     */
    disable(fromParent = false) {
        // The parent list should handle it, if it exists
        if (this._parentList && !fromParent) {
            return this._parentList.disable();
        }

        this.element.querySelectorAll("button, a.modal").forEach( (button) => {
            button.classList.add('disabled-via-submit');
            button.setAttribute('disabled', '');
        });

        // Also do so for the sublists
        this._subLists.forEach( (subList) => {
            subList.disable(true);
        });
    }

    /**
     * Enables the object list (and any sub-list)
     *
     * @param {bool} fromParent - When true, it is called from the parent list.
     */
    enable(fromParent = false) {
        // The parent list should handle it, if it exists
        if (this._parentList && !fromParent) {
            return this._parentList.enable();
        }

        let query = "button.disabled-via-submit, .modal.disabled-via-submit";
        this.element.querySelectorAll(query).forEach( (button) => {
            button.classList.remove('disabled-via-submit');
            button.removeAttribute('disabled');
        });

        // Also do so for the sublists
        this._subLists.forEach( (subList) => {
            subList.enable(true);
        });
    }

    /**
     * Returns the number of items currently in the list.
     */
    get length() {
        return this.element.querySelectorAll(":scope > li.object:not(.empty)").length;
    }

    /**
     * Replaces a list item with the given element.
     */
    replace(index_or_element, newEntry) {
        var oldEntry = this.elementFor(index_or_element);
        this.element.replaceChild(newEntry, oldEntry);

        this.bindItemEvents(newEntry);
        Occam.loadAll(newEntry);

        return newEntry;
    }

    /**
     * Retrieves the list element for the given index.
     *
     * Returns null when the item cannot be found.
     */
    elementFor(index_or_element) {
        if (typeof index_or_element != "number" && typeof index_or_element != "string") {
            return index_or_element;
        }

        return this.element.querySelector(":scope > li.object:nth-of-type(" + (index_or_element + 1) + ")");
    }

    /**
     * Retrieves the information represented by the given index.
     */
    infoFor(index_or_element) {
        var entry = this.elementFor(index_or_element);

        let ret = {};

        ["id", "uid", "revision", "filename", "name", "relation", "source", "type"].forEach( (key) => {
            let subElement = entry.querySelector('p.' + key);
            if (subElement) {
                ret[key] = subElement.textContent.trim();
            }
        });

        return ret;
    }
}

ObjectList._count = 0;
ObjectList._loaded = {};

export default ObjectList;
