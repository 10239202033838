"use strict";

import EventComponent from "./event_component.js";
import Wire from "./wire.js";
import Util from "./util.js";

/**
 * This represents a component that reflects a "port" for a workflow node.
 *
 * A port is a possible connection between nodes. It represents a relationship
 * between nodes. Ports may be 'inputs' or 'outputs', in that they are tagged
 * as such. In these cases, it reflects a semantic relationship and may be
 * used this way to indicate the movement of work or data throughout the graph.
 *
 * When you connect a node to another, the person will create a Wire between
 * each port. Each node will have their own Port, represented visually by a dot.
 * A wire may be created by dragging from one node to another, for instance.
 *
 * The Port has a name and type. The types may be used to semantically relate
 * different ports or nodes. For instance, it can be established that some ports
 * only allow connections from a Port with the same type (or set of possible
 * types)
 */
class Port extends EventComponent {
    /**
     * Instantiate a Port from an existing element.
     *
     * @params {HTMLElement} element The element representing the Port.
     * @params {object} options The overall workflow options.
     */
    constructor(element, node, options) {
        super();

        this._element = element;
        this._node = node;
        this._options = options;

        this._wires = [];

        // Attach events so they don't move the Node
        element.addEventListener("mousedown", (event) => {
            event.stopPropagation();
            event.preventDefault();
        });

        element.addEventListener("mouseup", (event) => {
            if (options.allowNewConnections) {
                // Select the port (if disconnected especially)
                this.toggle();
            }
        });

        element.addEventListener("mouseenter", (event) => {
            this.trigger("mouseenter");
        });

        element.addEventListener("mouseleave", (event) => {
            this.trigger("mouseleave");
        });

        this._attachButton = element.querySelector(".attach-button");

        let hideWireButton = element.querySelector(".hide-wire-button");
        hideWireButton.addEventListener("mousedown", (event) => {
            // Prevent wire from unselecting itself (and thus hiding
            // this button).
            event.stopPropagation();
            event.preventDefault();
        });
        hideWireButton.addEventListener("mouseup", (event) => {
            this.visibility = "hidden";
            event.stopPropagation();
            event.preventDefault();
        });
    }

    /**
     * Returns whether or not the port is a ghost port.
     *
     * Ghost ports are fake ports that are attached to a dummy node that is
     * being dragged for a new wire.
     */
    get isGhost() {
        return this._element.classList.contains("ghost");
    }

    /**
     * Returns the Node this Port belongs to.
     */
    get node() {
        return this._node;
    }

    /**
     * Returns the index of this Port.
     *
     * @returns {Number} The index. The first port is at index 0.
     */
    get index() {
        return Util.getChildIndex(this.element, ".port");
    }

    /**
     * Returns the element that represents this Port.
     *
     * @returns {HTMLElement} The representative element.
     */
    get element() {
        return this._element;
    }

    /**
     * Returns the direction of the port. That is, the side of the node it is
     * on (left, right, top, bottom).
     */
    get direction() {
        return ["left", "top", "bottom", "right"].filter( (direction) => this.element.classList.contains(direction) )[0];
    }

    /**
     * Sets the side that the port is facing.
     *
     * @param {string} value The direction. Either "left", "right", "top" or "bottom".
     */
    set direction(value) {
        if (["left", "top", "bottom", "right"].indexOf(value) >= 0) {
            this.element.classList.remove("left");
            this.element.classList.remove("right");
            this.element.classList.remove("top");
            this.element.classList.remove("bottom");
            this.element.classList.add(value);
        }
        else {
            throw "direction " + value + " invalid";
        }
    }

    /**
     * Gets the Y position of the Port relative to the Node.
     */
    get top() {
        return parseInt(this.element.style.top);
    }

    /**
     * Gets the X position of the Port relative to the Node.
     */
    get left() {
        return parseInt(this.element.style.left);
    }

    /**
     * Based on the current status, select or unselect this Port.
     */
    toggle() {
        if (this.selected) {
            this.unselect();
        }
        else {
            this.select();
        }
    }

    /**
     * Whether or not this Port is currently selected.
     */
    get selected() {
        return this._element.classList.contains("selected");
    }

    /**
     * Selects this Port.
     */
    select() {
        this._element.classList.add("selected");
        this.trigger("select");
    }

    /**
     * Unselects this Port.
     */
    unselect() {
        this._element.classList.remove("selected");
        this.trigger("unselect");
    }

    /**
     * Redraws the wires for this Port.
     */
    redraw() {
        if (this._redrawing) {
            return;
        }

        // Do not allow recursive redraws
        this._redrawing = true;

        // Determine if we are showing only partially-visible nodes.
        let partiallyVisible = true;
        this._wires.forEach( (wire) => {
            let portB = wire.portStart;
            if (portB == this) {
                portB = wire.portEnd;
            }

            if (portB && portB.node) {
                let nodeB = portB.node;

                if (!nodeB.element.classList.contains("partially-visible")) {
                    partiallyVisible = false;
                }
            }
        });

        if (partiallyVisible) {
            this.element.classList.add("contains-only-partially-visible");
        }
        else {
            this.element.classList.remove("contains-only-partially-visible");
        }

        // If this port can be repositioned, do that first to find a good spot for it.
        this.reposition();

        // Move connected partially-hidden nodes
        this._nudgeNodes();

        // Redraw each wire
        this._wires.forEach( (wire) => {
            wire.redraw();
        });

        // We are no longer redrawing
        this._redrawing = false;
    }

    /*
     * Internal method that moves partially hidden nodes.
     */
    _nudgeNodes() {
        // Ensure that invisible nodes connected to us also move.
        // TODO: make sure that if multiple invisible nodes exist that they
        // are styled reasonably.
        this.wires.forEach( (wire) => {
            let portB = wire.portStart;

            if (portB === this) {
                portB = wire.portEnd;
            }

            if (portB && portB.node) {
                let wireY = (this.node.y + this.top) - portB.top;

                if (portB.node.visibility === "hidden") {
                    // TODO: handle top/bottom ports
                    portB.node.move(this.node.x - 150, wireY);
                }
            }
        });
    }

    /**
     * When the port can be moved to different sides, do so when appropriate.
     */
    reposition() {
        if (this.connectionType === "port" && this.element.getAttribute("data-auto-orientation") === "closest") {
            if (this._wires.length > 0) {
                let choices = {"left": 0, "right": 0, "top": 0, "bottom": 0};
                let influentialPorts = {};
                this._wires.forEach( (wire) => {
                    let otherPort = [wire.portStart, wire.portEnd].filter( (subPort) => subPort !== this )[0];

                    let width  = Math.abs(otherPort.node.x - this.node.x);
                    let height = Math.abs(otherPort.node.y - this.node.y);

                    let choice = null;

                    if (width > 100) {
                        if (otherPort.node.x > this.node.x) {
                            choice = "right";
                        }
                        else {
                            choice = "left";
                        }
                    }
                    else {
                        if (otherPort.node.y > this.node.y) {
                            choice = "bottom";
                        }
                        else {
                            choice = "top";
                        }
                    }

                    if (choice) {
                        choices[choice]++;
                        influentialPorts[choice] = otherPort;
                    }
                });

                let choice = 'left';
                Object.keys(choices).forEach( (key) => {
                    if (choices[key] > choices[choice]) {
                        choice = key;
                    }
                });
                this.direction = choice;

                if (influentialPorts[choice]) {
                    if (influentialPorts[choice].connectionType === "port") {
                        if (choice == "left") {
                            influentialPorts[choice].direction = "right";
                        }
                        else if (choice == "right") {
                            influentialPorts[choice].direction = "left";
                        }
                        else if (choice == "bottom") {
                            influentialPorts[choice].direction = "top";
                        }
                        else if (choice == "top") {
                            influentialPorts[choice].direction = "bottom";
                        }
                        influentialPorts[choice].node.rearrangePorts();
                        influentialPorts[choice].node.redraw();
                    }
                }
            }
        }
    }

    /**
     * Returns the list of Wire objects involved with this Port.
     */
    get wires() {
        return this._wires.slice();
    }

    /**
     * Returns the number of Wire objects connected to this Port.
     */
    get count() {
        return this._wires.length;
    }

    /**
     * Returns the maximum allowed Wire objects that can connect to this Port.
     *
     * @returns {Number} The maximum wires allowed. If -1, then there is no limit.
     */
    get max() {
        return this._max;
    }

    /**
     * Appends the given Wire to this Port as a starting point.
     */
    appendStart(wire) {
        if (!(wire.path.classList.contains("dummy"))) {
            this._wires.push(wire);
        }
        this.element.querySelector("ol.connections").appendChild(wire.elementStart);

        this.element.classList.remove("disconnected");

        this._maintainAttachButtonVisibility();
    }

    /**
     * Appends the given Wire to this Port as an endpoint.
     */
    appendEnd(wire) {
        this._wires.push(wire);
        this.element.querySelector("ol.connections").appendChild(wire.elementEnd);

        this.element.classList.remove("disconnected");

        this._maintainAttachButtonVisibility();
    }

    _maintainAttachButtonVisibility() {
        if (!this._options.allowNewConnections) {
            // Disable the attach button.
            this._attachButton.setAttribute("hidden", true);
        }
        else if (this.max != -1) {
            if (this.count >= this.max) {
                // Disable the attach button.
                this._attachButton.setAttribute("hidden", true);
            }
            else {
                this._attachButton.removeAttribute("hidden");
            }
        }
    }

    /**
     * Removes the given wire.
     */
    remove(wire) {
        let idx = this._wires.indexOf(wire);
        if (idx >= 0) {
            this._wires.splice(idx, 1);
        }

        if (this._wires.length == 0) {
            this.element.classList.add("disconnected");
        }

        this._maintainAttachButtonVisibility();
    }

    /**
     * Get the port's connection type.
     *
     * @returns {string} Either "input", "output", or "port" for a general port.
     */
    get connectionType() {
        let ret = ["inputs", "outputs", "ports"].filter( (portType) => this.element.parentNode.classList.contains(portType) )[0];

        if (ret) {
            ret = ret.substring(0, ret.length - 1);
        }

        return ret;
    }

    /**
     * Get the port's type.
     */
    get type() {
        if (!this._type) {
            let typeLabel = this.element.querySelector(":scope > .label > span.type");
            if (typeLabel) {
                this._type = typeLabel.textContent.trim();
            }
        }

        return this._type || "";
    }

    /**
     * Update the port's type.
     *
     * @params {string} value The new type for this port.
     */
    set type(value) {
        this._type = value;

        let typeLabel = this.element.querySelector(":scope > .label > span.type");
        if (typeLabel) {
            typeLabel.textContent = value;
        }
    }

    /**
     * Get the port name.
     */
    get name() {
        if (!this._name) {
            let nameLabel = this.element.querySelector(":scope > .label > span.name");
            if (nameLabel) {
                this._name = nameLabel.textContent.trim();
            }
        }

        return this._name || "";
    }

    /**
     * Update the port's name.
     *
     * @params {string} value The new name for this port.
     */
    set name(value) {
        this._name = value;

        let nameLabel = this.element.querySelector(":scope > .label > span.name");
        if (nameLabel) {
            nameLabel.textContent = value;
        }
    }

    /**
     * Whether or not this port is currently visible.
     */
    get visibility() {
        return this.element.style.visibility;
    }

    /**
     * Sets the visibility of this port.
     *
     * @params {string} value The visibility value, based on CSS: "visible" or "hidden".
     */
    set visibility(value) {
        this.element.style.visibility = value;
        if (value == "hidden") {
            this.element.setAttribute("hidden", true);
            this.element.setAttribute("aria-hidden", "true");
            this.unselect();
        }
        else {
            this.element.removeAttribute("hidden");
            this.element.removeAttribute("aria-hidden");
        }

        // Nudge connected nodes to check their own visibility
        this.wires.forEach( (wire) => {
            let portB = wire.portStart;

            if (portB === this) {
                portB = wire.portEnd;
            }

            // Ensure that if we hide this port, the node is hidden.
            if (portB) {
                if (portB.node) {
                    portB.node.visibility = portB.node.visibility;
                }

                if (value == "visible" && portB.visibility == "hidden") {
                    portB.visibility = "visible";
                }
            }
        });

        // Nudge our own node so it repositions such partially shown nodes
        if (this.node) {
            this.node.visibility = this.node.visibility;
            this.node.move(this.node.x, this.node.y);
        }

        // Trigger an event to reflect that it is hidden/shown
        // TODO: do not trigger if the state does not actually change
        this.trigger( (value == "hidden" ? "hide" : "show") );
    }

    /**
     * Updates the Port and its DOM representation based on the given JSON data.
     *
     * @params {object} json The JSON-derived data for this port.
     */
    fromJSON(json) {
        // Attach the type/name to the port
        this.name = json.name;
        this.type = json.type;

        this.visibility = json.visibility || "visible";

        this._max = json.max || -1;

        // Go through each connection

        // Mark it 'disconnected' if there are no wires
        if (!json.connections || json.connections.length == 0) {
            this.element.classList.add("disconnected");
        }
        else {
            this.element.classList.remove("disconnected");
        }
    }

    /**
     * Returns a JSON-ready serialization of this port.
     *
     * @returns {object} The JSON-ready representation.
     */
    toJSON() {
        let ret = {
            type:       this.type,
            name:       this.name,
        };

        if (this.visibility && this.visibility != "visible") {
            ret.visibility = this.visibility;
        }

        // Go through each connection
        ret.connections = this.wires.map( (wire) => {
            let portEnd = wire.portEnd;
            let wireIndex = wire.indexEnd;

            if (portEnd.element.isSameNode(this.element)) {
                portEnd = wire.portStart;
                wireIndex = wire.indexStart;
            }

            return {
                "to": {
                    "node": portEnd.node.index,
                    "port": portEnd.index,
                    "wire": wireIndex
                }
            };
        });

        return ret;
    }

    /**
     * Removes this port completely.
     */
    destroy() {
        this._wires.forEach( (wire) => wire.destroy() );
        this.element.remove();
    }

    /**
     * Creates the DOM element for a Port from the optional given port JSON
     * serialization.
     *
     * @param {object} json The JSON-sourced serialization.
     * @param {Node} node The Node this Port is being created within.
     * @param {object} options The general workflow options.
     *
     * @returns {Port} An instantiated Port.
     */
    static create(json, node, options) {
        let element = document.createElement("li");
        element.classList.add("port");

        var label = document.createElement("div");
        label.classList.add("label");
        element.appendChild(label);

        var typeLabel = document.createElement("span");
        typeLabel.classList.add("type");
        label.appendChild(typeLabel);

        var nameLabel = document.createElement("span");
        nameLabel.classList.add("name");
        label.appendChild(nameLabel);

        // Attach button
        let attachButton = document.createElement("div");
        attachButton.classList.add("attach-button");
        element.appendChild(attachButton);

        let hideWireButton = document.createElement("div");
        hideWireButton.classList.add("hide-wire-button");
        element.appendChild(hideWireButton);

        // Wires (the element part)
        let connections = document.createElement("ol");
        connections.classList.add("connections");
        element.appendChild(connections);

        let port = new Port(element, node, options);
        if (json) {
            port.fromJSON(json);
        }
        return port;
    }
}

export default Port;
