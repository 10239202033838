"use strict";

import EventComponent from "../event_component.js";
import Util           from "../util.js";
import Tabs           from "../tabs.js";

/**
 * This implements behaviors for the objects/new modal.
 */
class MetadataModal extends EventComponent {
    constructor(element) {
        super();
        console.log("metadata", this);

        if (element === undefined) {
            return;
        }

        this.element = element;

        // Find the errors section
        this.errorsList = this.element.parentNode.querySelector(".errors");

        // Find the button
        this.submitButton = this.element.querySelector('input[name="submit"]');

        // Tabs
        this.tabs = Tabs.load(this.element.querySelector("ul.tab-bar"));

        MetadataModal._count++;
        this.element.setAttribute('data-new-modal-index', MetadataModal._count);

        MetadataModal._loaded[this.element.getAttribute('data-new-modal-index')] = this;

        this.tabTemplate = this.element.querySelector("template.tab");
        this.tabPanelTemplate = this.element.querySelector("template.tab-panel");

        this.bindEvents();
    }

    static loadAll(element) {
        var elements = element.querySelectorAll('h1.records ~ .card.records');

        elements.forEach( (element) => {
            MetadataModal.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-edit-modal-index');

        if (index) {
            return MetadataModal._loaded[index];
        }

        return new MetadataModal(element);
    }

    bindEvents() {
        // Bind to the 'add' button
        this.element.querySelectorAll("li.record > a.add").forEach( (addButton) => {
            addButton.addEventListener('click', (event) => {
                // Do not redirect to the edit page
                event.preventDefault();
                event.stopPropagation();

                // Get the format
                let format = addButton.parentNode.getAttribute('data-record-key');
                let name = addButton.previousElementSibling.textContent;

                // Open the edit page in a tab in this view, if it isn't already there
                let existingTab = this.tabs.element.querySelector(`li.tab[data-record-key="${format}"]`);
                if (existingTab) {
                    this.tabs.select(existingTab);
                }
                else {
                    // Create the tab from a template and select it to load the content
                    let tab = Util.createElementFromTemplate(this.tabTemplate);
                    let tabPanel = Util.createElementFromTemplate(this.tabPanelTemplate);


                    // Modify the 'tab' to point to the requested format
                    let a = tab.querySelector("a");
                    let href = a.getAttribute('href');
                    href = href.replace("/format/edit", `/${format}/edit`);
                    a.setAttribute('href', href);
                    tab.setAttribute("data-record-key", format);

                    // Modify tab to have the format name
                    a.textContent = name;

                    // Add the tab
                    tab = this.tabs.addTab([tab, tabPanel]);
                    this.tabs.select(tab);
                }
            });
        });
    }
}

MetadataModal._count  = 0;
MetadataModal._loaded = {};

export default MetadataModal;
