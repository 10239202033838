"use strict";

/**
 * Extending this class allows a component to provide event handling and
 * callbacks.
 */
class EventComponent {
    constructor() {
        this._events = {};
    }

    /**
     * A generic event callback.
     *
     * @callback eventCallback
     * @param {data} Any event data.
     */

    /**
     * Sets a callback for the given event.
     *
     * @param {string} name The name of the event.
     * @param {eventCallback} callback The callback function.
     */
    on(name, callback) {
        if (callback === undefined) {
          return this._events[name];
        }

        var eventName = name;
        var componentName = "__global__";

        if (name.includes(".")) {
            var parts = name.split(".");
            eventName = parts[0];
            componentName = parts[1];
        }

        if (!(eventName in this._events)) {
            this._events[eventName] = {};
        }
        this._events[eventName][componentName] = callback;

        return this;
    }

    /**
     * Removes a callback for the given event.
     *
     * @param {string} name The name of the event.
     */
    off(name) {
        var eventName = name;
        var componentName = "__global__";

        if (name.includes(".")) {
            var parts = name.split(".");
            eventName = parts[0];
            componentName = parts[1];
        }

        if (this._events[eventName] && this._events[eventName][componentName]) {
            delete this._events[eventName][componentName];
        }
        if (eventName in this._events && Object.keys(this._events[eventName]).length == 0) {
            delete this._events[eventName];
        }
        return this;
    }

    /**
     * Triggers an event of the given event type.
     *
     * @param {string} name The name of the event.
     * @param {any} data The data to send along with the event.
     */
    trigger(name, data) {
        if (this._events[name]) {
          Object.keys(this._events[name]).forEach( componentName => this._events[name][componentName].call(this, data) );
        }

        return this;
    }

    /**
     * Returns the @eventCallback of the given event name if it has been
     * registered.
     *
     * @param {string} name The name of the event.
     *
     * @returns {eventCallback}
     */
    callbackFor(name) {
        if (!this._events[name]) {
            return null;
        }

        return this._events[name]["__global__"];
    }
}

export default EventComponent;
