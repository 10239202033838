"use strict";

import EventComponent from "./event_component.js";

/**
 * This class represents the SVG layer.
 */
class SVGPlane extends EventComponent {
    /**
     * Instantiates a representation of the given <svg> via element.
     *
     * @param {HTMLElement} element The `<svg>` element.
     * @param {object} options The set of workflow widget options.
     */
    constructor(element, options) {
        super();

        this.options = options;

        this._element = element;

        this._element.style.position = "absolute";
        this._element.style["transform-origin"] = "top left";
        this._element.style.left = 0;
        this._element.style.top  = 0;

        this._element.setAttribute("height", 10 + "px");
        this._element.setAttribute("width", 10 + "px");
        this._element.style.transform = "scale(" + 1.0 + ")";
    }

    /**
     * Returns the <svg> element represented by this plane.
     */
    get element() {
        return this._element;
    }

    /**
     * Returns the width of the plane.
     */
    get width() {
        return parseInt(this._element.getAttribute("width"));
    }

    /**
     * Updates the width of the plane.
     */
    set width(width) {
        this._element.setAttribute("width", this.state.width + "px");
    }

    /**
     * Returns the height of the plane.
     */
    get height() {
        return parseInt(this._element.getAttribute("height"));
    }

    /**
     * Updates the height of the plane.
     */
    set height(height) {
        this._element.setAttribute("height",  this.state.height + "px");
    }

    /**
     * Adds the given DOM (namely SVG) element to the SVG.
     *
     * @param {HTMLElement} child The element to add.
     */
    appendChild(child) {
        return this._element.appendChild(child);
    }

    /**
     * Removes the given DOM (namely SVG) element from the SVG.
     *
     * @param {HTMLElement} child The element to remove.
     */
    removeChild(child) {
        return this._element.removeChild(child);
    }

    /**
     * Creates an <svg> element and returns an instantiation of this plane.
     *
     * @param {object} options The set of workflow widget options.
     * @returns {SVGPlane} The instantiated plane.
     */
    static create(options) {
        let element = document.createElementNS(SVGPlane.SVGNS, "svg");
        return new SVGPlane(element, options);
    }
}

SVGPlane.SVGNS = "http://www.w3.org/2000/svg";

export default SVGPlane;
