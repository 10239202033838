"use strict";

import EventComponent  from './event_component.js';
import Tabs            from './tabs.js';

class Markdown extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            return;
        }

        if (Markdown.renderer == undefined) {
            Markdown.renderer = new window.Showdown.Converter();
        }

        this.element = element;

        this.input = element.querySelector("textarea.markdown");
        this.simpleMDE = new window.SimpleMDE({
            element: this.input,
            autoDownloadFontAwesome: false,
            autoFocus: true,
            hideIcons: ["guide", "preview"],
            toolbar: false
        });
        this.preview = element.querySelector(".markdown-preview");
        this.tabs = Tabs.load(element.querySelector("ul.tabs"));

        Markdown._count++;
        this.element.setAttribute('data-markdown-index', Markdown._count);

        Markdown._loaded[this.element.getAttribute('data-markdown-index')] = this;

        this.bindEvents();
    }

    static loadAll(element) {
        var elements = element.querySelectorAll('.markdown-editor');

        elements.forEach(function(element) {
            Markdown.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-markdown-index');

        if (index) {
            return Markdown._loaded[index];
        }

        return new Markdown(element);
    }

    /**
     * Renders the current editor content to the preview pane.
     */
    updatePreview() {
        this.preview.innerHTML = this.html;
    }

    /**
     * Retrieves the html of the rendered markdown.
     */
    get html() {
        let value = this.input.value;
        if (this.simpleMDE) {
            value = this.simpleMDE.value();
        }
        return Markdown.renderer.makeHtml(value);
    }

    /**
     * Retrieves markdown from the given HTML.
     *
     * @param {String} html - The HTML to parse into markdown.
     *
     * @returns {String} The rough markdown equivalent of the given HTML.
     */
    static markdownFrom(html) {
        if (Markdown.renderer == undefined) {
            Markdown.renderer = new window.Showdown.Converter();
        }

        return Markdown.renderer.makeMarkdown(html).trim();
    }

    /**
     * Brings up the preview pane.
     */
    showPreview() {
        this.tabs.select(1);
    }

    /**
     * Internal method that attaches interaction events to relevant elements.
     */
    bindEvents() {
        this.tabs.on('change', (index) => {
            if (index == 1) {
                // Disable toolbar
                this.tabs.element.querySelectorAll("button.button").forEach( (el) => {
                    el.setAttribute('disabled', '');
                });

                // Update preview
                this.updatePreview();
            }
            else {
                // Enable toolbar
                this.tabs.element.querySelectorAll("button.button").forEach( (el) => {
                    el.removeAttribute('disabled');
                });

            }
        });

        // Bind events to the buttons on the tab bar
        [["button-bold", this.simpleMDE.toggleBold],
         ["button-italic", this.simpleMDE.toggleItalic],
         ["button-header", this.simpleMDE.toggleHeadingSmaller],
         ["button-quote", this.simpleMDE.toggleBlockquote],
         ["button-ul", this.simpleMDE.toggleUnorderedList],
         ["button-ol", this.simpleMDE.toggleOrderedList],
         ["button-code", this.simpleMDE.toggleCodeBlock],
         ["button-link", this.simpleMDE.drawLink], 
         ["button-image", this.simpleMDE.drawImage]].forEach( (info) => {
             let name = info[0];
             let func = info[1];
             let button = this.element.querySelector("button.button." + name);
             if (button) {
                 button.addEventListener("click", (event) => {
                     func.bind(this.simpleMDE)();
                 });
             }
         });

        this.simpleMDE.codemirror.on("changes", (_) => {
            this.trigger('change');
        });
    }
}

Markdown._count  = 0;
Markdown._loaded = {};

export default Markdown;
