"use strict";

import Occam from './occam/occam.js';
import ConfigurationValidator from './occam/configuration_validator.js';

document.body.classList.remove('no-javascript');

window.TogetherJSConfig_cloneClicks = true;

// Focus rings only visually happen when you explicitly start tabbing through the site.
let focusRingTrigger = (event) => {
    if (event.keyCode == 9) {
        document.body.classList.add('tabbing');
        window.removeEventListener('keydown', focusRingTrigger);
    }
};

window.addEventListener('keydown', focusRingTrigger);

// Blur detect
window.addEventListener('blur', (event) => {
    window.document.body.classList.add("blurred");
});

window.addEventListener('focus', (event) => {
    window.document.body.classList.remove("blurred");
});

// Pointer-lock detect
// Ideally, I could detect pointer-lock within iframes... but nah.
// I guess no Google/Facebook/etc site needs it, so it does not exist.
// Sandboxing only seems to be targetting advertising... seems bad to sandbox for the sake of capital and ignore play.
let pointerLockEvent = (event) => {
    let pointerLockElement = event.pointerLockElement || event.webkitPointerLockElement || event.mozPointerLockElement;

    if (pointerLockElement) {
        window.document.body.classList.add("pointer-locked");
    }
    else {
        window.document.body.classList.remove("pointer-locked");
    }
};

window.addEventListener('pointerlockchange', pointerLockEvent);
window.addEventListener('mozpointerlockchange', pointerLockEvent);
window.addEventListener('webkitpointerlockchange', pointerLockEvent);

Occam.loadAll(window.document.body);
ConfigurationValidator.initialize();

window.addEventListener('resize', (event) => {
    Occam.resize(document.body, event);
});

// We should focus on the main card, if it exists
let mainPanel = document.body.querySelector(".card-tabs ~ ul.tab-panels > li.tab-panel.active > .card.container > .card.filled.flex," +
                                            ".card-tabs ~ ul.tab-panels > li.tab-panel.active > .card:not(.container).filled.flex," +
                                            ".card-tabs ~ .container > ul.tab-panels > li.tab-panel.active > .card.container > .card.filled.flex," +
                                            ".card-tabs ~ .container > ul.tab-panels > li.tab-panel.active > .card:not(.container).filled.flex," +
                                            ".card-tabs ~ ul.tab-panels > li.tab-panel.active," +
                                            ".card-tabs ~ .container > ul.tab-panels > li.tab-panel.active");
if (mainPanel) {
    mainPanel.setAttribute('tabindex', '0');
    mainPanel.focus();
}

export default Occam;
