"use strict";

import EventComponent  from './event_component.js';
import Util            from './util.js';
import Tabs            from './tabs.js';
import Occam           from './occam.js';

class SocialPanel extends EventComponent {
    constructor(element) {
        super();

        // element is the .social.sidebar on the details page
        if (element === undefined) {
            return;
        }

        this.element = element;

        SocialPanel._count++;
        this.element.setAttribute('data-social-index', SocialPanel._count);

        SocialPanel._loaded[this.element.getAttribute('data-social-index')] = this;

        this.bindEvents();
        this.events = {};
    }

    static loadAll(element) {
        var elements = element.querySelectorAll('.card.social');

        elements.forEach(function(element) {
            SocialPanel.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-social-index');

        if (index) {
            return SocialPanel._loaded[index];
        }

        return new SocialPanel(element);
    }

    show() {
        this.element.parentNode.classList.add("reveal");
    }

    hide() {
        this.element.parentNode.classList.remove("reveal");
    }

    bindEvents() {
        // Get the edit form and reply form
        this.editForm  = this.element.querySelector("form.edit-field");
        this.replyForm = this.element.querySelector("form.comment-field");
        this.mainList  = this.element.querySelector(".card > ul.comments");

        // Bind panel events
        this.bindPanelEvents();
    }

    bindPanelEvents() {
        // Bind events for every comment
        this.element.querySelectorAll('li.comment').forEach( (comment) => {
            this.bindComment(comment);
        });

        if (this.replyForm) {
            this.replyForm.querySelector("input[type=\"submit\"]").disabled = true;

            var textarea = this.replyForm.querySelector("textarea");
            var queryButtonStatus = (event) => {
                var button = this.replyForm.querySelector("input[type=\"submit\"]");
                button.disabled = !(textarea.value);
            };
            textarea.addEventListener("keyup",  queryButtonStatus);
            textarea.addEventListener("change", queryButtonStatus);

            this.replyForm.addEventListener("submit", (event) => {
                event.stopPropagation();
                event.preventDefault();

                this.replyForm.querySelector("input[type=\"submit\"]").disabled = true;

                // Submit the form
                Util.submitForm(this.replyForm, (html) => {
                    // Add the new comment to the list
                    var node = document.createElement("div");
                    node.innerHTML = html;
                    var newElement = node.querySelector("li.comment");
                    this.mainList.appendChild(newElement);
                    this.bindComment(newElement);
                });

                this.replyForm.querySelector("textarea").value = "";
            });
        }
    }

    showReplyBox(commentID, element) {
        if (this.replyForm && !element.querySelector(':scope > .comment-body + form')) {
            var newForm = this.replyForm.cloneNode(true);
            newForm.removeAttribute("id");
            newForm.removeAttribute("hidden");
            newForm.querySelector(".dropdown-menu").remove();
            newForm.classList.add("reply-box");
            newForm.querySelector('input[name="inReplyTo"]').setAttribute("value", commentID);
            newForm.querySelector('label').setAttribute("for", commentID + "-post-anonymous");
            newForm.querySelector('input[type="checkbox"]').setAttribute("id", commentID + "-post-anonymous");
            element.insertBefore(newForm, element.querySelector('.comment-body + .replies.comments'));
            newForm.classList.add("revealed");

            // Remove RSS feed link
            let rssFeedLink = newForm.querySelector('a.feed');
            if (rssFeedLink) {
                rssFeedLink.remove();
            }

            var textarea = newForm.querySelector("textarea");
            var queryButtonStatus = (event) => {
                var button = newForm.querySelector("input[type=\"submit\"]");
                button.disabled = !(textarea.value);
            };
            textarea.addEventListener("keyup",  queryButtonStatus);
            textarea.addEventListener("change", queryButtonStatus);

            newForm.addEventListener("submit", (event) => {
                event.stopPropagation();
                event.preventDefault();

                newForm.querySelector("input[type=\"submit\"]").disabled = true;

                // Submit the form
                Util.submitForm(newForm, (html) => {
                    newForm.remove();
                    // Add the new comment to the list
                    var node = document.createElement("div");
                    node.innerHTML = html;
                    var newElement = node.querySelector("li.comment");
                    var replies = element.querySelector(':scope > .comment-body ~ .replies.comments');
                    replies.appendChild(newElement);
                    this.bindComment(newElement);
                });

                newForm.querySelector("textarea").value = "";
            });
        }
    }

    showEditBox(commentID, element) {
        if (this.editForm && !element.querySelector(':scope > .comment-body + form')) {
            var newForm = this.editForm.cloneNode(true);
            newForm.removeAttribute("id");
            newForm.removeAttribute("hidden");
            newForm.classList.add("edit-box");
            newForm.setAttribute("action", newForm.getAttribute("action") + commentID);
            element.insertBefore(newForm, element.querySelector('.comment-body + .replies.comments'));
            newForm.classList.add("revealed");
            newForm.querySelector(':scope > textarea').value = element.querySelector('.comment-body > .content').innerText;

            newForm.addEventListener("submit", (event) => {
                event.stopPropagation();
                event.preventDefault();

                // Submit the form
                Util.submitForm(newForm, (html) => {
                    newForm.remove();

                    // Update with the new comment
                    var node = document.createElement("div");
                    node.innerHTML = html;
                    var newElement = node.querySelector("li.comment");
                    element.parentNode.replaceChild(newElement, element);
                    this.bindComment(newElement);
                });
            });
        }
    }

    showMoreReplies(commentID, element) {
        var replies = element.querySelector('.comment-body ~ .replies.comments');
        var link = element.querySelector('.comment-body ~ .replies.comments + span > .show-replies');
        var url = link.getAttribute("href");
        replies.classList.add("loading");
        link.parentNode.remove();
        Util.get(url, (html) => {
            replies.classList.remove("loading");
            replies.innerHTML = html;
            element.appendChild(replies.firstChild);
            replies.remove();
            element.querySelectorAll('li.comment').forEach( (comment) => {
                this.bindComment(comment);
            });
        });
    }

    bindComment(element) {
        var commentID = element.getAttribute('id').substring(8);

        // Bind the show more comments link.
        var showMoreLink = element.parentNode.querySelector(".comment#comment-" + commentID + " > .comment-body ~ .replies.comments + span > .show-replies");
        if (showMoreLink) {
            showMoreLink.addEventListener("click", (event) => {
                event.preventDefault();
                event.stopPropagation();

                this.showMoreReplies(commentID, element);
            });
        }

        // Bind the 'reply' link
        var replyLink = element.parentNode.querySelector(".comment#comment-" + commentID + " > .comment-body > span.actions > .reply-link");
        if (replyLink) {
            replyLink.addEventListener("click", (event) => {
                event.preventDefault();
                event.stopPropagation();

                this.showReplyBox(commentID, element);
            });
        }

        // Bind the 'edit' link
        var editLink = element.parentNode.querySelector(".comment#comment-" + commentID + " > .comment-body > span.actions > .edit-link");
        if (editLink) {
            editLink.addEventListener("click", (event) => {
                event.preventDefault();
                event.stopPropagation();

                this.showEditBox(commentID, element);
            });
        }

        // Bind the 'delete' action
        var deleteForm = element.querySelector("form.delete-form");
        if (deleteForm) {
            deleteForm.addEventListener("submit", (event) => {
                event.stopPropagation();
                event.preventDefault();

                // Submit the form
                Util.submitForm(deleteForm, (html) => {
                    // Add the new comment body to the list.
                    // It will reflect that it has been deleted.
                    var node = document.createElement("div");
                    node.innerHTML = html;
                    var newElement = node.querySelector(".comment-body");
                    element.replaceChild(newElement, element.querySelector(".comment-body"));
                    this.bindComment(newElement.parentNode);
                });
            });
        }
    }
}

SocialPanel._count  = 0;
SocialPanel._loaded = {};

export default SocialPanel;
