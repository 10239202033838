"use strict";

import EventComponent  from './event_component.js';

/**
 * This represents a navigation list which is used to navigate within documentation pages.
 */
class NavigationList extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            throw new TypeError("element is required");
        }

        if (!element.tagName) {
            throw new TypeError("element must be an HTMLElement");
        }

        this.element = element;

        // Find navigating context
        let navigatesId = element.getAttribute('data-navigates');
        this.navigating = document.body.querySelector('#' + navigatesId);

        // Bind events
        this.bindEvents();

        NavigationList._count++;
        this.element.setAttribute('data-nav-list-index', NavigationList._count);
        NavigationList._loaded[this.element.getAttribute('data-nav-list-index')] = this;
    }

    /**
     * Returns an instance of NavigationList for the given element.
     *
     * This will create a NavigationList, if it doesn't exist, for this element.
     *
     * @param {HTMLElement} element The main element for the navigation list;
     *                              typically the `<nav>` element.
     */
    static load(element) {
        if (!element) {
          return null;
        }

        var index = element.getAttribute('data-nav-list-index');

        if (index) {
          return NavigationList._loaded[index];
        }

        return new NavigationList(element);
    }

    /**
     * Initializes all navigation lists found within the given element.
     *
     * @param {HTMLElement} element The root element to search for appropriate elements within.
     */
    static loadAll(element) {
        var elements = element.querySelectorAll('nav.navigation-list');

        elements.forEach(function(element) {
            NavigationList.load(element);
        });
    }

    /**
     * Establishes, visually, the active navigation entry by looking at the scroll position.
     */
    determineActiveNavButton() {
        let activeElement = null;
        let currentElement = null;

        if (this.navigating) {
            Array.prototype.slice.call(this.element.querySelectorAll("li a")).reverse().forEach( (link) => {
                let href = link.getAttribute('href');

                let id = null;
                if (href.includes('#')) {
                    id = link.getAttribute("href").split("#")[1];
                }

                let sectionElement = (id ?
                    this.navigating.querySelector("#" + id) :
                    this.navigating.querySelector(":scope > .card:first-child"));

                // We want to get the position of the parent card which holds the subheader anchor.
                if (sectionElement && id) {
                    sectionElement = sectionElement.parentNode;
                }

                if (sectionElement) {
                    if (link.parentNode.classList.contains("active")) {
                        currentElement = link;
                    }

                    // If we are now scrolled passed the top, we set the activeElement.
                    if (this.navigating.scrollTop >= sectionElement.offsetTop) {
                        // We want the first element that is above the scroll top position.
                        // This is why we reverse the list above and look at elements that
                        //   are at the bottom first.
                        activeElement = activeElement || link;
                    }
                }
            });

            if (this.navigating.scrollTop + this.navigating.offsetHeight >= this.navigating.scrollHeight) {
                // End of the document... select the last list element
                activeElement = this.element.querySelector("li:last-of-type a");
            }

            // If the active element changed, update the list.
            if (activeElement != currentElement) {
                if (currentElement) {
                    currentElement.parentNode.classList.remove("active");
                }
                if (activeElement) {
                    activeElement.parentNode.classList.add("active");
                }
            }
        }
    }

    /**
     * Attaches all relevant events to the list.
     */
    bindEvents() {
        this.determineActiveNavButton();

        if (this.navigating) {
            this.navigating.addEventListener("scroll", (event) => {
                if (this._ignoreScroll) {
                    this._ignoreScroll = false;
                    return;
                }
                this.determineActiveNavButton();
            });
        }

        // We also want to activate the list items by the page ID and clicking on them.
        this.element.querySelectorAll("li a").forEach( (link) => {
            link.addEventListener('click', (event) => {
                let href = link.getAttribute('href');

                this._ignoreScroll = true;

                if (!href.includes('#')) {
                    // Scroll to the top
                    if (this.navigating) {
                        this.navigating.scrollTop = 0;
                    }

                    // Do not follow the link normally
                    event.stopPropagation();
                    event.preventDefault();
                }

                // Deactivate other options.
                this.element.querySelectorAll("li.active").forEach( (item) => {
                    item.classList.remove('active');
                });

                // Activate this option.
                link.parentNode.classList.add('active');
            });
        });
    }
}

NavigationList._count = 0;
NavigationList._loaded = {};

export default NavigationList;
