"use strict";

import EventComponent from './event_component.js';
import Util           from './util.js';
import Occam          from './occam.js';
import Configuration  from './configuration.js';

/**
 * This class handles the configuration panel that appears in the workflow
 * editor.
 *
 * This panel contains one or more Configuration forms which can be viewed or
 * edited per node of the workflow.
 */
class ConfigurationPanel extends EventComponent {
    constructor(element) {
        super();

        ConfigurationPanel._count++;

        this.element = element;

        this.element.setAttribute('data-configuration-panel-index', ConfigurationPanel._count);
        ConfigurationPanel._loaded[this.element.getAttribute('data-configuration-panel-index')] = this;

        this.bindEvents();
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-configuration-panel-index');

        if (index) {
            return ConfigurationPanel._loaded[index];
        }

        return new ConfigurationPanel(element);
    }

    static loadAll(element) {
        var panels = element.querySelectorAll('.configuration-panel');

        var ret = [];
        panels.forEach(function(subElement) {
            var panel = ConfigurationPanel.load(subElement);
            ret.push(panel);
        });

        return ret;
    }

    bindEvents() {
        this.element.addEventListener('keypress', (event) => {
            event.stopPropagation();
        });

        this.element.addEventListener('keydown', (event) => {
            event.stopPropagation();
        });
    }

    /**
     * Creates the panel for the given object.
     */
    loadConfiguration(index, workflowObject, object) {
        // Update headers for the given type/name

        // Create a pane for the configuration.
        //   (Other objects will preserve their contents via the node index)
        var pane = this.element.querySelector('li.pane[data-node-index="' + index + '"]');
        if (!pane) {
            pane = document.createElement("li");
            pane.classList.add("pane");
            pane.classList.add("card");
            pane.classList.add("loading");
            pane.setAttribute("data-node-index", index);
            this.element.querySelector("ul.panes").appendChild(pane);

            // Dynamically load the configuration tabs
            var url = workflowObject.url({ path: "/configure-node/" + index });
            Util.get(url, {
                onload: (html) => {
                    // Update the pane to show this configuration
                    pane.classList.remove("loading");
                    pane.innerHTML = html;

                    Occam.loadAll(pane);

                    // Load each configuration object and bind for change
                    let configurations = Configuration.loadAll(pane);
                    configurations.forEach( (configuration) => {
                        configuration.on("change", (data) => {
                            this.trigger("change", {
                                "configuration": configuration,
                                "data": data
                            });
                        });
                    });
                }
            });
        }

        var active = this.element.querySelector('li.pane.active');
        if (active && active != pane) {
            active.classList.remove("active");
        }

        pane.classList.add("active");
    }
}

ConfigurationPanel._count = 0;
ConfigurationPanel._loaded = {};

export default ConfigurationPanel;
