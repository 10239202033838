"use strict";

import EventComponent from './event_component.js';
import Tabs           from './tabs.js';
import Selector       from './selector.js';
import Runner         from './runner.js';

/**
 * This class manages the viewer object selector.
 *
 * The Runner class still manages the runtime of these viewers.
 */
class Viewer extends EventComponent {
    constructor(element) {
        super();

        this.element = element;

        Viewer._count++;
        this.element.setAttribute('data-viewer-index', Viewer._count);
        Viewer._loaded[this.element.getAttribute('data-viewer-index')] = this;

        // Find the tab strip, which lets us handle the sidebar button
        this.tabs = Tabs.load(this.element);

        // Find the sidebar panel for the queue
        this.sidebar = this.element.nextElementSibling.querySelector("nav.sidebar.right");

        // Get the Runner instance that handles running the object
        this.runner = Runner.load(this.element.nextElementSibling.querySelector(".run-viewer"));

        // Find the viewer selector
        //this.viewerSelector = Selector.load(this.element.querySelector("select.selector.viewer-object"));

        this.viewButton       = this.element.querySelector(".button.view");
        this.configureButton  = this.element.querySelector(".button.configure");
        this.fullscreenButton = this.element.querySelector(".button.fullscreen");

        this.bindEvents();
    }

    static loadAll(element) {
        var viewers = element.querySelectorAll('.object-viewer-info');

        viewers.forEach( (subElement) => {
            Viewer.load(subElement);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-viewer-index');

        if (index) {
            return Viewer._loaded[index];
        }

        return new Viewer(element);
    }

    toggleQueue() {
        this.sidebar.classList.toggle("reveal");
    }

    showQueue() {
        this.sidebar.classList.remove("reveal");
    }

    hideQueue() {
        this.sidebar.classList.add("reveal");
    }

    bindEvents() {
        if (this.tabs && this.sidebar) {
            this.tabs.on("sidebar.viewer", (sidebarButton) => {
                if (sidebarButton.classList.contains("configure")) {
                    //this.runner.runList.trigger("action-configure", this.runner.runList.selected());
                }
            });
        }

        if (this.runner && this.runner.runList) {
            this.runner.runList.on("change.viewer", (item) => {
                if (item.classList.contains("queue")) {
                    var sidebarButton = this.tabs.element.querySelector(".sidebar.configure");
                    if (sidebarButton) {
                        sidebarButton.setAttribute("disabled", "");
                    }
                }
                else {
                    var sidebarButton = this.tabs.element.querySelector(".sidebar.configure");
                    if (sidebarButton) {
                        if (item.hasAttribute("data-configurable")) {
                            sidebarButton.removeAttribute("disabled");
                        }
                        else {
                            sidebarButton.setAttribute("disabled", "");
                        }
                    }
                }

                // Update the viewer dropdown
                let info = this.runner.runList.infoFor(item);

                if (info.status === "viewing") {
                    let name = info.name || "unknown";
                    let type = info.type || "object";

                    let data = {
                        type: type,
                        name: name,
                        attributes: {
                            'data-viewer-id': info.viewerID
                        }
                    };

                    this._suppressViewerSelectorEvent = true;
                    /*
                    let viewerItem = this.viewerSelector.select(data);
                    if (!viewerItem) {
                        // Add it to the list, and select it
                        data.selected = true;
                        this.viewerSelector.append(data);
                    }
                    else {
                        // Update any new information
                        let oldData = this.viewerSelector.infoFor(viewerItem);
                        let newData = Object.assign({}, oldData);
                        newData = Object.assign(newData, data);
                        this.viewerSelector.update(viewerItem, newData);
                    }
                    */
                    this._suppressViewerSelectorEvent = false;
                }
            });
        }

        if (this.viewerSelector) {
            this.viewerSelector.on("change", (item) => {
                if (this._suppressViewerSelectorEvent) {
                    return;
                }

                // Replace the current viewer.
                var data = this.viewerSelector.infoFor(item);

                // We can get the viewer id and use that
                let viewerID = data.attributes['data-viewer-id'];

                // Find the entry in the RunList, or go to the queue and
                // replace the Using section in the RunForm.
                let runListItem = this.runner.runList.select({
                    viewerID: viewerID
                });

                // If there is no active running item, we go to the queue and
                // present the option of starting such a viewer in a RunForm.
                if (!runListItem) {
                    // When the queue is loaded, update the viewer
                    this.runner.on('panel-loaded.viewer', () => {
                        this.runner.off('panel-loaded.viewer');

                        if (data.attributes['data-autorun']) {
                            this.runner.runForm.on('ready.viewer', () => {
                                this.runner.runForm.off('ready.viewer');
                                this.runner.runForm.run();
                            });
                        }

                        let task = this.runner.runForm.itemAt(1);
                        this.runner.runForm.update(task, data);
                    });

                    // Go to the queue tab
                    this.runner.runList.select(0);
                }
            });
        }

        if (this.fullscreenButton) {
            this.fullscreenButton.addEventListener("click", (event) => {
                event.preventDefault();

                this.runner.runList.trigger("action-fullscreen", this.runner.runList.selected());
            });
        }
    }
}

Viewer._loaded = {};
Viewer._count  = 0;

export default Viewer;
