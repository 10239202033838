"use strict";

import EventComponent from './event_component.js';
import OccamObject    from './occam_object.js';
import Util           from './util.js';

/**
 * This represents a text input that can auto complete using the search
 * capability. It will render icons specific to certain Occam objects and
 * people/accounts on the system.
 *
 * Generally, you instantiate an AutoComplete using AutoComplete.load(element)
 * instead of invoking this directly.
 *
 * @param {HTMLElement} element The main element for the auto complete;
 *                              typically the `<input>` element.
 */
class AutoComplete extends EventComponent {
    constructor(element) {
        super();

        this.element = element;
        this.objectTypeSelector = null;
        this.search = null;

        var typeSelector = this.element.parentNode.querySelector('.auto-complete.object-type, .object-type[type="hidden"]');

        if (typeSelector) {
          this.objectTypeSelector = typeSelector;
        }

        var subtypeSelector = this.element.parentNode.querySelector('.auto-complete.object-subtype, .object-subtype[type="hidden"]');

        if (subtypeSelector) {
          this.objectSubtypeSelector = subtypeSelector;
        }

        var viewsTypeSelector = this.element.parentNode.querySelector('.auto-complete.views-type');

        if (viewsTypeSelector) {
          this.objectViewsTypeSelector = viewsTypeSelector;
        }

        var providesEnvironmentSelector = this.element.parentNode.querySelector('.auto-complete.provides-environment');

        if (providesEnvironmentSelector) {
          this.objectProvidesEnvironmentSelector = providesEnvironmentSelector;
        }

        var providesArchitectureSelector = this.element.parentNode.querySelector('.auto-complete.provides-architecture');

        if (providesArchitectureSelector) {
          this.objectProvidesArchitectureSelector = providesArchitectureSelector;
        }

        this.element.setAttribute('data-loaded-index', 'auto-complete-' + AutoComplete._count);

        AutoComplete._count++;

        AutoComplete._loaded[this.element.getAttribute('data-loaded-index')] = this;

        this.initialize();
        this.bindEvents();
    }

    static loadAll(element) {
        var autocompletes = element.querySelectorAll('.auto-complete');

        autocompletes.forEach(function(element) {
            var autocomplete = AutoComplete.load(element);
        });
    }

    /**
     * This function will create the dropdown and attach it to the body.
     */
    initialize() {
        // Form dropdown section
        this.dropdown = document.createElement("div");
        this.dropdown.classList.add("dropdown");
        this.dropdown.setAttribute("tabindex", "1");
        this.dropdown.setAttribute("hidden", true);
        this.dropdown.setAttribute("id", this.element.getAttribute('data-loaded-index') + "-dropdown");

        // ARIA (initial state)
        this.element.setAttribute("role", "combobox");
        this.element.setAttribute("aria-autocomplete", "list");
        this.element.setAttribute("aria-haspopup", "true");
        this.element.setAttribute("aria-owns", "#" + this.dropdown.getAttribute('id'));
        this.element.setAttribute("aria-controls", this.dropdown.getAttribute('id'));
        this.element.setAttribute("aria-expanded", "false");
        this.dropdown.setAttribute("role", "listbox");
        this.dropdown.setAttribute("aria-label", this.element.getAttribute("data-aria-dropdown-label"));

        this.events = {};

        // Append dropdown to body
        document.querySelector('.content').appendChild(this.dropdown);

        // Initial list
        let initialList = this.element.nextElementSibling;
        if (initialList && initialList.classList.contains("initial-list")) {
            this.element.setAttribute('data-frozen', '');

            initialList.querySelectorAll('li').forEach( (item) => {
                let object = {};

                if (item.hasAttribute('data-icon-url')) {
                    object.icon = item.getAttribute('data-icon-url');
                    object.smallIcon = object.icon;
                }

                if (item.hasAttribute('data-small-icon-url')) {
                    object.smallIcon = item.getAttribute('data-small-icon-url');
                }

                object.type = item.textContent;

                if (this.element.classList.contains('object-type')) {
                    this.appendType(object);
                }
            });
        }

        // Form hidden form component for the object id
        var existing = this.element.parentNode.querySelector(":scope > input[type=hidden][name=object-id]");
        if (existing) {
            this.hidden = existing;
        }
        else {
            this.hidden = document.createElement("input");
            this.hidden.setAttribute("hidden", "");
            this.hidden.setAttribute("name", "object-id");
            this.hidden.setAttribute("type", "hidden");

            this.element.parentNode.insertBefore(this.hidden, this.element.nextElementSibling);
        }

        // Form hidden form component for the object id
        existing = this.element.parentNode.querySelector(":scope > input[type=hidden][name=object-identity]");
        if (existing) {
            this.hidden_identity = existing;
        }
        else {
            this.hidden_identity = document.createElement("input");
            this.hidden_identity.setAttribute("hidden", "");
            this.hidden_identity.setAttribute("name", "object-identity");
            this.hidden_identity.setAttribute("type", "hidden");

            this.element.parentNode.insertBefore(this.hidden_identity, this.element.nextElementSibling);
        }

        // Form hidden form component for the object revision
        existing = this.element.parentNode.querySelector(":scope > input[type=hidden][name=object-revision]");
        if (existing) {
            this.hidden_revision = existing;
        }
        else {
            this.hidden_revision = document.createElement("input");
            this.hidden_revision.setAttribute("hidden", "");
            this.hidden_revision.setAttribute("name", "object-revision");
            this.hidden_revision.setAttribute("type", "hidden");

            this.element.parentNode.insertBefore(this.hidden_revision, this.element.nextElementSibling);
        }

        // Initial icon
        if (this.element.hasAttribute('data-icon-url')) {
            let icon = this.element.getAttribute('data-icon-url');
            this.element.style.backgroundImage = "url('" + icon + "')";
        }
    }

    /**
     * Returns an instance of AutoComplete for the given element.
     *
     * This will create an AutoComplete, if it doesn't exist, for this element.
     *
     * @param {HTMLElement} element The main element for the auto complete;
     *                              typically the `<input>` element.
     */
    static load(element) {
        if (!element) {
          return null;
        }

        var index = element.getAttribute('data-loaded-index');

        if (index) {
          return AutoComplete._loaded[index];
        }

        return new AutoComplete(element);
    }

    /**
     * This function returns the Object currently displayed in the field.
     */
    object() {
        return new OccamObject(this.id(), this.revision(), this.type(), this.name());
    }

    /* This function returns the id of the object currently selected.
    */
    id() {
        return this.hidden.value;
    }

    /* This function returns the revision of the object currently selected.
    */
    revision() {
        return this.hidden_revision.value;
    }

    /* This function returns the name of the object currently selected.
    */
    name() {
        return this.element.getAttribute("data-object-name");
    }

    /* This function returns the type of the object currently selected.
    */
    type() {
        return this.element.getAttribute("data-object-type");
    }

    /* This function returns the environment of the object currently selected.
    */
    environment() {
        return this.element.getAttribute('data-environment');
    }

    /* This function returns the architecture of the object currently selected.
    */
    architecture() {
        return this.element.getAttribute('data-architecture');
    }

    /* This function clears the field and voids the dropdown.
    */
    clear() {
        this.element.value = "";
        this.dropdown.childNodes.forEach((node) => {
            node.remove();
        });
    }

    /**
     * This function selects the value indicated by the given item element.
     */
    select(item) {
        let header = item.querySelector("h2");

        let object = {
            "name":      header.getAttribute("data-object-name"),
            "type":      header.getAttribute("data-object-type"),
            "revision":  header.getAttribute("data-object-revision"),
            "identity":  header.getAttribute("data-object-identity"),
            "id":        header.getAttribute("data-object-id"),
            "uid":       header.getAttribute("data-object-uid"),
            "icon":      header.getAttribute("data-icon-url"),
            "smallIcon": header.getAttribute("data-small-icon-url"),
        };

        /* Set fields to reflect choice */
        this.element.style.backgroundImage = window.getComputedStyle(item.querySelector('h2')).backgroundImage;

        /* Set fields to reflect choice */
        this.element.setAttribute("data-object-type", object.type);

        if (object.name) {
            this.element.value = object.name;
        }
        else {
            this.element.value = object.type;
        }
        if (object.id) {
            this.hidden.value  = object.id;
        }

        if (object.revision) {
            this.hidden_revision.value = object.revision;
            this.element.setAttribute('data-revision', object.revision);
        }

        if (object.identity) {
            this.hidden_identity.value = object.identity;
            this.element.setAttribute('data-identity', object.identity);
        }

        this.element.setAttribute('data-object-type', object.type);

        if (object.name) {
            this.element.setAttribute('data-object-name', object.name);
        }

        if (object.icon) {
            this.element.setAttribute('data-icon-url', object.icon);
        }

        if (object.smallIcon) {
            this.element.setAttribute('data-small-icon-url', object.smallIcon);
        }

        this.showDropdown = false;
        if (!this._closing) {
            this.element.focus();
        }
        this.showDropdown = true;

        // Hide dropdown
        this.close();

        event.stopPropagation();
        event.preventDefault();

        this.trigger('change');
    }

    /**
     * This function issues a search to fill the dropdown with possible objects.
     */
    fillDropdown() {
        // Do not clear dropdown if it is marked as frozen
        if (this.element.hasAttribute('data-frozen')) {
            return;
        }

        // Reset any keyboard navigation
        this.element.scrollTop = 0;
        this.dropdownFocus = false;
        this.itemFocus = null;

        var url = '/search';
        var inputElement = this.element;
        var input_type = inputElement.value;

        var types_toggle = "off";
        var objects_toggle = "on";

        if (this.element.classList.contains('object-type')) {
            objects_toggle = "off";
            types_toggle = "on";
        }

        var obj_type      = this.element.getAttribute('data-object-type');;
        var obj_subtype   = null;
        var views_type    = this.element.getAttribute('data-views-type');
        var views_subtype = this.element.getAttribute('data-views-subtype');
        var environment   = this.element.getAttribute('data-environment');
        var architecture  = this.element.getAttribute('data-architecture');

        // Allow filtering of object types using a sibling input object-type field
        if (this.objectTypeSelector) {
            obj_type = this.objectTypeSelector.value;
        }

        // Allow filtering of object subtypes using a sibling input object-subtype field
        if (this.objectSubtypeSelector) {
            obj_subtype = this.objectSubtypeSelector.value;
        }

        if (this.objectViewsTypeSelector) {
            views_type = this.objectViewsTypeSelector.value;
        }

        if (this.objectProvidesEnvironmentSelector) {
            environment = this.objectProvidesEnvironmentSelector.value;
        }

        if (this.objectProvidesArchitectureSelector) {
            architecture = this.objectProvidesArchitectureSelector.value;
        }

        if (this.search) {
            // Abort any ongoing search
            this.search.abort();
        }

        var searchOptions = {
            "search": input_type,
            "objects": objects_toggle,
            "types": types_toggle,
        };

        if (environment) {
            searchOptions.environment = environment;
        }
        if (architecture) {
            searchOptions.architecture = architecture;
        }

        if (obj_type) {
            searchOptions.type = obj_type;
        }
        if (obj_subtype) {
            searchOptions.subtype = obj_subtype;
        }

        if (views_type) {
            searchOptions["views-type"] = views_type;
            if (views_subtype) {
                searchOptions["views-subtype"] = views_subtype;
            }
        }

        this.search = Util.get(url, (data) => {
            this.dropdown.innerHTML = "";

            if (types_toggle == "on") {
                // Ensures certain types exist even if no objects of those
                // types are known on the system.
                if (('configuration'.indexOf(searchOptions.search) >= 0) && data.types.indexOf('configuration') == -1) {
                    data.types.push({
                      type: "configuration",
                      icon: "/images/icons/objects/configuration.svg",
                      smallIcon: "/images/icons/objects/configuration.svg"
                    });
                }

                data.types.forEach( (type) => {
                    this.appendType(type);
                });
            }
            else {
                data.objects.slice(0,25).forEach( (object) => {
                    this.appendObject(object);
                });
            }
        }, 'json', searchOptions);
    }

    _cloneDropdownItem() {
        var dropdownItem = document.createElement("button");
        dropdownItem.classList.add("object");
        dropdownItem.classList.add("dropdown-item");

        // ARIA
        dropdownItem.setAttribute("role", "option");
        dropdownItem.setAttribute("aria-selected", "false");
        dropdownItem.setAttribute("tabindex", "-1");

        return dropdownItem;
    }

    appendType(object) {
        var item = this._cloneDropdownItem();

        var header = object.type;
        var h2 = document.createElement("h2");
        h2.classList.add("type");
        item.appendChild(h2);
        h2.textContent = header;
        h2.classList.add('icon');
        h2.setAttribute('data-object-type', object.type);
        h2.setAttribute('data-icon-url', object.icon);
        h2.setAttribute('data-small-icon-url', object.smallIcon);
        h2.style.backgroundImage = "url('" + object.smallIcon + "')";

        let index = this.dropdown.children.length;
        item.setAttribute("id", this.dropdown.getAttribute("id") + "-item-" + index);

        item.addEventListener('mousedown', (event) => {
            event.stopPropagation();
            event.preventDefault();

            this.select(item);
        });

        this.dropdown.appendChild(item);

        // Ensure the dropdown is at least a certain size
        var itemStyle = window.getComputedStyle(item);
        var itemHeight = item.offsetHeight + parseInt(itemStyle.marginTop) + parseInt(itemStyle.marginBottom);
        var dropdownHeight = itemHeight * this.dropdown.querySelectorAll('button').length;
        this.dropdown.style.height = dropdownHeight + "px";

        // Return the item
        return item;
    }

    appendObject(object) {
        var item = this._cloneDropdownItem();

        var header = object.type;
        if (object.type === "person") {
            if ("organization" in object) {
                header = object.organization;
            }
            else {
                header = null;
            }
        }

        if (header !== null) {
            var h2 = document.createElement("h2");
            h2.classList.add("type");
            h2.textContent = header;
            item.appendChild(h2);

            var p = document.createElement("p");
            p.textContent = object.name;
            item.appendChild(p);
        }
        else {
            var h2Type = document.createElement("h2");
            h2Type.classList.add("type");
            h2Type.textContent = object.name;
            item.appendChild(h2Type);
        }

        var icon = null;
        if (object.type === "person") {
            icon = "/people/" + object.identity + "/avatar?size=20";
        }

        if (icon !== null) {
            item.querySelector('h2').style.backgroundImage = "url('" + icon + "')";
            item.querySelector('h2').setAttribute('data-object-type', object.type);
            item.querySelector('h2').setAttribute('data-object-name', object.name);
            item.querySelector('h2').setAttribute('data-icon-url', object.icon);
            item.querySelector('h2').setAttribute('data-object-identity', object.identity);
            item.querySelector('h2').setAttribute('data-small-icon-url', object.smallIcon);
            item.querySelector('h2').setAttribute('data-object-revision', object.revision);
            item.querySelector('h2').setAttribute('data-object-id', object.id);
            item.querySelector('h2').setAttribute('data-object-uid', object.uid);
        }
        else {
            item.querySelector('h2').classList.add('icon');
            item.querySelector('h2').setAttribute('data-object-type', object.type);
            item.querySelector('h2').setAttribute('data-object-name', object.name);
            item.querySelector('h2').setAttribute('data-icon-url', object.icon);
            item.querySelector('h2').setAttribute('data-object-identity', object.identity);
            item.querySelector('h2').setAttribute('data-small-icon-url', object.smallIcon);
            item.querySelector('h2').setAttribute('data-object-revision', object.revision);
            item.querySelector('h2').setAttribute('data-object-id', object.id);
            item.querySelector('h2').setAttribute('data-object-uid', object.uid);
            item.querySelector('h2').style.backgroundImage = "url('" + object.smallIcon + "')";
        }

        item.addEventListener('mousedown', (event) => {
            event.stopPropagation();
            event.preventDefault();

            this.select(item);
        });

        this.dropdown.appendChild(item);

        // Ensure the dropdown is at least a certain size
        var itemStyle = window.getComputedStyle(item);
        var itemHeight = item.offsetHeight + parseInt(itemStyle.marginTop) + parseInt(itemStyle.marginBottom);
        var dropdownHeight = itemHeight * this.dropdown.querySelectorAll('button').length;
        this.dropdown.style.height = dropdownHeight + "px";

        // Return the item
        return item;
    }

    /**
     * This function opens the dropdown.
     */
    open() {
        if (this.dropdown.hasAttribute("hidden")) {
            var inputStyle = window.getComputedStyle(this.element);
            var inputWidth  = this.element.offsetWidth - parseInt(inputStyle.borderLeftWidth) - parseInt(inputStyle.borderRightWidth);
            var inputHeight = this.element.offsetHeight;
            this.dropdown.style.width = inputWidth + "px";
            var offset = this.element.getBoundingClientRect();

            this.dropdown.removeAttribute("hidden");
            this.dropdown.style.left = offset.left + "px";
            this.dropdown.style.top  = (offset.top + inputHeight) + "px";
            this.dropdown.style.display = "block";
            this.dropdown.style.height = "0px";

            var item = this.dropdown.querySelector("button");
            var dropdownHeight = 0;
            if (item) {
                var itemStyle = window.getComputedStyle(item);
                var itemHeight = item.offsetHeight + parseInt(itemStyle.marginTop) + parseInt(itemStyle.marginBottom);
                dropdownHeight = itemHeight * this.dropdown.querySelectorAll('button').length;
            }

            this.dropdown.style.height = dropdownHeight + "px";
        }

        this.fillDropdown();
    }

    update(value) {
        // Choose the item that matches the most... or create an item that matches
        let discovered = false;
        this.dropdown.querySelectorAll("button").forEach( (item) => {
            if (discovered) {
                return;
            }

            let h2 = item.querySelector('h2');
            if (this.element.classList.contains('object-type')) {
                let type = h2.getAttribute('data-object-type');
                if (type.startsWith(value)) {
                    this.select(item);
                    discovered = true;
                }
            }
            else {
            }
        });

        if (!discovered) {
            if (this.element.classList.contains('object-type')) {
                let item = this.appendType({
                    type: value
                });
                this.select(item);
            }
        }
    }

    /**
     * This function closes the dropdown.
     */
    close() {
        if (this._closing) {
            return;
        }

        this._closing = true;
        if (this.element.value) {
            this.update(this.element.value);
        }
        this.dropdown.setAttribute("hidden", true);
        this._closing = false;
    }

    /**
     * This function will attach the change events that will perform the
     * queries and update/show the dropdown.
     */
    bindEvents() {
        this.element.addEventListener('focus', () => {
            if (this.showDropdown == false) {
                this.showDropdown = true;
                return;
            }

            this.element.setSelectionRange(0, this.element.value.length);

            this.open();
        });

        this.element.addEventListener('keydown', (event) => {
            let code = Util.canonizeKey(event);
            if (code != "Tab") { // tab
                this.element.removeAttribute('data-frozen');
            }

            if (!this.dropdownFocus && code == "ArrowDown") {
                event.stopPropagation();
                event.preventDefault();

                // Focus on first item in dropdown
                let toggle = this.dropdown.querySelector("button:first-child");
                this.dropdownFocus = true;
                this.itemFocus = toggle;

                this.element.setAttribute("aria-activedescendant", toggle.getAttribute('id'));
                toggle.classList.add("focus");
            }
            else if (this.dropdownFocus) {
                let toggle = null;
                if (code == "ArrowUp") {
                    event.stopPropagation();
                    event.preventDefault();
                    toggle = this.itemFocus;
                    do {
                        toggle = toggle.previousElementSibling;
                    } while (toggle && toggle.hasAttribute("hidden"));

                    if (toggle && (toggle.offsetTop < this.dropdown.scrollTop)) {
                        this.dropdown.scrollTop = toggle.offsetTop;
                    }
                }
                else if (code == "ArrowDown") {
                    event.stopPropagation();
                    event.preventDefault();
                    toggle = this.itemFocus;
                    do {
                        toggle = toggle.nextElementSibling;
                    } while (toggle && toggle.hasAttribute("hidden"));

                    if (toggle) {
                        var itemStyle = window.getComputedStyle(toggle);
                        var itemHeight = toggle.offsetHeight + parseInt(itemStyle.marginTop) + parseInt(itemStyle.marginBottom);

                        if (toggle.offsetTop + itemHeight > this.dropdown.scrollTop + this.dropdown.offsetHeight) {
                            this.dropdown.scrollTop = this.dropdown.offsetHeight - itemHeight;
                        }
                    }
                }
                else if (code == "Escape") {
                    this.dropdownFocus = false;
                    this.itemFocus = null;
                    return;
                }
                else if (code == "Enter") {
                    this.dropdownFocus = false;
                    this.select(this.itemFocus);
                    this.itemFocus = null;
                    return;
                }

                if (toggle) {
                    this.element.setAttribute("aria-activedescendant", toggle.getAttribute('id'));
                    this.itemFocus.classList.remove("focus");
                    this.itemFocus = toggle;
                    toggle.classList.add("focus");
                }
            }
        });

        this.element.addEventListener('keyup', (event) => {
            let code = Util.canonizeKey(event);
            if (code != "ArrowDown" && code != "Enter" && code != "ArrowUp" && code != "Escape") {
                this.open();
            }
        });

        this.element.addEventListener('blur', (event) => {
            event.stopPropagation();
            event.preventDefault();
            let forceClose = false;

            if (forceClose || (!event.relatedTarget || (event.relatedTarget.parentNode != event.target && event.relatedTarget != this.dropdown))) {
                this.close();
            }
        });
    }
}

AutoComplete._loaded = {};
AutoComplete._count  = 0;

export default AutoComplete;
