"use strict";

import EventComponent from './event_component.js';
import Util           from './util.js';
import Dropdown       from './dropdown.js';

/**
 * This represents the actions tied to the object header (the main banner with
 * the object name and dropdowns.
 *
 * @param {HTMLElement} element The main element for the object header;
 *                              typically the `<h1>` element.
 */
class Header extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            return;
        }

        this.element = element;

        Header._count++;
        this.element.setAttribute('data-header-index', Header._count);

        Header._loaded[this.element.getAttribute('data-header-index')] = this;

        this.actionsDropdownElement = this.element.querySelector('.statistics.dropdown-menu');
        if (this.actionsDropdownElement) {
            this.actionsDropdown = Dropdown.load(this.actionsDropdownElement);
        }

        this.bindEvents();
    }

    static loadAll(element) {
        var headers = element.querySelectorAll('h1');

        headers.forEach(function(element) {
            Header.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-header-index');

        if (index) {
            return Header._loaded[index];
        }

        return new Header(element);
    }

    /**
     * This function initializes the header UI components and binds interactive
     * events.
     */
    bindEvents(force) {
        if (this.element) {
            if (!force && this.element.classList.contains('bound')) {
                return;
            }
        }

        this.element.classList.add('bound');

        if (this.actionsDropdown) {
            this.actionsDropdown.on('selected', (info) => {
                if (info.element.classList.contains("bookmark")) {
                    info.event.preventDefault();

                    var form = info.element.parentNode;

                    Util.submitForm(form);

                    let bookmarkToggle = this.actionsDropdownElement.querySelector("li.bookmark");
                    let bookmarkDelete = this.actionsDropdownElement.querySelector("li.bookmark_delete");
                    bookmarkToggle.setAttribute("hidden", "");
                    bookmarkDelete.removeAttribute("hidden");
                }
                else if (info.element.classList.contains("bookmark_delete")) {
                    info.event.preventDefault();

                    var form = info.element.parentNode;

                    Util.submitForm(form);

                    let bookmarkToggle = this.actionsDropdownElement.querySelector("li.bookmark");
                    let bookmarkDelete = this.actionsDropdownElement.querySelector("li.bookmark_delete");
                    bookmarkDelete.setAttribute("hidden", "");
                    bookmarkToggle.removeAttribute("hidden");
                }
            });
        }
    }
}

Header._loaded = {};
Header._count  = 0;

export default Header;
