"use strict";

import EventComponent from "../event_component.js";
import Selector       from "../selector.js";
import AutoComplete   from "../auto_complete.js";
import ObjectList     from "../object_list.js";
import Util           from "../util.js";
import Search         from "../search.js";
import Tabs           from "../tabs.js";

/**
 * This implements behaviors for the objects/new modal.
 */
class NewModal extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            return;
        }

        this.element = element;

        // Find the errors section
        this.errorsList = this.element.parentNode.querySelector(".errors");

        // Find the text input for the object type
        this.typeInput = this.element.querySelector('input[name="type"]');
        this.typeAutoComplete = AutoComplete.load(this.typeInput);

        this.nameInput = this.element.querySelector('input[name="name"]');

        // Find the search query field
        this.searchInput = this.element.querySelector('input[name="query"]');

        // Get the tabs for the modal
        this.tabs = Tabs.load(this.element.querySelector('.tabs.tab-bar'));

        // License section
        this.licensePicker = this.element.querySelector('.card.license-picker');
        this.licenseCard   = this.element.querySelector('.card.license');
        this.licenseClose  = this.element.querySelector('li.close');

        // Get the object listing
        let objectsListElement = this.element.querySelector(".objects-container ul.objects");
        if (objectsListElement) {
            this.objectsList = ObjectList.load(objectsListElement);
        }

        // Get the object-id placeholder
        this.objectIdInput = this.element.querySelector('input[name="object-id"]');
        this.objectRevisionInput = this.element.querySelector('input[name="object-revision"]');

        // Find the template input for the object type
        this.templateInput = this.element.querySelector('input[name="template"]');
        this.templateAutoComplete = AutoComplete.load(this.templateInput);

        // Find the button
        this.submitButton = this.element.querySelector('input[name="add"]');

        NewModal._count++;
        this.element.setAttribute('data-new-modal-index', NewModal._count);
        NewModal._loaded[this.element.getAttribute('data-new-modal-index')] = this;

        if (this.searchInput) {
            this.searchInput.focus();
        }

        this.bindEvents();

        this.search();
    }

    static loadAll(element) {
        var elements = element.querySelectorAll('.card.new-object');

        elements.forEach(function(element) {
            NewModal.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-new-modal-index');

        if (index) {
            return NewModal._loaded[index];
        }

        return new NewModal(element);
    }

    bindEvents() {
        if (this.objectsList) {
            this.objectsList.on('click', (item) => {
                if (this.tabs) {
                    // Re-enable and go to Metadata tab
                    let tab = this.tabs.tabAt(1);
                    tab.removeAttribute('disabled');
                    this.tabs.select(1);
                }

                // Update the type field to match
                // TODO: update icon
                this.typeInput.value = item.type;

                // Update the object-id/object-revision
                if (this.objectIdInput) {
                    this.objectIdInput.value = item.id;
                }
                if (this.objectRevisionInput) {
                    this.objectRevisionInput.value = item.revision;
                }

                // Focus on the name
                this.nameInput.focus();
            });
        }

        if (this.searchInput) {
            let searchTimer = null;
            let search = (e) => {
                // Perform a search within the objects listing
                if (searchTimer) {
                    window.clearTimeout(searchTimer);
                    searchTimer = null;
                }

                searchTimer = window.setTimeout( () => {
                    this.search();
                }, Search.POLL_TIME);
            };
            this.searchInput.addEventListener("change", search);
            this.searchInput.addEventListener("keyup", search);
        }

        if (this.templateAutoComplete && this.typeInput) {
            this.templateAutoComplete.on("change", () => {
                // Update the type field to the type of the template
                this.typeInput.value = this.templateAutoComplete.type();

                this.nameInput.focus();
            });
        }

        if (this.tabs) {
            this.tabs.on('change', () => {
                if (this.licenseClose && this.licenseCard && this.licensePicker) {
                    this.licenseCard.setAttribute('hidden', '');
                    this.licenseClose.setAttribute('hidden', '');
                    this.licensePicker.removeAttribute('hidden');
                    this.tabs.element.classList.remove("overlay-open");
                }
            });
        }

        if (this.licenseClose) {
            this.licenseClose.addEventListener('click', (event) => {
                this.licenseCard.setAttribute('hidden', '');
                this.licenseClose.setAttribute('hidden', '');
                this.licensePicker.removeAttribute('hidden');
                this.tabs.element.classList.remove("overlay-open");
            });
        }

        if (this.licensePicker) {
            let licenseShowAll = this.licensePicker.querySelector("input.show-all");
            if (licenseShowAll) {
                licenseShowAll.addEventListener('change', (event) => {
                    this.licensePicker.querySelectorAll("li.license").forEach( (license) => {
                        if (licenseShowAll.checked && license.getAttribute('data-recommend') == 'false') {
                            license.removeAttribute('hidden');
                        }
                        else if (!licenseShowAll.checked && license.getAttribute('data-recommend') == 'false') {
                            license.setAttribute('hidden', '');
                        }
                    });
                });
            }

            let licenses = this.licensePicker.querySelectorAll("li.license");
            licenses.forEach( (license) => {
                let checkbox = license.querySelector(".checkbox input[type=\"checkbox\"]");

                let licenseButton = license.querySelector(".license a.license");
                if (licenseButton && checkbox) {
                    licenseButton.addEventListener('click', (event) => {
                        event.stopPropagation();
                        event.preventDefault();

                        checkbox.checked = !checkbox.checked;
                    });
                }

                let viewButton = license.querySelector(".actions a.view");
                if (viewButton) {
                    viewButton.addEventListener('click', (event) => {
                        event.stopPropagation();
                        event.preventDefault();

                        let url = viewButton.getAttribute('href');

                        // Pull the license text and display it in the modal
                        this.licenseCard.innerHTML = "";
                        this.licenseCard.style.height = this.licensePicker.clientHeight + "px";
                        this.licenseCard.classList.add('loading');
                        this.licenseCard.removeAttribute('hidden');
                        this.licenseClose.removeAttribute('hidden');
                        this.licensePicker.setAttribute('hidden', '');
                        this.tabs.element.classList.add("overlay-open");
                        Util.get(url, (html) => {
                            let dummy = document.createElement("div");
                            dummy.innerHTML = html;
                            dummy = dummy.querySelector('.card.license');
                            if (dummy) {
                                this.licenseCard.innerHTML = dummy.innerHTML;
                            }

                            this.licenseCard.classList.remove('loading');
                        });
                    });
                }
            });
        }
    }

    /**
     * Performs the search for object templates indicated by the input query.
     */
    search() {
        if (this.searchInput && this.searchInput.value !== this._lastSearch) {
            this.objectsList.clear();
            this.objectsList.search({
                subtype: "object-template",
                query: this.searchInput.value
            });
            this._lastSearch = this.searchInput.value;
        }
    }
}

NewModal._count  = 0;
NewModal._loaded = {};

export default NewModal;
