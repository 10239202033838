"use strict";

import EventComponent from './event_component.js';

class Tokenizer {
    constructor() {
        this._tokens = [];
    }

    get tokens() {
        return this._tokens;
    }

    push(token, data) {
        if (token.length > 0) {
            this.tokens.push(token);

            if (token == "identifier" || token == "number") {
                this.tokens.push(data);
            }
        }
    }

    forEach(f) {
        return this.tokens.forEach(f);
    }

    tokenize(str) {
        var last_character = '';
        var state = 0;
        var token = "";

        for (var i = 0, len = str.length; i < len; i++) {
            var c = str.charAt(i);

            if (state == 0) {
                // Reading identifier
                if (c.match(/[*+-\/()%=<>!]/)) {
                    // Switch states
                    state = 1;

                    // Push token
                    if (token.match(/^\d+$/)) {
                        this.push("number", token);
                    }
                    else if (Tokenizer.IDENTIFIERS.indexOf(token) >= 0) {
                        this.push("identifier", token);
                    }
                    else if (token.length > 0) {
                        window.console.log("invalid identifier: " + token);
                        return false;
                    }
                    token = "";
                    last_character = "";
                }
            }
            else if (state == 1) {
                // Reading symbol
                if (c == '*' && last_character == '*' && token.length == 1) {
                    // **
                }
                else if (c == '=' && (last_character == '<' ||
                    last_character == '>' ||
                    last_character == '=' ||
                    last_character == '!') && token.length == 1) {
                    // ==, <=, >=, !=
                }
                else if (c.match(/\d|\w/)) {
                    // Switch states
                    state = 0;

                    // Push token
                    this.push(token);
                    token = "";
                    last_character = "";
                }
                else if (c.match(/[*+-\/()%=<>!]/)) {
                    // Push token
                    this.push(token);
                    token = "";
                    last_character = "";
                }
            }

            if (c == ' ') {
                this.push(token);
                token = "";
                last_character = "";
            }
            else {
                token = token + c;
                last_character = c;
            }
        }

        this.push(token);

        return this.tokens;
    }
}

Tokenizer.IDENTIFIERS = [
    "x",
    "log2",
    "floor",
    "isInteger",
    "isNumber"
];

/**
 * This class handles validation of configuration values.
 */
class Validator {
    /**
     * Runs a validation expression and returns the result.
     */
    static execute(expr, value) {
        return Validator.validate(expr, value);
    }

    /**
     * Runs a test against the given value.
     *
     * @param [String] test - The code to run.
     * @param [String] value - The value to use.
     */
    static validate(test, value) {
        var tokens = new Tokenizer();
        tokens.tokenize(test);

        // We will build a simple eval string
        var output = "this.f = function(x){return ";
        var lastToken = "";
        var leftParens = 0;

        // For every token, build up the eval string
        tokens.forEach( (token) => {
            if (lastToken == "identifier") {
                if (token == "log2") {
                    output += "Math.log2";
                }
                else if (token == "floor") {
                    output += "Math.floor";
                }
                else if (token == "ceiling") {
                    output += "Math.ceil";
                }
                else if (token == "log") {
                    output += "Math.log";
                }
                else if (token == "isInteger") {
                    output += "isInteger";
                }
                else if (token == "isNumber") {
                    output += "isNumber";
                }
                else if (token == "x") {
                    output += "x";
                }
            }
            else if (lastToken == "number") {
                output += "(" + token + ")";
            }
            else if (token == "identifier" || token == "number") {
            }
            else if (token == "==") {
                output += "===";
            }
            else {
                if (token == "(") {
                    leftParens++;
                }
                else if (token == ")") {
                    if (leftParens < 1) {
                        window.console.log("compile error");
                        return false;
                    }
                    leftParens--;
                }
                output = output + token;
            }

            lastToken = token;
        });

        function isInteger(x) {
            return Validator.isInteger(x);
        }

        function isNumber(x) {
            try {
                return ("" + parseFloat(x)) == x;
            }
            catch (error) {
                return false;
            }
        }

        output += "}";
        /* jshint ignore:start */
        let x = value;
        eval(output);
        let ret = this.f(x);
        /* jshint ignore:end */

        return ret;
    }

    /**
     * Determines whether or not the given value is an integer.
     *
     * That value might be a string with an encoding of an integer or some
     * expression that results in an integer value.
     */
    static isInteger(x) {
        try {
            let original = x.toString().trim();
            
            // Remove redundant leading '+'
            if (original.startsWith('+')) {
                original = original.substring(1);
            }

            let radix = 10;
            let prelim = "";
            if (x.trim().startsWith('0x')) {
                radix = 16;
                prelim = "0x";
            }
            else if (x.trim().startsWith('-0x')) {
                radix = 16;
                prelim = "-0x";
                x = x.substring(1);
            }

            if (x.trim().startsWith('0x')) {
                // Remove leading 0s after the '0x'
            }

            let check = x;
            if (radix == 10) {
                // Handle scientific notation
                // We just need to know that the number of digits is positive and
                // would yield a whole integer
                let e = original.indexOf('e');
                if (e > 0) {
                    original = Validator.scientificNotationToString(original);
                    return original !== "";
                }
                else {
                    // Update check instead
                    check = BigInt(Validator.scientificNotationToString(check)).toString();
                }
            }
            else {
                check = prelim + BigInt(x).toString(radix);
            }

            return (check === original);
        }
        catch (error) {
            return false;
        }
    }

    static scientificNotationToString(item) {
        // Do not apply if the item is a hex string
        if (item.startsWith('0x')) {
            return item;
        }

        // Apply the scientific notation to form a new string
        // containing the full realized number
        let parts = item.split('e');
        if (parts.length > 2) {
            // Ensure the check fails if it is not valid
            return "";
        }

        // If it isn't scientific notation, just return it
        if (parts.length == 1) {
            return item;
        }

        let numeric = parts[0];
        let exponent = parts[1];

        // The exponent should be an integer
        if (!Validator.isInteger(exponent)) {
            return "";
        }

        // Realize the exponent
        exponent = parseInt(exponent);

        // Count the digits
        // TODO: localized decimal separators
        parts = numeric.split('.');
        if (parts.length > 2) {
            // Ensure the check fails if it is not valid
            // (too many dots)
            return "";
        }

        let integer = parts[0];
        let part = parts[1] || "";
        if (exponent < part.length) {
            // The exponent results in a fraction
            return "";
        }

        // Create the rough value
        return "" + integer + part + "".padStart(exponent - part.length, "0");
    }
}

export default Validator;
