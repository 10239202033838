"use strict";

import SVGPlane from "./svg_plane.js";
import Node from "./node.js";
import EventComponent from "./event_component.js";
import World from "./world.js";
import Wire from "./wire.js";
import Mover from "./mover.js";
import Port from "./port.js";
import Palette from "./palette.js";

/**
 * This is the main class for instantiating a Workflow widget.
 */
export class Workflow extends EventComponent {
    /**
     * Creates a new Workflow within the given element, optionally with an
     * initial graph data.
     *
     * @param {HTMLElement} element The workflow element to instantiate within.
     * @param {object} [options] The optional set of options. See Workflow.defaults.
     * @param {object} [graph] The optional initial serialized graph data.
     */
    constructor(element, options, graph) {
        super();

        this._lastRedrawWidth = 0;

        this._nodes = [];
        this._edges = [];

        // It starts clean, as we all do
        this._dirty = false;

        // Whether or not to trigger a dirty event.
        // We can sleep this when we know we'll do a batch of updates
        this._suppressDirty = false;

        this.options = Object.assign({}, Workflow.defaults, options);

        // Initialize the Palette
        this._palette = new Palette(this, this.options);

        // Initialize the main element
        this.element = element;
        this.element.classList.add("workflow-widget-js");

        // Create all elements (unless they already exist)
        let ul = this.element.querySelector("ul.connections:not(.dummy)");
        if (!ul) {
            ul = document.createElement("ul");
        }
        this.svgPlane = SVGPlane.create(options);
        let selectionBox = document.createElement("div");

        // Create a ghost port (for wire drag)
        this._ghostPort = Port.create({}, null, this.options);
        this._ghostPort.element.classList.add("dummy");
        this._ghostPort.direction = "left";

        // Assemble elements
        this.element.appendChild(this.svgPlane.element);
        this.element.appendChild(ul);
        this.element.appendChild(selectionBox);
        this.element.setAttribute("tabIndex", -1);

        // Initialize elements
        ul.classList.add("connections");
        ul.classList.add("draggable");
        selectionBox.classList.add("selection");
        selectionBox.style.display = "none";

        // Store needed elements
        this.plane = ul;
        this.selectionBox = selectionBox;

        // Instantiate a World, which handles the input events and manages
        // the visual state of the DOM.
        this.world = new World(element, this.options);

        this.world.on("mousedown", (event) => {
            this.unselectPort();
            this.unselectWire();
        });

        this.world.on("pan", () => {
            this.trigger("pan", {
              x: this.world.centerX,
              y: this.world.centerY
            });
        });

        // Capture resize events
        if (window.elementResizeEvent) {
            window.elementResizeEvent(this.element, (event) => {
                this.redraw();
            });
        }

        this.fromJSON(graph || {});
    }

    /**
     * Adds a Node given the provided serialized representation.
     *
     * @param {object} node The serialized representation of the node.
     */
    insertNode(node) {
        // Create the node
        let newNode = node;
        if (!(node instanceof Node)) {
            newNode = Node.create(node, this.options);
        }

        newNode.on("destroy", (event) => {
            let node = event.node;
            let idx = this._nodes.indexOf(node);
            if(idx > -1) {
                this._nodes.splice(idx, 1);
            }

            // Remove the node
            this.world.remove(node);

            // Mark dirty
            this.dirty = true;
        });

        newNode.on("update", () => {
            // Mark dirty
            this.dirty = true;
        });

        if (this.options.allowNewConnections) {
            // Handle when the Port is selected
            newNode.on("port-select", (port) => {
                // If there is something selected... make a wire
                if (this._selectedPort && port != this._selectedPort) {
                    if (this._ghostWire) {
                        this._ghostWire.destroy();
                    }

                    // Connect
                    this.connect(this._selectedPort, port);
                    this._selectedPort.reposition();
                    port.reposition();

                    // Unselect both ports now
                    this.unselectPort();
                    port.unselect();
                }
                else {
                    // Unselect the last port
                    this.selectPort(port);
                }
            });

            newNode.on("port-mouseenter", (port) => {
                // Ghost the potential new wire.
                if (this._selectedPort && port != this._selectedPort) {
                    // Make sure there isn't an existing ghost wire
                    if (this._ghostWire) {
                        this._ghostWire.destroy();
                    }

                    // Create a ghost "Wire"
                    this._ghostWire = Wire.create(this.options, this._selectedPort, port);
                    this._ghostWire.path.classList.add("dummy");
                    let a = this._ghostWire.elementStart;
                    let b = this._ghostWire.elementEnd;
                    a.classList.add("ghost");
                    b.classList.add("ghost");

                    let portA = port;
                    let portB = this._selectedPort;

                    portA.element.querySelector(".connections").appendChild(a);
                    portB.element.querySelector(".connections").appendChild(b);
                    this.svgPlane.appendChild(this._ghostWire.path);
                    this._ghostWire.redraw();
                }
                else if (!this._selectedPort) {
                    // If there is no selected port, we can potentially click+drag
                    // to create a new Wire.

                    // Listen for mousedown on the port
                    if (this._selectingWire) {
                        // We only need to remember what port is being hovered
                        this._connectionPort = port;
                        // Have the wire 'connect' to this port
                        this._ghostWire.redraw();
                        return;
                    }

                    if (this._hoveredPort) {
                        this.unselectPort();
                    }
                    this._hoveredPort = port;

                    // Port mousedown event
                    this._hoveredPortEvent = (event) => {
                        this._selectingWire = true;

                        let hasDragged = false;

                        // Create a Mover
                        let mover = new Mover(event);

                        let a = null;
                        let b = null;
                        let box = null;

                        mover.on("dragged", (event) => {
                            if (!hasDragged) {
                                // Disable "disconnected" status
                                port.element.classList.add("drawing");
                                port.element.classList.remove("disconnected");

                                this._ghostSourcePort = port;

                                // Create a ghost Wire
                                // Make sure there isn't an existing ghost wire
                                if (this._ghostWire) {
                                    this._ghostWire.destroy();
                                }
                                this._ghostWire = Wire.create(this.options, port, this._ghostPort);
                                this._ghostWire.path.classList.add("dummy");
                                this._ghostWire.elementStart.classList.add("dummy");
                                if (port.direction == "left") {
                                    this._ghostPort.direction = "right";
                                }
                                if (port.direction == "right") {
                                    this._ghostPort.direction = "left";
                                }
                                if (port.direction == "bottom") {
                                    this._ghostPort.direction = "top";
                                }
                                if (port.direction == "top") {
                                    this._ghostPort.direction = "bottom";
                                }

                                a = this._ghostWire.elementStart;
                                b = this._ghostWire.elementEnd;
                                a.classList.add("ghost");
                                b.classList.add("ghost");

                                // We need a ghost Port as well

                                box = this.element.getBoundingClientRect();
                                b.style.left = event.pageX + this.world.x - box.left + "px";
                                b.style.top  = event.pageY + this.world.y - box.top + "px";

                                port.element.querySelector(".connections").appendChild(a);
                                this.world.draggable.appendChild(b);
                                this.svgPlane.appendChild(this._ghostWire.path);
                                this._ghostWire.redraw();

                                hasDragged = true;
                            }

                            box = this.element.getBoundingClientRect();
                            b.style.left = event.pageX + this.world.x - box.left + "px";
                            b.style.top  = event.pageY + this.world.y - box.top + "px";

                            // Re-evaluate the ghost port direction
                            if (this._connectionPort) {
                                this._ghostPort.direction = this._connectionPort.direction;
                            }
                            else if (port.direction === "right" || port.direction === "left") {
                                if (parseInt(b.style.left) > (port.node.x + port.node.width/2)) {
                                    this._ghostPort.direction = "left";
                                }
                                else {
                                    this._ghostPort.direction = "right";
                                }
                            }
                            else {
                                if (parseInt(b.style.top) > (port.node.y + port.node.height/2)) {
                                    this._ghostPort.direction = "top";
                                }
                                else {
                                    this._ghostPort.direction = "bottom";
                                }
                            }

                            this._ghostWire.redraw();
                            return;

                        });

                        mover.on("stopped", (event) => {
                            port.element.classList.remove("drawing");
                            if (port._wires.length == 0) {
                                port.element.classList.add("disconnected");
                            }

                            // Determine if a wire is underneath
                            if (this._connectionPort) {
                                // Ok. Connect.
                                this.connect(port, this._connectionPort);
                            }

                            // Determine if we did not move at all, in which case
                            // we are selecting the port instead
                            this.unselectPort();
                            if (event.delta.x > -5 && event.delta.x < 5 &&
                                event.delta.y > -5 && event.delta.y < 5) {
                                // Select the port
                                this.selectPort(port);
                            }

                            this._connectionPort = null;

                            if (this._ghostWire) {
                                this._ghostWire.destroy();
                            }
                        });
                    };

                    this._hoveredPort.element.addEventListener("mousedown", this._hoveredPortEvent);
                }
            });
        }

        newNode.on("port-hide", (port) => {
            this.unselectPort();

            // Pass along the event
            this.trigger("port-hide", port);

            // Make dirty when port is hidden
            this.dirty = true;
        });

        newNode.on("port-show", (port) => {
            // Pass along the event
            this.trigger("port-show", port);

            // Make dirty when port is shown
            this.dirty = true;
        });

        newNode.on("move", () => {
            // Pass along the event
            this.trigger("node-move", newNode);

            // Make dirty when node is moved
            this.dirty = true;
        });

        newNode.on("port-mouseleave", (port) => {
            // Remove the wire ghost (if it exists)
            if (this._selectedPort && port != this._selectedPort) {
                if (this._ghostWire) {
                    this._ghostWire.destroy();
                }
                this._ghostWire = null;
            }

            if (this._connectionPort) {
                this._connectionPort = null;
                this._ghostWire.redraw();
            }
        });

        // Unselect ports/nodes when this node is selected
        newNode.on("select", (event) => {
            this.unselectPort();
            this.unselectWire();
        });

        // Add it to our world
        this.world.add(newNode);

        // Handle node events
        newNode.on("button-click", (event) => {
            // Pass-through button click events
            this.trigger("button-click", event);
        });

        // Append the node to our node list
        this._nodes.push(newNode);

        // Make dirty
        this.dirty = true;
    }

    /**
     * Selects the given Port.
     *
     * @param {Port} port The Port to select.
     */
    selectPort(port) {
        this.world.selectionClear();
        this.unselectWire();
        if (!port.selected) {
            port.select();
        }
        if (this._selectedPort && this._selectedPort !== port) {
            this._selectedPort.unselect();
        }
        this._selectedPort = port;
    }

    /**
     * Unselects any selected ports.
     */
    unselectPort() {
        if (this._selectedPort) {
            this._selectedPort.unselect();
            this._selectedPort = null;
        }

        if (this._hoveredPort) {
            this._hoveredPort.unselect();
            this.unregisterHoverEvents();
        }

        this._selectingWire = false;
    }

    /**
     * Disables hover events on the currently hovered port.
     */
    unregisterHoverEvents() {
        if (this._hoveredPort) {
            // Detach registered events
            this._hoveredPort.element.removeEventListener("mousedown", this._hoveredPortEvent);
        }

        this._hoveredPort = null;
    }

    /**
     * Selects the given Wire.
     *
     * @param {Wire} wire The Wire to select.
     */
    selectWire(wire) {
        this.world.selectionClear();
        this.unselectPort();
        if (!wire.selected) {
            wire.select();
        }
        if (this._selectedWire && this._selectedWire !== wire) {
            this._selectedWire.unselect();
        }
        this._selectedWire = wire;

        // Now also show the 'delete' buttons
        // TODO: consider option to disable wire deletion specifically?
    }

    /**
     * Unselects any selected wires.
     */
    unselectWire() {
        if (this._selectedWire) {
            this._selectedWire.unselect();
        }
        this._selectedWire = null;
    }

    /**
     * Disconnects the given wire between the given ports.
     */
    disconnect(portA, portB, wire) {
        portA.remove(wire);
        portB.remove(wire);
        let existing_item = this._edges.find( (item) => {
          return (portA === item[0] && portB === item[1]) || (portA === item[1] && portB === item[0]);
        });
        var index = this._edges.indexOf(existing_item);
        if (index > -1) {
          this._edges.splice(index, 1);
        }
        wire.destroy();
    }

    /**
     * Connects the given ports.
     *
     * @param {Port} portA The starting Port.
     * @param {Port} portB The end Port.
     */
    connect(portA, portB) {
        let existing_item = this._edges.find( (item) => {
            return (portA === item[0] && portB === item[1]) || (portA === item[1] && portB === item[0]);
        });

        if (existing_item) {
            // Already exists
            return;
        }

        // Do not connect output to output or input to input
        if ((portA.connectionType == "output" &&
             portB.connectionType == "output") ||
            (portA.connectionType == "input" &&
             portB.connectionType == "input")) {

            // Do not connect
            return;
        }

        if (portA.count >= portA.max && portA.max != -1) {
            // Cannot connect any more to A
            return;
        }

        if (portB.count >= portB.max && portB.max != -1) {
            // Cannot connect any more to B
            return;
        }

        // Do not trigger a "dirty" event
        this._suppressDirty = true;

        this._edges.push([portA, portB]);

        // Create a Wire
        let wire = Wire.create(this.options, portA, portB);

        // Append the wire to the SVG plane
        this.svgPlane.appendChild(wire.path);

        // Handle wire disconnection
        wire.on("disconnect", (event) => {
            this.disconnect(portA, portB, wire);

            // Make dirty
            this.dirty = true;
        });

        // Handle wire selection
        wire.on("select", (event) => {
            this.selectWire(wire);
        });

        // Determine best position of ports
        portA.reposition();
        portB.reposition();

        // Ensure the wire is now drawn at the location of the ports:
        wire.redraw();

        // Ensure node is properly visible at each side
        let nodeA = portA.node;
        let nodeB = portB.node;
        nodeA.visibility = nodeA.visibility;
        nodeB.visibility = nodeB.visibility;
        nodeA.move(nodeA.x, nodeA.y);
        nodeB.move(nodeB.x, nodeB.y);

        // Now trigger a dirty
        this._suppressDirty = false;
        this.dirty = true;
    }

    /**
     * Pans the workflow's viewport to the given x and y coordinate.
     *
     * @param {number} x The x coordinate.
     * @param {number} y The y coordinate.
     */
    moveTo(x, y) {
        this.world.moveTo(x, y);
    }

    /**
     * Pans the world relative to its current position.
     *
     * @param {number} deltaX The amount to move horizontally.
     * @param {number} deltaY The amount to move vertically.
     */
    pan(deltaX, deltaY) {
        this.world.pan(deltaX, deltaY);
    }

    /**
     * Returns the current pan X coordinate.
     */
    get x() {
        return this.world.x;
    }

    /**
     * Returns the current pan Y coordinate.
     */
    get y() {
        return this.world.y;
    }

    /**
     * Returns whether or not the workflow has changed since it was saved.
     */
    get dirty() {
        return this._dirty;
    }

    /**
     * Sets the dirtiness of the workflow.
     *
     * Will force it to a boolean value.
     */
    set dirty(value) {
        let old = this._dirty;
        this._dirty = !!value;

        if (!old && this._dirty) {
            this.trigger("dirty");
        }
    }

    /**
     * Forces a layout calculation.
     */
    layout() {
        this._nodes.forEach( (node) => {
            node.redraw();

            node.allPorts.forEach( (port) => {
                port.reposition();

                port.wires.forEach( (wire) => {
                    wire.redraw();
                });
            });
        });
    }

    /**
     * Redraws the workflow.
     */
    redraw(force = false) {
        if (force || (this.element.offsetWidth > 0 && this._lastRedrawWidth == 0)) {
            // We need to reposition as well
            this.layout();
        }

        this.world.redraw();
        this._lastRedrawWidth = this.element.offsetWidth;
    }

    /**
     * Retrieves an instance of the Palette.
     *
     * The Palette will yield a way to define interactable elements for creating
     * new nodes to the workflow.
     */
    get palette() {
        return this._palette;
    }

    /**
     * Retrieves the node list.
     */
    get nodes() {
        // Shallow copy the array.
        return this._nodes.slice();
    }

    /**
     * Retrieves the node at the given index.
     */
    nodeAt(index) {
        return this._nodes[index];
    }

    /**
     * Removes everything from this workflow.
     */
    clear() {
        // Destroy every node
        this._nodes.forEach( (node) => {
            node.destroy();
        });

        // Clear nodes
        this._nodes = [];

        // Make dirty
        this.dirty = true;
    }

    /**
     * Deserializes the given JSON and reloads the workflow.
     *
     * @param {object} json The JSON object serialization to use.
     */
    fromJSON(json) {
        // Do not trigger a "dirty" event
        this._suppressDirty = true;

        // Clear the workflow
        this.clear();

        // Load the workflow
        let center = json.center || {};
        let x = center.x || 0;
        let y = center.y || 0;

        this.world.center(x, y);

        (json.connections || []).forEach( (node) => {
            this.insertNode(node);
        });

        // Now that all of the nodes exist, we can wire them together.
        (json.connections || []).forEach( (node, nodeIndex) => {
            let fromNode = this.nodeAt(nodeIndex);

            ["inputs", "outputs", "ports"].forEach( (portType) => {
                (node[portType] || []).forEach( (port, portIndex) => {
                    (port.connections || []).forEach( (connectionInfo, wireIndex) => {
                        // Reform 'array' style connections
                        if (connectionInfo.to instanceof Array) {
                            connectionInfo.to = {
                                "node": connectionInfo.to[0],
                                "port": connectionInfo.to[1],
                                "wire": connectionInfo.to[2]
                            }
                        }

                        // Get the connected port
                        let toNode = this.nodeAt(connectionInfo.to.node);

                        if (portType === "inputs") {
                            let fromPort = fromNode.inputAt(portIndex);
                            let toPort = toNode.outputAt(connectionInfo.to.port);

                            if (fromPort && toPort) {
                                this.connect(fromPort, toPort);
                            }
                        }

                        if (portType === "ports") {
                            let fromPort = fromNode.portAt(portIndex);
                            let toPort = toNode.portAt(connectionInfo.to.port);

                            if (fromPort && toPort) {
                                this.connect(fromPort, toPort);
                            }
                        }
                    });
                });
            });
        });

        this.data = json.data || {};
        if(this.data){
            for(let userData in this.data) {
                this.element.setAttribute("data-" + userData, this.data[userData]);
            }
        }

        // Redraw wires, etc.
        this.redraw(true);

        // Now trigger a dirty
        this._suppressDirty = false;
        this.trigger("dirty");
    }

    /**
     * Serializes the workflow as a JSON document.
     *
     * @returns {object} The JSON object representing the workflow.
     */
    toJSON() {
        let jsonExport = {};

        // Store the center of the viewport
        jsonExport.center = {
            x: this.world.centerX,
            y: this.world.centerY
        };

        // Store each node
        jsonExport.connections = [];
        this.nodes.forEach( (node) => {
            jsonExport.connections.push(node.toJSON());
        });

        // Store any other data
        if (this.data) {
            jsonExport.data = this.data;
        }

        // Return it
        return jsonExport;
    }
}

/**
 * The default workflow options.
 */
Workflow.defaults = {
    horizontalSnapTolerance: 10,
    verticalSnapTolerance:   20,
    nodeOverlapTolerance:    20,
    padding:                 30,
    allowSelections:         true,
    allowPanning:            true,
    allowNewConnections:     true,
    allowNodeMovement:       true,
    allowNodeDeletion:       true,
    imageBaseURL:            "",
    inputOrientation:        "left",
    outputOrientation:       "right",
    portOrientation:         "right",
    portAutoOrientation:     "closest",
    highlightWiresOnSelect:  true,
    palette: {
        createOnDrag:        true,
    },
    wire: {
        horizontalThickness:   1,
        verticalThickness:     3,
        arcRadius:             20,
        width:                 50,
    },
    job: {
        aggregate:             32,
        radius:                23,
        thickness:             5,
        padding:               3,
    },
    debug: {
        showCollisionBoxes:      false,
        showLabelCollisionBoxes: false,
        showQuadtreeBoxes:       false,
    }
};

export default Workflow;
