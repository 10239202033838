"use strict";

import EventComponent         from './event_component.js';
import Occam                  from './occam.js';
import Util                   from './util.js';
import Modal                  from './modal.js';
import Markdown               from './markdown.js';
import Tooltip                from './tooltip.js';
import OccamObject            from './occam_object.js';
import Validator              from './validator.js';
import TagInput               from './tag_input.js';
import Dropdown               from './dropdown.js';

// TODO: pressing ENTER in a field should go to the next one
// TODO: A "show all" or "reveal all" that shows any hidden options
// TODO: Editor views should always 'show all' (or be on by default)

/**
 * This class handles a configuration form.
 */
class Configuration extends EventComponent {
    /**
     * Constructs a representation of the configuration within the given element.
     *
     * @param {HTMLElement} element - An `<occam-configuration>` element.
     */
    constructor(element, callback) {
        super();

        // Retain the `<occam-configuration>` element
        this._element = element;

        // Retain a listing of keys that 'enable' something. Each key points to
        // a dictionary where values point to an array of item keys it enables.
        // This is repeated for 'revealed'.
        this._actions = {
          "enabled": {},
          "revealed": {}
        };

        // We retain a logical listing of items for filtering purposes
        this._items = [];

        // We need a unique identifier for new array items so they properly
        // append (items need unique names in forms, so we attach a throwaway
        // index to them)
        this._newArrayItemIndex = 0;

        // And a logical listing of keys to items for lookup
        this._keys = {};

        // We retain a logical listing of all expandable items
        this._expandable = [];

        // Get the validation item template
        this._validationTemplate = element.querySelector("template.validation-item");

        // Get the item template
        this._itemTemplate = element.querySelector("template.configuration-item");

        // Give it a sequential id for local(?) use
        this.element.setAttribute('data-configuration-internal-id', Configuration._count);
        Configuration._count++;

        // Store it for fast usage
        Configuration._loaded.push(this);

        // If it needs to be async loaded, load it
        if (this.element.hasAttribute('data-pjax')) {
            this.load();
        }
        else {
            // Bind interactive events to its current state.
            this.bindEvents();

            // Poop out the schema just to test
            console.log("schema", this.schema);
            console.log("value", this.value);
        }
    }

    /**
     * Loads all the configurations it finds within the given element.
     */
    static loadAll(element) {
        var configurations = element.querySelectorAll('occam-configuration');
        let ret = [];

        configurations.forEach( (element) => {
            // Load all configurations
            ret.push(Configuration.load(element));
        });

        return ret;
    }

    /**
     * Loads a representation of the given element.
     */
    static load(element, callback) {
        if (element.getAttribute('data-configuration-internal-id')) {
            // Already parsed.
            var configuration = Configuration._loaded[parseInt(element.getAttribute('data-configuration-internal-id'))];
            if (callback) {
                configuration.load().then( (data) => callback(data) );
            }

            return configuration;
        }
        else {
            let ret = new Configuration(element);
            if (callback) {
                ret.load().then( (data) => callback(data) );
            }
            return ret;
        }
    }

    /**
     * Returns the configuration value data for this form.
     */
    get value() {
        return this.valueFor(this.element.querySelector(":scope > ul.configuration-group"));
    }

    /**
     * Returns the schema represented by this configuration form.
     */
    get schema() {
        return this.schemaFor(this.element.querySelector(":scope > ul.configuration-group"));
    }

    /**
     * Returns the HTMLElement representing the `<occam-configuration>` tag.
     *
     * @returns {HTMLElement} The represented `<occam-configuration>` element.
     */
    get element() {
        return this._element;
    }

    /**
     * Returns, if any, the URL used to load the configuration.
     */
    get url() {
        return this._url;
    }

    /**
     * Asynchronously loads the configuration from its attached URL.
     */
    async load() {
        if (!this._loadPromise) {
            let url = this.element.getAttribute('data-pjax')
            this.element.removeAttribute('data-pjax');
            this._loadPromise = this.fetch(url);
        }

        return this._loadPromise;
    }

    /**
     * Asynchronously loads the configuration form and data from the given URL.
     *
     * @param {String} url - The URL providing the HTML for the configuration.
     */
    async fetch(url) {
        if (!url) {
            return {};
        }

        // Retain its URL
        this._url = url;

        // Fetch.
        let response = await window.fetch(url);

        // Get it as text
        let html = await response.text();

        // Find the occam-configuration element
        let dummy = document.createElement('div');
        dummy.innerHTML = html;
        let element = dummy.querySelector('occam-configuration');
        if (!element) {
            throw "Cannot get the configuration content.";
        }

        // Place it in the space
        this.element.innerHTML = element.innerHTML;

        // Remove any loading indicators
        this.element.classList.remove("loading");
        if (this.element.parentNode) {
            this.element.parentNode.classList.remove("loading");
        }

        // Bind Occam events
        Occam.loadAll(this.element);

        // Now, bind the internal events
        this.bindEvents();

        // Return the configuration value.
        return this.value;
    }

    /**
     * Causes a download of the configuration schema.
     */
    exportSchema(filename = "schema.json") {
        // Wrap schema data in a blob
        let data = JSON.stringify(this.schema, null, 2);
        let url = window.URL.createObjectURL(
            new Blob([data], { type: "application/json" })
        );

        // Create a link
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);

        // Click the link
        link.click();

        // Remove the link from the page
        link.remove();
    }

    /**
     * Causes a download of the configuration value, as currently represented.
     */
    exportValues(filename = "configuration.json") {
        // Wrap schema data in a blob
        console.log("exporting", this.value);

        // Handle big integers (some things are gonna mess these up on import,
        // but what can you do!!)
        let replacer = (key, value) => {
            if (typeof value === 'bigint') {
                return "%%%bigint%%%" + value.toString() + "%%%";
            }
            return value;
        };

        // Convert it
        let data = JSON.stringify(this.value, replacer, 2);

        // Handle the bigint shims
        data = data.replace(/\"%%%bigint%%%([^%]+)%%%\"/, "$1");

        // OK! YAY! (try to reverse it)
        console.log("yay", window.JSONbig.parse(data));

        // Create the blob and the URL for the blob
        let url = window.URL.createObjectURL(
            new Blob([data], { type: "application/json" })
        );

        // Create a link
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);

        // Click the link
        link.click();

        // Remove the link from the page
        link.remove();
    }

    /**
     * Internal method called upon construction that binds interactive events.
     */
    bindEvents() {
        // For every item, bind item events
        this.element.querySelectorAll("li.configuration-item").forEach( (item) => {
            this.bindItemEvents(item);
        });

        let navigationBar = this.element.querySelector(":scope > nav");
        if (navigationBar) {
            this.bindNavigationEvents(navigationBar);
        }

        // Bind 'add item' and other generic events on the base group
        this.bindItemEditorEvents(this.element);

        // Press all actionable inputs (so the default actions occur)
        ["enabled", "revealed"].forEach( (action, i) => {
            Object.keys(this._actions[action]).forEach( (key) => {
                let element = this.elementFor(key);

                if (element) {
                    let input = this.inputFor(element);

                    if (input) {
                        let changeEvent = new Event('change', {});
                        input.dispatchEvent(changeEvent);
                    }
                }
            });
        });
    }

    /**
     * Adds interactive events to the element representing a configuration item.
     *
     * The `element` should point to a `<li>` with the `configuration-item`
     * class.
     *
     * @param {HTMLElement} element - The overall containing item `<li>` element.
     */
    bindItemEvents(element) {
        // Add to lookup
        this._keys[this.keyFor(element)] = element;

        // There might be an expand button to open the description.
        let expand = element.querySelector(":scope > .label > button.expand, .configuration-array-actions > .label > button.expand");
        let description = this.descriptionFor(element);
        if (expand && description) {
            if (!expand.classList.contains("bound")) {
                expand.classList.add("bound");
                expand.addEventListener('click', (event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    if (expand.classList.contains("open")) {
                        this.closeDescription(element);
                    }
                    else {
                        this.openDescription(element);
                    }
                });
            }

            // Retain this expandable item
            if (!element._addedToExpandable) {
                this._expandable.push(element);
                element._addedToExpandable = true;
            }
        }

        // Keep track of this item for filters, etc
        let label = element.querySelector(":scope > .label > label");
        if (label) {
            // Add to our filter listing
            element._item = {
                name: label.textContent,
                element: element
            };
            this._items.push(element._item);
        }

        // Add validation bindings to any inputs
        this.inputsFor(element).forEach( (input) => {
            this.bindItemValidations(element, input);
            this.bindItemInputEvents(element, input);
        });

        // If it is a group listing, add those events specifically
        if (element.classList.contains("configuration-item-group")) {
            this.bindItemGroupEvents(element);
        }

        // If it is an array, bind those events, too
        if (this.typeFor(element) === "array") {
            this.bindItemArrayEvents(element);

            // And the items
            let arrayItems = element.querySelector(":scope > .value > .configuration-array-items, :scope > .configuration-array-items");
            if (arrayItems) {
                arrayItems.querySelectorAll(":scope > li.configuration-array-item").forEach( (arrayItem) => {
                    this.bindItemArrayItemEvents(arrayItem);
                    this.bindItemEditorEvents(arrayItem);
                });
            }
        }
        else if (this.typeFor(element) === "color") {
            this.bindItemColorEvents(element);
        }
        else if (this.typeFor(element) === "date" ||
                 this.typeFor(element) === "time" ||
                 this.typeFor(element) === "datetime") {
            this.bindItemDateTimeEvents(element);
        }

        // Now we also bind any editor events for this item
        this.bindItemEditorEvents(element);

        // Now we go through and attach enable/reveal/disable/hide events
        this.bindItemActionEvents(element);
    }

    /**
     * Adds interactive events to 'date', 'time', and 'datetime' fields.
     */
    bindItemDateTimeEvents(element) {
        let type = this.typeFor(element);
        let input = this.inputFor(element);

        // Ignore if this was already bound to these events
        if (input.classList.contains("bound-date")) {
            return;
        }
        input.classList.add("bound-date");

        // Get the header value from the label
        let label = element.querySelector(":scope > .label > label");
        if (label) {
            label = label.textContent;
        }
        if (!label) {
            let key = this.keyFor(element);
            label = key;
        }

        // Figure out what kind of date/time format is needed.
        let format = "YYYY-MM-DDTHH:mm:ss";
        let plugins = [window.easepickPlugins.TimePlugin];
        if (type === "date") {
            format = "YYYY-MM-DD";
            plugins = [];
        }
        else if (type === "time") {
            format = null;
        }

        // Create the date picker
        if (format) {
            input._picker = new window.easepick.create({
                element: input,
                zIndex: 9999999,
                header: label,
                format: format,
                css: [
                    window.location.protocol + '//' + window.location.host + '/css/easepick.css'
                ],
                plugins: plugins,
                TimePlugin: {
                    format: 'HH:mm:ss',
                    seconds: true,
                    stepMinutes: 1,
                    stepSeconds: 1,
                }
            });

            input._picker.on('select', (event) => {
                // Update internal notion of this value
                this.setValue(element, this.valueFor(element), false);
            });
        }
    }

    /**
     * Adds interactive events to 'color' fields.
     */
    bindItemColorEvents(element) {
        let input = this.inputFor(element);

        // Ignore if this was already bound to these events
        if (input.classList.contains("bound-color")) {
            return;
        }
        input.classList.add("bound-color");

        // Bind color picker events
        let picker = new window.jscolor(input, {
            valueElement: input,
            closeButton: true,
            // Tol Color Palette
            palette: '#332288\n#117733\n#44AA99\n#88CCEE\n#DDCC77\n#CC6677\n#AA4499\n#882255',
            hideOnPaletteClick: true,
            alphaChannel: true,
            onInput: () => {
            },
        });

        // Retain as part of the input
        input._picker = picker;

        // TODO: handle the default value instead
        picker.fromRGBA(0, 0, 0, 1.0);

        input.addEventListener('mousedown', (event) => {
            event.stopPropagation();
            event.preventDefault();

            // Show the color picker
            picker.show();

            // Ensure the button in the picker is styled properly
            let pickerButton = document.querySelector(".jscolor-btn-close");
            if (pickerButton) {
                pickerButton.classList.add('button');
            }
        });
    }

    /**
     * Adds interactive events to the element for editing the configuration item.
     *
     * The `element` should point to a `<li>` with the `configuration-item`
     * class.
     *
     * @param {HTMLElement} element - The overall containing item `<li>` element.
     */
    bindItemEditorEvents(element) {
        // There might be an expand button to open the editor.
        let expands = element.querySelectorAll(":scope > h2 > button.expand-editor, :scope > .label > button.expand-editor, .configuration-array-actions > .label > button.expand-editor");
        let editor = this.editorFor(element);

        // Add a new item to a configuration group
        let addItemButton = element.querySelector(":scope > ul > .configuration-item-actions > button.configuration-item-add");
        if (addItemButton && !addItemButton.classList.contains("bound-add-item")) {
            addItemButton.classList.add("bound-add-item");
            addItemButton.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();

                if (this._itemTemplate) {
                    let newItem = Util.createElementFromTemplate(this._itemTemplate);
                    let newItemElement = newItem.querySelector(":scope > li");
                    let newItemEditor = newItem.querySelector(":scope > .configuration-item-editor");

                    if (newItemElement) {
                        addItemButton.parentNode.parentNode.insertBefore(newItemElement, addItemButton.parentNode);
                    }

                    if (newItemEditor) {
                        addItemButton.parentNode.parentNode.insertBefore(newItemEditor, addItemButton.parentNode);
                    }

                    // TODO: update tab panels id for the markdown editor since
                    // it will not be unique on the page
                    let parentKey = "";
                    if (element.classList.contains("configuration-item")) {
                        parentKey = this.keyFor(element) + ".";
                    }

                    if (newItemElement) {
                        let newEditor = this.editorFor(newItemElement);

                        if (newEditor) {
                            let keyField = newEditor.querySelector('.editing-table-field[data-field="key"] input');
                            let newKey = parentKey + keyField.value;

                            // Cause an error if the key/label exists elsewhere
                            if (this._keys[newKey] && this._keys[newKey] !== element) {
                                // Ok, we choose a new key that iterates from the
                                // template key by appending an index.
                                for (let i = 2; i <= 100; i++) {
                                    newKey = parentKey + keyField.value + "-" + i.toString();

                                    if (!(this._keys[newKey] && this._keys[newKey] !== element)) {
                                        // Set the key in the field
                                        keyField.value = keyField.value + "-" + i.toString();
                                        break;
                                    }
                                }

                                // Update the key
                                newItemElement.setAttribute('data-key',
                                    Util.encodeURLSafeBase64(keyField.value)
                                );
                            }
                        }

                        // Finally, bind the item events
                        this.bindItemEvents(newItemElement);
                    }

                    console.log("new schema", this.schema);
                    console.log("new value", this.value);

                    // Save the schema
                    this.saveSchema();
                }
            });
        }

        // If there is an editor, we can handle those events specifically
        if (expands && editor) {
            // There might be multiple editor show buttons since items turn into
            // groups and retain their old settings for undo-ing.
            expands.forEach( (expand) => {
                if (!expand.classList.contains("bound")) {
                    expand.classList.add("bound");
                    expand.addEventListener('click', (event) => {
                        event.preventDefault();
                        event.stopPropagation();

                        if (expand.classList.contains("open")) {
                            this.closeEditor(element);
                        }
                        else {
                            this.openEditor(element);
                        }
                    });
                }
            });

            // Ignore if this was already bound to these events
            if (element.classList.contains("bound-editor")) {
                return;
            }
            element.classList.add("bound-editor");

            // Get fields
            let unitsField = editor.querySelector('.editing-table-field[data-field="units"] input');
            let keyField = editor.querySelector('.editing-table-field[data-field="key"] input');
            let labelField = editor.querySelector('.editing-table-field[data-field="label"] input');
            let itemsField = editor.querySelector('.editing-table-field[data-field="items"] input');
            let itemsTags = TagInput.load(itemsField);
            let typeField = editor.querySelector('.editing-table-field[data-field="type"] select');
            let defaultField = editor.querySelector('.editing-table-field[data-field="default"] input');
            let elementField = editor.querySelector('.editing-table-field[data-field="element"] select');

            // Update label when you type in the label or key field
            let label = element.querySelector(":scope > .label > label");
            if (keyField && labelField) {
                let updateLabel = (event) => {
                    // Determine the label (fallback to key)
                    let label = element.querySelector(":scope > .label > label");
                    let header = element.querySelector(":scope > h2 > .label");

                    let newLabel = labelField.value || keyField.value;
                    if (label) {
                        // Update the label in the form itself
                        label.textContent = newLabel;
                    }

                    if (header) {
                        // Update the label in the header of a group/array
                        header.textContent = newLabel;
                    }

                    // Update the tooltips on inputs
                    let input = this.inputFor(element);
                    if (input) {
                        input.setAttribute('title', newLabel);
                    }

                    // Update its internal listing for filter purposes
                    if (element._item) {
                        element._item.name = newLabel;
                    }

                    if (event.target === keyField) {
                        let parentKey = "";
                        let parent = this.parentFor(element);
                        if (parent) {
                            parentKey = this.keyFor(parent) + ".";
                        }
                        let newKey = parentKey + keyField.value;

                        // Cause an error if the key/label exists elsewhere
                        if (this._keys[newKey] && this._keys[newKey] !== element) {
                            keyField.parentNode.classList.add("error");
                        }
                        else {
                            // Remove any error on the field
                            keyField.parentNode.classList.remove("error");

                            // Set the new lookup
                            delete this._keys[this.keyFor(element)];
                            this._keys[newKey] = element;

                            // Set the base key
                            element.setAttribute('data-key',
                                Util.encodeURLSafeBase64(keyField.value)
                            );
                        }
                    }

                    // Save the schema
                    this.saveSchema();
                };

                // Both 'key' and 'label' can affect the name of the field as
                // seen by the actor.
                [keyField, labelField].forEach( (field) => {
                    field.addEventListener('keyup', updateLabel.bind(this));
                    field.addEventListener('change', updateLabel.bind(this));
                });
            }

            // Add items to an enumerated type
            if (itemsField) {
                itemsField.addEventListener('change', (event) => {
                    let select = this.inputFor(element);
                    if (select) {
                        select.innerHTML = "";

                        itemsTags.value.forEach( (tag) => {
                            let option = document.createElement("option");
                            option.textContent = tag;
                            option.setAttribute('value', tag);
                            select.appendChild(option);
                        });
                    }

                    // Save the schema
                    this.saveSchema();
                });
            }

            // Update the description
            let markdownField = editor.querySelector('.markdown-editor');
            if (markdownField) {
                // Load the markdown editor
                let markdown = Markdown.load(markdownField);

                // When the markdown changes, we update the description html
                markdown.on('change', () => {
                    let description = this.descriptionFor(element);
                    if (description) {
                        description = description.querySelector('.description-content');
                    }

                    if (description) {
                        description.innerHTML = markdown.html;
                    }

                    // Save the schema
                    this.saveSchema();
                });
            }

            // Update type
            if (typeField) {
                typeField.addEventListener('change', (event) => {
                    // Show/Hide editor options that relate to this type
                    if (["array"].indexOf(typeField.value) >= 0) {
                        // Show 'element'
                        if (elementField) {
                            elementField.parentNode.removeAttribute('hidden');
                            elementField.parentNode.removeAttribute('aria-hidden');
                        }
                    }
                    else {
                        if (elementField) {
                            elementField.parentNode.setAttribute('hidden', '');
                            elementField.parentNode.setAttribute('aria-hidden', 'true');
                        }
                    }

                    if (["enum"].indexOf(typeField.value) >= 0) {
                        // Show 'items'
                        if (itemsField) {
                            itemsField.parentNode.removeAttribute('hidden');
                            itemsField.parentNode.removeAttribute('aria-hidden');
                        }
                    }
                    else {
                        if (itemsField) {
                            itemsField.parentNode.setAttribute('hidden', '');
                            itemsField.parentNode.setAttribute('aria-hidden', 'true');
                        }
                    }

                    if (["array", "group"].indexOf(typeField.value) >= 0) {
                        // Hide 'default'
                        if (defaultField) {
                            defaultField.parentNode.setAttribute('hidden', '');
                            defaultField.parentNode.setAttribute('aria-hidden', 'true');
                        }
                    }
                    else {
                        if (defaultField) {
                            // For "color" fields, we show it but it should be a
                            // color picker. TODO
                            defaultField.parentNode.removeAttribute('hidden');
                            defaultField.parentNode.removeAttribute('aria-hidden');
                        }
                    }

                    // Update the type (update input field)
                    this.updateItemType(element, typeField.value);

                    // Reset the enum values
                    if (typeField.value === "enum" && itemsField) {
                        let select = this.inputFor(element);
                        if (select) {
                            select.innerHTML = "";

                            itemsTags.value.forEach( (tag) => {
                                let option = document.createElement("option");
                                option.textContent = tag;
                                option.setAttribute('value', tag);
                                select.appendChild(option);
                            });
                        }
                    }

                    // Reset the units and Show/Hide 'units'
                    if (["array", "enum", "boolean", "group", "color"].indexOf(typeField.value) >= 0) {
                        // Hide 'units'
                        if (unitsField) {
                            unitsField.parentNode.setAttribute('hidden', '');
                            unitsField.parentNode.setAttribute('aria-hidden', 'true');
                        }
                    }
                    else {
                        if (unitsField) {
                            unitsField.parentNode.removeAttribute('hidden');
                            unitsField.parentNode.removeAttribute('aria-hidden');

                            // Reset the units for the new input
                            let input = this.inputFor(element);
                            if (input) {
                                input.setAttribute('data-units', Util.encodeURLSafeBase64(
                                    unitsField.value
                                ));
                                this.updateUnitsPlacement(input);
                            }
                        }
                    }

                    // Save the schema
                    this.saveSchema();
                });
            }

            // Update units on any input when specified
            if (unitsField) {
                let updateUnits = () => {
                    let input = this.inputFor(element);
                    if (input) {
                        // We remove the units visual since we will force it to
                        // add it back to 'update' it.
                        let unitsElement = input.parentNode.querySelector("span.units");
                        if (unitsElement) {
                            // To reset, we remove the units and reset the
                            // right-hand padding.
                            unitsElement.remove();
                            input.style.paddingRight = "0px";
                        }

                        // Add the 'data-units' attribute which is used to
                        // create the visual.
                        if (unitsField.value) {
                            input.setAttribute('data-units', Util.encodeURLSafeBase64(
                                unitsField.value
                            ));
                        }
                        else {
                            input.removeAttribute('data-units');
                        }

                        // Re-create the visual
                        this.updateUnitsPlacement(input);
                    }

                    // Save the schema
                    this.saveSchema();
                };

                // Both 'key' and 'label' can affect the name of the field as
                // seen by the actor.
                unitsField.addEventListener('keyup', updateUnits.bind(this));
                unitsField.addEventListener('change', updateUnits.bind(this));
            }

            let removeButton = editor.querySelector(":scope button.configuration-item-delete");
            if (removeButton) {
                removeButton.addEventListener('click', (event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    // Delete the item
                    this.removeItem(element);

                    // Save the schema
                    this.saveSchema();
                });
            }

            // Retain this expandable item
            if (!element._addedToExpandable) {
                this._expandable.push(element);
                element._addedToExpandable = true;
            }
        }
    }

    /**
     * Adds interactive events for an input of a particular item.
     *
     * @param {HTMLElement} element - The overall containing item `<li>` element.
     * @param {HTMLElement} input - The input element of whatever kind.
     */
    bindItemInputEvents(element, input) {
        // Ignore if this was already bound to these events
        if (input.classList.contains("bound-inputs")) {
            return;
        }
        input.classList.add("bound-inputs");

        let label = element.querySelector(":scope > .label > label");

        // Highlight the item when you mouseover an input
        input.addEventListener('mouseenter', (event) => {
            if (label && !input.hasAttribute('disabled')) {
                label.classList.add("inspecting");
            }
        });

        input.addEventListener('mouseleave', (event) => {
            if (label) {
                label.classList.remove("inspecting");
            }
        });

        input.addEventListener('change', (event) => {
            // Update placement of 'units' description
            this.updateUnitsPlacement(input);

            // Update internal notion of this value
            this.setValue(element, this.valueFor(element), false);
        });

        input.addEventListener('keypress', (event) => {
            // Update placement of 'units' description
            this.updateUnitsPlacement(input);

            // Update internal notion of this value
            this.setValue(element, this.valueFor(element), false);
        });

        input.addEventListener('input', (event) => {
            // Update placement of 'units' description
            this.updateUnitsPlacement(input);

            // Update internal notion of this value
            this.setValue(element, this.valueFor(element), false);
        });

        // Initial placement of 'units' description
        this.updateUnitsPlacement(input);
    }

    /**
     * Internal method to update the positioning of the 'units' text.
     *
     * @param {HTMLElement} input - The input element to use.
     */
    updateUnitsPlacement(input) {
        if (input.hasAttribute("data-units")) {
            // Get width of text inside the input
            var computedStyle = window.getComputedStyle(input);
            var computedFont = computedStyle.fontWeight + " " + computedStyle.fontSize + " " + computedStyle.fontFamily;
            var textWidth = Util.getTextWidth(input.value, computedStyle.font);

            var unitsElement = input.nextElementSibling;
            var unitsWidth = 0;
            if (!unitsElement || !unitsElement.classList.contains("units")) {
                unitsElement = document.createElement("span");
                unitsElement.classList.add("units");
                unitsElement.textContent = "\xa0" + Util.decodeURLSafeBase64(
                    input.getAttribute("data-units")
                );
                unitsElement.style.font = computedStyle.font;
                input.parentNode.insertBefore(unitsElement, input.nextElementSibling);

                unitsWidth = Util.getTextWidth(unitsElement.textContent, computedFont);

                //input.style.width = (parseInt(computedStyle.width) - unitsWidth) + "px";
                input.style.paddingRight = "0px";
                computedStyle = window.getComputedStyle(input);
                input.style.paddingRight = parseInt(computedStyle.paddingRight) + unitsWidth + 10 + "px";
            }
            else {
                unitsWidth = Util.getTextWidth(unitsElement.textContent, computedFont);
            }

            unitsElement.style.right = "0px";
            var newRight = parseInt(computedStyle.width) - parseInt(computedStyle.paddingLeft) - unitsWidth + input.scrollLeft - (input.scrollLeft ? 0 : parseInt(computedStyle.paddingLeft)) - textWidth;
            newRight = Math.max(newRight, parseInt(computedStyle.paddingLeft));
            unitsElement.style.right = newRight + "px";
        }
    }

    /**
     * Removes the item from an editable configuration.
     *
     * This presumes the item is from an editable view. This is usually true by
     * having such items call this method from, say, a remove button event.
     */
    removeItem(element) {
        let editor = this.editorFor(element);
        editor.remove();

        // We need to remove the element from the document
        element.remove();

        // And remove it from internal lists
        if (element._item) {
            this._items.splice(this._items.indexOf(element._item), 1);
            element._item = null;
        }

        if (element._addedToExpandable) {
            this._expandable.splice(this._expandable.indexOf(element), 1);
            element._addedToExpandable = false;
        }
    }

    /**
     * Adds interactive events to support array configuration items.
     *
     * @param {HTMLElement} element - The overall containing item `<li>` element.
     */
    bindItemArrayEvents(element) {
        // Ignore it if it was already bound with these events
        if (element.classList.contains("bound-array")) {
            return;
        }
        element.classList.add("bound-array");

        // Find the template for the array
        let itemTemplate = element.querySelector(":scope > .value > template, :scope > template");

        // Find the array items listing
        let arrayItems = element.querySelector(":scope > .value > .configuration-array-items, :scope > .configuration-array-items");

        // Find the 'add' button
        let addButton = element.querySelector(":scope > .value > button.array-add, :scope > .configuration-array-actions > .value > button.array-add");

        if (addButton) {
            if (!addButton.classList.contains("bound")) {
                addButton.classList.add("bound");
                addButton.addEventListener('click', (event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    if (itemTemplate) {
                        // Clone the item template
                        let item = Util.createElementFromTemplate(itemTemplate);
                        if (item && arrayItems) {
                            // Acquire the new index
                            let index = arrayItems.querySelectorAll(":scope > li").length;
                            
                            // Acquire a random key for this new item
                            index = "--" + this._newArrayItemIndex;
                            this._newArrayItemIndex++;

                            // Update its key to include the index
                            // (This is used for complex array types that add groups)
                            let key = null;
                            let newKey = null;
                            if (item.hasAttribute('data-key')) {
                                key = Util.decodeURLSafeBase64(item.getAttribute('data-key'));
                                item.setAttribute('data-key', key + "(" + index + ")");
                                key = this.keyFor(element);
                            }

                            // Add to the configuration form
                            arrayItems.appendChild(item);

                            // Update all input fields to reflect that key
                            item.querySelectorAll('input[name*="data["], input[name*="nullify["], input[name*="datatype["]').forEach( (input) => {
                                if (key === null) {
                                    // For simple arrays (just a single field), the
                                    // old key is the full name
                                    key = input.getAttribute('name');
                                    let bracket = key.indexOf('[');
                                    key = key.substring(bracket);
                                    bracket = key.lastIndexOf("[");
                                    let base = key.substring(bracket + 1, key.length - 1);
                                    base = Util.decodeURLSafeBase64(base);
                                    base = base + "(" + index + ")";
                                    base = Util.encodeURLSafeBase64(base);
                                    newKey = key.substring(0, bracket) + "[" + base + "]";
                                }
                                else if (newKey === null) {
                                    // For complex arrays, we must replace the
                                    // initial section within the key with the
                                    // appropriate new key.
                                    key = this.formNameForKey(key);
                                    bracket = key.lastIndexOf("[");
                                    let base = key.substring(bracket + 1, key.length - 1);
                                    base = Util.decodeURLSafeBase64(base);
                                    base = base + "(" + index + ")";
                                    base = Util.encodeURLSafeBase64(base);
                                    newKey = key.substring(0, bracket) + "[" + base + "]";
                                }

                                let name = input.getAttribute('name');
                                let bracket = name.indexOf('[');
                                name = name.substring(0, bracket) + newKey + name.substring(bracket + key.length);
                                console.log("need to fix up", input, key, newKey, name);
                                input.setAttribute('name', name);
                            });

                            // Bind events to the inputs
                            this.bindItemEvents(element);

                            // Bind Occam events
                            Occam.loadAll(element);

                            // Bind events to the inputs within the item
                            // grouping, too, if it is a complex array.
                            let group = item.querySelector(":scope > .configuration-group");
                            let input = null;
                            if (group) {
                                group.querySelectorAll("li.configuration-item").forEach( (item) => {
                                    this.bindItemEvents(item);
                                });

                                // Focus on the first input
                                input = this.inputFor(group);
                            }
                            else {
                                // Focus on the new input
                                input = this.inputFor(item);
                            }

                            if (input) {
                                input.focus();
                            }

                            // Save the empty item
                            this.save();
                        }
                    }
                });
            }
        }
    }

    /**
     * Adds interactive events to array items and their actions.
     *
     * @param {HTMLElement} element - The `<li>` element for the array item.
     */
    bindItemArrayItemEvents(element) {
        // Ignore if this was already bound to these events
        if (element.classList.contains("bound-array-item")) {
            return;
        }
        element.classList.add("bound-array-item");

        // Find 'delete' button
        let deleteButton = element.querySelector(":scope > .value > button.configuration-array-delete");

        if (deleteButton) {
            deleteButton.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();

                // Remove the array item
                element.remove();

                // Save the new value set
                this.save();
            });
        }
    }

    /**
     * Adds interactive events for items that represent groups of items.
     *
     * @param {HTMLElement} element - The overall containing item `<li>` element.
     */
    bindItemGroupEvents(element) {
        // Ignore if this was already bound to these events
        if (element.classList.contains("bound-group")) {
            return;
        }
        element.classList.add("bound-group");

        // Find the group itself.
        let group = element.querySelector(":scope > .configuration-group");

        // Get the header, if any
        let header = element.querySelector(":scope > h2");
        if (header) {
            header.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();

                // Show/Hide group
                if (element.classList.contains('expanded')) {
                    this.collapse(element);
                }
                else {
                    this.expand(element);
                }
            });

            // Retain a reference to this item since it can be collapsed
            if (!element._addedToExpandable) {
                this._expandable.push(element);
                element._addedToExpandable = true;
            }
        }
    }

    /**
     * Adds validations to the given input element, if provided.
     *
     * The `element` should point to some kind of `<input>` element or other
     * element that has a value. Generally it will have a `configuration-input`
     * class attached.
     *
     * @param {HTMLElement} element - The containing item element for the input.
     * @param {HTMLElement} input - The input element to bind.
     */
    bindItemValidations(element, input) {
        // Ignore if this was already bound to these events
        if (input.classList.contains("bound-validations")) {
            return;
        }
        input.classList.add("bound-validations");

        let key = this.keyFor(element);

        input.addEventListener('change', (event) => {
            this.validateItem(element, input);

            let act = [this.enable.bind(this), this.show.bind(this)];
            let reverse = [this.disable.bind(this), this.hide.bind(this)];

            ["enabled", "revealed"].forEach( (action, i) => {
                if (this._actions[action][key]) {
                    let value = this.valueFor(element);

                    // Enable any keys that look for this current value
                    (this._actions[action][key][value] || []).forEach( (subKey) => {
                        let element = this.elementFor(subKey);
                        if (element) {
                            act[i](element);
                        }
                    });

                    // Disable the rest
                    Object.keys(this._actions[action][key]).forEach( (otherValue) => {
                        if (otherValue !== ("" + value)) {
                            (this._actions[action][key][otherValue] || []).forEach( (subKey) => {
                                let element = this.elementFor(subKey);
                                if (element) {
                                    reverse[i](element);
                                }
                            });
                        }
                    });
                }
            });
        });

        input.addEventListener('blur', (event) => {
            this.validateItem(element, input);
        });
    }

    /**
     * Adds interactive events that fulfill any enabledBy/revealedBy/disabledBy/hiddenBy action.
     *
     * If the `data-enabledBy` attribute exists, this will have a base64 encoded
     * JSON string that describes the keys and values that will enable the
     * option. This means the input is initially disabled.
     *
     * @param {HTMLElement} element - The item's `<li>` element.
     */
    bindItemActionEvents(element) {
        let reverse = [this.disable.bind(this), this.hide.bind(this)];

        ["enabled", "revealed"].forEach( (action, i) => {
            if (!element.hasAttribute(`data-${action}-by`)) {
                return;
            }

            // Assume the item is in the 'off' state
            reverse[i](element);

            // Get and store the keys
            let keys = JSON.parse(Util.decodeURLSafeBase64(element.getAttribute(`data-${action}-by`)));

            // Get our own key
            let key = this.keyFor(element);

            // Get our description
            let description = this.descriptionFor(element);

            // Retain the reference for the enables so that when an input changes
            // it can look it up and react.
            Object.keys(keys).forEach( (subKey) => {
                this._actions[action][subKey] = this._actions[action][subKey] || {};
                this._actions[action][subKey][keys[subKey]] = this._actions[action][subKey][keys[subKey]] || [];
                this._actions[action][subKey][keys[subKey]].push(key);

                // TODO: add something to the description so they can know how to enable it
                if (description) {
                }
            });
        });
    }

    /**
     * Updates the item with respect to an updated schema.
     *
     * The item's input regions will be updated to reflect the new schema
     * parameters. Any values will be removed and the item will reflect its form
     * if it was empty and new.
     *
     * @param {HTMLElement} element - The item's `<li>` element.
     * @param {String} type - The item's new type.
     */
    updateItemType(element, type) {
        // Find the value template for this type and replace the value with it.
        let template = this.element.querySelector(`template.configuration-item-${type}`);
        if (!template) {
            throw `Type ${type} not understood`;
        }

        let dummy = Util.createElementFromTemplate(template);
        if (!dummy) {
            throw `Cannot create new input for type ${type}`;
        }

        if (type === "group") {
            // This hides the value and changes this to a header instead.
            let label = element.querySelector(":scope > .label");
            if (label) {
                label.setAttribute('hidden', '');
                label.setAttribute('aria-hidden', 'true');
            }

            let value = element.querySelector(":scope > .value");
            if (value) {
                value.setAttribute('hidden', '');
                value.setAttribute('aria-hidden', 'true');
            }

            // Find and show or create the header for the group
            let header = element.querySelector(":scope > h2");
            if (!header) {
                // Get the template for a group
                header = dummy.querySelector(":scope > li.configuration-item > h2");
                element.appendChild(header);

                // Also add the empty list of items (and the add item button)
                let listing = dummy.querySelector(":scope > li.configuration-item > ul");
                if (listing) {
                    element.appendChild(listing);
                }
            }
            header.removeAttribute('hidden');
            header.removeAttribute('aria-hidden');

            // Ensure the editor expand button is 'open'
            let expand = header.querySelector("button.expand-editor");
            if (expand) {
                expand.classList.add("open");
            }

            // Get the old label and apply it
            header = header.querySelector(":scope > .label");
            header.textContent = "";
            if (label) {
                label = label.querySelector("label");
                if (label) {
                    header.textContent = label.textContent;
                }
            }

            // Add the group class
            element.classList.add("configuration-item-group");
        }
        else {
            // Remove the group class
            element.classList.remove("configuration-item-group");

            // Show the label/value
            let label = element.querySelector(":scope > .label");
            if (label) {
                label.removeAttribute('hidden');
                label.removeAttribute('aria-hidden');
            }
            else {
                // Add it from the dummy template
                label = dummy.querySelector(":scope > li.configuration-item .label");
                element.appendChild(label);
            }

            let value = element.querySelector(":scope > .value");
            if (value) {
                value.removeAttribute('hidden');
                value.removeAttribute('aria-hidden');
            }
            else {
                // Add it from the dummy template
                value = dummy.querySelector(":scope > li.configuration-item .value");
                element.appendChild(value);
            }

            // Hide any header (if it was a group)
            let header = element.querySelector(":scope > h2");
            if (header) {
                header.setAttribute('hidden', '');
                header.setAttribute('aria-hidden', 'true');
            }

            // Get the current schema
            let schema = this.schemaFor(element);

            // Replace the input
            let input = this.inputFor(element);
            dummy = dummy.querySelector(":scope > li.configuration-item .value");

            // Actually replace it in the document
            if (value) {
                value.parentNode.insertBefore(dummy, value);
                value.remove();
            }

            // Assign new type
            element.setAttribute('data-type',
                Util.encodeURLSafeBase64(
                    JSON.stringify(type)
                )
            );

            // Re-establish the input element
            input = this.inputFor(element);

            // Re-add parameters to the input
            if (schema.units) {
                input.setAttribute('data-units', Util.encodeURLSafeBase64(
                    schema.units
                ));
                this.updateUnitsPlacement(input);
            }

            // Assign validations, enables, etc
        }

        // Rebind
        this.bindItemEvents(element);

        console.log("new schema", this.schema);
        console.log("new value", this.value);
    }

    /**
     * Determines the parent group item that contains the given item.
     *
     * If the parent would be the root, this returns null instead of returning
     * that root group element since that group is not within a `<li>`.
     *
     * @returns {HTMLElement|null} The containing `<li>` element for this item or null.
     */
    parentFor(element) {
        // Return the parent group, if any. The root is `null`.
        let parents = Util.getParents(element, ".configuration-item", ".configuration-item");

        if (parents) {
            return parents[0];
        }

        return null;
    }

    /**
     * Determines the key for the given item element.
     */
    keyFor(element) {
        // Our own key and our parent's key
        if (!element.getAttribute('data-key')) {
            console.log("what", element);
        }
        let key = Util.decodeURLSafeBase64(element.getAttribute('data-key'));
        let parent = this.parentFor(element);
        if (parent) {
            // TODO: the parent of an array item should append the index as well
            key = this.keyFor(parent) + "." + key;
        }

        return key;
    }

    /**
     * Returns the form name that encodes the given key.
     */
    formNameForKey(key) {
        let ret = "";
        let parts = key.split('.');
        parts.forEach( (subKey) => {
            // TODO: array parts, which add '(i)' to the encoded key
            let b64key = Util.encodeURLSafeBase64(subKey);
            ret = ret + "[" + b64key + "]";
        });
        console.log("form name for", key, "is", ret);

        return ret;
    }

    /**
     * Retrieves the item element that represents the provided key.
     *
     * @param {String} key - The configuration key to lookup.
     *
     * @returns {HTMLElement|null} The configuration item's `<li>` or null.
     */
    lookup(key, from) {
        from = from || this.element.querySelector(":scope > ul.configuration-group");

        // Split by '.' and look up the remaining key by encoding it as a
        // base64 urlsafe string.
        let b64key = Util.encodeURLSafeBase64(key)

        return from.querySelector(`:scope > li[data-key="${b64key}"]`);
    }

    /**
     * Disables the given item and its inputs via its base `<li>` element.
     *
     * @param {HTMLElement} element - The item's `<li>` element.
     */
    disable(element) {
        // For every input, disable it; the proper way, if possible
        this.inputsFor(element).forEach( (input) => {
            element.classList.add('configuration-item-disabled');
            input.setAttribute('disabled', '');
        });

        // For groups, we just apply the configuration-item-disabled class
        if (element.classList.contains('configuration-item-group')) {
            element.classList.add('configuration-item-disabled');
        }

        // Collapse it, if it can be collapsed
        this.collapse(element);
    }

    /**
     * Enables the given item and its inputs via its base `<li>` element.
     *
     * @param {HTMLElement} element - The item's `<li>` element.
     */
    enable(element) {
        // For every input, enable it
        this.inputsFor(element).forEach( (input) => {
            element.classList.remove('configuration-item-disabled');
            input.removeAttribute('disabled');
        });

        // For groups, we just remove the configuration-item-disabled class
        if (element.classList.contains('configuration-item-group')) {
            element.classList.remove('configuration-item-disabled');
        }
    }

    /**
     * Reveals the given item.
     *
     * @param {HTMLElement} element - The item's `<li>` element.
     */
    show(element) {
        element.removeAttribute('hidden');
        element.removeAttribute('aria-hidden');
    }

    /**
     * Hides the given item.
     *
     * @param {HTMLElement} element - The item's `<li>` element.
     */
    hide(element) {
        element.setAttribute('hidden', '');
        element.setAttribute('aria-hidden', 'true');
    }

    /**
     * Adds interactive events to the navigation bar elements.
     *
     * @param {HTMLElement} element - The `<nav>` element to use.
     */
    bindNavigationEvents(element) {
        // Bind the search filter input
        let searchInput = element.querySelector(':scope > input[name="configuration-search"]');
        if (searchInput) {
            let searchClearButton = element.querySelector(":scope > button.configuration-search-clear");

            if (searchClearButton) {
                // Clear search input and simulate a key event
                searchClearButton.addEventListener('click', (event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    searchInput.value = "";
                    let keyevent = document.createEvent("KeyboardEvent");
                    keyevent.initEvent("keyup", false, true);
                    searchInput.dispatchEvent(keyevent);
                });
            }

            searchInput.addEventListener('keyup', (event) => {
                // Do not filter if the value has not changed
                if (searchInput._last === searchInput.value) {
                    return;
                }
                searchInput._last = searchInput.value;

                let value = searchInput.value.toLowerCase();
                let group = this.element.querySelector(":scope > .configuration-group");

                if (!group) {
                    return;
                }

                // Ensure all items are removed from the filter list
                let items = group.querySelectorAll("li.configuration-item.filtered");
                items.forEach( (item) => {
                    item.classList.remove("filtered");
                });

                // When the filter is blank, do not filter at all
                if (value === "") {
                    group.classList.remove("filtering");
                }
                else {
                    group.classList.add("filtering");

                    // Find all matching items and tag them
                    this._items.forEach( (item) => {
                        if (item.name.toLowerCase().indexOf(value) != -1) {
                            item.element.classList.add("filtered");
                        }
                    });
                }
            });
        }

        // Bind the collapse all button
        let collapseAllButton = element.querySelector(':scope > button.configuration-collapse-all');
        if (collapseAllButton) {
            collapseAllButton.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();

                this.collapseAll();
            });
        }

        // Get the download dropdown
        let exportDropdownElement = element.querySelector(':scope > .dropdown-menu.configuration-export');
        if (exportDropdownElement) {
            let exportDropdown = Dropdown.load(exportDropdownElement);
            exportDropdown.on('selected', (item) => {
                item.event.preventDefault();
                item.event.stopPropagation();

                // Perform event
                if (item.element.classList.contains('configuration-export-schema-button')) {
                    this.exportSchema();
                }
                else if (item.element.classList.contains('configuration-export-values-button')) {
                    this.exportValues();
                }
            });
        }
    }

    /**
     * Gives the default value for the given input element or null if none.
     *
     * @param {HTMLElement} input - The containing input element.
     *
     * @returns {string|null} The default value or null if none.
     */
    defaultFor(input) {
        // If it doesn't have a default, return `null`
        if (!input.hasAttribute('data-default')) {
            return null;
        }

        // Retain it just to avoid the decode the second time
        if (!input._default) {
            input._default = JSON.parse(Util.decodeURLSafeBase64(input.getAttribute('data-default')));
        }

        return input._default;
    }

    /**
     * Updates the value of the given item element or key.
     */
    setValue(key_or_element, value, updateInput = true) {
        let element = key_or_element;
        if (!key_or_element.tagName) {
            element = this.elementFor(key_or_element);
        }

        if (!element) {
            throw "Cannot find configuration element to set.";
            return;
        }

        // null out value
        element._value = null;
        let input = this.inputFor(element);
        if (input) {
            input._value = null;
        }

        // If we update the input field, then do that.
        // (It may be false if we are reacting to an input event and therefore
        // the input is already established)
        if (updateInput) {
            // Set the input field to the provided value
            if (input) {
                input.value = value || "";
            }
        }

        // Re-cache the value from the input field.
        this.valueFor(element);

        // Perform an auto-save, perhaps
        this.save();

        // Trigger an update
        this.trigger('change', this.value);
    }

    /**
     * Submits new data to the configuration form, if present.
     */
    save() {
        var form = Util.getParents(this.element, 'form.configuration', 'form.configuration')[0];
        if (form) {
            // Save configuration (Autosaves on timer)
            if (this._saveTimer) {
                window.clearTimeout(this._saveTimer);
            }

            this._saveTimer = window.setTimeout( () => {
                // If it is a target (deploy) configuration, set it via the workflow node
                if (this.url && this.url.endsWith("target")) {
                    return;
                }

                Util.submitForm(form);
            }, 500);
        }
    }

    /**
     * Submits new schema data to the configuration schema form, if present.
     */
    saveSchema() {
        var form = Util.getParents(this.element, 'form.configuration-schema', 'form.configuration-schema')[0];
        if (form) {
            // Save configuration (Autosaves on timer)
            if (this._saveTimer) {
                window.clearTimeout(this._saveTimer);
            }

            this._saveTimer = window.setTimeout( () => {
                // Update the schema file with the schema.
                let url = form.getAttribute('action');
                let data = JSON.stringify(this.schema);
                console.log("updating", url, "with", data);
                Util.post(url, data, {}, "json");
            }, 500);
        }
    }

    /**
     * Returns the item element for the given key.
     *
     * @param {String} key - The key for the item to look up.
     *
     * @returns {HTMLElement|null} The item `<li>` element for the key or null.
     */
    elementFor(key) {
        // Split the key into parts via '.'
        let parts = key.split('.');

        // For each one, find the group or the ultimate item element
        // left-to-right
        let group = this.element.querySelector(":scope > ul.configuration-group");
        parts.forEach( (part) => {
            if (!group.classList.contains('configuration-group')) {
                group = group.querySelector(':scope > ul.configuration-group');
            }

            // TODO: Decode array indexes (foo[0][2], etc)
            let key = Util.encodeURLSafeBase64(part);
            group = group.querySelector(`:scope > li[data-key="${key}"]`);
            if (!group) {
                return null;
            }
        });

        return group;
    }

    /**
     * Gives the realized value for the given input element or null if none.
     *
     * When `defaultValue` is not specified, the default value of the item is
     * given when the input is considered empty. Otherwise, the provided value
     * is used as the default value in that case.
     *
     * You can pass `null` to `defaultValue` to detect an empty input as it will
     * then return `null` only in that case. `null` is never a valid `default`
     * value within an Occam configuration.
     *
     * @param {HTMLElement} element - The containing input element or group.
     * @param {?number|String} defaultValue - The value to return if the input is empty.
     *
     * @returns {number|string|Array|null} The value of the field or null if none.
     */
    valueFor(element, defaultValue) {
        let ret = null;

        if (!element) {
            return ret;
        }

        // No value for disabled items
        if (element.classList.contains('configuration-item-disabled')) {
            return null;
        }

        // No value for hidden items
        if (element.hasAttribute('hidden')) {
            return null;
        }

        // If it is cached, return this
        if (element._value) {
            return element._value;
        }

        if (element.classList.contains("configuration-group")) {
            ret = {};

            // Get the value for each item in the group
            element.querySelectorAll(":scope > li.configuration-item").forEach( (item) => {
                // Decode the key
                let key = Util.decodeURLSafeBase64(item.getAttribute('data-key'));

                // Decode the schema for the item and append it.
                let value = this.valueFor(item);
                if (value !== null) {
                    ret[key] = value;
                }
            });
        }
        else if (element.classList.contains("configuration-item-array")) {
            // This encapsulates an array of some kind
            ret = [];

            // Complex array
            element.querySelectorAll(":scope > .configuration-array-items > .configuration-array-item").forEach( (item) => {
                let value = this.valueFor(item.querySelector(":scope > .configuration-group"));
                if (value !== null) {
                    ret.push(value);
                }
            });
        }
        else if (element.classList.contains("configuration-item-group")) {
            // This item encapsulates a group
            ret = this.valueFor(element.querySelector(":scope > ul.configuration-group"));
        }
        else { // Normal field
            let type = this.typeFor(element);

            if (type === "tuple" || type === "array") {
                // A tuple (it's much like a simple array, really)
                // or a simple array
                ret = [];

                let items = element.querySelector(":scope > .value > .configuration-tuple-items, :scope > .value > .configuration-array-items");
                if (items) {
                    items.querySelectorAll(":scope > .configuration-tuple-item, :scope > .configuration-array-item").forEach( (item) => {
                        let value = this.valueFor(item);
                        if (value !== null) {
                            ret.push(value);
                        }
                    });
                }
            }
            else {
                let input = element;
                if (!element.classList.contains("configuration-input")) {
                    input = this.inputFor(element);

                    if (input) {
                        type = this.typeFor(input);
                    }
                    else {
                        console.log("element has no input", element);
                    }
                }
                else {
                    // Get the proper element
                    element = this.parentFor(input);
                }

                ret = input.value;
                if (input.getAttribute('type') === 'checkbox') {
                    // Checkbox goes to true or false via `checked`
                    ret = input.checked;
                }

                // If the input is empty and a default has been passed, return that
                // default value instead of the one provided in the schema, if any.
                if (ret == "" && defaultValue !== undefined) {
                    ret = defaultValue;
                }

                // Get the default value if the input is empty and one is available
                if (ret == "" && this.defaultFor(input) !== null) {
                    ret = this.defaultFor(input);
                }

                // Do not coerce the type if it is null (no value)
                if (ret === null) {
                    return ret;
                }

                // Do not coerce the type if it contains a '...' (ranged value)
                if (ret.indexOf && ret.indexOf("...") > 0) {
                    return ret;
                }

                // Negotiate the type to ensure `ret` matches the type.
                switch (type) {
                    case "int":
                        try {
                            if (ret === "") {
                                ret = null;
                            }
                            else {
                                // Only if the value *is* an integer
                                // Otherwise, it stays a string (for ranges)
                                if (Validator.isInteger(ret)) {
                                    ret = BigInt(Validator.scientificNotationToString(ret));
                                }
                            }
                        } catch (error) {}
                        break;

                    case "number":
                        try {
                            if (ret === "") {
                                ret = null;
                            }
                            else {
                                ret = parseFloat(ret);
                            }
                        } catch (error) {}
                        break;

                    case "boolean":
                        break;

                    case "color":
                        // Break down color into component fields
                        let picker = input._picker;

                        // By default, the input is a hex value
                        ret = {
                            hex: ret
                        };

                        if (picker) {
                            ret = {
                                r: picker.channels.r,
                                g: picker.channels.g,
                                b: picker.channels.b,
                                a: picker.channels.a,
                                hex: picker.toHEXString(),
                                rgba: picker.toRGBAString()
                            };
                        }
                        break;

                    case "string":
                        ret = "" + ret;
                        break;

                    default:
                        break;
                }
            }

            // Cache value
            let value = ret;
            element._value = value;

            // Update parents as well
            let parent = element;
            while (parent) {
                parent = this.parentFor(parent);
                if (parent) {
                    if (!parent._value) {
                        parent._value = {};
                    }

                    let key = Util.decodeURLSafeBase64(element.getAttribute('data-key') || "");
                    parent._value[key] = value;
                    value = parent._value;
                }
                element = parent;
            }
        }

        return ret;
    }

    /**
     * Retrieves the interactive input for the given configuration item element.
     *
     * If there is more than one, it will return the first one.
     */
    inputFor(element) {
        return element.querySelector(":scope > .value .configuration-input");
    }

    /**
     * Retrieves all interactive inputs for the given configuration item element.
     */
    inputsFor(element) {
        return element.querySelectorAll(":scope > .value .configuration-input");
    }

    /**
     * Retrieves the `type` field for the given configuration item.
     *
     * @param {HTMLElement} element - The overall containing item `<li>` element.
     */
    typeFor(element) {
        return JSON.parse(
            Util.decodeURLSafeBase64(
                element.getAttribute('data-type') || ""
            ) || "\"string\""
        );
    }

    /**
     * Returns the configuration schema for the given item element.
     *
     * @param {HTMLElement} element - The containing item `<li>` element.
     *
     * @returns {object} The schema describing this item or group.
     */
    schemaFor(element) {
        let ret = {};

        if (!element) {
            return ret;
        }

        // If this is a group, we parse the items differently
        if (element.classList.contains("configuration-group")) {
            // Go through every item
            element.querySelectorAll(":scope > li.configuration-item").forEach( (item) => {
                // Decode the key
                let key = Util.decodeURLSafeBase64(item.getAttribute('data-key'));

                // Decode the schema for the item and append it.
                ret[key] = this.schemaFor(item);
            });
        }
        else if (element.classList.contains("configuration-item-array")) {
            // This encapsulates an array of some kind
            // Find the template that describes the fields
            let template = element.querySelector(":scope > template");

            ret.type = "array";

            if (template) {
                // Complex array
                let item = template.content.children[0];
                ret.element = this.schemaFor(item.querySelector(":scope > .configuration-group"));
            }
        }
        else if (element.classList.contains("configuration-item-group")) {
            // This item encapsulates a group
            ret = this.schemaFor(element.querySelector(":scope > ul.configuration-group"));
        }
        else if (element.classList.contains("configuration-item")) {
            ret = {
              type: this.typeFor(element)
            };

            if (ret.type === "array") {
                // Simple array
                let items = element.querySelector(":scope > .value > .configuration-array-items");
                if (items) {
                    ret.element = {
                      type: this.typeFor(items)
                    };
                }
            }
            else {
                // Get the inputs
                let input = this.inputFor(element);
                if (input) {
                    // Get validations
                    let validations = this.validationsFor(input);
                    if (validations.length > 0) {
                        ret.validations = validations;
                    }

                    // Get defaults
                    let defaultValue = this.defaultFor(input);
                    if (defaultValue !== null) {
                        ret.default = defaultValue;
                    }

                    // Get label
                    let label = element.querySelector(":scope > .label > label");
                    if (label) {
                        ret.label = label.textContent;
                    }

                    // Get description (markdown)
                    let description = this.descriptionFor(element);
                    if (description) {
                        description = description.querySelector('.description-content');
                    }

                    if (description) {
                        let markdown = Markdown.markdownFrom(description.innerHTML);
                        if (markdown !== "") {
                            ret.description = markdown;
                        }
                    }
                }
            }
        }

        return ret;
    }

    /**
     * Returns the validation schema for the given input.
     */
    validationsFor(input) {
        // Get all validations
        if (!input._validations) {
            input._validations = [];

            let tests = [];
            let messages = [];

            // Go through the validations in the description
            let description = this.descriptionFor(this.parentFor(input));
            let validations = description.querySelector('ul.configuration-validations');
            if (validations) {
                validations.querySelectorAll('li.configuration-validation').forEach( (validation) => {
                    tests.push(validation.getAttribute('data-test') || "");

                    let validationMessage = validation.querySelector('.configuration-validation-message p');
                    if (validationMessage) {
                        messages.push(validationMessage.textContent);
                    }
                });
            }

            tests.forEach( (test, i) => {
                // Empty tests are ignored
                if (test) {
                    let message = messages[i] || "";
                    test = Util.decodeURLSafeBase64(test);
                    input._validations.push({
                      test: test,
                      message: message,
                      index: i
                    });
                }
            });
        }

        return input._validations;
    }

    /**
     * Validates the given input element.
     *
     * @param {HTMLElement} element - The containing item element for the input.
     * @param {HTMLElement} input - The input element to validate.
     *
     * @returns {boolean} Returns `true` if the field validates and `false` otherwise.
     */
    validateItem(element, input) {
        let value = input.value;
        let defaultValue = this.defaultFor(input);

        // Do not repeatably run the test
        if (element._lastValue === value) {
            return element._lastValidation;
        }
        element._lastValue = value;
        element._lastValidation = true;

        // Clear last errors
        this.clearError(element);

        // If there is a default value and there is no value
        // we assume the default is "correct"... Otherwise, the value is
        // always optional.
        if (defaultValue !== null && (value === null || value === "")) {
            return true;
        }

        // Unless there is some kind of validation for requiring, an empty
        // value is allowed.
        if (value === null || value === "") {
            // TODO: required validation
            return true;
        }

        // Get the set of validations
        let validations = this.validationsFor(input);

        // Split by ',' for a set of expressions/values
        var values = value.split(",");

        // Assume we validated everything correctly
        let allFound = true;

        // Keep track of the error we encounter
        let lastError = null;

        // For each expression in the set, validate that ranged value
        values.forEach( (value) => {
            value = value.trim();

            // Split the expression by the '...' to get the start, end, and step
            if (value.indexOf("...") > 0) {
                // The step is indicated by the ':' character
                var parts = value.split(":");

                // By default, the step is 'x+1' which increments the value by 1
                var step = "x+1";

                // Otherwise, the parts will yield the step provided
                if (parts.length > 1) {
                    step = parts[1].trim();
                    value = parts[0].trim();
                }

                // Actually split the remaining part by the '...' to get start
                // and the end expressions.
                parts = value.split("...");
                var start = parseInt(parts[0].trim());
                var end   = parseInt(parts[1].trim());

                // Assume we don't validate a start value
                var found = true;

                // Only check a few steps
                var iterations = 0;

                // Find the true start value
                do {
                    found = true;

                    // Run all validations and mark found to false if any fail.
                    for (let i = 0; i < validations.length; i++) {
                        let test = validations[i].test;

                        if (!Validator.validate(test, start)) {
                            // Error!
                            lastError = validations[i];
                            found = false;
                            break;
                        }
                    }

                    // If we did not find a suitable start value, try the next
                    // possible value in the set.
                    if (!found) {
                        start = Validator.execute(step, start);
                    }

                    // We only run for a certain number of iterations before
                    // assuming the range does not contain a valid value.
                    iterations++;
                } while(!found && iterations < 50);

                // If we found a start value, good!
                if (found) {
                    // Validate the end value
                    for (let i = 0; i < validations.length; i++) {
                        let test = validations[i].test;

                        if (!Validator.validate(test, end)) {
                            // Error!
                            lastError = validations[i];
                            found = false;
                            break;
                        }
                    }

                    // However, if the start value we found is beyond the range,
                    // then we also fail out.
                    if (!Validator.validate("" + start + " <= x", end)) {
                        let rangeError = this.element.querySelector(':scope > template.configuration-item[data-i18n-range-error]');
                        let message = "Cannot find a value within the given range.";
                        if (rangeError) {
                            message = rangeError.getAttribute('data-i18n-range-error');
                        }
                        lastError = {
                            message: message
                        };
                        allFound = false;
                    }
                }
                else {
                    // We, of course, fail if we don't find any valid value in
                    // the range (before giving up, at least)
                    allFound = false;
                }
            }
            else {
                // Validate the single value
                for (let i = 0; i < validations.length; i++) {
                    let test = validations[i].test;

                    console.log("validating", test, value);
                    if (!Validator.validate(test, value)) {
                        // Error!
                        lastError = validations[i];
                        allFound = false;
                        break;
                    }
                }
            }
        });

        if (!allFound) {
            // Error!
            element._lastValidation = false;
            this.renderError(element, input, lastError);
        }

        return allFound;
    }

    /**
     * Renders the given error message for the given item.
     *
     * The `element` should point to the item itself. Typically this is the
     * `<li>` with the `configuration-item` class.
     *
     * If the validation does not exist, but is given, it will be added to the
     * validation list in a way where it will be destroyed when the errors are
     * cleared.
     *
     * @param {HTMLElement} element - The `<li>` for the item in question.
     * @param {HTMLElement} input - The input element containing the error.
     * @param {Object} error - A description of the validation.
     */
    renderError(element, input, error) {
        // Mark the item and input as having an error
        element.classList.add('configuration-error');
        input.classList.add('configuration-error');

        let validations = this.validationsFor(input);

        let index = error.index;
        let newError = false;
        if (index === undefined || index >= validations.length) {
            // Does not exist
            newError = true;
        }

        // Find the description div, if available, and add the error to it.
        let description = this.descriptionFor(element);
        let validationsList = description.querySelector(":scope > ul.configuration-validations");
        if (validationsList) {
            if (newError) {
                // Add a 'temporary' validation item.
                // This will be destroyed whenever the errors are cleared.
                let validation = Util.createElementFromTemplate(this._validationTemplate);
                validation.classList.add('configuration-validation-temporary');
                validation.classList.add("error");

                let validationMessage = validation.querySelector('.configuration-validation-message p');
                if (validationMessage) {
                    validationMessage.textContent = error.message;
                }

                // Add it
                validationsList.appendChild(validation);
            }
            else {
                // Modify an existing validation item to be an 'error'
                let error = validationsList.querySelector(":scope > li.configuration-validation:nth-child(" + (index + 1) + ")");
                if (error) {
                    error.classList.add("error");
                }
            }
        }

        // Open the description div.
        this.openDescription(element);
    }

    /**
     * Clears any evidence of an error for the given item element.
     *
     * @param {HTMLElement} element - The `<li>` for the item in question.
     */
    clearError(element) {
        // Only proceed if we have had an error here.
        if (!element.classList.contains("configuration-error")) {
            return;
        }

        // Remove the error flag for the item itself
        element.classList.remove('configuration-error');

        // Remove the error flags of any input elements
        element.querySelectorAll(':scope > .value .configution-error').forEach( (subElement) => {
            subElement.classList.remove('configuration-error');
        });

        // Unmark the validation list
        element.querySelectorAll(":scope > .label > div.description > ul.configuration-validations > li.configuration-validation.error").forEach( (validation) => {
            validation.classList.remove("error");

            if (validation.classList.contains("configuration-validation-temporary")) {
                validation.remove();
            }
        });

        // Hide the description panel
        this.closeDescription(element);
    }

    /**
     * Returns the description section of an item.
     *
     * @param {HTMLElement} element - The containing item `<li>` element.
     *
     * @returns {HTMLElement|null} The description element or null if there is not one.
     */
    descriptionFor(element) {
        return element.querySelector(":scope > .label > div.description, .configuration-array-actions > .label > div.description");
    }

    /**
     * Closes any open item description for the given item element.
     *
     * @param {HTMLElement} element - The containing item `<li>` element.
     */
    closeDescription(element) {
        let expand = element.querySelector(":scope > .label > button.expand, .configuration-array-actions > .label > button.expand");
        let description = this.descriptionFor(element);

        if (expand) {
            expand.classList.remove("open");
        }

        if (description) {
            description.setAttribute('hidden', '');
            description.setAttribute('aria-hidden', 'true');
        }
    }

    /**
     * Opens any existing item description sub-element for the given item element.
     *
     * @param {HTMLElement} element - The containing item `<li>` element.
     */
    openDescription(element) {
        let expand = element.querySelector(":scope > .label > button.expand, .configuration-array-actions > .label > button.expand");
        let description = this.descriptionFor(element);

        if (expand) {
            expand.classList.add("open");
        }

        if (description) {
            description.removeAttribute('hidden');
            description.removeAttribute('aria-hidden');
        }
    }

    /**
     * Returns the editor section of an item, if one exists.
     *
     * @param {HTMLElement} element - The containing item `<li>` element.
     *
     * @returns {HTMLElement|null} The editor element or null if there is not one.
     */
    editorFor(element) {
        if (element.nextElementSibling &&
            element.nextElementSibling.classList.contains('configuration-item-editor')) {
            return element.nextElementSibling;
        }

        return null;
    }

    /**
     * Closes any open item editor for the given item element.
     *
     * @param {HTMLElement} element - The containing item `<li>` element.
     */
    closeEditor(element) {
        let expands = element.querySelectorAll(":scope > h2 > button.expand-editor, :scope > .label > button.expand-editor, .configuration-array-actions > .label > button.expand-editor");
        let editor = this.editorFor(element);

        expands.forEach( (expand) => {
            if (expand) {
                expand.classList.remove("open");
            }
        });

        if (editor) {
            editor.setAttribute('hidden', '');
            editor.setAttribute('aria-hidden', 'true');
        }
    }

    /**
     * Opens any existing item editor sub-element for the given item element.
     *
     * @param {HTMLElement} element - The containing item `<li>` element.
     */
    openEditor(element) {
        let expands = element.querySelectorAll(":scope > h2 > button.expand-editor, :scope > .label > button.expand-editor, .configuration-array-actions > .label > button.expand-editor");
        let editor = this.editorFor(element);

        expands.forEach( (expand) => {
            if (expand) {
                expand.classList.add("open");
            }
        });

        if (editor) {
            editor.removeAttribute('hidden');
            editor.removeAttribute('aria-hidden');
        }
    }

    /**
     * Collapses the given element, if possible.
     *
     * @param {HTMLElement} element - The containing item `<li>` element.
     */
    collapse(element) {
        element.classList.remove('expanded');
    }

    /**
     * Expands the given element, if possible.
     *
     * @param {HTMLElement} element - The containing item `<li>` element.
     */
    expand(element) {
        element.classList.add('expanded');
    }

    /**
     * Collapses all collapsable regions.
     */
    collapseAll() {
        this._expandable.forEach( (element) => {
            this.closeDescription(element);
            this.closeEditor(element);
            this.collapse(element);
        });
    }
}

Configuration._count  = 0;
Configuration._loaded = [];

export default Configuration;
