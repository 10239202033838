"use strict";

import EventComponent     from './event_component.js';
import AutoComplete       from './auto_complete.js';
import Tabs               from './tabs.js';
import RunList            from './run_list.js';
import ConfigurationPanel from './configuration_panel.js';
import Terminal           from './terminal.js';
import OccamObject        from './occam_object.js';
import Util               from './util.js';
import Occam              from './occam.js';
import Modal              from './modal.js';
import Tooltip            from './tooltip.js';

import { Workflow as WorkflowWidget } from '../workflow-widget/workflow.js';
import { Palette } from '../workflow-widget/palette.js';
import Node from '../workflow-widget/node.js';

// TODO: accept a lack of a 'position' in imported json
// TODO: accept a lack of 'inputs' and 'outputs'

/**
 * This class represents any Workflow component.
 *
 * A workflow pane consists of at least a Workflow widget and possibly a set of
 * sidebars for selecting objects, viewing configurations, and viewing job logs.
 */
class Workflow extends EventComponent {
    constructor(element) {
        super();

        // This handles the delay for the autosave
        this._suppressSave = true;
        this._saveTimer = null;

        var tabElement = element.previousElementSibling;
        if (tabElement && tabElement.classList.contains("tabs")) {
            this._tabs = Tabs.load(tabElement);
        }

        // Negotiate for fullscreen actions
        this.fullScreen = document.fullScreen ||
            document.mozFullScreen      ||
            document.webkitIsFullScreen ||
            (window.innerHeight == screen.height);

        var fullScreenEvent = (event) => {
            this.fullScreen = document.fullscreenElement ||
                document.mozFullscreenElement ||
                document.webkitFullscreenElement;
        };

        ['mozfullscreenchange', 'webkitfullscreenchange', 'MSFullscreenChange', 'fullscreenchange'].forEach( (eventName) => {
            document.addEventListener(eventName, fullScreenEvent);
        });

        // Get the main tabs as well
        this._mainTabs = Tabs.load(document.querySelector(".content > ul.card-tabs"));
        if (this._mainTabs && !element.classList.contains("mock")) {
            // When the main tab is selected that contains this workflow... redraw
            this._mainTabs.on("change", (index) => {
                if (!this._center) {
                    return;
                }

                let panel = this._mainTabs.tabPanelAt(index);
                if (panel.contains(element)) {
                    // Re-center
                    this.workflow.world.center(this._center.x, this._center.y);

                    // Redraw
                    this.workflow.redraw(true);
                }
            });
        }

        this._configurationPanel = ConfigurationPanel.loadAll(element)[0];

        if (this._configurationPanel) {
            this._configurationPanel.on("change", (info) => {
                if (info.configuration.url.endsWith("target")) {
                    // Get target name
                    var targetSelected = info.configuration.element.querySelector("select[name=\"data[dGFzaw==][dGFyZ2V0]\"] option:checked");
                    var targetKey = "";
                    if (targetSelected) {
                        targetKey = targetSelected.value;
                        targetSelected = targetSelected.textContent;
                        info.data.task.targets = targetKey;
                    }
                    else {
                        targetSelected = "";
                    }

                    if (targetSelected.indexOf("[") == -1) {
                        targetSelected = "";
                    }
                    else {
                        targetSelected = targetSelected.substring(0, targetSelected.lastIndexOf("[")).trim();
                    }

                    info.data.task.targetName = targetSelected;

                    // TODO: Get target requirements and add them to the target description

                    if (this._configuring) {
                        let targets = this.targetsFromDeployConfiguration(info.data);
                        this._configuring.targets = targets;
                        this._configuring.data.deploy = info.data;
                        Tooltip.loadAll(this._configuring.element);

                        console.log("new data.json", this.workflow.toJSON());
                    }
                }
            });
        }

        var index = Occam.object().index.slice();
        index.push(0);
        this.workflowObject = new OccamObject(Occam.object().rootId, Occam.object().rootRevision, "workflow", "Main", undefined, index, Occam.object().link);

        this.element = element;

        // Look for a run id
        this.runID = this.element.getAttribute("data-run-id");
        this.lastPoll = false;

        if (this.element.classList.contains("editable")) {
            this._uploadDummy = this.element.querySelector(".sidebar li.connection");
            if (this._uploadDummy) {
                this._uploadDummy = this._uploadDummy.cloneNode(true);
            }

            // Allow dragging files into the workflow to create objects
            this.element.addEventListener('drop', (event) => {
                event.preventDefault();
                console.log("adding file to workflow", event, event.dataTransfer);
                var dataTransfer = event.dataTransfer;
                var files = dataTransfer.files;
                var items = dataTransfer.items || files;

                if (items.length > 1) {
                    console.error("error: too many items being uploaded at once. we only support a single file upload.");
                    return;
                }

                // Detect the type of item
                let file = items[0];
                let info = {};

                file = this.resolveFile(file);

                // Add an upload progress thingy node on the workflow
                let dummy = this._uploadDummy;
                let ghostNode = this._ghostNode;
                let node = null;
                if (dummy) {
                    dummy = dummy.cloneNode(true);
                    dummy.classList.remove("initial");
                    dummy.querySelector("span.type").textContent = "uploading";
                    dummy.querySelector("span.name").textContent = file.name;

                    let connections = this.element.querySelector(":scope > ul.connections");
                    connections.appendChild(dummy);

                    if (ghostNode) {
                        dummy.style.left = ghostNode.style.left;
                        dummy.style.top = ghostNode.style.top;
                    }

                    node = new Node(dummy, { job: {
                        aggregate: 32,
                        radius:    23,
                        thickness: 5,
                        padding:   3,
                    } });

                    for (let i = 0; i < 100; i++) {
                        node.updateJob({ id: i, status: "started" });
                    }
                }

                // Upload the file... tracking progress on the node
                // POST file data to /people/<current>/uploads
                let identity = document.body.querySelector("#username").getAttribute("data-identity-uri");
                let url = "/people/" + identity + "/uploads";
                let last = 0;
                Util.post(url, {
                    file: file
                }, {
                    onload: (json) => {
                        // Ok, create the real node now
                        let info = json.object;

                        let nodeInfo = {
                            type: info.type,
                            name: info.name,
                            icon: json.icon.url,
                            data: {
                                "object-id": info.id,
                                "object-revision": info.revision
                            },
                            position: {
                                x: parseInt(dummy.style.left),
                                y: parseInt(dummy.style.top)
                            },
                            outputs: [
                                {
                                    "name": "self",
                                    "type": info.type,
                                    "subtype": info.subtype,
                                    "visibility": "visible"
                                }
                            ]
                        };

                        this.workflow.insertNode(nodeInfo);

                        dummy.remove();
                    },
                    onprogress: (event) => {
                        if (event.lengthComputable) {
                            let now = Math.round((event.loaded / event.total) * 100);
                            for (let i = last; i < now; i++) {
                                if (node) {
                                    node.updateJob({ id: i, status: "finished" });
                                }
                            }
                            last = now;
                        }
                    }
                }, "application/json");

                if (this._ghostNode) {
                    // Get rid of the ghost node
                    this._ghostNode.remove();
                    this._ghostNode = null;
                }
            });

            this.element.addEventListener('dragover', (event) => {
                let dummy = this._uploadDummy;
                if (dummy) {
                    // Get the current world x/y from the workflow itself.
                    let connections = this.element.querySelector(":scope > ul.connections");
                    if (!this._ghostNode) {
                        this._ghostNode = dummy.cloneNode(true);
                        connections.appendChild(this._ghostNode);

                        // Update ghost
                        this._ghostNode.querySelector("span.type").textContent = "uploading";
                        this._ghostNode.querySelector("span.name").textContent = "...";
                    }

                    // Get the relative coordinate of the event
                    let zoom = this.workflow.world.zoom;
                    let box = this.element.getBoundingClientRect();
                    let relativeX = event.pageX - box.x;
                    let relativeY = event.pageY - box.y;

                    // Move box: place at top left of workflow and then move to offset
                    this._ghostNode.style.left = (((-connections.offsetLeft + relativeX) * (1 / zoom)) - this._ghostNode.clientWidth / 2.0) + "px";
                    this._ghostNode.style.top = (((-connections.offsetTop + relativeY) * (1 / zoom)) - this._ghostNode.clientHeight / 2.0) + "px";
                }

                event.preventDefault();
                event.stopPropagation();
            });
        }

        if (this.runID) {
            // This represents a run
            // The workflow should not be editable
            // And we need to get the run information
            this.runObject = new OccamObject(
                this.element.getAttribute("data-run-object-id"),
                this.element.getAttribute("data-run-object-revision")
            );

            this.runData(this.runObject, this.runID, (data) => {
                // Get the initial state (so we can react to changes since
                // the initial rendering)
                this.initialData = data;

                // Create a polling timer for updating the run (if it is not finished/failed)
                if (!data.run.failureTime && !data.run.finishTime) {
                    this.pollRunTimer = window.setInterval( () => {
                        if (this.lastPoll) {
                            window.clearInterval(this.pollRunTimer);
                        }

                        // Poll the run info and pass it to the workflow
                        this.pollRun();
                    }, Workflow.POLL_TIME);
                }

                this.initializeWidget();
            });
        }
        else {
            this.initializeWidget();
        }
    }

    resolveFile(file) {
        if (!file.getAsEntry) {
            file.getAsEntry = file.webkitGetAsEntry;
        }

        // If it is a DataTransferItem, we can potentially get a FileSystemEntry
        if (file.getAsEntry) {
            let info = file.getAsEntry();
            if (info.isDirectory) {
                //this.uploadDirectory(info, path);
                console.log("wow a directory", info);
            }
            else {
                //this.uploadFile(file.getAsFile(), path);
                console.log("wow a file", file.getAsFile(), info);
                file = file.getAsFile();
            }
        }
        else {
            // Now, it can be a FileSystemFileEntry or a normal File
            if (file.isDirectory) {
                console.log("wow a directory", file);
                //this.uploadDirectory(file, path);
            }
            else if (file.file) {
                file.file( (f) => {
                    console.log("wow an async file", f);
                    //this.uploadFile(f, path);
                });
            }
            else {
                // Treat it as a File type
                console.log("wow a file maybe", file);
                //this.uploadFile(file, path);
            }
        }

        return file;
    }

    targetsFromDeployConfiguration(data) {
        let targets = [];

        // TODO: when it is a target with a specific requirement, add that label
        if (data && data.task) {
            let targetSelected = data.task.targetName;
            if (targetSelected !== "") {
                targets.push({
                    "name": targetSelected,
                    "tag": "GPU"
                });
            }

            // Look for 'network' deployment constraint as well
            var networkSelected = data.task.network;
            if (networkSelected) {
                targets.push({
                    "key": "network",
                    "name": "network",
                    "tag": "net",
                    "color": "#564cd2"
                });
            }
        }

        return targets;
    }

    static loadAll(element) {
        var zones = element.querySelectorAll('.content .workflow-widget-zone');

        zones.forEach( (zone) => {
            var options = Object.assign({}, WorkflowWidget.defaults);
            options.allowNodeDeletion = false;
            options.allowNodeMovement  = false;
            options.palette.createOnDrag = false;
            options.palette.allowSelections = true;
            var dummyPalette = new Palette(null, options);
            var info = {}
            var item = zone.querySelector("li.connection");
            if (item) {
                var subElement = item.querySelector("span.type");
                if (subElement) {
                    info.type = subElement.textContent;
                }
                subElement = item.querySelector("span.name");
                if (subElement) {
                    info.name = subElement.textContent;
                }
                subElement = item.querySelector("img.icon");
                if (subElement) {
                    info.icon = subElement.src;
                }
                ['inputs', 'outputs', 'ports:not(.inputs):not(.outputs)'].forEach( (portType) => {
                    item.querySelectorAll("ol." + portType + " > li").forEach( (port) => {
                        portType = portType.split(':')[0];
                        info[portType] = info[portType] || [];
                        let portInfo = {}

                        subElement = port.querySelector("span.name");
                        if (subElement) {
                            portInfo.name = subElement.textContent;
                        }

                        subElement = port.querySelector("span.type");
                        if (subElement) {
                            portInfo.type = subElement.textContent;
                        }

                        info[portType].push(portInfo);
                    });
                });

                dummyPalette.updateItem(zone, info);
            }
        });

        var workflows = element.querySelectorAll('.content occam-workflow');

        workflows.forEach( (element, index) => {
            var workflow = new Workflow(element, index);
        });
    }

    pollRun() {
        this.runData(this.runObject, this.runID, (data) => {
            this.updateRun(data);
        });
    }

    updateRun(data) {
        // Pass along job details to workflow
        Object.keys(data.nodes).forEach( (nodeIndex) => {
            let node = this.workflow.nodeAt(nodeIndex);

            data.nodes[nodeIndex].jobs.forEach( (job) => {
                // Reform our job status into just the three steps
                if (job.status == "initialized" || job.status == "ran") {
                    job.status = "started";
                }
                node.updateJob(job);
            });
        });

        // Detect a change in the run status
        if (data.run.finishTime != this.initialData.run.finishTime) {
            this.trigger('change', data);
        }
        else if (data.run.failureTime != this.initialData.run.failureTime) {
            this.trigger('change', data);
        }

        if (data.run.finishTime || data.run.failureTime) {
            // We are not polling anymore
            this.lastPoll = true;

            // Invalidate and reload 'output' tab
            this._mainTabs.invalidate(3, true);

            // Post an event for the finished run
            this.trigger('done', data);
        }

        // Also pass along relevant information to the job list panel
        var jobPanel = this.element.querySelector(".jobs.sidebar");
        if (jobPanel) {
            var jobsList = jobPanel.querySelector("ul.jobs");

            var nodeIndex = jobsList.getAttribute("data-node-index");
            if (nodeIndex) {
                if (data.nodes && data.nodes[nodeIndex] && data.nodes[nodeIndex].jobs) {
                    var runList = RunList.load(jobsList);
                    data.nodes[nodeIndex].jobs.forEach( (job, i) => {
                        // Update job information
                        var entry = runList.elementFor(i);
                        if (entry) {
                            runList.update(entry, job);
                        }
                    });
                }
            }
        }
    }

    runData(object, runID, callback) {
        Util.get(object.url({"path": "runs/" + runID}), (data) => {
            callback(data);
        }, "json");
    }

    jobList(object, runID, nodeIndex, callback) {
        Util.get(object.url({"path": "runs/" + runID + "/" + nodeIndex}), (html) => {
            callback(html);
        }, "text/html");
    }

    initializeWidget() {
        var options = {};

        options.buttons = [];

        if (this.runID || this.element.classList.contains("run")) {
            options.allowSelections     = false;
            options.allowNodeMovement   = false;
            options.allowNodeDeletion   = false;
            options.allowWireSelection  = false;
            options.allowNewConnections = false;
            options.buttons.push({
                classes: ["view-jobs-button"],
                inactive: {
                    icon: "/images/dynamic/hex/5999a6/icons/ui/workflow-job-list.svg"
                },
                hover: {
                    icon: "/images/dynamic/hex/ffffff/icons/ui/workflow-job-list.svg"
                }
            });
        }
        else {
            options.buttons.push({
                classes: ["view-button"],
                inactive: {
                    icon: "/images/dynamic/hex/5999a6/icons/ui/search.svg"
                },
                hover: {
                    icon: "/images/dynamic/hex/ffffff/icons/ui/search.svg"
                }
            });
            options.buttons.push({
                classes: ["configure-button"],
                inactive: {
                    icon: "/images/dynamic/hex/5999a6/icons/objects/configuration.svg"
                },
                hover: {
                    icon: "/images/dynamic/hex/ffffff/icons/objects/configuration.svg"
                }
            });
            // TODO: re-enable edit support
            /*options.buttons.push({
                classes: ["edit-button"],
                inactive: {
                    icon: "/images/dynamic/hex/5999a6/icons/ui/edit.svg"
                },
                hover: {
                    icon: "/images/dynamic/hex/ffffff/icons/ui/edit.svg"
                }
            });*/
        }

        this.workflow = new WorkflowWidget(this.element, options);
        var sidebar = this.element.querySelector(".sidebar li.connection");
        if (sidebar) {
            var selectionNode = Node.create(sidebar, this.workflow);

            var empty = !this.element.querySelector('.input.start');

            this._sidebar  = this.element.querySelector('.sidebar:not(.right)');
            this._sidebar2 = this.element.querySelector('.sidebar.right');
        }

        this.workflow.on("pan", (center) => {
            // Remember what our 'center' is for redraw/resize
            this._center = center;
        });

        this.workflow.dirty = false;
        this.workflow.on("dirty", () => {
            // Ignore when not editable
            if (!this.element.classList.contains("editable")) {
                return;
            }

            if (this._suppressSave) {
                return;
            }

            if (this._saveTimer) {
                window.clearTimeout(this._saveTimer);
            }

            this._saveTimer = window.setTimeout( () => {
                this.save();
            }, 500);

            this.workflow.dirty = false;
        });

        this.workflow.on("button-click", (event) => {
            if (event.element.classList.contains("configure-button")) {
                this._configuring = event.node;
                this.showConfigurations();
                this._configurationPanel.loadConfiguration(event.node.index, this.workflowObject,
                    new OccamObject(event.node.element.getAttribute("data-object-id"), event.node.element.getAttribute("data-object-revision"))
                );
            }
            else if (event.element.classList.contains("view-button")) {
                // Open the view modal
                let objectURL = "";
                objectURL += "/" + event.node.element.getAttribute("data-object-id");
                objectURL += "/" + event.node.element.getAttribute("data-object-revision");
                Modal.open(objectURL, { large: true, display: "flex", height: "90%" });
            }
            else if (event.element.classList.contains("edit-button")) {
                // Open the edit modal
                let objectURL = "";
                objectURL += "/" + event.node.element.getAttribute("data-object-id");
                objectURL += "/" + event.node.element.getAttribute("data-object-revision");
                objectURL += "/file";
                Modal.open(objectURL, { large: true, display: "flex", height: "90%", query: { full: "true", fileListing: "closed" } });
            }
            else if (event.element.classList.contains("view-jobs-button")) {
                var jobPanel = this.element.querySelector(".jobs.sidebar");

                if (jobPanel) {
                    // Ensure the jobs list panel is open.
                    jobPanel.classList.remove("reveal");

                    // Tell others that the sidebar is shown.
                    this.trigger("sidebar");

                    // Make sure the loading icon is displayed
                    var jobsList = jobPanel.querySelector("ul.jobs");
                    var loadingDiv = jobPanel.querySelector(".loading");
                    var runList = RunList.load(jobsList);
                    runList.clear();

                    // Destroy existing terminal
                    var terminalElement = this.element.querySelector("*:not(template) > .terminal.job-viewer");
                    if (terminalElement) {
                        terminalElement.remove();
                    }

                    // Update object node visualization
                    var node = jobPanel.querySelector(".connection.dummy");

                    // Prevent it from being colored as the default 'help' colors
                    node.classList.remove("initial");

                    // Update name and icon
                    var name = event.node.name;
                    node.querySelector(".name").textContent = name;
                    var type = event.node.type;
                    node.querySelector(".type").textContent = type;
                    var icon = event.node.icon;
                    if (icon) {
                        node.querySelector("img.icon").src = icon;
                    }

                    if (!loadingDiv) {
                        loadingDiv = document.createElement("div");
                        loadingDiv.classList.add("loading");
                        jobsList.appendChild(loadingDiv);
                    }

                    jobsList.setAttribute("data-node-index", event.node.index);

                    runList.on('action-fullscreen', (item) => {
                        if (!this.fullScreen) {
                            var currentViewer = this.element.querySelector("*:not(template) > .terminal.job-viewer");
                            if (!currentViewer) {
                                return;
                            }

                            if (currentViewer.requestFullscreen) {
                                currentViewer.requestFullscreen();
                            }
                            else if (currentViewer.mozRequestFullscreen) {
                                currentViewer.mozRequestFullscreen();
                            }
                            else if (currentViewer.webkitRequestFullscreen) {
                                currentViewer.webkitRequestFullscreen();
                            }
                            else {
                                currentViewer.style.position = "fixed";
                                currentViewer.style.left = 0;
                                currentViewer.style.right = 0;
                                currentViewer.style.top = 0;
                                currentViewer.style.bottom = 0;
                                currentViewer.style.zIndex = 9999999999;
                                currentViewer.style.height = "100%";
                                currentViewer.style.width  = "100%";

                                let fullScreenCancelEvent = (event) => {
                                    if (event.key == "Escape" || event.code == "Escape" || event.keyCode == 27) {
                                        document.body.removeEventListener("keydown", fullScreenCancelEvent);
                                        currentViewer.style.position = "";
                                        currentViewer.style.left = "";
                                        currentViewer.style.right = "";
                                        currentViewer.style.top = "";
                                        currentViewer.style.bottom = "";
                                        currentViewer.style.zIndex = "";
                                        currentViewer.style.height = "";
                                        currentViewer.style.width = "";
                                        this.fullScreen = false;
                                    }
                                };

                                document.body.addEventListener("keydown", fullScreenCancelEvent);
                            }
                            currentViewer.focus();
                        }

                        this.fullScreen = !this.fullScreen;
                    });

                    // Populate the jobs list
                    this.jobList(this.runObject, this.runID, event.node.index, (html) => {
                        loadingDiv.remove();

                        runList.loadHTML(html);

                        runList.on("change", (item) => {
                            var jobID = item.getAttribute("data-job-id");

                            // Destroy existing terminal
                            var terminalElement = this.element.querySelector("*:not(template) > .terminal.job-viewer");
                            if (!terminalElement) {
                                // Clone template terminal
                                var template = this.element.querySelector("template.job-terminal");
                                var newTerminal = null;
                                if ('content' in template) {
                                    newTerminal = document.importNode(template.content, true);
                                    newTerminal = newTerminal.querySelector("div");
                                }
                                else {
                                    newTerminal = template.querySelector("div").cloneNode(true);
                                }

                                if (newTerminal) {
                                    template.parentNode.insertBefore(newTerminal, template.nextElementSibling);

                                    terminalElement = newTerminal;
                                }
                            }

                            // Update terminal
                            if (terminalElement) {
                                terminalElement.setAttribute("data-job-id", jobID);
                                var terminal = Terminal.load(terminalElement);
                                terminal.reset();
                                terminal.open();

                                runList.on("action.fullscreen", (item) => {
                                    // Fullscreen the terminal
                                    if (terminalElement.requestFullScreen) {
                                        terminalElement.requestFullscreen();
                                    }
                                    else if (terminalElement.mozRequestFullscreen) {
                                        terminalElement.mozRequestFullscreen();
                                    }
                                    else if (terminalElement.webkitRequestFullscreen) {
                                        terminalElement.webkitRequestFullscreen();
                                    }
                                    else {
                                        terminalElement.style.position = "fixed";
                                        terminalElement.style.left = 0;
                                        terminalElement.style.right = 0;
                                        terminalElement.style.top = 0;
                                        terminalElement.style.bottom = 0;
                                        terminalElement.style.zIndex = 999999;
                                        terminalElement.style.height = "100%";
                                        terminalElement.style.width  = "100%";
                                    }

                                    terminalElement.onfullscreenchange =
                                        terminalElement.onwebkitfullscreenchange =
                                        terminalElement.onmozfullscreenchange =
                                        terminalElement.MSFullscreenChange = (event) => {
                                            terminalElement.classList.toggle("fullscreen");
                                        };

                                    terminalElement.focus();
                                });
                            }
                        });
                    });
                }
            }
        });

        this.workflow.on("node-removed", (event) => {
            var node = event.node;
            var element = event.element;

            var index = parseInt(element.getAttribute('data-index'));
        });

        this.connections = this.element.querySelector(":scope > ul.connections");
        this.draggable   = this.connections;

        // Apply save/update events when the workflow is editable
        if (this.element.classList.contains("editable")) {
            this.setupSaveButton();
            this.applySidebarEvents();
        }

        // Initialize the sidebars
        if (this.element.querySelector(".sidebar")) {
            this.initializeSidebar();
        }

        // Load from the experiment
        if (!this.element.classList.contains("mock")) {
            if (Occam.object() && Occam.object().type == "experiment") {
                Occam.object().objectInfo( (info) => {
                    (info.contains || []).forEach( (item, index) => {
                        if (item.type == "workflow") {
                            this.loadFrom(this.workflowObject);
                        }
                    });
                });
            }
        }
    }

    /**
     * Saves the workflow.
     */
    save() {
        var experiment_data = this.exportJSON();

        var url = Occam.object().url({path: "/0/files/data.json", query: { commit: true }});

        // POST the new workflow data
        Util.post(url, experiment_data, {
            onload: (metadata) => {
                var query = (window.location.href.split("?", 2)[1] || "");
                var newURL = metadata.url.split("?", 2)[0] + "/../../../workflow";
                if (query) {
                    newURL += "?" + query;
                }
                //window.location.replace(newURL);
            },
            onprogress: (event) => {
            }
        }, "json");
    }

    setupSaveButton() {
        this._save = this._tabs.element.querySelector('.save');

        if (this._save) {
            this._save.addEventListener('click', (event) => {
                this.save();
            });
        }
    }

    /**
     * This function shows the object selection sidebar, if obscured.
     */
    showObjectSelector() {
        if (this.objectSelectorSidebar) {
            this.objectSelectorSidebar.classList.remove("reveal");
            if (this._tabs) {
                this._tabs.detectSidebarState();
            }
        }
    }

    /**
     * This function hides the object selection sidebar, if shown.
     */
    hideObjectSelector() {
        if (this.objectSelectorSidebar) {
            this.objectSelectorSidebar.classList.add("reveal");
            if (this._tabs) {
                this._tabs.detectSidebarState();
            }
        }
    }

    /**
     * This function shows the configuration sidebar, if obscured.
     */
    showConfigurations() {
        if (this.configurationSidebar) {
            this.configurationSidebar.classList.remove("reveal");
            if (this._tabs) {
                this._tabs.detectSidebarState();
            }
        }
    }

    /**
     * This function hides the configuration sidebar, if shown.
     */
    hideConfigurations() {
        if (this.configurationSidebar) {
            this.configurationSidebar.classList.add("reveal");
            if (this._tabs) {
                this._tabs.detectSidebarState();
            }
        }
    }

    /**
     * This function sets up the dynamic interactions with the sidebar.
     */
    initializeSidebar() {
        this.objectSelectorSidebar = this.element.parentNode.querySelector("occam-workflow .sidebar.object-select");
        this.configurationSidebar  = this.element.parentNode.querySelector("occam-workflow .sidebar.configuration");
    }

    loadObject(object) {
        // Stop dragging of node
        var objectSelected = this._sidebar.querySelector(".object-selected");
        objectSelected.classList.add("disabled");

        // Destroy contents of zone (if never loaded)
        if (!objectSelected.classList.contains("workflow-widget-zone")) {
            objectSelected.innerHTML = "";
        }

        // Ping site to add a recently-used link
        var currentPersonURL = "/people/" + document.body.querySelector("#username").getAttribute("data-identity-uri");
        var recentlyUsedURL = currentPersonURL + "/recentlyUsed";
        Util.post(recentlyUsedURL, {
            "object_id": object.uid,
            "object_revision": object.revision
        });

        // We will get the node information here
        let nodeInfo = {
            name: object.name,
            type: object.object_type,
            icon: object.icon,
            data: {
                "object-revision": object.revision,
                "object-id": object.uid
            }
        };

        // Load input/output pins
        var realized = new OccamObject(object.uid, object.revision, object.object_type);
        this.currentObject = realized;
        realized.objectInfo( (data) => {
            if (data.inputs) {
                nodeInfo.inputs = [];
                data.inputs.forEach( (input, i) => {
                    if (input.type == "configuration") {
                        input.visibility = "hidden";
                    }

                    nodeInfo.inputs.push(input);
                });
            }
            data.outputs = (data.outputs || []);
            data.outputs.unshift({
                "name": "self",
                "type": data.type
            });
            if (data.outputs) {
                nodeInfo.outputs = [];
                data.outputs.forEach( (output, i) => {
                    if (i == 0 && data.outputs.length > 1) {
                        // Hide the 'self' pin
                        output.visibility = "hidden";
                    }

                    nodeInfo.outputs.push(output);
                });
            }

            // Add the palette item
            this.workflow.palette.updateItem(objectSelected, nodeInfo);

            // Allow dragging
            objectSelected.classList.remove("disabled");
        });
    }

    /* This function will set up the events to attach the sidebar attach
     * button to the current input box.
     */
    applySidebarEvents() {
        // Close the sidebar when a node is clicked and dragged
        this.workflow.on("palette-node-drag", (_) => {
            this._sidebar.classList.add("reveal");
            if (this._tabs) {
                this._tabs.detectSidebarState();
            }
        });

        // General object autocomplete
        var autoCompleteType = AutoComplete.load(this._sidebar.querySelector('.auto-complete[name="type"]'));
        var autoCompleteElement = this._sidebar.querySelector('.auto-complete[name="name"]');
        var autoComplete = AutoComplete.load(autoCompleteElement);
        autoCompleteType.on("change", (event) => {
            autoComplete.clear();
        });

        autoComplete.on("change", (event) => {
            var object = {};
            var hidden         = autoCompleteElement.parentNode.querySelector(':scope > input[name="object-id"]');
            object.object_type = autoCompleteElement.getAttribute('data-object-type');
            object.revision    = autoCompleteElement.getAttribute('data-revision');
            object.icon        = autoCompleteElement.getAttribute('data-icon-url');
            object.name        = autoCompleteElement.value;
            object.uid         = hidden.value;

            this.loadObject(object);
        });

        this._sidebar.querySelectorAll('ul.object-list li').forEach((recentlyUsedItem) => {
            recentlyUsedItem.addEventListener('click', (event) => {
                var object = {};
                object.object_type = recentlyUsedItem.querySelector('h2 span.type, p.type').textContent.trim();
                object.revision    = recentlyUsedItem.getAttribute('data-object-revision');
                object.icon        = recentlyUsedItem.querySelector('img').getAttribute('src');
                object.name        = recentlyUsedItem.querySelector('h2 span.name').textContent.trim();
                object.uid         = recentlyUsedItem.getAttribute('data-object-id');

                this.loadObject(object);
            });
        });
        this._sidebar.addEventListener('keypress', (event) => {
            event.stopPropagation();
        });
    }

    loadFrom(obj) {
        // Place spinner on workflow
        this.workflow.element.classList.add("loading");
        obj.objectInfo( (info) => {
            if (info.file === undefined) {
                return;
            }

            obj.retrieveJSON(info.file, (data) => {
                this.workflow.element.classList.remove("loading");

                // Get workflow pan position
                var width  = this.workflow.element.offsetWidth;
                var height = this.workflow.element.offsetHeight;

                // Get the intended center point and retain it
                data.center = data.center || {"x": 0, "y": 0};
                this._center = data.center;

                // Center the workflow diagram upon the stored point
                // (The workflow's viewport position is the top left coordinate)
                var workflowLeft = data.center.x + (width  / 2);
                var workflowTop  = data.center.y + (height / 2);

                // For each node, add that node at its given position
                (data.connections || []).forEach( (nodeInfo, i) => {
                    nodeInfo.position = nodeInfo.position || {"x": 0, "y": 0};
                    nodeInfo.position.x = nodeInfo.position.x || 0;
                    nodeInfo.position.y = nodeInfo.position.y || 0;

                    nodeInfo.data = {
                        "object-id": nodeInfo.id,
                        "object-revision": nodeInfo.revision,
                        "deploy": nodeInfo.deploy
                    };

                    // Interpret targets

                    nodeInfo.icon = "/images/icons/for?object-type=" + nodeInfo.type;

                    let targets = this.targetsFromDeployConfiguration(nodeInfo.deploy);
                    nodeInfo.targets = targets;

                    // Add implicit 'self' output as index 0
                    nodeInfo.outputs.unshift(nodeInfo.self || {
                        hidden: true,
                        type: nodeInfo.type,
                        name: "self"
                    });

                    // Rewire indicies to account for 'self' wire
                    (nodeInfo.inputs || []).forEach( (port) => {
                        (port.connections || []).forEach( (wire) => {
                            if (wire.to instanceof Array) {
                                wire.to = {
                                    "node": wire.to[0],
                                    "port": wire.to[1],
                                    "wire": wire.to[2]
                                }
                            }
                            wire.to.port++;
                        });
                    });
                });

                this.workflow.fromJSON(data);

                // Initialize any elements we want via Occam's internal JS
                // This will ensure tooltips work, etc.
                Occam.loadAll(this.workflow.element);

                if (this.initialData) {
                    this.updateRun(this.initialData);
                }

                // Allow saving upon dirty
                this._suppressSave = false;
                this.workflow.dirty = false;
            });
        });
    }

    exportJSON() {
        // Attach this object to the workflow
        var workflowData = this.workflow.toJSON();

        // Mutate this into the appropriate structure
        var canonicalData = {};

        canonicalData.connections = [];
        workflowData.connections.forEach( (nodeData) => {
            var canonicalNode = {};

            canonicalNode.inputs = [];
            canonicalNode.position = {
                "x": nodeData.position.x,
                "y": nodeData.position.y
            };
            canonicalNode.name       = nodeData.name;
            canonicalNode.type       = nodeData.type;
            canonicalNode.id         = nodeData.data["object-id"];
            canonicalNode.revision   = nodeData.data["object-revision"];
            canonicalNode.target     = nodeData.target;
            canonicalNode.visibility = nodeData.visibility || "visible";
            canonicalNode.deploy     = nodeData.data["deploy"] || {};

            (nodeData.inputs || []).forEach( (pinData, pinIndex) => {
                var canonicalPin = {};
                canonicalPin.connections = [];
                canonicalPin.name = pinData.name;
                canonicalPin.type = pinData.type;
                canonicalPin.visibility = pinData.visibility || "visible";
                (pinData.connections || []).forEach( (wireData) => {
                    var canonicalWire = {};
                    canonicalWire.to = [wireData.to.node, wireData.to.port - 1, wireData.to.wire];
                    if (canonicalWire.to[0] !== undefined) {
                        canonicalPin.connections.push(canonicalWire);
                    }
                });
                canonicalNode.inputs.push(canonicalPin);
            });

            canonicalNode.outputs = [];
            (nodeData.outputs || []).forEach( (pinData, pinIndex) => {
                // self pin
                var canonicalPin = {};
                canonicalPin.connections = [];
                canonicalPin.name = pinData.name;
                canonicalPin.type = pinData.type;
                canonicalPin.visibility = pinData.visibility || "visible";
                (pinData.connections || []).forEach( (wireData) => {
                    var canonicalWire = {};
                    canonicalWire.to = [wireData.to.node, wireData.to.port, wireData.to.wire];
                    if (canonicalWire.to[0] !== undefined) {
                        canonicalPin.connections.push(canonicalWire);
                    }
                });
                if(pinIndex == 0){
                    canonicalNode.self = canonicalPin;
                }
                else{
                    canonicalNode.outputs.push(canonicalPin);
                }
            });

            canonicalData.connections.push(canonicalNode);
        });

        var width  = this.workflow.element.clientWidth;
        var height = this.workflow.element.clientHeight;

        // Set and retain the intended center point
        canonicalData.center = workflowData.center;
        this._center = canonicalData.center;

        return JSON.stringify(canonicalData);
    }
}

/**
 * The time in milliseconds between polling for updates in a run.
 */
Workflow.POLL_TIME = 4000;

export default Workflow;
