"use strict";

import EventComponent from './event_component.js';
import Util           from './util.js';

class ObjectTree extends EventComponent {
    constructor(element, root) {
        super();

        var self = this;

        // Initialize events
        self.events = {};

        if (root === undefined) {
            root = element;
            while(root.parentNode &&
                root.parentNode.parentNode &&
                root.parentNode.parentNode.classList.contains("object-tree")) {
                root = root.parentNode.parentNode;
            }
        }

        // Keep track of element and tree type
        self.element = element;
        self.type    = element.getAttribute("data-tree-type") || "selection";
        self.root    = root;

        // Initialize all event functions
        self.bindEvents();
    }

    // TODO: add a load() function to instantiate an ObjectTree

    /* Loads all object trees within the given element.
    */
    static loadAll(element) {
        element.querySelectorAll("ol.object-tree").forEach(function(tree) {
            new ObjectTree(tree);
        });
    }

    /* Expands the given row.
    */
    expand(row) {
        var self = this;

        var childList = row.querySelector(":scope > ol.object-tree");
        var expander = row.querySelector(":scope > span.expand");

        // If there isn't a list loaded, load that list
        if (!childList) {
            childList = document.createElement("ol");
            childList.classList.add("object-tree");
            childList.classList.add("loading");

            row.appendChild(childList);

            var url = expander.getAttribute("data-url");
            window.console.log("gathering " + url);
            Util.get(url, function(html) {
                var dummyNode = document.createElement("div");
                dummyNode.innerHTML = html;
                childList.innerHTML = dummyNode.querySelector("ol").innerHTML;
                childList.classList.remove("loading");
                new ObjectTree(childList, self.root);
            });
        }

        // Create the row by polling the server
        expander.classList.toggle("collapse");

        childList.setAttribute("aria-hidden", !expander.classList.contains("collapse"));
    }

    /* Selects the row corresponding to the given element within the row.
    */
    select(element) {
        var row = element;

        while (row && row.tagName.toUpperCase() != "LI") {
            row = element.parentNode;

        }

        if (!row) {
            return;
        }

        var nameElement = row.querySelector(":scope > span.name");

        // Unselect the last item from this tree
        this.root.querySelectorAll("span.selected").forEach(function(lastSelected) {
            lastSelected.classList.remove("selected");
        });

        // Select this item
        nameElement.classList.add("selected");

        // Update form items
        this.root.querySelector('input[name="to[id]"]').setAttribute("value",       row.querySelector("span.id").textContent.trim());
        this.root.querySelector('input[name="to[revision]"]').setAttribute("value", row.querySelector("span.revision").textContent.trim());
        this.root.querySelector('input[name="to[index]"]').setAttribute("value",    row.querySelector("span.index").textContent.trim());
        this.root.querySelector('input[name="to[link]"]').setAttribute("value",     row.querySelector("span.link").textContent.trim());
    }

    /* Attaches events to the given item element.
    */
    bindEvents() {
        var self = this;

        self.element.querySelectorAll(":scope > li > span").forEach(function(span) {
            span.addEventListener("click", function(event) {
                if (self.type == "selection" && this.classList.contains("name")) {
                    self.select(this);
                }
                else {
                    self.expand(this.parentNode);
                }
            });
        });
    }
}

export default ObjectTree;
