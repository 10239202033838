"use strict";

import EventComponent  from './event_component.js';

/**
 * Represents a calendar widget which is used primarily for the Object history page.
 */
class Calendar extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            throw "element is not defined";
        }

        this.element = element;

        Calendar._count++;
        this.element.setAttribute('data-calendar-index', Calendar._count);
        Calendar._loaded[this.element.getAttribute('data-calendar-index')] = this;

        this.initialize();
    }
    
    /**
     * Returns an instance of Calendar for the given element.
     *
     * This will create a Calendar, if it doesn't exist, for this element.
     *
     * @param {HTMLElement} element The main element for the calendar.
     */
    static load(element) {
        if (!element) {
          return;
        }

        var index = element.getAttribute('data-loaded-index');

        if (index) {
          return Calendar._loaded[index];
        }

        return new Calendar(element);
    }

    /**
     * Instantiates all Calendar elements within the given element.
     */
    static loadAll(element) {
        var calendars = element.querySelectorAll('.calendar');

        calendars.forEach( (element) => {
            Calendar.load(element);
        });
    }

    /**
     * Initializes the widget.
     */
    initialize() {
        this._today = window.moment();
        this.current = window.moment().date(1);
        this.events = [];
        this.draw();

        var current = document.querySelector(".today");

        if (current) {
            window.setTimeout( () => {
            }, 500);
        }
    }

    /**
     * Redraws the widget.
     */
    draw() {
        // Create header
        this.drawHeader();

        // Draw month
        this.drawMonth();

        // Draw legend
        this.drawLegend();
    }

    /**
     * Redraws the header of the widget.
     */
    drawHeader() {
        if (!this.header) {
            // Create the header elements
            this.header = Calendar._createElement('div', 'header');
            this.header.className = 'header';

            this.title = Calendar._createElement('h3');

            var right = Calendar._createElement('div', 'right');
            right.addEventListener('click', (event) => {
                this.nextMonth();
            });

            var left = Calendar._createElement('div', 'left');
            left.addEventListener('click', (event) => {
                this.prevMonth();
            });

            // Append the Elements
            this.header.appendChild(this.title); 
            this.header.appendChild(right);
            this.header.appendChild(left);

            this.element.appendChild(this.header);
        }

        this.title.innerHTML = this.current.format('MMMM YYYY');
    }
    
    /**
     * Redraws the legend.
     */
    drawLegend() {
        var legend = this.element.querySelector(".legend");

        if (legend) {
            return;
        }
            
        legend = Calendar._createElement('div', 'legend');

        var calendars = this.events.map( (eventInformation) => {
            return eventInformation.calendar + '|' + e.color;
        }).reduce( (memo, eventInformation) => {
            if (memo.indexOf(eventInformation) === -1) {
                memo.push(eventInformation);
            }

            return memo;
        }, []).forEach( (eventInformation) => {
            var parts = eventInformation.split('|');
            var entry = Calendar._createElement('span', 'entry ' +  parts[1], parts[0]);
            legend.appendChild(entry);
        });

        this.element.appendChild(legend);
    }

    /**
     * Redraws the month element.
     */
    drawMonth() {
        if (this.month) {
            this.oldMonth = this.month;
            this.oldMonth.className = 'month out ' + (this.next ? 'next' : 'prev');
            this.oldMonth.addEventListener('webkitAnimationEnd', (event) => {
                this.oldMonth.parentNode.removeChild(this.oldMonth);
                this.month = Calendar._createElement('div', 'month');
                this.backFill();
                this.currentMonth();
                this.forwardFill();
                this.element.insertBefore(this.month, this.element.querySelector(".legend"));
                window.setTimeout( () => {
                    this.month.className = 'month in ' + (this.next ? 'next' : 'prev');
                }, 16);
            });
        }
        else {
            this.month = Calendar._createElement('div', 'month');
            this.element.insertBefore(this.month, this.element.querySelector(".legend"));
            this.backFill();
            this.currentMonth();
            this.forwardFill();
            this.month.className = 'month new';
        }
    }

    /**
     * Redraws the day elements.
     */
    drawDay(day) {
        this.getWeek(day);

        // Outer Day
        var outer = Calendar._createElement('div', this.getDayClass(day));
        outer.addEventListener('click', (event) => {
            //this.openDay(outer);
        });

        // Day Name
        var name = Calendar._createElement('div', 'day-name', day.format('ddd'));

        // Day Number
        var number = Calendar._createElement('div', 'day-number', day.format('DD'));

        // Events
        var events = Calendar._createElement('div', 'day-events');
        this.drawEvents(day, events);

        outer.appendChild(name);
        outer.appendChild(number);
        outer.appendChild(events);
        this.week.appendChild(outer);
    }

    /**
     * Draws event listing.
     */
    drawEvents(day, element) {
        if (day.month() === this.current.month()) {
            var todaysEvents = this.events.reduce( (memo, ev) => {
                if (ev.date.isSame(day, 'day')) {
                    memo.push(ev);
                }

                return memo;
            }, []);

            todaysEvents.forEach( (ev) => {
                var evSpan = Calendar._createElement('span', ev.color);
                element.appendChild(evSpan);
            });
        }
    }

    backFill() {
        var clone = this.current.clone();
        var dayOfWeek = clone.day();

        if (!dayOfWeek) {
            return;
        }

        clone.subtract(dayOfWeek + 1, 'days');

        for (var i = dayOfWeek; i > 0; i--) {
            this.drawDay(clone.add(1, 'days'));
        }
    }

    forwardFill() {
        var clone = this.current.clone().add(1, 'months').subtract(1, 'days');
        var dayOfWeek = clone.day();

        if (dayOfWeek === 6) {
            return;
        }

        for(var i = dayOfWeek; i < 6; i++) {
            this.drawDay(clone.add(1, 'days'));
        }
    }

    currentMonth() {
        var clone = this.current.clone();

        while (clone.month() === this.current.month()) {
            this.drawDay(clone);
            clone.add(1, 'days');
        }
    }

    getWeek(day) {
        if (!this.week || day.day() === 0) {
          this.week = Calendar._createElement('div', 'week');
          this.month.appendChild(this.week);
        }
    }

    getDayClass(day) {
        var classes = ['day'];

        if (day.month() !== this.current.month()) {
            classes.push('other');
        }
        else if (this._today.isSame(day, 'day')) {
            classes.push('today');
        }

        return classes.join(' ');
    }

    openDay(el) {
        var details, arrow;
        var dayNumber = +el.querySelectorAll('.day-number')[0].innerText || +el.querySelectorAll('.day-number')[0].textContent;
        var day = this.current.clone().date(dayNumber);

        var currentOpened = this.element.querySelector('.details');

        // Check to see if there is an open detais box on the current row
        if (currentOpened && currentOpened.parentNode === el.parentNode) {
            details = currentOpened;
            arrow = this.element.querySelector('.arrow');
        }
        else {
            // Close the open events on differnt week row
            // currentOpened && currentOpened.parentNode.removeChild(currentOpened);
            if (currentOpened) {
                currentOpened.addEventListener('webkitAnimationEnd', function() {
                    currentOpened.parentNode.removeChild(currentOpened);
                });
                currentOpened.addEventListener('oanimationend', function() {
                    currentOpened.parentNode.removeChild(currentOpened);
                });
                currentOpened.addEventListener('msAnimationEnd', function() {
                    currentOpened.parentNode.removeChild(currentOpened);
                });
                currentOpened.addEventListener('animationend', function() {
                    currentOpened.parentNode.removeChild(currentOpened);
                });
                currentOpened.className = 'details out';
            }

            //Create the Details Container
            details = Calendar._createElement('div', 'details in');

            //Create the arrow
            var arrow = Calendar._createElement('div', 'arrow');

            //Create the event wrapper

            details.appendChild(arrow);
            el.parentNode.appendChild(details);
        }

        var todaysEvents = this.events.reduce(function(memo, ev) {
            if(ev.date.isSame(day, 'day')) {
                memo.push(ev);
            }
            return memo;
        }, []);

        this.renderEvents(todaysEvents, details);

        arrow.style.left = el.offsetLeft - el.parentNode.offsetLeft + 27 + 'px';
    }

    renderEvents(events, ele) {
        //Remove any events in the current details element
        var currentWrapper = ele.querySelector('.events');
        var wrapper = Calendar._createElement('div', 'events in' + (currentWrapper ? ' new' : ''));

        events.forEach( (ev) => {
            var div = Calendar._createElement('div', 'event');
            var square = Calendar._createElement('div', 'event-category ' + ev.color);
            var span = Calendar._createElement('span', '', ev.eventName);

            div.appendChild(square);
            div.appendChild(span);
            wrapper.appendChild(div);
        });

        if (!events.length) {
            var div = Calendar._createElement('div', 'event empty');
            var span = Calendar._createElement('span', '', 'No Events');

            div.appendChild(span);
            wrapper.appendChild(div);
        }

        if (currentWrapper) {
            currentWrapper.className = 'events out';
            currentWrapper.addEventListener('webkitAnimationEnd', function() {
                currentWrapper.parentNode.removeChild(currentWrapper);
                ele.appendChild(wrapper);
            });
            currentWrapper.addEventListener('oanimationend', function() {
                currentWrapper.parentNode.removeChild(currentWrapper);
                ele.appendChild(wrapper);
            });
            currentWrapper.addEventListener('msAnimationEnd', function() {
                currentWrapper.parentNode.removeChild(currentWrapper);
                ele.appendChild(wrapper);
            });
            currentWrapper.addEventListener('animationend', function() {
                currentWrapper.parentNode.removeChild(currentWrapper);
                ele.appendChild(wrapper);
            });
        } else {
            ele.appendChild(wrapper);
        }
    }

    nextMonth() {
        this.current.add(1, 'months');
        this.next = true;
        this.draw();
    }

    prevMonth() {
        this.current.subtract(1, 'months');
        this.next = false;
        this.draw();
    }

    static _createElement(tagName, className, innerText) {
        var ele = document.createElement(tagName);

        if (className) {
            ele.className = className;
        }

        if (innerText) {
            ele.textContent = innerText;
        }

        return ele;
    }
}

Calendar._count  = 0;
Calendar._loaded = {};

export default Calendar;
