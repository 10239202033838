"use strict";

import EventComponent from "./event_component.js";
import AutoComplete   from "./auto_complete.js";
import Dropdown       from "./dropdown.js";

class PermissionsPanel extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            return;
        }

        this.element = element;

        PermissionsPanel._count++;
        this.element.setAttribute('data-permissions-index', PermissionsPanel._count);

        PermissionsPanel._loaded[this.element.getAttribute('data-permissions-index')] = this;

        this.bindEvents();
    }

    static loadAll(element) {
        var elements = element.querySelectorAll('ul.permissions');

        elements.forEach( (element) => {
            PermissionsPanel.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-permissions-index');

        if (index) {
            return PermissionsPanel._loaded[index];
        }

        return new PermissionsPanel(element);
    }

    bindRowEvents(row) {
        // Override each permission field to create a dropdown instead.
        var items = row.querySelectorAll("span.key");
        items.forEach( (itemElement) => {
            // Value can be "on", "off", or null
            var key   = itemElement.getAttribute("data-key");
            var value = itemElement.getAttribute("data-value");

            // Nullify the form
            var itemForm = itemElement.querySelector("form");
            itemForm.addEventListener("submit", (event) => {
                event.preventDefault();
            });

            // Attach the dropdown events
            var dropdown = Dropdown.load(itemElement.querySelector(".dropdown-menu"));
            dropdown.on("selected", (data) => {
                // Update value of input
                var inputElement = itemForm.querySelector("input[name=value]");
                var key = itemForm.querySelector("input[name=update]").getAttribute("value");
                if (data.index == 0) {
                    itemElement.setAttribute("data-value", "on");
                    inputElement.setAttribute("value", "on");
                }
                else if (data.index == 1) {
                    itemElement.setAttribute("data-value", "off");
                    inputElement.setAttribute("value", "off");
                }
                else {
                    itemElement.removeAttribute("data-value");
                    inputElement.setAttribute("value", "");
                }

                // Send request
                var oReq = new XMLHttpRequest();
                oReq.addEventListener("load", () => {
                });
                oReq.open("POST", itemForm.getAttribute("action"));
                oReq.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

                var formdata = new FormData(itemForm);
                formdata.set("update", key);
                oReq.send(formdata);
            });
        });

        // Override the remove action to asynchronously post the delete request.
        var deleteLink = row.querySelector("input.link.delete");
        if (deleteLink) {
            var deleteForm = deleteLink.parentNode;

            deleteForm.addEventListener("submit", (event) => {
                event.preventDefault();

                var oReq = new XMLHttpRequest();
                oReq.addEventListener("load", () => {
                    // Remove the row from the document
                    row.remove();
                });
                oReq.open("POST", deleteForm.getAttribute("action"));
                oReq.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
                oReq.send(new FormData(deleteForm));
            });
        }
    }

    bindEvents() {
        var permissionRows = this.element.querySelectorAll(":scope > li");

        permissionRows.forEach( (row) => {
            this.bindRowEvents(row);
        });

        var autoComplete = AutoComplete.load(this.element.parentNode.querySelector(".add input.auto-complete"));
        autoComplete.on("change", (event) => {
            // Re-enable add button
            this.element.parentNode.querySelector('.add input[type="submit"]').removeAttribute("disabled");
        });

        var permissionAddRowButton = this.element.parentNode.querySelector(".add a.add-person");

        var permissionList = this.element;

        var form = permissionAddRowButton.previousElementSibling;
        if (form.classList.contains("bound")) {
            return;
        }
        form.classList.add("bound");

        permissionAddRowButton.addEventListener("click", (event) => {
            event.preventDefault();
            event.stopPropagation();

            // Find form and reveal it. Unreveal the button.
            // The form precedes the button

            permissionAddRowButton.style.display = "none";
            form.removeAttribute("hidden");
        });

        form.addEventListener("submit", (event) => {
            console.log("OH HEY", form);
            event.preventDefault();

            // Submit the form asynchronously and update the DOM with the result
            var oReq = new XMLHttpRequest();
            var loadingElement = form.previousElementSibling;
            var warningElement = loadingElement.previousElementSibling;
            loadingElement.style.width  = permissionList.querySelector("li").clientWidth  + "px";
            loadingElement.style.height = permissionList.querySelector("li").clientHeight + "px";
            loadingElement.removeAttribute("hidden");
            form.setAttribute("hidden", "");
            permissionAddRowButton.style.display = "";

            oReq.addEventListener("load", () => {
                loadingElement.setAttribute("hidden", "");
                var newElement = document.createElement("div");
                newElement.innerHTML = oReq.responseText;
                newElement = newElement.querySelector("li");
                newElement.classList.add("reveal");

                // Get the person id, if it exists here
                var personInput = newElement.querySelector("input[name=person]");
                if (personInput) {
                    var personId = personInput.getAttribute("value");
                    var existingRow = permissionList.querySelector("input[value='" + personId + "']");

                    if (existingRow) {
                        // Do not add
                        // Instead, show a dismissable warning
                        var deleteElement = warningElement.querySelector(".delete");
                        var removeEvent = (event) => {
                            warningElement.setAttribute("hidden", "");
                            warningElement.querySelector(".person-already-exists").setAttribute("hidden", "");
                        };
                        deleteElement.removeEventListener("click", removeEvent);
                        deleteElement.addEventListener("click", removeEvent);

                        warningElement.querySelector(".person-already-exists").removeAttribute("hidden");

                        warningElement.style.width = permissionList.querySelector("li").clientWidth  + "px";
                        warningElement.style.height = permissionList.querySelector("li").clientHeight + "px";
                        warningElement.style.lineHeight = permissionList.querySelector("li").clientHeight + "px";
                        warningElement.removeAttribute("hidden");
                        return;
                    }
                }

                // Add the row to the document
                permissionList.appendChild(newElement);

                // Add events
                this.bindRowEvents(newElement);
            });
            oReq.open("POST", form.getAttribute("action"));
            oReq.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            oReq.send(new FormData(form));
        });
    }
}

PermissionsPanel._count  = 0;
PermissionsPanel._loaded = {};

export default PermissionsPanel;
