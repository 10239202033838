"use strict";

import EventComponent from './event_component.js';
import OccamObject    from './occam_object.js';
import Tooltip        from './tooltip.js';
import Selector       from './selector.js';
import Util           from './util.js';

/**
 * This class represents any running interactive object.
 */
class Widget extends EventComponent {
    constructor(element, configurations) {
        super();

        this.element = element;
        this.configurations = configurations;

        // Get the widget host, if any
        if (this.element.hasAttribute('data-trusted-host')) {
            this.host = this.element.getAttribute('data-trusted-host');
        }

        // The current state
        this.state = "started";
        this.heartbeat = 0;
        this.pinged = 0;

        this._dirty = false;

        Widget._count++;
        this.element.setAttribute('data-widget-index', Widget._count);
        Widget._loaded[this.element.getAttribute('data-widget-index')] = this;

        // Add a 'cover' for dragging
        this.coverElement = document.createElement("div");
        this.coverElement.classList.add("cover");
        this.element.parentNode.appendChild(this.coverElement);

        // Add a 'loading' for loading indicator
        this.loadingElement = document.createElement("div");
        this.loadingElement.classList.add("cover");
        this.loadingElement.classList.add("loading");
        this.loadingElement.classList.add("show");
        this.element.parentNode.appendChild(this.loadingElement);

        this.bindEvents();

        if (this.isPreview()) {
            this.initializePreview();
        }
    }

    /**
     * Determines whether or not this is a preview widget.
     *
     * @returns {boolean} Returns true when the widget is being rendered as a preview widget and false otherwise.
     */
    isPreview() {
        return this.element.classList.contains("preview");
    }

    /**
     * Reports whether or not the data within the widget is considered changed without a save.
     *
     * @returns {boolean} Returns true when the widget data is dirty and false otherwise.
     */
    isDirty() {
        return this._dirty == true;
    }

    static load(element, configurations) {
        if (element === undefined) {
            throw "Element required";
        }

        var index = element.getAttribute('data-widget-index');

        if (index) {
            return Widget._loaded[index];
        }

        return new Widget(element, configurations);
    }

    static loadAll(element) {
        var widgets = element.querySelectorAll('iframe.widget');
        widgets.forEach( (widgetElement) => {
            Widget.load(widgetElement);
        });
    }

    /**
     * The object of the object that is running.
     */
    get object() {
        if (!this._object) {
            this._object = new OccamObject(
                this.element.getAttribute("data-object-id"),
                this.element.getAttribute("data-object-revision")
            );
        }

        return this._object;
    }

    /**
     * The object being viewed within this object.
     */
    get input() {
        if (!this._input && this.element.hasAttribute("data-input-object-id")) {
            this._input = new OccamObject(
                this.element.getAttribute("data-input-object-id"),
                this.element.getAttribute("data-input-object-revision")
            );

            if (this.element.hasAttribute('data-token')) {
                this._input.token = this.element.getAttribute('data-token');
            }

            if (this.element.hasAttribute('data-input-object-index')) {
                let index = this.element.getAttribute('data-input-object-index');
                if (index !== "") {
                    this._input.index = index.split(';');
                }
            }

            if (this.element.hasAttribute('data-link')) {
                this._input.link = this.element.getAttribute('data-link');
            }
        }

        return this._input;
    }

    /**
     * The task manifest, if available, for the running object.
     */
    get taskInfo() {
        return this._taskInfo;
    }

    set taskInfo(data) {
        // Update URL fields
        this.normalizeTask(data);

        this._taskInfo = data;
    }

    /**
     * Initializes the preview pane.
     */
    initializePreview() {
        this.element.src = this.element.getAttribute("data-src");
    }

    /**
     * Handles the "updateTask" event.
     */
    handleUpdateTask(message) {
        var iframe = this.element;

        // Resend input data
        if (this.taskInfo) {
            message.data = this.taskInfo;
            iframe.contentWindow.postMessage(message, '*');
        }
        else {
            this.input.objectInfo( (info) => {
                // Retain the task info
                this.taskInfo = info;

                // Send the message to the iframe
                this.handleUpdateTask(iframe, message);
            });
        }
    }

    /**
     * Normalizes task file and url fields.
     */
    normalizeTask(taskInfo) {
        if (taskInfo.file) {
            taskInfo.url = this.urlForFile(taskInfo.file, taskInfo);
        }

        if ((taskInfo.run || {}).file) {
            taskInfo.run.url = this.urlForFile(taskInfo.run.file, taskInfo);
        }

        (taskInfo.inputs || []).forEach( (wire) => {
            (wire.connections || []).forEach( (input) => {
                this.normalizeTask(input)
            });
        });

        let processes = (((taskInfo.running || [])[0] || {}).objects || []);

        processes.forEach( (process) => {
            this.normalizeTask(process);
        });
    }

    urlForFile(path, info) {
        let url = "/" + info.id + "/";
        if (info.link) {
            url = url + ":" + info.link;
        }
        else {
            url = url + info.revision;
        }

        if (info.indices) {
            info.indices.forEach( (index) => {
                url = url + "/" + index;
            });
        }
        if (info.buildId) {
            url += "/builds/" + info.buildId;
        }
        else if (info.build && info.build.id) {
            url += "/builds/" + info.build.id;
        }
        url += "/raw";
        if (info.file[0] != "/") {
            url += "/";
        }
        url += info.file;
        if (info.token) {
            url += "?token=" + encodeURIComponent(info.token);
        }

        return url;
    }

    /**
     * Sends the task to the widget.
     */
    sendUpdateTask() {
        this.handleUpdateTask({
            "name":  "updateTask",
            "data":  {}
        });
    }

    /**
     * Handles an "updateConfiguration" message.
     */
    handleUpdateConfiguration(message) {
        (this.configurations || []).forEach( (configuration) => {
            let inputIndex = configuration.element.getAttribute('data-input-index');
            configuration.load().then( (data) => {
                let index = parseInt(inputIndex);
                var iframe = this.element;
                var message = {
                    "name": "updateConfiguration",
                    "data": {
                        "index": index,
                        "name": "",
                        "values": data
                    }
                };
                if (iframe) {
                    iframe.contentWindow.postMessage(message, '*');
                }
            });
        });
    }

    /**
     * Handles an "updateInput" message.
     */
    handleUpdateInput(message) {
        var iframe = this.element;

        // Resend input data
        if (!iframe.hasAttribute("data-input-file") && !iframe.hasAttribute("data-input-object-id")) {
            // No input object
            iframe.contentWindow.postMessage(message, '*');
            return;
        }

        if (this.inputInfo) {
            message.data = this.inputInfo;
            if (iframe && iframe.contentWindow) {
                iframe.contentWindow.postMessage(message, '*');
            }
        }
        else {
            var path = iframe.getAttribute('data-input-file');
            this.input.objectInfo( (info) => {
                // Force update the path with the requested file
                if (path) {
                    info.file = path;
                }

                // Add the object id
                info.id = this.input.id;

                // Add the revision to the input file info
                info.revision = this.input.revision;

                if (this.input.index) {
                    info.indices = this.input.index;
                }

                // Add the access token to the input file info
                if (this.input.token) {
                    info.token = this.input.token;
                }

                // Add link tag
                if (this.input.link) {
                    info.link = this.input.link;
                }

                // Add the build this file/input is coming from
                if (iframe.hasAttribute('data-input-build-id')) {
                    info.buildId = iframe.getAttribute('data-input-build-id');
                }

                // Just include the URL for the sake of friendliness
                if (info.file) {
                    info.url = this.urlForFile(info.file, info);
                }

                this.inputInfo = info;
                this.handleUpdateInput(message);
            });
        }
    }

    /**
     * Handles an "updateToolbar" message.
     */
    handleUpdateToolbar(message) {
        let iframe = this.element;

        let items = message.data.items || [];
        let toolbar = this.element.parentNode.parentNode.querySelector(".widget-toolbar");
        toolbar.removeAttribute("hidden");
        toolbar.innerHTML = "";

        if (items.length == 0) {
            toolbar.setAttribute("hidden", "");
            toolbar.innerHTML = "";
        }

        let theme = {
            icon: toolbar.getAttribute('data-icon-color'),
            active: {
                icon: toolbar.getAttribute('data-active-icon-color')
            },
            hover: {
                icon: toolbar.getAttribute('data-hover-icon-color')
            },
            inactive: {
                icon: toolbar.getAttribute('data-inactive-icon-color')
            },
            disabled: {
                icon: toolbar.getAttribute('data-disabled-icon-color')
            }
        };

        // Creates each toolbar item
        items.forEach( (item) => {
            if (item.type == "icon") {
                let element = document.createElement("img");
                let url = this.object.url({
                    path: "dynamic/hex/" + theme.icon + "/" + item.file,
                    host: this.host
                });
                element.src = url;
                toolbar.appendChild(element);
            }
            else if (item.type == "button") {
                let element = document.createElement("button");
                element.classList.add("button");

                if (item.tooltip) {
                    element.setAttribute("title", item.tooltip);
                    Tooltip.load(element);
                }

                if (item.file) {
                    element.classList.add("icon");
                    let img = document.createElement("img");
                    let url = this.object.url({
                        path: "dynamic/hex/" + theme.icon + "/" + item.file,
                        host: this.host
                    });
                    img.src = url;
                    element.appendChild(img);

                    img = document.createElement("img");
                    img.classList.add("disabled");
                    url = this.object.url({
                        path: "dynamic/hex/" + theme.disabled.icon + "/" + item.file
                    });
                    img.src = url;
                    element.appendChild(img);

                    img = document.createElement("img");
                    img.classList.add("hover");
                    url = this.object.url({
                        path: "dynamic/hex/" + theme.hover.icon + "/" + item.file,
                        host: this.host
                    });
                    img.src = url;
                    element.appendChild(img);
                }

                if (item.disabled) {
                    element.setAttribute("disabled", "");
                }
                else {
                    element.removeAttribute("disabled");
                }

                toolbar.appendChild(element);

                element.addEventListener('click', (event) => {
                    if (iframe.contentWindow) {
                        iframe.contentWindow.postMessage({
                            "name":  "updateToolbar",
                            "data":  item,
                        }, '*');
                    }
                });
            }
            else if (item.type == "label") {
                let element = document.createElement("span");
                element.textContent = item.value;
                toolbar.appendChild(element);
            }
            else if (item.type == "select") {
                let element = document.createElement("select");
                element.classList.add("selector");
                (item.items || []).forEach( (option, i) => {
                    let subElement = document.createElement("option");
                    subElement.innerHTML = option;
                    if (i == item.selected) {
                        subElement.setAttribute('selected', '');
                    }
                    element.appendChild(subElement);
                });
                toolbar.appendChild(element);
                if (item.tooltip) {
                    element.setAttribute("title", item.tooltip);
                    Tooltip.load(element);
                }
                let selector = Selector.load(element);
                if (item.loading) {
                    selector.loading();
                }
                selector.on('change', (event) => {
                    item.selected = selector.indexFor(selector.selected());
                    if (iframe.contentWindow) {
                        iframe.contentWindow.postMessage({
                            "name":  "updateToolbar",
                            "data":  item,
                        }, '*');
                    }
                });
                Tooltip.load(selector.element);
            }
            else if (item.type == "separator") {
                let element = document.createElement("span");
                element.classList.add("separator");
                toolbar.appendChild(element);
            }
        });
    }

    /**
     * Handles an "updateFile" message.
     */
    handleUpdateFile(message) {
        var iframe = this.element;

        if (!message.file.startsWith("/")) {
            message.file = "/" + message.file;
        }

        var url = this.input.url({path: "files" + message.file});

        // POST the new file
        Util.post(url, message.data, {
            onload: (metadata) => {
                // Save is complete
            },
            onprogress: (event) => {
            }
        }, "json");
    }

    /**
     * Handles an "updateData" message.
     */
    handleUpdateData(message) {
        var iframe = this.element;

        var objectId       = this.id;
        var objectRevision = this.revision;

        if (message.name === 'updateData') {
            var url = "/" + objectId +
                "/"         + objectRevision +
                "/configurations/data";
            Util.post(url, JSON.stringify({
                "object_id":       objectId,
                "object_revision": objectRevision,
            }), (data) => {
            });
        }
    }

    /**
     * Handles an "updateSize" message.
     *
     * This event is triggered when the widget requests a resize.
     */
    handleUpdateSize(message) {
        var iframe = this.element;

        if (this.canResize) {
            var height;

            if (message.data && message.data.aspectRatio) {
                // It gave us an aspect ratio, so we can resize width and height.
                this.aspectRatio = message.data.aspectRatio;
                this.resize(this.height());
            }
            else if (message.data && message.data.height) {
                // It gave us an explicit height, so we should just use that (within reason).
                this.aspectRatio = null;
                height = message.data.height;
                this.resize(height);
            }
        }
    }

    /**
     * Handles an "updateStatus" message.
     */
    handleUpdateStatus(message) {
        var iframe = this.element;

        if (message.data === 'loading') {
            // The widget has indicated it is loading and we should
            // provide a loading graphic
            this.state = "loading";
            this.loadingElement.classList.remove("hide");
            this.loadingElement.classList.add("show");
        }
        else if (message.data === 'loaded') {
            // The widget is telling us it has finished loading
            this.state = "loaded";
            this.loadingElement.classList.remove("show");
            this.loadingElement.classList.add("hide");
        }
        else if (message.data === 'ready') {
            // The widget has loaded, remove the loading graphic.
            this.state = "ready";
            this.loadingElement.classList.remove("show");
            this.loadingElement.classList.add("hide");
        }
        else if (message.data === 'failed') {
            // The widget has failed loading, add an error graphic.
            // TODO: provide an error element
        }
        else if (message.data === 'dirty') {
            this._dirty = true;
        }

        if (this.pinged == 0) {
            // We don't know if we've contacted it yet
            // So we give it a heartbeat of our own.
            this.sendReadyStatus();
            this.pinged++;
        }
    }

    /**
     * Sends an "updateStatus" message that says we are ready.
     *
     * TODO: make this a general function that you pass the status.
     */
    sendReadyStatus() {
        var iframe = this.element;

        // Tell the widget we are ready
        if (iframe.contentWindow) {
            iframe.contentWindow.postMessage({
                "name":  "updateStatus",
                "data":  "ready"
            }, '*');
        }
    }

    /**
     * Binds events for this iframe and widget.
     */
    bindEvents() {
        // When the iframe loads, remove the loading graphic.
        // Alternatively, the widget can send a ready response to do the same.
        this.element.addEventListener("load", (event) => {
            if (this.state != "loading") {
                this.loadingElement.classList.remove("show");
                this.loadingElement.classList.add("hide");
            }
        });

        // This is attached to the 'onload' property for knowing when the page
        // is loaded prior to running this class. This gets around a race
        // condition. It must have 'this._ready = true;' in an onload
        // attribute of the iframe in the view.
        if (this.element._ready) {
            if (this.state != "loading") {
                this.loadingElement.classList.remove("show");
                this.loadingElement.classList.add("hide");
            }
        }

        // Set up the message passing events.
        this.bindMessageEvents();
    }

    /**
     * Sets up events for iframe communication.
     */
    bindMessageEvents() {
        var iframe = this.element;

        window.addEventListener('message', (event) => {
            this.heartbeat++;

            var message = event.data;

            // React if the source of the message is the iframe
            if (event.source === iframe.contentWindow) {
                if (message.name === 'updateStatus') {
                    this.handleUpdateStatus(message);
                }
                else if (message.name === 'updateConfiguration') {
                    this.handleUpdateConfiguration(message);
                }
                else if (message.name === 'updateInput') {
                    this.handleUpdateInput(message);
                }
                else if (message.name === 'updateFile') {
                    // Stores data to the file (if possible)
                    this.handleUpdateFile(message);
                }
                else if (message.name === 'updateToolbar') {
                    // Stores data to the file (if possible)
                    this.handleUpdateToolbar(message);
                }
                else if (message.name === 'updateTask') {
                    this.handleUpdateTask(message);
                }
                else if (message.name === 'updateSize') {
                    this.handleUpdateSize(message);
                    if (window.parent) {
                        window.parent.postMessage(message, '*');
                    }
                }
            }
        });

        this.sendReadyStatus();
    }
}

Widget._loaded = {};
Widget._count  = 0;

export default Widget;
