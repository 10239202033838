"use strict";

import EventComponent from './event_component.js';
import Util           from './util.js';
import OccamObject    from './occam_object.js';
import WebSocket      from './web_socket.js';
import Occam          from './occam.js';

/**
 * This class represents a Job in the job queue.
 */
class Job extends EventComponent {
    constructor(element) {
        super();

        // Initially hasn't started
        this.started = false;

        this.element = element;
        this.tag = Math.floor(Math.random() * 10000);

        // Look for a run id
        this.jobID = this.element.getAttribute("data-job-id");

        if (this.jobID) {
            var job_object_id = this.element.getAttribute("data-job-object-id");
            if (job_object_id) {
                this.object = new OccamObject(this.element.getAttribute("data-job-object-id"),
                    this.element.getAttribute("data-job-object-revision"));
            }
            else {
                this.object = Occam.object();
            }

            this.jobData(this.object, this.jobID, (data) => {
                // Create a polling timer for updating the job (if it is not finished/failed)
                if (!data.job.failureTime && !data.job.finishTime) {
                    this.pollJobTimer = window.setInterval( () => {
                        this.poll();
                    }, Job.POLL_TIME);
                }
            });
        }

        Job._count++;
        this.element.setAttribute('data-loaded-job-index', 'job-' + Job._count);
        Job._loaded[this.element.getAttribute('data-loaded-job-index')] = this;
    }

    static load(element) {
        if (!element) {
            return;
        }

        var index = element.getAttribute('data-loaded-job-index');

        if (index) {
            return Job._loaded[index];
        }

        return new Job(element);
    }

    /**
     * Override the on to send the 'start' event right away.
     */
    on(name, callback) {
        super.on(name, callback);

        if (name === "start" && this.started) {
            this.trigger('start', this.lastData);
        }
    }

    /**
     * Will call the callback with the job's network manifest.
     */
    networkInfo(callback) {
        if (this._networkInfo) {
            callback(this._networkInfo);
            return;
        }

        Util.get(this.object.url({"path": "jobs/" + this.jobID + "/network"}), (data) => {
            if (data) {
                this._networkInfo = data;
                callback(data);
            }
        }, "json");
    }

    /**
     * Will call the callback with the job's task manifest.
     */
    taskInfo(callback) {
        if (this._taskInfo) {
            callback(this._taskInfo);
            return;
        }

        Util.get(this.object.url({"path": "jobs/" + this.jobID + "/task"}), (data) => {
            if (data) {
                this._taskInfo = data;
                callback(data);
            }
        }, "json");
    }

    /**
     * Opens a log socket and calls the given callback for when the log is updated.
     */
    log() {
        this.logWS = WebSocket.route("job-log-" + this.jobID + "-" + this.tag, (data) => {
            // Buffer until a newline and parse the JSON if possible
            if (data.base64) {
                data.output = atob(data.base64 || "")
            }
            this.trigger("log-update", data);
        }, this);

        this.logWS.send({
            "request": "open",
            "spawning": "logs",
            "terminal": "log",
            "data": {"job_id": this.jobID}
        });
    }

    /**
     * Opens a two-way connection with the running job.
     */
    connect(callback) {
        this.connectWS = WebSocket.route("job-connect-" + this.tag, function(data) {
            // TODO: Buffer until a newline and parse the JSON if possible
            data.output = atob(data.output || "")
            callback(data);
        }, this);

        let connection = "connection-" + Math.random();

        this.connectWS.send({
            "request": "open",
            "spawning": "connect",
            "terminal": connection,
            "data": {"job_id": this.jobID}
        });

        return {
            "send": (data) => {
                this.connectWS.send({
                    "request": "send",
                    "terminal": connection,
                    "input": data,
                    "data": {"job_id": this.jobID}
                });
            }
        };
    }

    /**
     * Opens a log socket and calls the given callback for each new event.
     */
    eventsLog(callback) {
        var buffer = "";
        this.eventWS = WebSocket.route("job-event-log-" + this.tag, (data) => {
            // Buffer until a newline and parse the JSON if possible
            if (data.output) {
                buffer = buffer + data.output;
            }
            var newline;
            do {
                // JavaScript's split function is terrible wow
                newline = buffer.indexOf("\n");
                if (newline > -1) {
                    var line = buffer.substring(0, newline);
                    buffer = buffer.substring(newline + 1);
                    this.trigger("event", JSON.parse(line));
                }
            } while (newline > -1);
        }, this);

        this.eventWS.send({
            "request": "open",
            "spawning": "events",
            "terminal": "events",
            "data": {"job_id": this.jobID}
        });
    }

    poll() {
        this.jobData(this.object, this.jobID, (data) => {
            if (data && data.job) {
                if (data.job.startTime) {
                    if (!this.started) {
                        this.lastData = data;
                        this.started = true;
                        this.trigger('start', data);
                    }
                }

                if (data.job.finishTime || data.job.failureTime) {
                    window.clearInterval(this.pollJobTimer);
                    this.trigger('done', data);
                }
            }
        });
    }

    jobData(object, jobID, callback) {
        Util.get(this.object.url({"path": "jobs/" + jobID}), function(data) {
            callback(data);
        }, "json");
    }
}

Job._count  = 0;
Job._loaded = {};

/**
 * The time in milliseconds between polling for updates in a run.
 */
Job.POLL_TIME = 4000;

export default Job;
