"use strict";

import Flash from "./flash.js";

/**
 * This class module manages any open WebSocket connections.
 * 
 * Generally, there is a single open websocket connection that is shared among
 * all page components. This module routes requests from the server to the
 * correct component.
 */
class WebSocket {

    /**
     * This method will open the main websocket on the current domain.
     */
    static initialize() {
        var scheme = "ws://";
        if (window.document.location.protocol == "https:") {
            scheme = "wss://";
        }
        var uri    = scheme + window.document.location.host + "/";
        //window.console.log(uri);

        var ws;
        try {
            ws = new window.WebSocket(uri);
        }
        catch (e) {
            return;
        }

        // TODO: handle this event
        //window.on('beforeunload', function(){
        //  ws.close();
        //});

        ws.onmessage = WebSocket.onmessage;
        ws.onopen    = WebSocket.onopen;
        ws.onclose   = WebSocket.onclose;
        ws.onerror   = WebSocket.onerror;

        WebSocket.ws = ws;
    }

    /**
     * This method handles websocket connections. The parameter 'event'
     * contains the websocket event.
     */
    static onopen(event) {
        if (WebSocket._reconnect_timer) {
            window.clearInterval(WebSocket._reconnect_timer);
            WebSocket._reconnect_timer = null;
        }

        if (WebSocket._errorFlash !== undefined) {
            Flash.pop(WebSocket._errorFlash);
            WebSocket._errorFlash = undefined;
            Flash.push("success", "Connected to server.", 1000);
        }

        //window.console.log("websocket opened");
    }

    /**
     * This method handles websocket disconnection. The parameter 'event'
     * contains the websocket event.
     */
    static onclose(event) {
        // Tell the client via Flash message
        if (WebSocket._errorFlash === undefined) {
            window.setTimeout( () => {
                // First disconnect... show message and reset delay to starting delay.
                WebSocket._errorFlash = Flash.push("error", "Cannot connect to server.");
            }, 1500);

            WebSocket._period = WebSocket.RECONNECT_PERIOD;
        }

        // Attempt to reconnect after a delay (stagger delay each time)
        WebSocket._reconnect_timer = window.setTimeout(function() {
            WebSocket._period = Math.min(WebSocket._period * 2, WebSocket.MAX_RECONNECT_PERIOD);
            WebSocket.initialize();
        }, WebSocket._period);
    }

    /**
     * This method handles websocket messages incoming. The parameter 'message'
     * contains the message from the server.
     */
    static onmessage(message) {
        // By default, we simply route the message to a callback
        // that was registered previously.
        var data = JSON.parse(message.data);

        if (data.tag) {
            var tuple = WebSocket.router[data.tag];
            var callback = tuple[0];
            var self     = tuple[1];

            callback.call(self, data.data);
        }
    }

    /**
     * This method captures any errors of the websocket.
     */
    static onerror(event) {
    }

    /**
     * This method adds the given item to the routing table such that it will
     * invoke the callback whenever it sees a websocket message with 'item' as a
     * tag.
     */
    static route(item, callback, self) {
        WebSocket.router[item] = [callback, self];
        return {
            "send": function(data) {
                var taggedData = {
                    "tag": item,
                    "data": data
                };

                WebSocket.ws.send(JSON.stringify(taggedData));
            }
        };
    }
}

/**
 * The amount of time between a disconnect and attempting to reconnect
 */
WebSocket.RECONNECT_PERIOD = 1000; // 1 second

/**
 * The maximum amount of time between a reconnection attempt and the next.
 */
WebSocket.MAX_RECONNECT_PERIOD = (1000 * 60 * 30); // 30 minutes

/**
 * The handle for the actual websocket.
 */
WebSocket.ws = null;

/**
 * A map between unique tags and messages.
 */
WebSocket.router = {};

// Initialize the websocket
WebSocket.initialize();

export default WebSocket;
