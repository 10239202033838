"use strict";

import EventComponent from "./event_component.js";
import Util           from "./util.js";
import Tooltip        from "./tooltip.js";

/**
 * This represents a dropdown selection and typically wraps a `<select>` element
 * on the page.
 *
 * Generally, you instantiate an Selector using Selector.load(element)
 * instead of invoking the constructor directly.
 *
 * @param {HTMLElement} element The main element for the selector;
 *                              typically the `<select>` element.
 */
class Selector extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            throw new TypeError("element is required");
        }

        if (!element.tagName) {
            throw new TypeError("element must be an HTMLElement");
        }

        if (element.tagName.toUpperCase() == "SELECT") {
            this.element = undefined;
            this.selectElement = element;
        }
        else {
            this.element = element;
            this.selectElement = element.previousElementSibling;
        }

        this._items = [];
        this._icons = 0;

        // Bind events
        this.bindEvents();

        Selector._count++;
        this.element.setAttribute('data-loaded-index', 'selector-' + Selector._count);
        this.selectElement.setAttribute('data-loaded-index', this.element.getAttribute('data-loaded-index'));
        Selector._loaded[this.element.getAttribute('data-loaded-index')] = this;
    }

    /**
     * Returns an instance of Selector for the given element.
     *
     * This will create a Selector, if it doesn't exist, for this element.
     *
     * @param {HTMLElement} element The main element for the selector;
     *                              typically the `<select>` element.
     */
    static load(element) {
        if (!element) {
            throw new TypeError("element argument required");
        }

        var index = element.getAttribute('data-loaded-index');

        if (index) {
            return Selector._loaded[index];
        }

        return new Selector(element);
    }

    static loadAll(element) {
        if (!element) {
            throw new TypeError("element argument required");
        }

        if (!element.tagName) {
            throw new TypeError("element must be an HTMLElement");
        }

        var selectors = element.querySelectorAll('select.selector');

        selectors.forEach( (element) => {
            Selector.load(element);
        });
    }

    /**
     * Clears out the selector and replaces it with a loading spinner.
     */
    loading() {
        this.clear();
        this.element.innerHTML = "";
        this.element.classList.add('waiting');

        var loadingElement = document.createElement("div");
        loadingElement.classList.add("loading");
        this.element.appendChild(loadingElement);
    }

    /**
     * Updates the element to remvoe the waiting status.
     */
    loaded() {
        this.element.innerHTML = "";
        this.element.classList.remove('waiting');
    }

    /**
     * Clears items.
     */
    clear() {
        this.dropdown.querySelectorAll('button').forEach( (element) => {
            element.remove();
        });
    }

    /**
     * Returns the list of items in the dropdown as an element array.
     */
    items() {
        return this.dropdown.querySelectorAll('button');
    }

    /**
     * Returns the list item that is currently selected.
     *
     * @returns {HTMLElement} The `<button>` element representing the item.
     */
    selected() {
        return this.dropdown.querySelector('button:nth-child('+(parseInt(this.element.getAttribute('data-selected-index'))+1)+')');
    }

    indexFor(index_or_element) {
        if (!!index_or_element.tagName) {
            // We were given an HTMLElement, get index by element position
            index_or_element = Util.getChildIndex(index_or_element, "button");
        }
        else if (typeof index_or_element == "object") {
            // Check by node data
            let data = index_or_element;
            index_or_element = -1;
            this.dropdown.querySelectorAll('button').forEach( (button, i) => {
                let typePanel = button.querySelector('h2');
                let namePanel = button.querySelector('p');
                let discovered = true;
                if (typePanel) {
                    if (typePanel.textContent != (data.i18n || data.type)) {
                        discovered = false;
                    }
                }
                else if (data.type) {
                    discovered = false;
                }

                if (namePanel) {
                    if (namePanel.textContent != data.name) {
                        discovered = false;
                    }
                }
                else if (data.name) {
                    discovered = false;
                }

                if (discovered) {
                    index_or_element = i;
                }
            });
        }
        else {
            // Select the actual index position.
            index_or_element = parseInt(index_or_element);
        }

        return index_or_element;
    }

    /**
     * This function will select the given list item by the given reference.
     *
     * You can pass an integer index that represents the position. Index 0 is
     * the first item in the list.
     *
     * You can also pass in the item element.
     *
     * Finally, you can pass in a dictionary object that contains data to
     * identify the entry, such as `{ name: "foo", type: "bar" }`
     *
     * If it cannot find the item, it will return null.
     *
     * @returns {HTMLElement} The '<button>' element representing the selected
     *                        item, or null if the element cannot be selected.
     */
    select(index_or_element) {
        let index = this.indexFor(index_or_element);

        var listItem = this.dropdown.querySelector('button:nth-child(' + (index + 1) + ')');
        var selector = this.element;

        // Unselect the last selected option
        var last = this.selected();
        if (last) {
            // Unselect the rendered option
            last.removeAttribute("selected");
            last.setAttribute("aria-checked", "false");

            // Retrieve and unselect the native form <option>
            var lastIndex = Util.getChildIndex(last, "button");
            var lastOption = this.selectElement.children[lastIndex];
            if (lastOption) {
                lastOption.removeAttribute("selected");
            }
        }

        // Clear front of widget
        this.element.innerHTML = "";

        if (listItem) {
            // Replace the front of the widget with the contents of the option.
            Array.from(listItem.children).forEach( (childNode) => {
                this.element.appendChild(childNode.cloneNode(true));
            });

            // Copy some of the properties of the option to the main body.
            selector.setAttribute('data-original-text', listItem.getAttribute('data-original-text'));

            // Make this item 'selected'
            this.selectElement.children[index].setAttribute('selected', '');
            listItem.setAttribute('selected', '');
            listItem.setAttribute('aria-checked', 'true');
            this.element.setAttribute('data-selected-index', index);

            // Trigger the original element's change
            let changeEvent = new Event('change', {});
            this.selectElement.dispatchEvent(changeEvent);

            // Trigger the internal event
            this.trigger('change', listItem);
        }

        return listItem;
    }

    /**
     * Returns a dictionary object representing the data of the given item.
     */
    infoFor(index_or_element) {
        let index = this.indexFor(index_or_element);
        return this._items[index];
    }

    /**
     * This function reveals the dropdown list.
     */
    open() {
        // Do not load and show the dropdown if the selector is in a loading
        // state.
        if (this.element.classList.contains('waiting')) {
            return;
        }

        // Ensure some elements cannot be interacted with
        document.body.classList.add('await-blur');

        this.element.setAttribute("aria-expanded", "true");

        var inputStyle = window.getComputedStyle(this.element);
        var inputWidth  = this.element.offsetWidth - parseInt(inputStyle.borderLeftWidth) - parseInt(inputStyle.borderRightWidth);
        var inputHeight = this.element.offsetHeight;

        var offset = this.element.getBoundingClientRect();

        this.dropdown.style.width = inputWidth + "px";
        this.dropdown.style.height = "";
        this.dropdown.style.display = "block";
        this.dropdown.style.left = offset.left + "px";
        this.dropdown.style.top  = (offset.top + inputHeight) + "px";
        this.dropdown.removeAttribute("hidden");
        if (parseInt(this.dropdown.style.top) + this.dropdown.clientHeight > (window.innerHeight + window.scrollY)) {
            this.dropdown.style.top = (parseInt(this.dropdown.style.top) - this.dropdown.clientHeight - inputHeight) + "px";
        }
        var selectedItem = this.dropdown.querySelector('button[aria-checked="true"]');
        if (selectedItem) {
            selectedItem.setAttribute("tabindex", "0");
            selectedItem.focus();
            selectedItem.setAttribute("tabindex", "-1");
        }
        else {
            // We need something to receive focus
            this.dropdown.setAttribute("tabindex", "0");
            this.dropdown.focus();
            this.dropdown.setAttribute("tabindex", "-1");
        }
        this.dropdown.removeEventListener('blur', this.blurEvent.bind(this));
        this.dropdown.addEventListener('blur', this.blurEvent.bind(this));
    }

    blurEvent(event, forceClose) {
        // Ignore blur event if the target is a button within the dropdown
        if (forceClose || (!event.relatedTarget || (event.relatedTarget.parentNode != event.target && !event.relatedTarget.isSameNode(this.dropdown)))) {
            this.dropdown.setAttribute("hidden", "");
            this.element.setAttribute("aria-expanded", "false");
            this.element.focus();

            // Get rid of interactivity block
            document.body.classList.remove('await-blur');
        }
    }

    /**
     * Updates an existing item based on the given data.
     *
     * @returns {HTMLElement} The updated item element.
     */
    update(index_or_element, data) {
        var item = this.elementFor(index_or_element);

        var index = this.indexFor(index_or_element);

        // Update the data we know about
        this._items[index] = data;

        // Remove any stuff
        item.innerHTML = "";

        // Add new stuff
        var header = document.createElement("h2");
        var namePlate = document.createElement("p");
        var attributes = data.attributes || {};

        // The icons
        if (data.icon) {
            var icon = document.createElement('img');

            var hoverIcon = icon.cloneNode();
            hoverIcon.classList.add('hover');

            var disabledIcon = icon.cloneNode();
            disabledIcon.classList.add('disabled');

            item.appendChild(icon);
            item.appendChild(hoverIcon);
            item.appendChild(disabledIcon);

            let imageURL = data.icon;

            let iconURL = data.icon;
            let hoverIconURL = data.icon;
            let disabledIconURL = data.icon;

            if (this.element.hasAttribute('data-icon-base-url')) {
                imageURL = this.element.getAttribute('data-icon-base-url');

                if (this.element.hasAttribute('data-icon-color')) {
                    iconURL = imageURL + this.element.getAttribute('data-icon-color') + data.icon;
                    disabledIconURL = imageURL + this.element.getAttribute('data-icon-color') + data.icon;
                    hoverIconURL = imageURL + this.element.getAttribute('data-icon-color') + data.icon;
                }

                if (this.element.hasAttribute('data-icon-hover-color')) {
                    hoverIconURL = imageURL + this.element.getAttribute('data-icon-hover-color') + data.icon;
                }

                if (this.element.hasAttribute('data-icon-disabled-color')) {
                    disabledIconURL = imageURL + this.element.getAttribute('data-icon-disabled-color') + data.icon;
                }
            }

            icon.src = iconURL;
            hoverIcon.src = hoverIconURL;
            disabledIcon.src = disabledIconURL;
        }

        item.setAttribute("type", "button");
        item.setAttribute("role", "menuitemradio");
        item.setAttribute("tabindex", "-1");

        Object.keys(attributes).forEach( (attribute) => {
            if (attribute.startsWith("data-")) {
                item.setAttribute(attribute, attributes[attribute]);
            }
        });

        header.setAttribute('data-object-type', data.type || "object");

        (data.classes || []).forEach( (className) => {
            header.classList.add(className);
        });

        if (data.hidden) {
            item.setAttribute('hidden', '');
        }

        header.classList.add('icon');
        header.textContent = data.i18n || data.type || data.name;
        item.setAttribute('data-original-text', data.type || data.name);

        item.removeAttribute("selected");
        item.setAttribute("aria-checked", "false");

        if (data.selected) {
            item.setAttribute("selected", "");
            item.setAttribute("aria-checked", "true");
        }

        if (data.i18n) {
            header.setAttribute('data-i18n-default', data.type);
        }

        item.appendChild(header);

        if (data.type && data.name) {
            namePlate.textContent = data.name;
            item.appendChild(namePlate);
        }

        // Add it to the original <select> as well, if needed
        if (this.selectElement.children.length < this.dropdown.children.length) {
            let optionElement = document.createElement("option");
            optionElement.textContent = data.name || data.type;
            this.selectElement.appendChild(optionElement);
        }

        // Set up events
        this.bindItemEvents(item);

        if (data.selected) {
            this.select(item);
        }

        return item;
    }

    /**
     * Adds an item to the list.
     */
    append(data, toIndex) {
        var item = document.createElement("button");
        item.classList.add("dropdown-item");

        if (data.icon) {
            this._icons++;
        }

        toIndex = toIndex || this.dropdown.children.length;

        // Keep track of the data.
        this._items.push(data);

        // Add it to the document
        this.dropdown.appendChild(item);

        // Fill it in
        this.update(item, data)
    }

    /**
     * Disables this component.
     */
    disable() {
        this.element.setAttribute('disabled', '');
    }

    /**
     * Enables this component.
     */
    enable() {
        this.element.removeAttribute('disabled');
    }

    elementFor(index_or_element) {
        let index = this.indexFor(index_or_element);
        return this.dropdown.querySelector('button:nth-child('+(index+1)+')');
    }

    /**
     * This function initializes the selector and binds interactive events.
     */
    bindEvents(force) {
        if (this.element) {
            if (!force && this.element.classList.contains('bound')) {
                return;
            }
        }

        var selectedOption = this.selectElement.querySelector('option[selected]');
        if (!selectedOption) {
            var firstOption = this.selectElement.querySelector('option:first-child');
            if (firstOption) {
                firstOption.setAttribute("selected", "");
            }
        }

        var options = this.selectElement.querySelectorAll('option');

        // Form dropdown section
        var dropdown = this.dropdown = document.createElement("div");
        dropdown.classList.add("dropdown");
        dropdown.setAttribute("tabindex", "0");
        dropdown.setAttribute('hidden', '');
        dropdown.setAttribute('role', 'menu');

        this.dropdown.addEventListener('keydown', (event) => {
            let keyCode = event.keyCode || event.code;

            if (keyCode == 38) { // up arrow
                // Focus on last item
                let item = this.dropdown.querySelector('button:last-child');
                if (item) {
                    item.setAttribute("tabindex", "0");
                    item.focus();
                    item.setAttribute("tabindex", "-1");
                }
            }
            else if (keyCode == 40) { // down arrow
                // Focus on first item
                let item = this.dropdown.querySelector('button:first-child');
                if (item) {
                    item.setAttribute("tabindex", "0");
                    item.focus();
                    item.setAttribute("tabindex", "-1");
                }
            }
            else if (keyCode == 27) { // escape
                // Ensure relatedTarget doesn't keep the dropdown open
                this.blurEvent(event, true);
                return;
            }
        });

        var selector = this.element;
        var selectedItem = this.selectElement.querySelector('option:checked');
        var selected = -1;

        var current = selectedItem;
        while (current) {
            selected++;
            current = current.previousElementSibling;
        }

        if (this.element === undefined) {
            selector = document.createElement("button");
            selector.setAttribute('class', this.selectElement.getAttribute('class'));
            selector.setAttribute('id', this.selectElement.getAttribute('id'));
            this.selectElement.removeAttribute('id');
            selector.setAttribute('aria-haspopup', 'true');
            selector.setAttribute('aria-expanded', 'false');
            dropdown.setAttribute('id', selector.getAttribute('id') + "-dropdown");
            selector.setAttribute('aria-controls', dropdown.getAttribute('id'));
            selector.setAttribute('type', 'button');

            ['hidden', 'title', 'aria-label', 'data-tooltip-text',
                'data-icon-base-url', 'data-icon-color', 'data-icon-hover-color', 'data-icon-disabled-color'].forEach( (attribute) => {
                    if (this.selectElement.hasAttribute(attribute)) {
                        selector.setAttribute(attribute, this.selectElement.getAttribute(attribute));
                    }
                });

            this.selectElement.parentNode.insertBefore(selector, this.selectElement.nextElementSibling);
            this.element = selector;
            this.element.setAttribute('data-selected-index', selected);

            Tooltip.loadAll(selector);
        }

        options.forEach( (option) => {
            let name = null;
            let type = option.getAttribute('data-object-type');
            if (option.hasAttribute('data-object-type')) {
                name = option.textContent;
            }
            else {
                type = option.textContent;
            }
            let id = option.getAttribute('data-object-id');
            let revision = option.getAttribute('data-object-revision');

            let attrs = {};

            Array.from(option.attributes).forEach( (attr) => {
                attrs[attr.name] = attr.value || true;
            });

            // Gather the information for the <option>
            let data = {
                // All HTML attributes to be duplicated
                attributes: attrs,

                // The object type
                type: type,

                // The object name
                name: name,

                // The object id/revision
                id: id,
                revision: revision,

                // The class attribute list
                classes: [],

                // Whether or not it is hidden
                hidden: false
            }

            // Add any i18n hints for the text of this <option>
            // (Because we often want the un-i18n value for the <select> but
            //  want to display a localized string.)
            if (option.hasAttribute('data-i18n')) {
                data.i18n = option.getAttribute('data-i18n');
            }

            // Append any classes that are attached to the <option>
            if (option.hasAttribute('class') && option.getAttribute('class').trim() != "") {
                data.classes = option.getAttribute('class').split(' ');
            }

            // Append any classes that we want applied only to the created <button>
            if (option.hasAttribute('data-class') && option.getAttribute('data-class').trim() != "") {
                data.classes.push(option.getAttribute('data-class'));
            }

            // Get the icon
            if (option.hasAttribute('data-icon-url')) {
                data.icon = option.getAttribute('data-icon-url');
            }

            // Hide, if the <option> is hidden.
            data.hidden = option.hasAttribute("hidden");

            // Whether or not the <option> is selected.
            data.selected = option.hasAttribute("selected");

            this.append(data);
        });

        // Append dropdown to body
        document.querySelector('.content').appendChild(dropdown);

        if (dropdown.children.length > 0) {
            var originalText = dropdown.children[selected].getAttribute('data-original-text');
            selector.setAttribute('data-original-text', originalText);
        }

        this.selectElement.setAttribute('hidden', '');
        this.selectElement.setAttribute('aria-hidden', 'true');

        this.element.addEventListener('keydown', (event) => {
            let keyCode = event.keyCode || event.code;

            if (keyCode == 40) {
                this.open();
            }
        });

        this.element.addEventListener('click', (event) => {
            event.stopPropagation();
            event.preventDefault();

            this.open();
        });

        this.select(selected);

        if (this._icons == 0) {
            this.element.classList.add("no-icons");
            this.dropdown.classList.add("no-icons");
        }

        this.element.classList.add('bound');
    }

    /**
     * Binds the interactive events for a dropdown item.
     */
    bindItemEvents(item) {
        item.addEventListener('click', (event) => {
            this.blurEvent(event);

            event.stopPropagation();
            event.preventDefault();

            var index = Util.getChildIndex(item);
            this.select(index);
        });

        item.addEventListener('blur', (event) => {
            if (!event.relatedTarget || (event.relatedTarget.parentNode != item.parentNode && event.relatedTarget != item.parentNode)) {
                // Ensure relatedTarget doesn't keep the dropdown open
                this.blurEvent(event, true);
            }
        });

        item.addEventListener('keydown', (event) => {
            event.stopPropagation();

            let keyCode = event.keyCode || event.code;

            var toggle = null;
            if (keyCode == 38) { // up arrow
                toggle = item;
                do {
                    toggle = toggle.previousElementSibling;
                } while (toggle && toggle.hasAttribute("hidden"));
            }
            else if (keyCode == 40) { // down arrow
                toggle = item;
                do {
                    toggle = toggle.nextElementSibling;
                } while (toggle && toggle.hasAttribute("hidden"));
            }
            else if (keyCode == 27) { // escape
                // Ensure relatedTarget doesn't keep the dropdown open
                this.blurEvent(event, true);
                return;
            }

            if (toggle) {
                toggle.setAttribute("tabindex", "0");
                toggle.focus();
                toggle.setAttribute("tabindex", "-1");
            }
        });
    }
}

Selector._count  = 0;
Selector._loaded = {};

export default Selector;
