"use strict";

/**
 * This class contains useful functions mostly related to DOM exploration and
 * ajax requests.
 */
class Util {
    /**
     * This will submit the given form.
     *
     * @param {HTMLFormElement} form The `<form>` element to submit.
     *
     * @returns {XMLHttpRequest} The ajax request object.
     */
    static submitForm(form, data_or_callback, callback) {
        var data = data_or_callback;
        if (callback === undefined) {
            callback = data_or_callback;
            data = undefined;
        }

        var formdata = new FormData(form);

        if (data) {
            data.forEach(function(tuple) {
                formdata.set(tuple[0], tuple[1]);
            });
        }

        var method = form.getAttribute("method") || "GET";
        var url    = form.getAttribute("action");

        if (method.toUpperCase() == "GET") {
            let params = [...formdata.entries()].map(e => encodeURIComponent(e[0]) + "=" + encodeURIComponent(e[1]));
            url = url + "?" + params.join("&");
        }

        return Util.ajax(method, url, formdata, callback);
    }

    /**
     * This will issue an ajax request. Generally, the functions get() and
     * post() are more convenient.
     *
     * @param {string} method The HTTP method ("GET", "POST") to use.
     * @param {string} url The URL to request.
     * @param {string} data The data to send.
     * @param {ajaxCallback} callback The callback function when the request is successful.
     * @param {string} responseType The type of response to ask for as the Accept parameter.
     *                              If this is "json" or "application/json", the callback
     *                              will automatically receive parsed JSON and fail if the
     *                              JSON is unacceptable.
     *
     * @returns {XMLHttpRequest} The ajax request object.
     */
    static ajax(method, url, data, callback, responseType) {
        var oReq = new XMLHttpRequest();

        // This ensures that the browser doesn't cache a partial ajax request as the
        // actual page. If you don't do this, the browser may respond to a back button
        // or page reload with the response of the ajax request if the URL is the
        // same. Kinda baffling it doesn't cache XML Request stuff separate really!!
        if (method == "GET") {
            if (url.includes("?")) {
                url = url + "&_ajax";
            }
            else {
                url = url + "?_ajax";
            }
        }

        if (callback) {
            if (callback.onprogress) {
                oReq.addEventListener("progress", function(event) {
                    callback.onprogress(event);
                });
            }
            oReq.addEventListener("load", function(event) {
                if (oReq.readyState == 4 && oReq.status == 200) {
                    var response = oReq.responseText;

                    if (responseType == "application/json" || responseType == "json") {
                        // TODO: handle errors
                        response = JSON.parse(response);
                    }

                    if (callback.onload) {
                        callback.onload(response);
                    }
                    else {
                        callback(response);
                    }
                }
            });
        }

        oReq.open(method, url);
        oReq.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

        if (responseType == "json" || responseType == "application/json") {
            oReq.setRequestHeader('Accept', 'application/json');
        }
        else if (responseType) {
            oReq.setRequestHeader('Accept', responseType);
        }

        if (data) {
            if ((typeof data) == "string") {
                oReq.setRequestHeader('Content-Type', 'application/octet-stream');
            }
            oReq.send(data);
        }
        else {
            oReq.send();
        }

        return oReq;
    }

    /**
     * This will issue an ajax GET request.
     *
     * @param {string} url The URL to request.
     * @param {ajaxCallback} callback The callback function when the request is successful.
     * @param {string} responseType The type of response to ask for as the Accept parameter.
     *                              If this is "json" or "application/json", the callback
     *                              will automatically receive parsed JSON and fail if the
     *                              JSON is unacceptable.
     * @param {Object} options The query parameters to send along with the request.
     *
     * @returns {XMLHttpRequest} The ajax request object.
     */
    static get(url, callback, responseType, options) {
        if (options) {
            let params = Object.entries(options).map(e => encodeURIComponent(e[0]) + "=" + encodeURIComponent(e[1]));
            url = url + "?" + params.join("&");
        }

        return Util.ajax("GET", url, null, callback, responseType);
    }

    /**
     * This will issue an ajax GET request and expect JSON data back.
     *
     * @param {string} url The URL to request.
     * @param {ajaxCallback} callback The callback function when the request is successful.
     * @param {Object} options The query parameters to send along with the request.
     *
     * @returns {XMLHttpRequest} The ajax request object.
     */
    static getJSON(url, callback, options) {
        return Util.get(url, callback, "json", options);
    }

    /**
     * This will issue an ajax POST request.
     *
     * @param {string} url The URL to request.
     * @param {string|Object} data The data to send. If it is an Object, then it will
     *                             send the data as a FormData.
     * @param {ajaxCallback} callback The callback function when the request is successful.
     * @param {string} responseType The type of response to ask for as the Accept parameter.
     *                              If this is "json" or "application/json", the callback
     *                              will automatically receive parsed JSON and fail if the
     *                              JSON is unacceptable.
     *
     * @returns {XMLHttpRequest} The ajax request object.
     */
    static post(url, data, callback, responseType) {
        var postData;

        if (data) {
            if ((typeof data) == "string") {
                postData = data;
            }
            else {
                postData = new FormData();
                Object.keys(data).forEach(function(key) {
                    postData.set(key, data[key]);
                });
            }
        }

        return Util.ajax("POST", url, postData, callback, responseType);
    }

    /**
     * This function retrieves the query value from the given url.
     *
     * @param {string} name The query key to look for.
     * @param {string} [url] The URL to parse. If not given, then it will use
     *                       the current location.
     * @returns {string} If found, the value for the given key is given.
     */
    static getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    /**
     * This function returns the position of the given element within its parent.
     *
     * Optionally, a filter can be provided, and it will get the index of the
     * element among the list of sibling elements that match that filter.
     *
     * @params {HTMLElement} element The element to query the index.
     * @params {string} [filter] The optional css selector to use to filter the
     *                           sibling elements.
     *
     * @returns {number} The index of the element or -1 if the element doesn't
     *                   exist in the given filter.
     */
    static getChildIndex(element, filter) {
        if (filter) {
            return (Array.prototype.indexOf.call(element.parentNode.querySelectorAll(":scope > " + filter), element));
        }

        return (Array.prototype.indexOf.call(element.parentNode.children, element));
    }

    /**
     * Returns an array of HTMLElement items that consist of the parents of the
     * given element. These will be the ancestors in order from closest to the
     * document root at the end.
     *
     * If a parentSelector is given, the parents will be retrieved up until the
     * ancestor that matches the given selector.
     *
     * If a filter is given, the parent list will be truncated to only those
     * that match the given filter.
     *
     * @param {HTMLElement} element The element to initialize the query.
     * @param {string} [parentSelector] The css selector to match against to stop
     *                                  the function.
     * @param {string} [filter] The css selector to match against to filter the
     *                          results.
     *
     * @returns {Array} A list containing zero or more HTMLElement items.
     */
    static getParents(element, parentSelector, filter) {
        // If no parentSelector defined will bubble up all the way to *document*
        if (parentSelector === undefined) {
            parentSelector = document;
        }

        if (filter === undefined) {
            filter = false;
        }

        var parents = [];
        var p = element.parentNode;

        while (p && p.matches && !(p.matches(parentSelector))) {
            var o = p;
            if (!filter) {
                parents.unshift(o);
            }
            p = o.parentNode;
        }

        if (p.matches) {
            parents.unshift(p);
        }

        return parents;
    }

    // From https://stackoverflow.com/questions/36721830/convert-hsl-to-rgb-and-hex

    /**
     * Converts an HSL color value to RGB. Conversion formula
     * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
     * Assumes h, s, and l are contained in the set [0, 1] and
     * returns r, g, and b in the set [0, 255].
     *
     * @param   {number} h The hue.
     * @param   {number} s The saturation.
     * @param   {number} l The lightness.
     * @return  {Array}    The RGB representation.
     */
    static hslToRgb(h, s, l) {
        var r, g, b;

        if (s == 0) {
            r = g = b = l; // achromatic
        } else {
            var hue2rgb = function hue2rgb(p, q, t) {
                if(t < 0) t += 1;
                if(t > 1) t -= 1;
                if(t < 1/6) return p + (q - p) * 6 * t;
                if(t < 1/2) return q;
                if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
                return p;
            };

            var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            var p = 2 * l - q;
            r = hue2rgb(p, q, h + 1/3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1/3);
        }

        return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    }

    /**
     * Copies the given text to the clipboard.
     *
     * @param {string} text The text to copy.
     */
    static copyToClipboard(text) {
        if (window.clipboardData && window.clipboardData.setData) {
            // IE specific code path to prevent textarea being shown while dialog is visible.
            return window.clipboardData.setData("Text", text); 

        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy");  // Security exception may be thrown by some browsers.
            } catch (ex) {
                window.console.warn("Copy to clipboard failed.", ex);
                return false;
            } finally {
                document.body.removeChild(textarea);
            }
        }
    }
}

export default Util;
