"use strict";

import EventComponent from "./event_component.js";
import Selector       from "./selector.js";

/**
 * This implements behaviors for the people/collection page.
 */
class Collection extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            return;
        }

        this.element = element;

        // Find the selector that picks default object types
        let selectorElement = this.element.querySelector(".selector");
        if (selectorElement) {
            this.selector = Selector.load(selectorElement);
        }

        // Also find the text input for the object type
        this.typeInput = this.element.querySelector('input[name="custom-type"]');
        this.typeLabel = this.element.querySelector('label[for="new-object-customType"]');

        Collection._count++;
        this.element.setAttribute('data-collection-index', Collection._count);

        Collection._loaded[this.element.getAttribute('data-collection-index')] = this;

        this.bindEvents();
    }

    static loadAll(element) {
        var elements = element.querySelectorAll('.card.collection');

        elements.forEach(function(element) {
            Collection.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-collection-index');

        if (index) {
            return Collection._loaded[index];
        }

        return new Collection(element);
    }

    bindEvents() {
        if (this.selector) {
            this.selector.on("change", (item) => {
                if (item.querySelector("h2").getAttribute("data-i18n-default") === "object") {
                    if (this.typeInput) {
                        this.typeInput.removeAttribute("hidden");
                    }

                    if (this.typeLabel) {
                        this.typeLabel.removeAttribute("hidden");
                    }
                }
                else {
                    if (this.typeInput) {
                        this.typeInput.setAttribute("hidden", true);
                        this.typeInput.value = "";
                        this.typeInput.setAttribute("value", "");
                    }

                    if (this.typeLabel) {
                        this.typeLabel.setAttribute("hidden", true);
                    }
                }
            });
        }
    }
}

Collection._count  = 0;
Collection._loaded = {};

export default Collection;
