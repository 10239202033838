"use strict";

import EventComponent from './event_component.js';
import Util           from './util.js';
import Tabs           from './tabs.js';
import Occam          from './occam.js';

class Modal extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            return;
        }

        this.element = element;

        if (Modal.element === undefined) {
            Modal.element     = document.querySelector("body > .modal-window:not(.modal-confirm-window)");
            Modal.content     = Modal.element.querySelector(":scope > .content");
            Modal.closeButton = Modal.element.querySelector(":scope > .close");
            Modal.element.addEventListener("click", function(event) {
                Modal.close();
            });

            window.addEventListener("keyup",   Modal.handleKeyEvent);
            window.addEventListener("keydown", Modal.handleKeyEvent);

            Modal.content.addEventListener("click", function(event) {
                event.stopPropagation();
            });
        }

        if (Modal.confirmElement === undefined) {
            Modal.confirmElement     = document.querySelector("body > .modal-confirm-window");
            Modal.confirmContent     = Modal.confirmElement.querySelector(":scope > .content");
            Modal.confirmCloseButton = Modal.confirmElement.querySelector(":scope > .close");
            Modal.confirmElement.addEventListener("click", function(event) {
                Modal.close();
            });

            // Get the place where a confirmation message happens
            Modal.confirmMessageElement = Modal.confirmContent.querySelector(".card.filled.message");
            Modal.confirmForm = Modal.confirmContent.querySelector("form");
            Modal.confirmSubmit = Modal.confirmContent.querySelector("input[type=\"submit\"]");

            // Get the original confirm message content
            Modal.confirmMessage = Modal.confirmMessageElement.innerHTML;

            Modal.confirmContent.addEventListener("click", function(event) {
                event.stopPropagation();
            });

            // TODO: Add event listeners to the confirmation buttons
        }

        Modal._count++;
        this.element.setAttribute('data-loaded-index', Modal._count);

        Modal._loaded[this.element.getAttribute('data-loaded-index')] = this;

        this.bindEvents();
        this.events = {};
    }

    static loadAll(element) {
        var modals = element.querySelectorAll('a.modal, input.modal');

        modals.forEach(function(element) {
            Modal.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-loaded-index');

        if (index) {
            return Modal._loaded[index];
        }

        return new Modal(element);
    }

    static handleKeyEvent(event) {
        if (Modal.isOpen()) {
            if (event.key == "Escape" || event.code == "Escape" || event.keyCode == 27) {
                Modal.close();
            }
        }
    }

    /**
     * Returns the current option set for the given key.
     */
    static optionFor(key) {
        return this._options[key];
    }

    bindEvents() {
        this.element.addEventListener("click", (event) => {
            event.preventDefault();

            // Do not open the modal if the link is 'disabled'
            if (this.element.hasAttribute('disabled')) {
                return;
            }

            Modal.open(this.element);
        });
    }

    static isOpen() {
        return Modal.element && Modal.element.style.display == "flex";
    }

    static open(url, options) {
        options = options || {};

        // By default, we are using the normal modal
        let content = Modal.content;
        let closeButton = Modal.closeButton;
        let element = Modal.element;

        // If passed a link, get the options and url from the link attributes
        if (url instanceof HTMLElement) {
            // Gather generic options
            let attrs = url.attributes;
            for (let i = 0; i < attrs.length; i++) {
                let attribute = attrs[i];
                if (attribute.name.indexOf("data-modal") == 0) {
                    if (url.hasAttribute(attribute.name)) {
                        let key = attribute.name.split("data-modal-")[1];
                        if (options.key === undefined) {
                            let value = url.getAttribute(attribute.name);
                            if (value === "") {
                                value = true;
                            }
                            options[key] = value;
                        }
                    }
                }
            }

            // Gather 'large' option
            if (options.large === undefined && url.hasAttribute("data-modal-large")) {
                options.large = true;
            }

            // Gather 'tall' option
            if (options.tall === undefined && url.hasAttribute("data-modal-tall")) {
                options.tall = true;
            }

            // Gather 'full' option
            if (options.full === undefined && url.hasAttribute("data-modal-full")) {
                options.full = true;
            }

            // Open a modal to 'confirm' the action within the form or link
            // instead of loading a bespoke modal.
            if (url.classList.contains("modal-confirm")) {
                // Use the confirmation modal
                content = Modal.confirmContent;
                closeButton = Modal.confirmCloseButton;
                element = Modal.confirmElement;

                // Copy the form/link into the confirm modal
                let form = Util.getParents(url, "form", "form");
                if (form && form[0]) {
                    form = form[0];
                    ["name", "action", "method"].forEach( (key) => {
                        Modal.confirmForm.setAttribute(key, form.getAttribute(key));
                    });
                }

                // Copy the message
                let message = (form || url).querySelector(".modal-confirm-message");
                if (message) {
                    // Update the message
                    Modal.confirmMessageElement.innerHTML = message.innerHTML;
                }
                else {
                    // Restore the original message
                    Modal.confirmMessageElement.innerHTML = Modal.confirmMessage;
                }

                // Do not load content into the confirmation modal
                url = null;
            }
            else {
                // Get the actual url to load
                url = url.getAttribute("data-url") || url.getAttribute("href");
            }
        }

        // Reset event notifications
        Modal.onSubmit = null;

        options = options || {};
        Modal._options = options;

        // Makes the Modal full sized (almost the size of the browser)
        element.classList.remove("large");
        if (options.large) {
            element.classList.add("large");
        }

        // Makes the Modal tall sized (height of browser, more or less)
        element.classList.remove("tall");
        if (options.tall) {
            element.classList.add("tall");
        }

        // Makes the modal automatically its maximum height
        element.classList.remove("full");
        if (options.full) {
            element.classList.add("full");
        }

        // Remove tabs elsewhere
        document.querySelectorAll("*[tabindex]:not([disabled]), button:not([disabled]), input:not([disabled]), select:not([disabled]), a:not([disabled])").forEach(function(tabElement) {
            // Store the previous values of the tabindex
            // It will ensure that the default value is '0' which means it is navigated
            // in the default order.
            tabElement.setAttribute("data-tabindex", tabElement.getAttribute("tabindex") || "");

            // Set tabindex to -1 so that it is not able to be tabbed to
            tabElement.setAttribute("tabindex", "-1");
        });

        // Show the modal
        element.style.display = "flex";

        // Focus on modal
        element.focus();

        // Do not continue if there is nothing to load in the modal
        if (!url) {
            return;
        }

        // If we are loading content into the modal, we make the old content invisible
        content.style.display = "none";
        closeButton.style.display = "none";

        // Get the modal content
        Util.get(url, (html) => {
            content.innerHTML = html;
            if (options.display) {
                content.style.display = options.display;
            }
            else {
                content.style.display = "flex";
            }

            if (options.height) {
                content.style.height = options.height;
            }
            else {
                content.style.height = "";
            }

            closeButton.style.display = "block";
            content.style.top = (document.body.clientHeight - content.clientHeight)/2 + "px";
            element.querySelector(":scope > .close").style.top = ((document.body.clientHeight - content.clientHeight)/2 + 2) + "px";

            Object.keys(options).forEach( (key) => {
                content.setAttribute('data-modal-' + key, options[key]);
            });

            Occam.loadAll(content);

            // Any next buttons should go to the next 'step' in the chain
            let nextButtons = content.querySelectorAll('input.next[type="button"]');
            nextButtons.forEach( (button) => {
                button.addEventListener('click', (event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    let tab = content.querySelector('li.tab.submitting');
                    let tabs = Tabs.load(tab.parentNode);

                    if (tabs) {
                        // Ensure it is enabled
                        let currentTab = tabs.selected() + 1;
                        tab = tabs.tabAt(currentTab);

                        if (tab) {
                            tab.removeAttribute('disabled');

                            // Switch
                            tabs.select(currentTab);
                        }
                    }
                });
            });

            // If there is an 'data-modal-check-errors' then we can submit this
            // form asynchronously and look for 422 responses and redirect to
            // the URL on success.
            let submitButton = content.querySelector('input[type="submit"][data-modal-check-errors]');
            if (submitButton) {
                submitButton.addEventListener('click', (event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    let errorsList = content.querySelector('.card.errors');

                    let form = Util.getParents(submitButton, "form")[0];

                    submitButton.setAttribute("disabled", "");

                    // Show the submitting card, if exists
                    let formCard = form.querySelectorAll(".card:not(:last-child):not(.submitting):not(.errors)");

                    let submittingCard = content.querySelector('.card.submitting');
                    if (submittingCard && formCard) {
                        submittingCard.style.height = formCard[0].clientHeight + "px";
                        submittingCard.removeAttribute("hidden");
                        formCard.forEach( (card) => {
                            card.setAttribute('hidden', '');
                        });
                    }

                    // Go to the submitting tab, if exists
                    let tab = content.querySelector('li.tab.submitting');
                    let disabled = false;
                    let currentTab = 0;
                    let tabs = null;
                    if (tab) {
                        tabs = Tabs.load(tab.parentNode);

                        // Ensure it is enabled
                        disabled = tab.hasAttribute('disabled');
                        tab.removeAttribute('disabled');

                        // Switch
                        if (tabs) {
                            currentTab = tabs.selected();
                            tabs.select(tab);
                        }
                    }

                    Util.submitForm(form, null, {
                        onprogress: (event) => {
                            if (event.lengthComputable) {
                                let elapsed = Math.round((event.loaded / event.total) * 100);
                                if (submittingCard) {
                                    let indicator = submittingCard.querySelector(".progress-indicator");
                                    if (indicator) {
                                        indicator.style.width = elapsed + "%";
                                    }
                                }
                            }
                        },
                        onerror: (data) => {
                            submitButton.removeAttribute("disabled");
                            
                            // Re-switch tabs
                            if (tabs) {
                                // Re-disable the submitting tab
                                if (disabled) {
                                    tab.setAttribute('disabled', '');
                                }

                                // Switch
                                tabs.select(currentTab);
                            }

                            // Re-hide the submitting card
                            if (submittingCard && formCard) {
                                submittingCard.setAttribute("hidden", "");
                                formCard.forEach( (card) => {
                                    card.removeAttribute('hidden');
                                });
                            }

                            // Show the errors block
                            if (errorsList) {
                                errorsList.removeAttribute("hidden");

                                // Clear existing errors
                                let errors = errorsList.querySelectorAll("li.error");
                                errors.forEach( (errorElement) => {
                                    errorElement.remove();
                                });

                                // Add the errors
                                ((data || {}).errors || []).forEach( (error) => {
                                    let errorListing = errorsList.querySelector("ul");

                                    let newError = document.createElement("li");
                                    newError.classList.add("error");
                                    newError.textContent = error;
                                    errorListing.appendChild(newError);
                                    newError.style.transition = "none";
                                    newError.style.opacity = "0";

                                    // Allows the transition to see the resolution of a frame at 0
                                    window.requestAnimationFrame( () => {
                                        // And then in the next frame, starts the actual animation:
                                        newError.style.transition = "";
                                        newError.style.opacity = 1.0;
                                    });
                                });
                            }
                        },
                        onload: (data) => {
                            window.location.href = data.url;
                        },
                    }, 'application/json');
                });
            }

            if (Modal.onSubmit) {
                content.querySelectorAll("form").forEach(function(form) {
                    form.addEventListener("submit", function(event) {
                        Modal.onSubmit(event);
                    });
                });
            }
            else if (options.onSubmit) {
                content.querySelectorAll("form").forEach(function(form) {
                    form.addEventListener("submit", function(event) {
                        options.onSubmit(event);
                    });
                });
            }

            if (options.onLoad) {
                options.onLoad();
            }
        }, null, Object.assign({ modal: "true" }, options.query || {}));
    }

    static close() {
        // Reset tabs elsewhere
        document.querySelectorAll("*[tabindex], input, select, a").forEach( (tabElement) => {
            // Restore the previously saved tabindexes
            // If, for some reason, that value is not available, this will set it to -1
            tabElement.setAttribute("tabindex", tabElement.getAttribute("data-tabindex") || "-1");
        });

        // Remove modal(s)
        Modal.element.style.display = "none";
        Modal.confirmElement.style.display = "none";
    }
}

Modal._count = 0;
Modal._loaded = {};

export default Modal;
