"use strict";

// Import our vendored Quadtree implementation:
import './vendor/quadtree.js';

/**
 * This represents the Quadtree which manages the collision space.
 */
class Quadtree {
    /**
     * Constructs our component-aware quadtree collection.
     */
    constructor(nodes, options) {
        this.quadtree = new window.Quadtree({
            x: -999999999,
            y: -999999999,
            width:  999999999,
            height: 999999999
        });

        this.components = new window.WeakMap();

        this.quadtree.clear();
    }

    /**
     * Adds the given component to the quadtree.
     *
     * @param {StaticComponent} component - The component to add.
     */
    add(component) {
        let quadtreeObject = {
            x: component.x,
            y: component.y,
            width: component.width,
            height: component.height,
            object: component
        };

        this.components.set(component, quadtreeObject);

        this.quadtree.insert(quadtreeObject);
    }

    /**
     * Updates the position of the given component within the quadtree.
     *
     * @param {StaticComponent} component - The component to update.
     */
    update(component) {
        let quadtreeObject = this.components.get(component);

        if (quadtreeObject) {
            this.quadtree.removeObject(quadtreeObject);
        }

        return this.add(component);
    }

    /**
     * Removes the component from the quadtree.
     *
     * @param {StaticComponent} component - The component to remove.
     */
    remove(component) {
        let quadtreeObject = this.components.get(component);

        if (quadtreeObject) {
            this.quadtree.removeObject(quadtreeObject);
        }
    }

    /**
     * Returns the set of components that are completely within the given region.
     *
     * @param {number} x - The left-most point of the region.
     * @param {number} y - The top-most point of the region.
     * @param {number} width - The width of the region.
     * @param {number} height - The height of the region.
     *
     * @returns {Array} The list of components, empty if none found.
     */
    queryWithin(x, y, width, height) {
        var ret = [];
        this.quadtree.retrieve({
            x: x,
            y: y,
            width: width,
            height: height
        }).forEach( (quadtreeObject) => {
            // Actually check for the intersection
            if (quadtreeObject.x > x                                   &&
                quadtreeObject.x < (x + width)                         &&
                quadtreeObject.y > y                                   &&
                quadtreeObject.y < (y + height)                        &&
                quadtreeObject.x + quadtreeObject.width  > x           &&
                quadtreeObject.x + quadtreeObject.width  < (x + width) &&
                quadtreeObject.y + quadtreeObject.height > y           &&
                quadtreeObject.y + quadtreeObject.height < (y + height)) {

                ret.push(quadtreeObject.object);
            }
        });

        return ret;
    }

    /**
     * Returns the set of components that intersect the given region, even
     * if only partially.
     *
     * @param {number} x - The left-most point of the region.
     * @param {number} y - The top-most point of the region.
     * @param {number} width - The width of the region.
     * @param {number} height - The height of the region.
     *
     * @returns {Array} The list of components, empty if none found.
     */
    queryOverlapping(x, y, width, height) {
        var ret = [];
        this.quadtree.retrieve({
            x: x,
            y: y,
            width: width,
            height: height
        }).forEach( (quadtreeObject) => {
            // Actually check for the intersection
            if (!(quadtreeObject.x                           > (x + width)  ||
                  (quadtreeObject.x + quadtreeObject.width)  < x            ||
                  quadtreeObject.y                           > (y + height) ||
                  (quadtreeObject.y + quadtreeObject.height) < y)) {

                ret.push(quadtreeObject.object);
            }
        });

        return ret;
    }
}

export default Quadtree;
