"use strict";

import Util from './util.js';
import Terminal from './terminal.js';
import Configuration from './configuration.js';
import Tabs from './tabs.js';
import NavigationState from './navigation_state.js';

/**
 * This class handles functionality related to Occam objects. This class can
 * pull down information and metadata and post updates to the Occam backend,
 * if that is allowed by the object.
 *
 * Objects have queues of pending actions which need to be ACK'd before
 * continuing. That way actions are invoked in the correct order. An object's
 * revision is updated whenever an action is acknowledged. Some actions
 * require an object to be up-to-date in the backend worker, and thus must wait
 * until the queue is empty. For instance, running the object in the browser.
 * Basically, these actions are just in the queue as well... but we should
 * somehow indicate that the actions are delayed.
 */
class OccamObject {
    constructor(id, revision, type, name, file, index, link, token) {
        // If no id/revision are given, pull out the object represented on the
        // page (if any)
        this.main = false;

        if (id && !revision) {
            if (id.indexOf("@")) {
                let parts = id.split("@");
                id = parts[0];
                revision = parts[1];
                // TODO: rest of ID parsing
            }
        }

        if (!id) {
            this.main = true;
            this.element = document.querySelector('body > .content > h1');

            let linkElement = this.element.querySelector('#object-link');
            if (linkElement) {
                this.link = linkElement.textContent.trim();
            }

            this.token = Util.getParameterByName("token");

            if (!this.element) {
                return;
            }

            if (this.element.querySelector('#object-id')) {
                this.id = this.element.querySelector('#object-id').textContent.trim();
                this.type = this.element.querySelector('#object-type').textContent.trim();
                this.revision = this.element.querySelector('#object-revision').textContent.trim();
            }

            // Discover the root object
            var rootIdElement = this.element.querySelector('#object-root-id');
            if (rootIdElement) {
                this.rootId = rootIdElement.textContent.trim();
            }
            var rootRevisionElement = this.element.querySelector('#object-root-revision');
            if (rootRevisionElement) {
                this.rootRevision = rootRevisionElement.textContent.trim();
            }

            // Discover the index
            this.index = [];
            var indexElement = this.element.querySelector('ol#object-index');
            if (indexElement) {
                indexElement.querySelectorAll('li').forEach( (itemElement) => {
                    this.index.push(itemElement.textContent.trim());
                });
            }

            // Discover the path/file
            var pathElement = this.element.querySelector('#object-path');
            this.path = pathElement && pathElement.textContent.trim();
            var fileElement = this.element.querySelector('#object-file');
            this.file = fileElement && fileElement.textContent.trim();
        }
        else {
            // This is just an object abstraction
            this.id = id;
            this.type = type;
            this.name = name;
            this.revision = revision;
            this.index = index || [];
            this.link = link;
            this.token = token;

            // It is not represented in the DOM
            this.element = null;
        }

        this.queue = [];
        this.pending = 0;
        this.queueLock = false;

        this.file = file;
    }

    /* This method yields the object viewer for the given object if it exists.
    */
    viewer() {
    }

    /*
     * This method ensures that the browser's current URL is correct for the
     * revision of this object/workset.
     */
    updateLocation() {
        var newURL = "/" + this.id +
                     "/" + this.revision;

        newURL = newURL + NavigationState.currentPath();

        NavigationState.updateLocation(newURL);
    }

    /**
     * This method returns the url to this object.
     */
    url(options={}) {
        var ret = "";

        if (options.host) {
            ret = "https://" + options.host
        }

        if (this.rootId) {
            ret = ret + "/" + this.rootId;
            if (this.link && options.link !== false) {
                ret = ret + "/:" + this.link;
            }
            else if (this.rootRevision) {
                ret = ret + "/" + this.rootRevision;
            }
        }
        else {
            ret = ret + "/" + this.id;
            if (this.link && options.link !== false) {
                ret = ret + "/:" + this.link;
            }
            else if (this.revision) {
                ret = ret + "/" + this.revision;
            }
        }

        if (this.index) {
            this.index.forEach( (index) => {
                ret = ret + "/" + index;
            });
        }

        var query = {};

        if (this.token) {
            query.token = this.token;
        }

        if (options.path) {
            if (!options.path.startsWith("/")) {
                ret = ret + "/";
            }
            ret = ret + options.path;
        }

        if (options.query) {
            window.Object.keys(options.query).forEach( (key) => {
                query[key] = options.query[key];
            });
        }

        // Amend query parameters
        var queryKeys = window.Object.keys(query);
        if (queryKeys.length > 0) {
            var queryKey = queryKeys.pop();
            ret = ret + "?" + queryKey + "=" + encodeURIComponent(query[queryKey]);
        }

        queryKeys.forEach(function(queryKey) {
            ret = ret + "&" + queryKey + "=" + encodeURIComponent(query[queryKey]);
        });

        return ret;
    }

    /* This method adds an append event to the queue. When an empty value is
     * given, this will remove the key from the object metadata.
     */
    queuePushSet(key, value, callback) {
        var args = [];

        args.push({ "values": [key] });

        if (value !== undefined) {
            args.push({ "values": [JSON.stringify(value)] });
        }

        args.push({ "key": "--input-type",
            "values": ['json'] });

        this.queuePush({
            "command": "set",
            "arguments": args
        }, callback);

        return this;
    }

    /* This method adds an append event to the queue. The optional 'at' field
     * will determine the index it will push the new item.
     */
    queuePushAppend(key, value, at, callback) {
        var args = [];

        args.push({ "values": [key] });
        args.push({ "values": [JSON.stringify(value)] });

        if (at !== undefined && at !== null) {
            args.push({ "key": "--at",
                "values": [""+at] });
        }

        args.push({ "key": "--input-type",
            "values": ['json'] });

        this.queuePush({
            "command": "append",
            "arguments": args
        }, callback);

        return this;
    }

    /* This method adds an attach event to the queue.
    */
    queuePushAttach(connection_index, object_id, object_revision, callback) {
        var args = [];

        if (connection_index >= 0) {
            args.push({
                "values": [connection_index.toString()]
            });
        }

        args.push({ "key": "--id",
            "values": [object_id] });
        args.push({ "key": "--object-revision",
            "values": [object_revision] });

        this.queuePush({
            "command": "attach",
            "arguments": args
        }, callback);

        return this;
    }

    queuePushDetach(connection_index, callback) {
        this.queuePush({
            "command": "detach",
            "arguments": [
                { "values": [connection_index.toString()] }
            ]
        }, callback);

        return this;
    }

    /* This method adds an event to the queue.
    */
    queuePush(command, callback) {
        this.queue.push([command, callback]);

        // ok. so when we have a command, issue it, and then have the ack of the
        // command issue the next one in sequence while firing any callback.
        // when there is no command, then just stop

        if (this.queueLock == false) {
            this.queueIssue();
        }

        return this;
    }

    /* This method yields the queue size.
    */
    queueCount() {
        return this.queue.length;
    }

    /* This method, which is generally called internally and not meant to be
     * used externally, will invoke the next queued command.
     */
    queueIssue() {
        if (this.queue.length == 0) {
            return this;
        }

        // Lock queue
        this.queueLock = true;

        // Pull next action
        var queueItem = this.queue[0];
        var action    = queueItem[0];
        var callback  = queueItem[1];

        // Truncate queue
        this.queue = this.queue.slice(1);
        this.pending += 1;

        // Form action url
        var url = "/worksets/" + this.workset.id + "/" + this.workset.revision +
            "/"  + this.id    + "/" + this.revision + "/history";

        // Reveal 'saving...' box
        window.document.querySelectorAll('.content h1 .saving').forEach(function(element) {
            element.setAttribute('aria-hidden', 'false');
        });

        // Perform action and issue another command when it is successful
        Util.post(url, JSON.stringify(action), (revisions) => {
            var revision = revisions.objectRevision;
            var worksetRevision = revisions.worksetRevision;
            var worksetId = this.workset.id;
            this.workset = new OccamObject(worksetId, worksetRevision, "workset");

            this.revision = revision;

            // Update location
            this.updateLocation();

            // Call the callback once completed successfully
            if (callback !== undefined) {
                callback.call(this, true);
            }
        }, "application/json");
        /*
        }).fail(function(error) {
            window.console.log("error??");
            document.querySelector('.content h1 .saving.flash').getAttribute('aria-hidden', 'true');
            document.querySelector('.content h1 .error.flash').getAttribute('aria-hidden', 'false');

            // Call the callback and indicate a failure
            if (callback !== undefined) {
                callback.call(this, false);
            }

            // TODO: we should flush the queue and try to react to failures
            //       everywhere we issue them. All flushed queue items should
            //       have their callbacks issued in failure.
            this.pending = 0;
        }).always(function() {
            // Issue another command
            this.pending -= 1;
            if (this.pending == 0 && this.queue.length == 0) {
                document.querySelector('.content h1 .saving').setAttribute('aria-hidden', 'true');
            }

            // Unlock queue
            if (this.pending == 0) {
                this.queueLock = false;
            }

            this.queueIssue();
        });*/

        return this;
    }

    /*
     * This method returns whether or not the given backend is supported as a
     * possible means of running this object. Will return a true or false to the
     * given callback.
     */
    runsOn(backend, callback) {
        if (this._runsOn          !== undefined &&
            this._runsOn[backend] !== undefined) {
            // Pull result from cache
            callback(this._runsOn[backend]);
        }
        else {
            var objectURL = "/" + this.id + "/"
                                + this.revision + "/runsOn" +
                                "?backend=" + backend;

            if (this.id === null) {
                return this;
            }

            Util.getJSON(objectURL, (data) => {
                // Cache result
                this._runsOn = this._runsOn || {};
                this._runsOn[backend] = data;

                callback(data);
            });
        }

        return this;
    }

    /*
     * This method returns whether or not the given environment/architecture pair
     * are supported as a possible means of running this object. Will return a
     * true or false to the given callback.
     */
    supports(environment, architecture, callback) {
        if (this._supports               !== undefined &&
            this._supports[environment]  !== undefined &&
            this._supports[architecture] !== undefined) { 
            // Pull result from cache
            callback(this._supports[environment][architecture]);
        }
        else {
            var objectURL = "/" + this.id + "/"
                                + this.revision + "/supports"
                                + "?environment="  + environment
                                + "&architecture=" + architecture;

            if (this.id === null) {
                return this;
            }

            Util.getJSON(objectURL, (data) => {
                // Cache result
                this._supports = this._supports || {};
                this._supports[environment] = this._supports[environment] || {};
                this._supports[environment][architecture] = data;

                callback(data);
            });
        }

        return this;
    }

    /*
     * This method retrieves a list of backends that can be used to run this
     * object. Will pass that array of strings to the given callback.
     */
    backends(environmentList, callback) {
        if (callback == undefined) {
            callback = environmentList;
            environmentList = undefined;
        }

        if (this._backends !== undefined) {
            // Pull result from cache
            callback(this._backends);
        }
        else {
            var objectURL = "/" + this.id + "/" + this.revision + "/backends";

            if (environmentList) {
                objectURL = objectURL + "?";
                var i = 1;

                environmentList.forEach(function(pair) {
                    if (i > 1) {
                        objectURL = objectURL + "&";
                    }
                    objectURL = objectURL + "environment_"  + i + "=" + pair[0] + "&" +
                        "architecture_" + i + "=" + pair[1];

                    i++;
                });
            }

            if (this.id === null) {
                return this;
            }

            Util.getJSON(objectURL, (data) => {
                // Cache result
                this._backends = data;
                callback(data);
            });
        }

        return this;
    }

    /* This method retrieves json content from within the object.
    */
    retrieveJSON(path, callback) {
        if (this.id === null) {
            return this;
        }

        var objectURL = this.url({path: "raw/" + path});

        Util.getJSON(objectURL, function(data) {
            callback(data);
        });

        return this;
    }

    /* This method retrieves the object info for this object.
    */
    objectInfo(callback) {
        if (this._objectInfo !== undefined) {
            // Pull result from cache
            callback(this._objectInfo);
        }
        else {
            if (this.id === null) {
                return this;
            }

            var objectURL = this.url();

            Util.getJSON(objectURL, (data) => {
                // Cache result
                this._objectInfo = data;

                if (this.file) {
                    this._objectInfo.file = this.file;
                }

                callback(this._objectInfo);
            });
        }

        return this;
    }
}

/**
 * This constant sets the number of preview panes that can be loading at a
 * time. This will help limit the load on the server and client when loading
 * a whole page of widgets.
 */
OccamObject.MAX_CONCURRENT_PREVIEW_LOADS = 8;

/**
 * This is the amount of time in milliseconds to wait for a widget to give
 * a "loaded" event. We will remove the progress indicator and allow
 * interaction only when receiving that message. Otherwise, after the
 * timeout, we will display an error notification.
 */
OccamObject.PREVIEW_TIMEOUT = 10000;

export default OccamObject;
