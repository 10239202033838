"use strict";

import EventComponent from "./event_component.js";
import Util           from "./util.js";

// Patch Tagify
//
// In Tagify, when values are placed into the field, they are always tokenized
// by the delimiter. This means that the delimiter cannot be within the tag.
// That's not great for our command-line entry fields. So we disable that
// tokenization when the tagsItems are well-formed (Array of Objects.)
// Otherwise, you will type in complex tags (with spaces) and then save them
// (successfully, mind you) and then when you re-edit them... they will be
// separated further.
let oldNormalizeTags = window.Tagify.prototype.normalizeTags;
window.Tagify.prototype.normalizeTags = function(tagsItems) {
    var isArray = tagsItems instanceof Array,
        isCollection = isArray && tagsItems[0] instanceof Object && "value" in tagsItems[0];

    if (isCollection) {
      return tagsItems;
    }

    return oldNormalizeTags.bind(this)(tagsItems);
};

/**
 * This represents a tagged input element.
 */
class TagInput extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            throw new TypeError("element is required");
        }

        if (!element.tagName) {
            throw new TypeError("element must be an HTMLElement");
        }

        this.element = element;

        // Bind events
        this.bindEvents();

        TagInput._count++;
        this.element.setAttribute('data-tag-input-index', 'tag-input-' + TagInput._count);
        TagInput._loaded[this.element.getAttribute('data-tag-input-index')] = this;
    }

    /**
     * Returns an instance of TagInput for the given element.
     *
     * This will create a TagInput, if it doesn't exist, for this element.
     *
     * @param {HTMLElement} element The main element for the tag input;
     *                              typically the `<input>` element.
     */
    static load(element) {
        if (!element) {
            throw new TypeError("element argument required");
        }

        var index = element.getAttribute('data-tag-input-index');

        if (index) {
            return TagInput._loaded[index];
        }

        return new TagInput(element);
    }

    static loadAll(element) {
        if (!element) {
            throw new TypeError("element argument required");
        }

        if (!element.tagName) {
            throw new TypeError("element must be an HTMLElement");
        }

        var inputs = element.querySelectorAll('input.tags');

        inputs.forEach( (element) => {
            TagInput.load(element);
        });
    }

    /**
     * Returns an array of strings that correspond to the value of the input.
     */
    get value() {
        return this._tagify.value.map( (item) => item.value );
    }

    bindEvents() {
        this._tagify = new window.Tagify(this.element, {
          duplicates: this.element.hasAttribute("data-tagify-allow-duplicates"),
          backspace: this.element.getAttribute("data-tagify-backspace") || true,
          delimiters: this.element.getAttribute("data-tagify-delimiters") || ",",
        });
    }
}

TagInput._count  = 0;
TagInput._loaded = {};

export default TagInput;
