"use strict";

import EventComponent from './event_component.js';

/**
 * The gallery is a series of images which are represented by a series of thumb
 * images (smaller images). When somebody clicks on the thumb, the larger image
 * is displayed above.
 *
 * By default, a slideshow is started where the images cycle through in the
 * thumbnail order every few seconds (configured using SLIDESHOW_INTERVAL).
 *
 * A particular image may be displayed on page load when targetted in the URL
 * using the hash syntax: "/foo/bar#gallery-image-3" will select the 4th image.
 * This targetting also allows the gallery to be used when javascript is
 * disabled through the use of css ":target" pseudo selector. This is why
 * styling is done on both :target and .active, and why this module has to
 * set the style of the opacity to 0.0 for all elements since the CSS-only
 * way of doing things always reveals the first image, in case no hash is
 * given in the URL.
 *
 * When a particular image is selected in this manner, the slideshow feature is
 * disabled. Also, when somebody clicks on a particular image, it is assumed
 * they want to look at it, so the slideshow is similarly disabled.
 *
 * Generally you use the Gallery.load method to instantiate a Gallery component.
 *
 * @param {HTMLElement} element The element that contains the image gallery.
 */
class Gallery extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            throw new TypeError("element is required");
        }

        if (!element.tagName) {
            throw new TypeError("element must be a HTMLElement");
        }

        this.element = element;

        Gallery._count++;
        this.element.setAttribute('data-loaded-index', Gallery._count);

        Gallery._loaded[this.element.getAttribute('data-loaded-index')] = this;

        if (this.element.querySelector("ul.thumbs")) {
            // If there are multiple images, reset the opacity on them
            //
            // This is because there is a failsafe for the CSS-only (js-less) version
            // of the gallery.
            this.element.querySelectorAll("ul.preview li").forEach(function(image) {
                image.style.opacity = 0.0;
                image.classList.remove('active');
            });
        }

        this.bindEvents();
        this.events = {};

        let hashParts = [];
        if (this.element.querySelector("ul.preview > a")) {
            hashParts = this.element.querySelector("ul.preview > a").getAttribute('id').split("-");
        }
        else {
            hashParts = this.element.querySelector("ul.preview > li").getAttribute('id').split("-");
        }
        this.tag = hashParts.slice(0, hashParts.length - 1).join("-");

        var initialImage = 0;
        this.autoplay = true;

        if (window.location.hash) {
            // See if the hash contains a valid image
            var image = this.element.querySelector("ul.preview > a" + window.location.hash + " + li, ul.preview li" + window.location.hash);
            if (image) {
                let hashParts = window.location.hash.split("-");
                if (hashParts.slice(0, hashParts.length - 1).join("-") == "#" + this.tag) {
                    initialImage = parseInt(hashParts[hashParts.length - 1]);
                    this.autoplay = false;
                }
            }
        }

        this.select(initialImage);
    }

    static loadAll(element) {
        var galleries = element.querySelectorAll('.gallery');

        galleries.forEach(function(element) {
            Gallery.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            throw new TypeError("element is required");
        }

        if (!element.tagName) {
            throw new TypeError("element must be a HTMLElement");
        }

        var index = element.getAttribute('data-loaded-index');

        if (index) {
            return Gallery._loaded[index];
        }

        return new Gallery(element);
    }

    /*
     * Slideshow timer event which will advance the image.
     */
    slideshowTimerEvent() {
        // Get the current image
        var currentImage = this.element.querySelector("ul.preview .active");
        if (!currentImage) {
            currentImage = this.element.querySelector("ul.preview li:last-child");
        }

        if (!currentImage) {
            return;
        }

        let id = currentImage.getAttribute('id');
        if (!id) {
            id = currentImage.previousElementSibling.getAttribute('id');
        }
        let hashParts = id.split('-');
        var index = parseInt(hashParts[hashParts.length - 1]);
        index++;

        this.select(index);
    }

    /*
     * This function selects and displays the given image by index.
     *
     * If the index is too large, it will use index = 0.
     */
    select(index) {
        // Load the appropriate image
        var thumb = this.element.querySelector("ul.thumbs li#" + this.tag + "-thumb-" + index);
        if (!thumb) {
            index = 0;
            thumb = this.element.querySelector("ul.thumbs li#" + this.tag + "-thumb-" + index);
        }

        if (!thumb) {
            return;
        }

        var id = thumb.getAttribute("id").replace('thumb-', '');
        this.element.querySelectorAll("ul.preview .active").forEach(function(image) {
            image.style.opacity = 0.0;
            image.classList.remove('active');
        });
        this.element.querySelectorAll("ul.thumbs .active").forEach(function(activeThumb) {
            activeThumb.classList.remove('active');
        });

        var image = this.element.querySelector("ul.preview a#" + id + " + li, ul.preview li#" + id);
        if (image) {
            image.style.opacity = "";
            image.classList.add('active');
        }
        thumb.classList.add('active');

        // Ensure thumb is visible by scrolling thumb area
        var thumbs = this.element.querySelector("ul.thumbs");
        var thumbsW = thumbs.getBoundingClientRect().width;
        var thumbX = thumb.getBoundingClientRect().x - thumbs.getBoundingClientRect().x;
        var thumbW = thumb.getBoundingClientRect().width;

        if (thumbX + thumbW + Gallery.THUMB_PADDING > thumbsW) {
            thumbs.scrollLeft += (thumbX + thumbW + Gallery.THUMB_PADDING) - thumbsW;
        }
        else if (thumbX < Gallery.THUMB_PADDING) {
            thumbs.scrollLeft += (thumbX - Gallery.THUMB_PADDING);
        }

        // Reset the autoplay timer
        if (this.timer) {
            window.clearInterval(this.timer);
        }
        if (this.autoplay) {
            this.timer = window.setInterval(this.slideshowTimerEvent.bind(this), Gallery.SLIDESHOW_INTERVAL);
        }
    }

    /*
     * This function initializes the gallery and binds interactive events.
     */
    bindEvents() {
        if (this.element) {
            if (this.element.classList.contains('gallery-loaded')) {
                return;
            }
        }

        // Bind the thumb image events
        this.element.querySelectorAll("ul.thumbs li a").forEach((thumb) => {
            thumb.addEventListener("click", (event) => {
                // Get the index of this thumb
                let hashParts = thumb.parentNode.getAttribute("id").split('-');
                var index = parseInt(hashParts[hashParts.length - 1]);

                // Turn off slideshow
                this.autoplay = false;

                // Select this index
                this.select(index);
            });
        });

        this.element.classList.add('gallery-loaded');
    }
}

/**
 * Time (in ms) before automatically revealing next image
 */
Gallery.SLIDESHOW_INTERVAL = 4000;

/**
 * The amount of pixels to ensure are to the left or right of the current
 * thumb.
 */
Gallery.THUMB_PADDING = 64;

Gallery._count = 0;
Gallery._loaded = {};

export default Gallery;
