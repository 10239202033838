"use strict";

import Node from './node.js';

export class Palette {
    constructor(workflow, options = {}) {
        this._options = options;
        this._workflow = workflow;

        // We want a fullscreen dragging plane
        this._draggable = document.createElement("div");
        this._draggable.classList.add("draggable");
        this._draggable.classList.add("workflow-widget-zone");
        this._draggable.style.position = "absolute";
        this._draggable.style.left = "0";
        this._draggable.style.right = "0";
        this._draggable.style.top = "0";
        this._draggable.style.bottom = "0";
        this._draggable.style.zIndex = "9999999999";

        // And a panning plane within that
        this._pannable = document.createElement("ul");
        this._pannable.classList.add("connections");
        this._pannable.classList.add("dummy");
        this._draggable.appendChild(this._pannable);
    }

    updateItem(element, info = {}) {
        // Retain original information
        let originalInfo = Object.assign({}, info);

        let collection = null;

        // Construct the element based on the info
        if (!element.classList.contains("workflow-widget-zone")) {
            element.classList.add("workflow-widget-zone");
            collection = document.createElement("ul");
            collection.classList.add("connections");
            collection.classList.add("dummy");
            element.appendChild(collection);
        }
        else {
            collection = element.querySelector("ul.connections");

            // Clear out the node within
            collection.innerHTML = "";
        }

        let node = Node.create(info, this._options);

        node.element.classList.add("dummy");
        collection.appendChild(node.element);

        // Center node within container (if position is not given)
        let x = (element.offsetWidth - node.element.offsetWidth) / 2;
        let y = (element.offsetHeight - node.element.offsetHeight) / 2;
        let position = originalInfo.position;
        if (position && position.x !== undefined) {
            x = position.x;
        }
        if (position && position.y !== undefined) {
            y = position.y;
        }
        node.move(x, y);

        let generateNode = (event) => {
            this._workflow.trigger("palette-node-drag", node);

            node.off("mousedown", generateNode);

            // Place the draggable plane on the screen
            document.body.appendChild(this._draggable);

            // Move it to the appropriate starting position
            let worldX = this._workflow.x;
            let worldY = this._workflow.y;

            // (worldX, worldY) places the node at the top-left of the workflow
            // element. So we need to move it relative to that.
            let pageRect = this._workflow.element.getBoundingClientRect();
            let pageX = pageRect.left;
            let pageY = pageRect.top;

            let nodeRect = node.element.getBoundingClientRect();
            let nodeX = nodeRect.left;
            let nodeY = nodeRect.top;

            let relativeX = (nodeX - pageX) + worldX;
            let relativeY = (nodeY - pageY) + worldY;

            // Add the node to the workflow and have it continue to move
            this._workflow.insertNode(node);

            // Move it to its relative position
            node.move(relativeX, relativeY);

            // Align the pannable plane
            this._pannable.style.left = -this._workflow.x + pageX + "px";
            this._pannable.style.top  = -this._workflow.y + pageY + "px";

            // Put it on the draggable plane
            this._pannable.appendChild(node.element);

            let moveComplete = (event) => {
                node.off("move", moveComplete);

                // Remove the dummy tag
                node.element.classList.remove("dummy");

                // Place it in the normal plane
                this._workflow.world.draggable.appendChild(node.element);

                // Remove the draggable plane
                document.body.removeChild(this._draggable);

                // Remove the node if it has not reached the bounds of the
                // workflow element.
                let nodeRect = node.element.getBoundingClientRect();
                let nodeX = nodeRect.left;
                let nodeY = nodeRect.top;

                let pageRect = this._workflow.element.getBoundingClientRect();
                let pageX = pageRect.left;
                let pageY = pageRect.top;

                if (nodeX < pageX || nodeY < pageY) {
                    node.destroy();
                }

                this._workflow.trigger("palette-node-create", node);
            };

            node.on("move", moveComplete);

            // Replace this node element in the zone
            this.updateItem(element, originalInfo);
        };

        if ((this._options.palette || {}).createOnDrag) {
            node.on("mousedown", generateNode);
        }
        else {
            node.on("mousedown", (_) => {
                node.select();
                node.view();
            });
        }
    }
}

export default Palette;
