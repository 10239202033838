"use strict";

import EventComponent from "../event_component.js";
import Search         from '../search.js';
import OccamObject    from '../occam_object.js';
import Util           from "../util.js";
import Tabs           from "../tabs.js";
import Selector       from "../selector.js";
import Modal          from "../modal.js";
import FileList       from '../file_list.js';

/**
 * This implements behaviors for the object selector modal.
 */
class SelectModal extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            return;
        }

        this.element = element;

        // Find the errors section
        this.errorsList = this.element.parentNode.querySelector(".errors");

        // Find the form
        this.form = this.element.parentNode.querySelector("form#select-object");

        this.filesElement = this.element.querySelector(".tab-panel.file > .card.files");

        // Find the button and other form elements
        if (this.form) {
            this.messageField = this.form.querySelector('input[name="message"]');
            this.messageTemplate = this.messageField.value;
            this.nameField = this.form.querySelector('input#select-object-name');
            this.typeField = this.form.querySelector('input#select-object-type');
            this.idField = this.form.querySelector('input#select-object-id');
            this.uidField = this.form.querySelector('input#select-object-uid');
            this.revisionField = this.form.querySelector('input#select-object-revision');
            this.submitButton = this.form.querySelector('input[name="submit"]');
        }

        // Tabs
        this.tabs = Tabs.load(this.element.querySelector("ul.tab-bar"));

        // Search
        this.search = Search.load(this.element);

        SelectModal._count++;
        this.element.setAttribute('data-select-modal-index', SelectModal._count);

        SelectModal._loaded[this.element.getAttribute('data-select-modal-index')] = this;

        // Detect if part of Modal (I know... confusing, but this can be not-a-modal)
        this.withinModal = false;
        if (Util.getParents(this.element, ".modal-window", ".modal-window").length > 0) {
            this.withinModal = true;

            Modal.onSubmit = (event) => {
                event.preventDefault();
            };
        }

        this.bindEvents();
    }

    static loadAll(element) {
        var elements = element.querySelectorAll('h1.select-object ~ .card.select-object');

        elements.forEach(function(element) {
            SelectModal.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-select-modal-index');

        if (index) {
            return SelectModal._loaded[index];
        }

        return new SelectModal(element);
    }

    bindEvents() {
        if (this.tabs) {
            this.tabs.on('change', () => {
                // TODO: handle sidebar changes, if needed
            });
        }

        // Handle selection of object
        if (this.search) {
            this.search.on('change', () => {
                // Search results were updated...
                // We can bind events on the object listing.
                let list = this.search.element.querySelector(".results .search-results-preview-container");

                list.querySelectorAll("li.object a").forEach( (object) => {
                    object.addEventListener('click', (event) => {
                        event.stopPropagation();
                        event.preventDefault();

                        let node = object.parentNode;

                        if (this.form) {
                            // Update form entries
                            this.nameField.value = node.querySelector('p.name').textContent.trim();
                            this.typeField.value = node.getAttribute('data-object-type');
                            this.revisionField.value = node.getAttribute('data-object-revision');
                            this.idField.value = node.getAttribute('data-object-id');
                            this.uidField.value = node.getAttribute('data-object-uid');
                            let imageURL = node.querySelector("svg.icon > use").getAttribute('xlink:href');
                            this.submitButton.removeAttribute('disabled');
                            console.log(node);

                            // Pull down version information
                            let object = new OccamObject(this.idField.value);

                            // Populate the file tab
                            // TODO: XXX
                            let fileURL = object.url({
                                path: "/files/"
                            });

                            Util.get(fileURL, (data) => {
                                this.filesElement.innerHTML = data;
                                let fileList = FileList.load(this.filesElement.parentNode);
                                fileList.on('file', (fileInfo) => {
                                    // Close the modal
                                    if (this.withinModal) {
                                        Modal.close();
                                    }

                                    fileInfo.id = this.idField.value;
                                    fileInfo.uid = this.uidField.value;
                                    fileInfo.revision = this.revisionField.value;

                                    // Report it back up
                                    this.trigger('object', fileInfo);
                                });
                            });

                            // Switch over to the file tab
                            this.tabs.select(1);

                            // Update commit message text
                            this.messageField.value = Util.formatString(this.messageTemplate, {
                                name: this.nameField.value,
                                type: this.typeField.value
                            });

                            // Submit the form and close the modal, if within one.
                            if (this.withinModal) {
                                Modal.onSubmit = (event) => {
                                    event.preventDefault();

                                    this.trigger('object', {
                                        name: this.nameField.value,
                                        type: this.typeField.value,
                                        image: imageURL,
                                        id: this.idField.value,
                                        uid: this.uidField.value,
                                        revision: this.revisionField.value
                                    });

                                    Modal.close();
                                };
                            }
                        }
                    });
                });
            });
        }
    }
}

SelectModal._count  = 0;
SelectModal._loaded = {};

export default SelectModal;
