"use strict";

import EventComponent from './event_component.js';

/**
 * This class handles the index page at the root of the site ('/').
 *
 * @param {HTMLElement} element The element to look for the index page.
 *
 */
class Index extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            return;
        }

        this.element = element;

        this.bindEvents();
    }

    /**
     * Finds all elements with a title attribute and instantiates a Tooltip
     * object for each of them.
     *
     * Only looks for elements within the given element.
     *
     * @param {HTMLElement} element The element to search within.
     */
    static loadAll(element) {
        var elements = element.querySelectorAll('.content > .index');

        elements.forEach(function(element) {
            Index.load(element);
        });
    }

    /**
     * Instantiates a Tooltip, if it hasn't already, for the given element.
     *
     * @param {HTMLElement} element The element to instantiate a Tooltip around.
     */
    static load(element) {
        if (element === undefined) {
            return null;
        }

        return new Index(element);
    }

    /**
     * Determines the active item in the navigation listing.
     */
    determineActiveNavButton() {
        let navElement = this.element.querySelector("nav");
        if (!navElement) {
            return;
        }
        let navSection = navElement.parentNode;

        var activeElement = null;
        var currentElement = null;

        var sections = navElement.querySelectorAll("li a");
        
        sections.forEach((item, i) => {
            let id = item.getAttribute("href");

            var sectionElement = null;
            try {
                sectionElement = this.element.querySelector(id);
            }
            catch(error) {
            }

            if (sectionElement) {
                if (item.parentNode.classList.contains("active")) {
                    currentElement = item;
                }

                // Show this particular section header if we are within that section
                // This will overwrite as we go through each section
                if (document.body.scrollTop > sectionElement.offsetTop) {
                    activeElement = item;
                }

                // Hide the section header if we are past the navigation section
                if (i == (sections.length-1) && document.body.scrollTop >= (navSection.offsetTop + navSection.clientHeight - 75)) {
                    activeElement = null;
                }
            }
        });

        if (activeElement != currentElement) {
            if (currentElement) {
                currentElement.parentNode.classList.remove("active");
            }
            if (activeElement) {
                activeElement.parentNode.classList.add("active");
            }
        }
    }

    /**
     * Binds the DOM events for this Tooltip object.
     */
    bindEvents() {
        this.determineActiveNavButton();
        
        document.body.addEventListener("resize", (event) => {
            this.determineActiveNavButton();
        });

        document.body.addEventListener("scroll", (event) => {
            this.determineActiveNavButton();
        });
    }
}

export default Index;
