"use strict";

import EventComponent from "./event_component.js";
import Util           from "./util.js";
import Tooltip        from "./tooltip.js";

/**
 * This represents a dropdown selection for changing a button's behavior
 * and typically wraps a div containing some number of `<button>` or `<input>`
 * elements on the page.
 *
 * Generally, you instantiate a ButtonSelector using
 * ButtonSelector.load(element) instead of invoking the constructor directly.
 *
 * @param {HTMLElement} element The main element for the dropdown;
 *                              typically some `<div>` element.
 */
class ButtonSelector extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            throw new TypeError("element is required");
        }

        if (!element.tagName) {
            throw new TypeError("element must be an HTMLElement");
        }

        this.element     = element;

        ButtonSelector._count++;
        this.element.setAttribute('data-button-dropdown-index', ButtonSelector._count);

        ButtonSelector._loaded[this.element.getAttribute('data-button-dropdown-index')] = this;

        this.bindEvents();
    }

    /**
     * Returns an instance of ButtonSelector for the given element.
     *
     * This will create a ButtonSelector, if it doesn't exist, for this element.
     *
     * @param {HTMLElement} element The main element for the selector;
     *                              typically the surrounding `<div>` element.
     */
    static load(element) {
        if (!element) {
            throw new TypeError("element argument required");
        }

        var index = element.getAttribute('data-button-dropdown-index');

        if (index) {
            return ButtonSelector._loaded[index];
        }

        return new ButtonSelector(element);
    }

    static loadAll(element) {
        if (!element) {
            throw new TypeError("element argument required");
        }

        if (!element.tagName) {
            throw new TypeError("element must be an HTMLElement");
        }

        var selectors = element.querySelectorAll('.button-selector');

        selectors.forEach( (element) => {
            ButtonSelector.load(element);
        });
    }

    /**
     * Returns the list of items in the dropdown as an element array.
     */
    items() {
        return this.dropdown.querySelectorAll('button');
    }

    /**
     * Returns the list item that is currently selected.
     *
     * @returns {HTMLElement} The `<button>` element representing the item.
     */
    selected() {
        return this.dropdown.querySelector('button:nth-child('+(parseInt(this.element.getAttribute('data-selected-index'))+1)+')');
    }

    /**
     * This function reveals the dropdown list.
     */
    open() {
        // Do not load and show the dropdown if the selector is in a loading
        // state.
        if (this.element.classList.contains('waiting')) {
            return;
        }

        // Ensure some elements cannot be interacted with
        document.body.classList.add('await-blur');

        this.element.setAttribute("aria-expanded", "true");

        var lastIndex = parseInt(this.element.getAttribute('data-selected-index'));
        let selectedButton = this.element.querySelector('.button-selector-item:nth-child(' + (lastIndex+1) + ')');
        if (!selectedButton) {
            return;
        }

        var inputStyle = window.getComputedStyle(selectedButton);
        var inputWidth  = selectedButton.offsetWidth + this.dropdownButton.offsetWidth - parseInt(inputStyle.borderLeftWidth) - parseInt(inputStyle.borderRightWidth);
        var inputHeight = selectedButton.offsetHeight;

        var offset = selectedButton.getBoundingClientRect();

        this.dropdown.style.width = inputWidth + "px";
        this.dropdown.style.height = "";
        this.dropdown.style.display = "block";
        this.dropdown.style.left = offset.left + "px";
        this.dropdown.style.top  = (offset.top + inputHeight) + "px";
        this.dropdown.removeAttribute("hidden");
        if (parseInt(this.dropdown.style.top) + this.dropdown.clientHeight > (window.innerHeight + window.scrollY)) {
            this.dropdown.style.top = (parseInt(this.dropdown.style.top) - this.dropdown.clientHeight - inputHeight) + "px";
        }
        var selectedItem = this.dropdown.querySelector('button[aria-checked="true"]');
        if (selectedItem) {
            selectedItem.setAttribute("tabindex", "0");
            selectedItem.focus();
            selectedItem.setAttribute("tabindex", "-1");
        }
        else {
            // We need something to receive focus
            this.dropdown.setAttribute("tabindex", "0");
            this.dropdown.focus();
            this.dropdown.setAttribute("tabindex", "-1");
        }
        this.dropdown.removeEventListener('blur', this.blurEvent.bind(this));
        this.dropdown.addEventListener('blur', this.blurEvent.bind(this));
    }

    blurEvent(event, forceClose) {
        // Ignore blur event if the target is a button within the dropdown
        if (forceClose || (!event.relatedTarget || (event.relatedTarget.parentNode != event.target && !event.relatedTarget.isSameNode(this.dropdown)))) {
            this.dropdown.setAttribute("hidden", "");
            this.element.setAttribute("aria-expanded", "false");
            this.element.focus();

            // Get rid of interactivity block
            document.body.classList.remove('await-blur');
        }
    }

    /**
     * Disables this component.
     */
    disable() {
        this.element.setAttribute('disabled', '');
    }

    /**
     * Enables this component.
     */
    enable() {
        this.element.removeAttribute('disabled');
    }

    elementFor(index) {
        return this.dropdown.querySelector('button:nth-child('+(index+1)+')');
    }

    /**
     * This function will select the given list item by the given reference.
     *
     * You can pass an integer index that represents the position. Index 0 is
     * the first item in the list.
     *
     * If it cannot find the item, it will return null.
     *
     * @returns {HTMLElement} The '<button>' element representing the selected
     *                        item, or null if the element cannot be selected.
     */
    select(index) {
        // Unselect the last selected option
        var last = this.selected();
        var lastIndex = parseInt(this.element.getAttribute('data-selected-index'));
        if (last) {
            // Unselect the rendered option
            last.removeAttribute("selected");
            last.setAttribute("aria-checked", "false");

            let selectedItem = this.element.querySelector('.button-selector-item:nth-child(' + (lastIndex+1) + ')');
            selectedItem.setAttribute('hidden', '');
        }

        var listItem = this.element.querySelector('.button-selector-item:nth-child(' + (index+1) + ')');
        var dropdownItem = this.dropdown.querySelector('button:nth-child(' + (index+1) + ')');
        if (listItem) {
            // Un-hide the selected button
            listItem.removeAttribute('hidden');

            dropdownItem.setAttribute('selected', '');
            dropdownItem.setAttribute('aria-checked', 'true');
            this.element.setAttribute('data-selected-index', index);

            this.trigger('change', listItem);
        }

        return listItem;
    }

    /**
     * This function initializes the selector and binds interactive events.
     */
    bindEvents(force) {
        if (this.element) {
            if (!force && this.element.classList.contains('bound')) {
                return;
            }
        }

        this.element.classList.add('button-selector-bound')

        // Add the dropdown expand (arrow) button
        this.dropdownButton = document.createElement("button");
        this.dropdownButton.textContent = this.element.getAttribute("data-dropdown-label") || "More Options";
        this.dropdownButton.classList.add("button");
        this.dropdownButton.classList.add("button-selector-show");
        this.dropdownButton.setAttribute("value", " ");
        this.dropdownButton.setAttribute("type", "button");

        this.element.appendChild(this.dropdownButton);

        var selectedOption = this.element.querySelector('.button-selector-item.selected');
        if (!selectedOption) {
            var firstOption = this.element.querySelector('.button-selector-item:first-child');
            if (firstOption) {
                firstOption.classList.add("selected");
            }
        }

        var options = this.element.querySelectorAll('.button-selector-item');

        // Form dropdown section
        var dropdown = this.dropdown = document.createElement("div");
        dropdown.classList.add("dropdown");
        dropdown.classList.add("button-selector-dropdown");
        dropdown.setAttribute("tabindex", "0");
        dropdown.setAttribute('hidden', '');
        dropdown.setAttribute('role', 'menu');

        this.dropdown.addEventListener('keydown', (event) => {
            let keyCode = event.keyCode || event.code;

            if (keyCode == 38) { // up arrow
                // Focus on last item
                let item = this.dropdown.querySelector('button:last-child');
                if (item) {
                    item.setAttribute("tabindex", "0");
                    item.focus();
                    item.setAttribute("tabindex", "-1");
                }
            }
            else if (keyCode == 40) { // down arrow
                // Focus on first item
                let item = this.dropdown.querySelector('button:first-child');
                if (item) {
                    item.setAttribute("tabindex", "0");
                    item.focus();
                    item.setAttribute("tabindex", "-1");
                }
            }
            else if (keyCode == 27) { // escape
                // Ensure relatedTarget doesn't keep the dropdown open
                this.blurEvent(event, true);
                return;
            }
        });

        var selectedItem = this.element.querySelector('.button-selector-item.selected');
        var selected = -1;

        var current = selectedItem;
        while (current) {
            selected++;
            current = current.previousElementSibling;
        }

        this.dropdownButton.setAttribute('aria-haspopup', 'true');
        this.dropdownButton.setAttribute('aria-expanded', 'false');
        dropdown.setAttribute('id', this.element.getAttribute('id') + "-dropdown");
        this.dropdownButton.setAttribute('aria-controls', dropdown.getAttribute('id'));
        this.dropdownButton.setAttribute('type', 'button');

        this.element.setAttribute('data-selected-index', selected);

        options.forEach( (option) => {
            let name = option.getAttribute('value');

            var item = document.createElement("button");
            item.classList.add("dropdown-item");
            item.classList.add("button-selector-dropdown-item");
            item.textContent = name;

            // Add it to the document
            this.dropdown.appendChild(item);

            // Add keydown event to every button
            option.addEventListener('keydown', (event) => {
                let keyCode = event.keyCode || event.code;

                if (keyCode == 40) {
                    this.open();
                }
            });

            // Hide every option to begin with.
            option.setAttribute('hidden', '');

            // Set up item events
            this.bindItemEvents(item);
        });

        // Append dropdown to body
        document.querySelector('.content').appendChild(dropdown);

        this.dropdownButton.addEventListener('keydown', (event) => {
            let keyCode = event.keyCode || event.code;

            if (keyCode == 40) {
                this.open();
            }
        });

        this.dropdownButton.addEventListener('click', (event) => {
            event.stopPropagation();
            event.preventDefault();

            this.open();
        });

        this.select(selected);

        this.dropdown.classList.add("no-icons");
        this.element.classList.add('bound');
    }

    /**
     * Binds the interactive events for a dropdown item.
     */
    bindItemEvents(item) {
        item.addEventListener('click', (event) => {
            this.blurEvent(event);

            event.stopPropagation();
            event.preventDefault();

            var index = Util.getChildIndex(item);
            this.select(index);
        });

        item.addEventListener('blur', (event) => {
            if (!event.relatedTarget || (event.relatedTarget.parentNode != item.parentNode && event.relatedTarget != item.parentNode)) {
                // Ensure relatedTarget doesn't keep the dropdown open
                this.blurEvent(event, true);
            }
        });

        item.addEventListener('keydown', (event) => {
            event.stopPropagation();

            let keyCode = event.keyCode || event.code;

            var toggle = null;
            if (keyCode == 38) { // up arrow
                toggle = item;
                do {
                    toggle = toggle.previousElementSibling;
                } while (toggle && toggle.hasAttribute("hidden"));
            }
            else if (keyCode == 40) { // down arrow
                toggle = item;
                do {
                    toggle = toggle.nextElementSibling;
                } while (toggle && toggle.hasAttribute("hidden"));
            }
            else if (keyCode == 27) { // escape
                // Ensure relatedTarget doesn't keep the dropdown open
                this.blurEvent(event, true);
                return;
            }

            if (toggle) {
                toggle.setAttribute("tabindex", "0");
                toggle.focus();
                toggle.setAttribute("tabindex", "-1");
            }
        });
    }
}

ButtonSelector._loaded = {};
ButtonSelector._count  = 0;

export default ButtonSelector;
