"use strict";

import EventComponent from "../event_component.js";
import Selector       from "../selector.js";
import AutoComplete   from "../auto_complete.js";
import ObjectList     from "../object_list.js";
import Util           from "../util.js";
import Search         from "../search.js";
import Tabs           from "../tabs.js";

/**
 * This implements behaviors for the objects/new modal.
 */
class AddRequirementModal extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            return;
        }

        this.element = element;

        // Find the errors section
        this.errorsList = this.element.parentNode.querySelector(".errors");

        // The name of the service
        this.nameInput = this.element.querySelector('input#add-requirement-name');
        this.nameDisplay = this.element.querySelector('input#add-requirement-name-display');

        // Find the summary input
        this.summaryInput = this.element.querySelector('input#add-requirement-summary');

        // Find the search query field
        this.searchInput = this.element.querySelector('input[name="query"]');

        // Get the tabs for the modal
        this.tabs = Tabs.load(this.element.querySelector('.tabs.tab-bar'));

        // Get the object listing
        let objectsListElement = this.element.querySelector(".objects-container ul.objects");
        if (objectsListElement) {
            this.objectsList = ObjectList.load(objectsListElement);
        }

        // Find the button
        this.submitButton = this.element.querySelector('input[name="add"]');

        AddRequirementModal._count++;
        this.element.setAttribute('data-add-requirement-modal-index', AddRequirementModal._count);
        AddRequirementModal._loaded[this.element.getAttribute('data-add-requirement-modal-index')] = this;

        if (this.searchInput) {
            this.searchInput.focus();
        }

        this.bindEvents();

        this.search();
    }

    static loadAll(element) {
        var elements = element.querySelectorAll('form.card.new-requirement-data');

        elements.forEach(function(element) {
            AddRequirementModal.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-add-requirement-modal-index');

        if (index) {
            return AddRequirementModal._loaded[index];
        }

        return new AddRequirementModal(element);
    }

    bindEvents() {
        if (this.objectsList) {
            this.objectsList.on('click', (item) => {
                if (this.tabs) {
                    // Re-enable and go to Metadata tab
                    let tab = this.tabs.tabAt(1);
                    tab.removeAttribute('disabled');
                    this.tabs.select(1);
                }

                // Update name fields
                this.nameInput.value = item.name;
                this.nameDisplay.value = item.name;

                // Empty summary field
                this.summaryInput.value = "";

                // Focus on the summary
                this.summaryInput.focus();
            });
        }

        if (this.searchInput) {
            let searchTimer = null;
            let search = (e) => {
                // Perform a search within the objects listing
                if (searchTimer) {
                    window.clearTimeout(searchTimer);
                    searchTimer = null;
                }

                searchTimer = window.setTimeout( () => {
                    this.search();
                }, Search.POLL_TIME);
            };
            this.searchInput.addEventListener("change", search);
            this.searchInput.addEventListener("keyup", search);
        }
    }

    /**
     * Performs the search for object templates indicated by the input query.
     */
    search() {
        if (this.searchInput && this.searchInput.value !== this._lastSearch) {
            this.objectsList.clear();
            this.objectsList.search({
                service: this.searchInput.value
            });
            this._lastSearch = this.searchInput.value;
        }
    }
}

AddRequirementModal._count  = 0;
AddRequirementModal._loaded = {};

export default AddRequirementModal;
