"use strict";

import EventComponent from './event_component.js';

/**
 * This class handles a configuration form.
 */
class ConfigurationValidator {
    static initialize() {
        function Tokens() {
            this.tokens = [];
        }

        Tokens.prototype.push = function(token, data) {
            if (token.length > 0) {
                this.tokens.push(token);
                if (token == "identifier" || token == "number") {
                    this.tokens.push(data);
                }
            }
        };

        Tokens.prototype.each = function(f) {
            this.tokens.forEach(f);
        };

        Tokens.prototype.tokenize = function(str) {
            var last_character = '';
            var state = 0;
            var token = "";
            for (var i = 0, len = str.length; i < len; i++) {
                var c = str.charAt(i);

                if (state == 0) {
                    // Reading identifier
                    if (c.match(/[*+-\/()%=<>!]/)) {
                        // Switch states
                        state = 1;

                        // Push token
                        if (token.match(/^\d+$/)) {
                            this.push("number", token);
                        }
                        else if (token == "x" || token == "log2" || token == "floor") {
                            this.push("identifier", token);
                        }
                        else if (token.length > 0) {
                            window.console.log("invalid identifier: " + token);
                            return false;
                        }
                        token = "";
                        last_character = "";
                    }
                }
                else if (state == 1) {
                    // Reading symbol
                    if (c == '*' && last_character == '*' && token.length == 1) {
                        // **
                    }
                    else if (c == '=' && (last_character == '<' ||
                        last_character == '>' ||
                        last_character == '=' ||
                        last_character == '!') && token.length == 1) {
                        // ==, <=, >=, !=
                    }
                    else if (c.match(/\d|\w/)) {
                        // Switch states
                        state = 0;

                        // Push token
                        this.push(token);
                        token = "";
                        last_character = "";
                    }
                    else if (c.match(/[*+-\/()%=<>!]/)) {
                        // Push token
                        this.push(token);
                        token = "";
                        last_character = "";
                    }
                }

                if (c == ' ') {
                    this.push(token);
                    token = "";
                    last_character = "";
                }
                else {
                    token = token + c;
                    last_character = c;
                }
            }

            this.push(token);

            return this.tokens;
        };

        // Compiler

        function Compiler() {
        }

        Compiler.prototype.execute = function(expr, x) {
            var tokens = new Tokens();
            tokens.tokenize(expr);

            var output = "this.f = function(x){return ";
            var lastToken = "";
            var leftParens = 0;

            tokens.each(function(token) {
                if (lastToken == "identifier") {
                    if (token == "log2") {
                        output += "Math.log2";
                    }
                    else if (token == "floor") {
                        output += "Math.floor";
                    }
                    else if (token == "ceiling") {
                        output += "Math.ceil";
                    }
                    else if (token == "log") {
                        output += "Math.log";
                    }
                    else if (token == "isInteger") {
                        output += "isInteger";
                    }
                    else if (token == "isNumber") {
                        output += "isNumber";
                    }
                    else if (token == "x") {
                        output += "x";
                    }
                }
                else if (lastToken == "number") {
                    output += "(" + token + ")";
                }
                else if (token == "identifier" || token == "number") {
                }
                else if (token == "==") {
                    output += "===";
                }
                else {
                    if (token == "(") {
                        leftParens++;
                    }
                    else if (token == ")") {
                        if (leftParens < 1) {
                            window.console.log("compile error");
                            return false;
                        }
                        leftParens--;
                    }
                    output = output + token;
                }

                lastToken = token;
            });

            /* jshint ignore:start */
            eval(output + "}");
            /* jshint ignore:end */

            return this.f(x);
        };

        // Add compiled expression validator
        /*window.ParsleyValidator.addValidator('test', function(value, requirement) {
            var compiler = new Compiler();
            return compiler.execute(requirement, value);
        }, 32); // 32 is the priority; a recommended value; higher #s are validated
        // first

        // Add compiled expression validator for ranges
        window.ParsleyValidator.addValidator('test_range', function(value, requirement) {
            var compiler = new Compiler();
            var values = value.split(",");
            var allFound = true;
            values.forEach(function(value) {
                value = value.trim();
                if (value.indexOf("...") > 0) {
                    var parts = value.split(":");
                    var step = "x+1";
                    if (parts.length > 1) {
                        step = parts[1].trim();
                        value = parts[0].trim();
                    }

                    parts = value.split("...");
                    var start = parseInt(parts[0].trim());
                    var end   = parseInt(parts[1].trim());
                    var found = false;
                    var iterations = 0;
                    do {
                        found = compiler.execute(requirement, ""+start);
                        if (!found) {
                            start = compiler.execute(step, start);
                        }
                        iterations++;
                    } while(!found && iterations < 50);

                    if (found) {
                        if (!compiler.execute("" + start + " <= x", end)) {
                            allFound = false;
                        }
                    }
                    else {
                        allFound = false;
                    }
                }
                else {
                    if (!compiler.execute(requirement, value)) {
                        allFound = false;
                    }
                }
            });

            return allFound;
        }, 32);

        // Add type validator for min
        window.ParsleyValidator.addValidator('min_range', function(value, requirement) {
            var assert = new window.Validator.Assert().GreaterThanOrEqual(requirement);

            var allFound = true;

            value.split(",").forEach(function(value) {
                value = value.trim();
                if (value.indexOf("...") > 0) {
                    var parts = value.split(":");
                    if (parts.length > 1) {
                        value = parts[0].trim();
                    }
                    parts = value.split("...");
                    try {
                        assert.validate(parts[0].trim());
                        assert.validate(parts[1].trim());
                    }
                    catch (e) {
                        allFound = false;
                    }
                }
                else {
                    try {
                        assert.validate(value);
                    }
                    catch (e) {
                        allFound = false;
                    }
                }
            });

            return allFound;
        }, 30) // Priority is higher for min/max checks
            .addMessage('en', 'min_range', 'The lower bound of this range should be greater than or equal to %s');

        // Add type validator for max
        window.ParsleyValidator.addValidator('max_range', function(value, requirement) {
            var assert = new window.Validator.Assert().LessThanOrEqual(requirement);


            var allFound = true;

            value.split(",").forEach(function(value) {
                value = value.trim();
                if (value.indexOf("...") > 0) {
                    var parts = value.split(":");
                    if (parts.length > 1) {
                        value = parts[0].trim();
                    }
                    parts = value.split("...");
                    try {
                        assert.validate(parts[0].trim());
                        assert.validate(parts[1].trim());
                    }
                    catch (e) {
                        allFound = false;
                    }
                }
                else {
                    try {
                        assert.validate(value);
                    }
                    catch (e) {
                        allFound = false;
                    }
                }
            });

            return allFound;
        }, 30) // Priority is higher for min/max checks
            .addMessage('en', 'max_range', 'The upper bound of this range should be less than or equal to %s');

        // Add type validator for ranges
        window.ParsleyValidator.addValidator('type_range', function(value, requirement) {
            var assert;

            var assertRange = false;

            switch (requirement) {
                case 'email':
                    assert = new window.Validator.Assert().Email();
                    break;
                case 'number':
                    assert = new window.Validator.Assert().Regexp('^-?(?:\\d+|\\d{1,3}(?:,\\d{3})+)?(?:\\.\\d+)?(?:e(?:-|\\+)?\\d+)?$');
                    assertRange = true;
                    break;
                case 'integer':
                    assert = new window.Validator.Assert().Regexp('^-?\\d+$');
                    assertRange = true;
                    break;
                case 'digits':
                    assert = new window.Validator.Assert().Regexp('^\\d+$');
                    assertRange = true;
                    break;
                case 'alphanum':
                    assert = new window.Validator.Assert().Regexp('^\\w+$', 'i');
                    break;
                case 'url':
                    assert = new window.Validator.Assert().Regexp('(https?:\\/\\/)?(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'i');
                    break;
                default:
                    throw new Error('validator type `' + requirement + '` is not supported');
            }

            var allFound = true;
            value.split(",").forEach(function(value) {
                value = value.trim();
                if (assertRange) {
                    if (value.indexOf("...") > 0) {
                        var parts = value.split(":");
                        if (parts.length > 1) {
                            value = parts[0].trim();
                        }
                        parts = value.split("...");
                        try {
                            assert.validate(parts[0].trim());
                            assert.validate(parts[1].trim());
                        }
                        catch (e) {
                            allFound = false;
                        }
                    }
                    else {
                        try {
                            assert.validate(value);
                        }
                        catch (e) {
                            allFound = false;
                        }
                    }
                }
                else {
                    try {
                        assert.validate(value);
                    }
                    catch (e) {
                        allFound = false;
                    }
                }
            });

            return allFound;
        }, 256) // Priority is higher for type checks
        .addMessage('en', 'type_range', 'This range should represent %s types');

        // Automatically expand description upon error
        window.$.listen('parsley:field:error', function(field) {
            var elem = document.querySelector('input[data-parsley-id="'+field.__id__+'"]');
            var elemParent = elem.parentNode;
            if (elemParent.classList.contains("tuple") || elemParent.classList.contains("number")) {
                elemParent = elemParent.parentNode;
            }

            var expand = elemParent.querySelector('.expand');
            if (!expand.classList.contains('shown')) {
                window.$(expand).trigger('click');
            }
        });*/
    }
}

export default ConfigurationValidator;
