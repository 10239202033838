"use strict";

import Occam from './occam.js';

/**
 * This class module holds useful functionality for keeping track of the
 * browser history and state.
 *
 * This can coordinate among elements on the page such that the browser back
 * and forward buttons do not do a full page refresh, but rather update the
 * page when the content already exists.
 */
class NavigationState {
    /**
     * This function pushes the given module state to the current state.
     *
     * When the location changes, the data will be preserved. When the page is
     * reloaded via the back button or other mechanism, the page can be updated
     * based on the state instead of loading it again from the server.
     *
     * This works well for smaller modules, such as tabs, where the state (which
     * tab is selected) can be preserved and restored as page navigation
     * happens.
     *
     * @param {string} module The name of the class that manages the given state.
     * @param {string} index The tag that identifies the instance of the class.
     * @param {object} data Any data to preserve for this instance.
     */
    static updateState(module, index, data) {
        let currentSubState = null;

        NavigationState.currentState.forEach( (subState) => {
            if (subState.module == module && subState.index == index) {
                currentSubState = subState;
            }
        });

        if (!currentSubState) {
            currentSubState = {}
            NavigationState.currentState.push(currentSubState);
        }

        currentSubState.module = module;
        currentSubState.index  = index;
        currentSubState.data   = data;

        let record = window.history.state;
        record.state = NavigationState.currentState;
        window.history.replaceState(record, "", record.href);
    }

    /**
     * Performs the given state changes to the current page.
     * Generally called internally. Not meant to be called directly.
     */
    static perform(state) {
        state.state.forEach( (subState) => {
            if (subState.module) {
                const item = Occam.load(subState.module, subState.index);
                if (item) {
                    item.updateState(subState.data);
                }
            }
        });
    }

    /**
     * This method returns the browser's current url. It is window.location.pathname;
     */
    static currentLocation() {
        return window.location.pathname;
    }

    /**
     * This method returns the path from the current object.
     */
    static currentPath() {
        var currentURL = NavigationState.currentLocation();

        var paths = currentURL.slice(1).split("/");
        var SEPARATORS = ["objects", "groups", "experiments",
            "metadata", "files", "tree", "output",
            "run", "view"];

        if (paths[0] == "worksets") {
            paths.shift(); // worksets
            paths.shift(); // id

            // Check for revision
            if (SEPARATORS.indexOf(paths[0]) == -1 && paths[0].match(/^[a-z0-9]+$/)) {
                paths.shift();
            }
        }

        if (paths[0] == "objects") {
            paths.shift(); // objects
            paths.shift(); // id

            if (SEPARATORS.indexOf(paths[0]) == -1 && paths[0].match(/^[a-z0-9]+$/)) {
                paths.shift();
            }
        }

        return "/" + paths.join("/");
    }

    /**
     * This changes the current location (address bar) without navigating.
     *
     * By default, this will push the navigation state as well such that the
     * back button, etc, will preserve the state and a new state will then
     * be made as a clone of the current state.
     *
     * @param {string} newURL The URL to change the address bar to.
     */
    static updateLocation(newURL, keepState) {
        if (!keepState) {
            // Clone the current state
            let newState = [];
            NavigationState.currentState.forEach( (subState) => {
                newState.push(Object.assign({}, subState));
            });
            NavigationState.currentState = newState;
            
            // Push this new state
            window.history.pushState({
                'title':  window.document.title,
                'href':   newURL,
                'state':  NavigationState.currentState
            }, "", newURL);
        }
        else {
            window.history.replaceState(window.history.state, "", newURL);
        }
    }

    /**
     * This is the callback that is fired when a person presses the back or
     * forward buttons in their browser. The given event will have the index of
     * the "state" in our history. This function will iterate through the history
     * to recreate the page state.
     */
    static popState(event) {
        if (event === null || event.state === null) {
            // Cannot find state!!
            console.error("NavigationState: state not found. panicking.");
        }
        else {
            NavigationState.perform(event.state);
            NavigationState.currentState = event.state.state;
        }
    }

    /**
     * Sets up the navigation state stack.
     */
    static load() {
        if (NavigationState.loaded) {
            return;
        }

        window.onpopstate = NavigationState.popState;
        
        // Create the initial state
        window.history.replaceState({
            'title':  window.document.title,
            'href':   window.location.href,
            'state':  NavigationState.currentState
        }, "", window.document.href);

        NavigationState.loaded = true;
    }

    /**
     * Initializes based on elements found within the given element.
     */
    static loadAll(element) {
        if (element === undefined) {
            throw new TypeError("element is required");
        }

        if (!element.tagName) {
            throw new TypeError("element must be an HTMLElement");
        }

        let elements = element.querySelectorAll("*[data-navigation-watch]");
        elements.forEach( (subElement) => {
            NavigationState.watch(subElement);
        });
    }

    /**
     * Watches an iframe for navigation and updates our own location.
     */
    static watch(element) {
        if (element === undefined) {
            throw new TypeError("element is required");
        }

        if (!element.tagName) {
            throw new TypeError("element must be an HTMLElement");
        }

        // Get base URL
        let base = element.getAttribute('data-navigation-base');
        if (base[0] == "/") {
            base = window.location.origin + base;
        }
        let current = element.src;
        let relative = current.substring(base.length);

        let root = window.location.pathname;
        root = root.substring(0, root.length - relative.length);

        if (root[root.length - 1] != "/") {
            root = root + "/";
        }

        element.addEventListener('load', (event) => {
            let url = element.src;
            url = element.contentWindow.location.href;
            url = url.substring(base.length);

            if (url[0] == "/") {
                url = url.substring(1);
            }

            url = root + url;
            // TODO: back button issues with iframes
            NavigationState.updateLocation(url, false);
        });
    }
}

NavigationState.loaded = false;
NavigationState.currentState = [];

export default NavigationState;
