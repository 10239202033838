"use strict";

import Util        from './util.js';
import OccamObject from './occam_object.js';
import Occam       from './occam.js';

/**
 * This class retrieves information about a particular Task object.
 */
class Task {
    constructor(element) {
        this.element = element;

        this.taskID = this.element.getAttribute("data-task-id");
        this.buildID = this.taskID;

        if (this.taskID) {
            this.task = new OccamObject(this.taskID);

            this.loadTask();
        }
    }

    /**
     * Renders the task.
     */
    loadTask() {
        var url = "";
        if (this.buildID) {
            url = "/builds/" + this.buildID;
        }

        Util.get(Occam.object().url({
            path: url
        }), (html) => {
            this.element.classList.remove("loading");
            this.element.innerHTML = html;

            // Initialize the content
            this.initialize();
        });
    }

    /**
     * Initializes content within the task view
     */
    initialize() {
        // Bind events and initialize other components
        Occam.loadAll(this.element);
    }
}

export default Task;
