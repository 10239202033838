"use strict";

import EventComponent from "./event_component.js";

/**
 * This represents a visual component that cannot be moved.
 */
class StaticComponent extends EventComponent {
    /**
     * Initializes the component by wrapping the given element.
     *
     * @param {HTMLElement} element The element to wrap.
     */
    constructor(element) {
        super();

        this._element = element;
    }

    /**
     * Whether or not this component can be selected.
     */
    get selectable() {
        return false;
    }

    /**
     * Whether or not this component can be region selected.
     */
    get regionSelectable() {
        return false;
    }

    /**
     * Whether or not this component can be collided.
     */
    get collidable() {
        return false;
    }

    /**
     * Retrieves the current bound element.
     */
    get element() {
        return this._element;
    }

    /**
     * Retrieves the current x position.
     */
    get x() {
        return this._element.offsetLeft;
    }

    /**
     * Retrieves the current x position.
     */
    get y() {
        return this._element.offsetTop;
    }

    /**
     * Retrieves the current width of the component.
     */
    get width() {
        return this._element.clientWidth;
    }

    /**
     * Retrieves the current height of the component.
     */
    get height() {
        return this._element.clientHeight;
    }

    /**
     * Retrieves the bounding box for this component.
     */
    get bounds() {
        return {
            x: this.x,
            y: this.y,
            width: this.width,
            height: this.height
        };
    }
}

export default StaticComponent;
