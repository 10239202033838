"use strict";

import EventComponent from "../event_component.js";
import Modal          from "../modal.js";
import AutoComplete   from "../auto_complete.js";

/**
 * This implements behaviors for the objects/add-io modal.
 */
class AddIOModal extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            return;
        }

        this.element = element;

        // Find the form
        this.form = this.element.parentNode.querySelector("form#new-io");

        // Find the button and other form elements
        if (this.form) {
            this.typeField = AutoComplete.load(this.form.querySelector('input#add-io-type'));
            this.subTypeField = this.form.querySelector('input#add-io-subtype');
            this.fileField = this.form.querySelector('input#add-io-file');
            this.fileLabel = this.form.querySelector('label[for="add-io-file"]');
            this.schemaField = this.form.querySelector('input#add-io-schema');
            this.schemaLabel = this.form.querySelector('label[for="add-io-schema"]');
            this.summaryField = this.form.querySelector('label[for="add-io-summary"]');
        }

        AddIOModal._count++;
        this.element.setAttribute('data-add-io-modal-index', AddIOModal._count);
        AddIOModal._loaded[this.element.getAttribute('data-add-io-modal-index')] = this;

        // Bind the modal events
        this.bindEvents();
    }

    static loadAll(element) {
        var elements = element.querySelectorAll('h1.new-io');

        elements.forEach(function(element) {
            AddIOModal.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-add-io-modal-index');

        if (index) {
            return AddIOModal._loaded[index];
        }

        return new AddIOModal(element);
    }

    bindEvents() {
        if (this.typeField) {
            this.typeField.on('change', () => {
                // For configuration inputs, we add a schema and file field.
                if (this.typeField.type() == "configuration") {
                    this.fileField.removeAttribute('hidden');
                    this.fileField.value = "config.json";
                    this.fileLabel.removeAttribute('hidden');
                    this.schemaField.removeAttribute('hidden');
                    this.schemaField.value = "";
                    this.schemaLabel.removeAttribute('hidden');
                    this.subTypeField.value = "application/json";
                    this.subTypeField.setAttribute('readonly', '');
                    this.subTypeField.setAttribute('tabindex', '-1');
                }
                else {
                    this.fileField.setAttribute('hidden', '');
                    this.fileField.value = "";
                    this.fileLabel.setAttribute('hidden', '');
                    this.schemaField.setAttribute('hidden', '');
                    this.schemaField.value = "";
                    this.schemaLabel.setAttribute('hidden', '');
                    this.subTypeField.removeAttribute('readonly');
                    this.subTypeField.removeAttribute('tabindex');
                }
            });
        }

        this.subTypeField.addEventListener('focus', (event) => {
            if (this.typeField.type() == "configuration") {
                this.summaryField.focus();
            }
        });
    }
}

AddIOModal._count  = 0;
AddIOModal._loaded = {};

export default AddIOModal;
