"use strict";

import Util from "./util.js";

/**
 * This represents an object lookup.
 */
export class Locator {
    /**
     * Constructs an instance of a Locator for the given element.
     */
    constructor(element) {
        this.element = element;

        Locator._count++;
        this.element.setAttribute('data-locator-index', Locator._count);
        Locator._loaded[this.element.getAttribute('data-locator-index')] = this;

        this._searchCard = this.element.querySelector(".card.searching");

        this.search();
    }

    /**
     * Returns an instance of Locator for the given element.
     *
     * This will create a Locator, if it doesn't exist, for this element.
     *
     * @param {HTMLElement} element The main element for the locator panel;
     */
    static load(element) {
        if (!element) {
            throw new TypeError("element argument required");
        }

        var index = element.getAttribute('data-locator-index');

        if (index) {
            return Locator._loaded[index];
        }

        return new Locator(element);
    }

    static loadAll(element) {
        if (!element) {
            throw new TypeError("element argument required");
        }

        if (!element.tagName) {
            throw new TypeError("element must be an HTMLElement");
        }

        var locators = element.querySelectorAll('.object-locator');

        locators.forEach( (element) => {
            Locator.load(element);
        });
    }

    /**
     * Attempts to determine if the object can be found and then redirect to
     * the same page while discovering it.
     */
    search() {
        let url = window.location.origin + window.location.pathname;
        let query = Util.getParameters(window.location.href);
        query["discover"] = true;

        var queryKeys = window.Object.keys(query);
        if (queryKeys.length > 0) {
            var queryKey = queryKeys.pop();
            url = url + "?" + queryKey + "=" + encodeURIComponent(query[queryKey]);
        }

        queryKeys.forEach(function(queryKey) {
            url = url + "&" + queryKey + "=" + encodeURIComponent(query[queryKey]);
        });

        // Try to discover on the federation
        fetch(url).then( (response) => {
            // If it finds something
            if (response.status == 200) {
                // Then redirect to that discovered object
                window.location.href = url;
            }
            else {
                if (this._searchCard) {
                    this._searchCard.classList.remove("loading");
                    this._searchCard.classList.add("error");
                }
            }
        });
    }
}

Locator._count  = 0;
Locator._loaded = {};

export default Locator;
