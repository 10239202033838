"use strict";

import Util from './util.js';

/**
 * Handles jobs listing pages.
 *
 * This is mainly on the /system/jobs page. It shows a table of the pending or
 * running jobs. That page has a sidebar that allows filtering the jobs based
 * on the job status or the target.
 */
export class JobsViewer {
    /**
     * Creates an instance of the JobsViewer for the given element.
     */
    constructor(element) {
        if (element === undefined) {
            throw "element needs to be the jobs-viewer container";
        }

        // Retain the element
        this.element = element;

        // Ensure this element is loaded only once
        JobsViewer._count++;
        this.element.setAttribute('data-jobs-viewer-index', JobsViewer._count);
        JobsViewer._loaded[this.element.getAttribute('data-jobs-viewer-index')] = this;

        // Get the 'search' sidebar
        this.sidebar = element.querySelector(".search.sidebar");

        // Bind the events for the sidebar
        if (this.sidebar) {
            // As long as there is a sidebar, we bind the events.
            this.bindSidebarEvents();
        }
    }

    static loadAll(element) {
        // Look for all matching elements that are the root for the job viewer
        var elements = element.querySelectorAll('.jobs-viewer');

        // Load each and every one rooted from this element.
        elements.forEach( (element) => {
            JobsViewer.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-jobs-viewer-index');

        if (index) {
            return JobsViewer._loaded[index];
        }

        return new JobsViewer(element);
    }

    /**
     * Installs the event handlers for the sidebar forms, etc.
     */
    bindSidebarEvents() {
        // Gather the form
        let form = this.sidebar.querySelector("form");

        if (!form) {
            return;
        }

        // Hide submit button (Is only useful when JavaScript is disabled/unused)
        let submitButton = form.querySelector('input[type="submit"]');
        if (submitButton) {
            submitButton.setAttribute("hidden", "");
        }

        // Gather the sliders
        let checkboxes = form.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach( (checkbox) => {
            checkbox.addEventListener('change', (event) => {
                Util.submitForm(form, (html) => {
                    // We want to replace the table on the page with this one

                    // Place html in a container so we can pull from it
                    var node = document.createElement("div");
                    node.innerHTML = html;

                    let table = node.querySelector("table.jobs");
                    let oldTable = this.element.querySelector("table.jobs");

                    oldTable.parentNode.replaceChild(table, oldTable);
                });
            });
        });
    }
}

JobsViewer._count  = 0;
JobsViewer._loaded = {};

export default JobsViewer;
