"use strict";

import EventComponent from './event_component.js';
import Util           from './util.js';
import Tabs           from './tabs.js';
import Occam          from './occam.js';

/**
 * This class implements the search results viewer and facet panel.
 */
class Search extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            throw "element needs to be the search results container";
        }

        this.element = element;
        this.sidebar = element.querySelector(".search.sidebar");

        // Keep track of the tabs
        this.tabs = Tabs.load(element.querySelector("ul.tabs.listview"));
        if (this.tabs) {
            this.tabPanels = this.tabs.tabPanels();
        }

        // Keep track of the sidebar form fields
        this.queryField = this.sidebar.querySelector('input[name="query"]');
        this.typeField = this.sidebar.querySelector('input[name="type"]');
        this.form = this.sidebar.querySelector("form");

        Search._count++;
        this.element.setAttribute('data-search-index', Search._count);

        Search._loaded[this.element.getAttribute('data-search-index')] = this;

        this.bindEvents();
        this.events = {};

        // Detect if part of Modal
        this.withinModal = false;
        if (Util.getParents(this.element, ".modal-window", ".modal-window").length > 0) {
            this.withinModal = true;
            this.submit();
        }
    }

    static loadAll(element) {
        var elements = element.querySelectorAll('.card.objects.results');

        elements.forEach( (element) => {
            Search.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-search-index');

        if (index) {
            return Search._loaded[index];
        }

        return new Search(element);
    }

    toggleFacets() {
        this.sidebar.classList.toggle("reveal");
        if (this.sidebar.classList.contains("reveal")) {
            this.tabs.hideSidebarButton();
        }
        else {
            this.tabs.showSidebarButton();
        }
    }

    showFacets() {
        this.sidebar.classList.remove("reveal");
        this.tabs.hideSidebarButton();
    }

    hideFacets() {
        this.sidebar.classList.add("reveal");
        this.tabs.showSidebarButton();
    }

    submit() {
        // Cancel pending search
        if (this.pendingReq) {
            this.pendingReq.abort();
        }

        // Replace search panels with loading panes
        this.pendingReq = Util.submitForm(this.form, (html) => {
            // Update the navigation bar
            if (!this.withinModal) {
                // TODO: use NavigationState
                window.history.replaceState({}, window.document.title, this.pendingReq.responseURL);
            }

            // Place html in a container so we can pull from it
            var node = document.createElement("div");
            node.innerHTML = html;

            // Place preview/list views in correct panes
            let panelSelectors = [
                ".results .search-results-list-container",
                ".results .search-results-preview-container",
            ];

            panelSelectors.forEach( (selector) => {
                var panel = node.querySelector(selector);
                if (panel) {
                    var targetPanel = this.element.querySelector(selector);
                    if (targetPanel) {
                        targetPanel.classList.remove("loading");
                        targetPanel.innerHTML = panel.innerHTML;

                        Occam.loadAll(targetPanel);
                    }
                }
            });

            // Facets
            var newFacets = node.querySelectorAll("ul.facets");
            this.sidebar.querySelectorAll("ul.facets").forEach((e, i) => {
                e.innerHTML = newFacets[i].innerHTML;
            });

            this.bindFacetEvents();

            this.trigger('change');
        });
    }

    bindFacetEvents() {
        var updateField = (event) => {
            event.stopPropagation();
            event.preventDefault();

            if (this.pollTimer) {
                window.clearTimeout(this.pollTimer);
            }

            this.pollTimer = window.setTimeout(() => {
                this.pollTimer = null;
                this.submit();
            }, Search.POLL_TIME);
        };

        this.sidebar.querySelectorAll("input[type=checkbox]").forEach(function(e) {
            e.addEventListener("change", updateField);
        });
    }

    bindEvents() {
        if (this.tabs) {
            this.tabs.on("sidebar", (item) => {
                this.toggleFacets();
            });
        }

        // Progressive search
        var updateField = (event) => {
            event.stopPropagation();
            event.preventDefault();

            if (this.pollTimer) {
                window.clearTimeout(this.pollTimer);
            }

            this.pollTimer = window.setTimeout(() => {
                this.pollTimer = null;
                this.submit();
            }, Search.POLL_TIME);
        };

        this.form.addEventListener("submit", updateField);
        this.queryField.addEventListener("change", updateField);
        this.queryField.addEventListener("keyup", updateField);
        this.typeField.addEventListener("change", updateField);
        this.typeField.addEventListener("keyup", updateField);

        this.bindFacetEvents();
    }

    /**
     * Performs a JSON service search with the provided query parameters.
     */
    static services(criteria) {
        return new Promise( (resolve, reject) => {
            let url = '/services';

            let query = criteria.query;

            var searchOptions = {
                "search": query
            };

            let request = Util.get(url, (data) => {
                resolve(data);
            }, 'json', searchOptions);
        });
    }

    /**
     * Performs a JSON search with the provided query parameters.
     */
    static perform(criteria) {
        return new Promise( (resolve, reject) => {
            // Form the query URL
            let url = '/search';

            let query = criteria.query;
            let showObjects = true;
            let showTypes = false;

            var searchOptions = {
                "search": query,
                "objects": showObjects ? "on" : "off",
                "types": showTypes ? "on" : "off"
            };

            // TODO: views-type, views-subtype
            ["subtype", "type", "environment", "architecture"].forEach( (key) => {
                if (criteria[key]) {
                    searchOptions[key] = criteria[key];
                }
            });

            // Submit it with a JSON response
            let request = Util.get(url, (data) => {
                resolve(data);
            }, 'json', searchOptions);
        });
    }
}

/**
 * The time in milliseconds before allowing a search.
 * Any searches queued during the timeout will delay the search.
 */
Search.POLL_TIME = 250;

Search._count  = 0;
Search._loaded = {};

export default Search;
