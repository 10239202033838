"use strict";

import EventComponent from './event_component.js';
import Configuration  from './configuration.js';
import RunList        from './run_list.js';
import FileList       from './file_list.js';
import OccamObject    from './occam_object.js';
import RunForm        from './run_form.js';
import AutoComplete   from './auto_complete.js';
import Selector       from './selector.js';
import Tabs           from './tabs.js';
import Terminal       from './terminal.js';
import Util           from './util.js';
import Workflow       from './workflow.js';
import Task           from './task.js';
import Job            from './job.js';
import Modal          from './modal.js';
import Occam          from './occam.js';
import Widget         from './widget.js';

/**
 * This class manages the various run panes throughout the site.
 *
 * An instance of the class is created for the "View" or "Run" tab, of course,
 * but also for the "Build" tab, any open file tabs, and any preview pane.
 *
 * It is responsible for queuing runs or individual jobs and managing any
 * widget events and widget configurations.
 */
class Runner extends EventComponent {
    constructor(element) {
        super();

        this.element = element;
        this.suppressEvents = false;

        Runner._count++;
        this.element.setAttribute('data-runner-index', Runner._count);
        Runner._loaded[this.element.getAttribute('data-runner-index')] = this;

        // Find the tab strip, which lets us handle the sidebar button and
        // switching builds for the build tab.
        this.tabs = Tabs.tabsFor(this.element);
        if (!this.tabs) {
            this.tabs = Tabs.tabsFor(this.element.parentNode);
        }

        // Whether or not these tabs exist within a modal
        this.withinModal = false;
        if (Util.getParents(this.element, ".modal-window", ".modal-window").length > 0) {
            this.withinModal = true;
        }

        this.configurationPanel = this.currentConfigurationPanel();

        this.loadConfigurations();

        var runListElement = element.parentNode.querySelector(".sidebar.right ul.runs");
        if (runListElement) {
            this._runList = RunList.load(runListElement);

            this.runList.on('change', (runListItem) => {
                if (this.tabs && this.runList.element.classList.contains("builds")) {
                    this.loadBuild(runListItem);
                }
                else {
                    // Close sidebar panels
                    if (this.tabs) {
                        this.tabs.hideSidebar(0);
                    }
                    this.loadPanel(runListItem);
                    this.configurationPanel = this.currentConfigurationPanel();
                }
            });

            this.runList.on('action-cancel', this.cancel.bind(this));

            this.runList.on('focus', this.focus.bind(this));

            this.runList.on('hidden', () => {
                if (this.tabs) {
                    this.tabs.detectSidebarState();
                }
                this.trigger("sidebar-hidden");
            });

            this.runList.on('shown', () => {
                if (this.tabs) {
                    this.tabs.detectSidebarState();
                }
                this.trigger("sidebar-shown");
            });
        }

        // Gather the run form, if it has been loaded
        let runFormElement = element.querySelector(".task-form");
        if (runFormElement) {
            this.runForm = RunForm.load(runFormElement);
        }

        this.loadedPanel = this.element.querySelector(":scope > .active");

        this.inputInfo = null;
        this.taskInfo  = null;

        this.fullScreen = document.fullScreen ||
            document.mozFullScreen      ||
            document.webkitIsFullScreen ||
            (window.innerHeight == screen.height);

        var fullScreenEvent = (event) => {
            this.fullScreen = document.fullscreenElement ||
                document.mozFullscreenElement ||
                document.webkitFullscreenElement;
        };

        ['mozfullscreenchange', 'webkitfullscreenchange', 'MSFullscreenChange', 'fullscreenchange'].forEach( (eventName) => {
            document.addEventListener(eventName, fullScreenEvent);
        });

        // When 'true', the runner, when the widget requests a resize, can resize
        // the height of the iframe/canvas.
        this.canResize = true;

        // Get the iframe for javascript runs
        this.viewerPanel = this.element.querySelector("div.viewer");
        this.viewerCard = this.element.querySelector('.object-viewer');

        var iframe;
        if (this.viewerCard) {
            iframe = this.viewerCard.querySelector('iframe');
            Widget.load(iframe, this.configurations);
        }

        // Get the terminal for the run
        this.runnerCard = Util.getParents(this.element, '.object-runner.terminal')[0];
        var terminalElement = this.runnerCard.querySelector('.run-terminal .terminal');
        this.terminal = Terminal.load(terminalElement);

        var currentPanel = Util.getParents(this.element, ".tab-panel")[0];

        this.bindEvents();

        if (Util.getParameterByName('autorun') === "true") {
            this.runForm.run();
        }
    }

    static loadAll(element) {
        var runners = element.querySelectorAll('.run-viewer');
        runners.forEach( (element) => {
            Runner.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-runner-index');

        if (index) {
            return Runner._loaded[index];
        }

        return new Runner(element);
    }

    /**
     * Prepares and loads the Configuration panel.
     */
    loadConfigurations() {
        if (!this.configurationPanel) {
            return;
        }

        this.configurations = Array.prototype.map.call(this.configurationPanel.querySelectorAll("form.configuration occam-configuration"), (subElement) => {
            return Configuration.load(subElement, (data) => {
                let index = parseInt(subElement.getAttribute('data-input-index'));
                var loadedPanel = this.currentPanel();
                var iframe = loadedPanel.querySelector("iframe");
                var message = {
                    "name": "updateConfiguration",
                    "data": {
                        "index": index,
                        "name": "",
                        "values": data
                    }
                };
                if (iframe) {
                    iframe.contentWindow.postMessage(message, '*');
                }
            });
        });

        this.configurations.forEach( (configuration) => {
            let inputIndex = configuration.element.getAttribute('data-input-index');

            configuration.on('change', (data) => {
                var loadedPanel = this.currentPanel();
                var iframe = loadedPanel.querySelector("iframe");
                var message = {
                    "name": "updateConfiguration",
                    "data": {
                        "index": parseInt(inputIndex),
                        "name": "",
                        "values": data
                    }
                };
                if (iframe) {
                    iframe.contentWindow.postMessage(message, '*');
                }
            });
        });
    }

    /**
     * Returns the RunList component associated with this runner.
     */
    get runList() {
        return this._runList;
    }

    /**
     * Focus on the content of the given run list item.
     */
    focus(runListItem) {
        var panel = this.loadPanel(runListItem);
        var oldTabIndex = panel.getAttribute("tabindex");
        panel.setAttribute("tabindex", "0");
        panel.focus();
        panel.setAttribute("tabindex", oldTabIndex || "-1");
    }

    /**
     * Asynchronously cancels a run or job
     */
    cancel(runListItem) {
        var listItemInfo = this.runList.infoFor(runListItem);

        var form = runListItem.querySelector("form");

        Util.submitForm(form, (data) => {
            //runListItem.setAttribute("data-status", "done");
        });
    }

    currentPanel() {
        return this.element.querySelector(":scope > .active");
    }

    currentConfigurationPanel() {
        let currentPanel = this.currentPanel();
        if (!currentPanel) {
            return null;
        }

        return this.currentPanel().querySelector(".configuration-panel");
    }

    /**
     * Loads the build indicated by the given run list item.
     */
    loadBuild(runListItem, ignoreLog = false) {
        if (this.suppressEvents) {
            return;
        }

        var listItemInfo = this.runList.infoFor(runListItem);

        if (listItemInfo.taskID !== undefined) {
            // Load the build information
            // Create a loading pane for each build tab
            [1, 2].forEach( (index) => {
                // Always reload the manifest (tab 1) but do not reload the log
                // (tab 2) if ignore log is set.
                if (index != 2 || !ignoreLog) {
                    let panel = this.tabs.tabPanelAt(index);
                    panel.innerHTML = "";
                    panel.classList.add("loading");
                    panel.setAttribute("data-task-id", listItemInfo.taskID);
                }
            });

            let fileListing = this.tabs.element.parentNode.querySelector(".file-listing-container");
            if (fileListing) {
                let filePanel = fileListing.querySelector(".file-list-panel");
                if (filePanel) {
                    filePanel.remove();
                }
                fileListing.classList.add("loading");
            }

            // Select the manifest tab (if not on the manifest/log tab)
            if (this.tabs.selected() != 1 && this.tabs.selected() != 2) {
                this.tabs.select(1);
            }

            // Asynchronously grab the build info
            var buildingObject = this.inputObject();
            let url = buildingObject.url({ link: !!listItemInfo.staged, path: "builds/" + listItemInfo.taskID });

            Util.get(url, (html) => {
                // Pull out the manifest, terminal, and file listing
                let dummy = document.createElement("div");
                dummy.innerHTML = html;

                let manifest = dummy.querySelector(".tab-panel.build-manifest");
                if (manifest) {
                    let panel = this.tabs.tabPanelAt(1);
                    panel.classList.remove("loading");
                    panel.innerHTML = manifest.innerHTML;
                }

                let log = dummy.querySelector(".tab-panel.build-log");
                if (log && !ignoreLog) {
                    let panel = this.tabs.tabPanelAt(2);
                    panel.classList.remove("loading");
                    panel.innerHTML = log.innerHTML;

                    if (this.tabs.selected() == 2) {
                        this.loadTerminalPanel(panel);
                    }
                }

                let listing = dummy.querySelector(".file-list-panel");

                if (listing && fileListing) {
                    fileListing.classList.remove("loading");

                    // This little bugger has a habit of sneaking back in during the request
                    // Let's destroy it again, if it exists.
                    let filePanel = fileListing.querySelector(".file-list-panel");
                    if (filePanel) {
                        filePanel.remove();
                    }
                    fileListing.insertBefore(listing, fileListing.querySelector(".pending"));

                    let fileViewerElement = Util.getParents(fileListing, '.file-viewer')[0];
                    let fileViewer = FileList.load(fileViewerElement);
                    fileViewer.rebind();
                }
            });
        }
        else {
            // By default, show run/queue form
            this.tabs.select(0);
        }
    }

    /**
     * Loads the left-hand panel for the given run list item.
     */
    loadPanel(runListItem) {
        var listItemInfo = this.runList.infoFor(runListItem);

        // By default, show run/queue form
        var loadedPanel = this.element.querySelector(".run-form");
        var initialize  = false;
        var panelType   = "queue";

        // Depending on whether or not the execution being tracked is a js viewer, job or a run
        if (listItemInfo.viewerID !== undefined) {
            // Look for the loaded panel
            loadedPanel = this.element.querySelector(':scope > [data-viewer-id="' + listItemInfo.viewerID + '"]');
            panelType = "viewer";
        }
        else if (listItemInfo.runID !== undefined) {
            // Look for the loaded panel
            loadedPanel = this.element.querySelector(':scope > [data-run-id="' + listItemInfo.runID + '"]');
            panelType = "run";

            if (!loadedPanel) {
                // Look at the given run
                var workflowTemplate = this.element.querySelector("template.workflow");
                if ('content' in workflowTemplate) {
                    loadedPanel = document.importNode(workflowTemplate.content, true);
                    loadedPanel = loadedPanel.querySelector("ul.workflows");
                }
                else {
                    loadedPanel = this.workflowTemplate.querySelector("ul.workflows").cloneNode(true);
                }

                loadedPanel.setAttribute("data-run-id", listItemInfo.runID);

                var workflowElement = loadedPanel.querySelector("occam-workflow");
                workflowElement.setAttribute("data-run-id", listItemInfo.runID);
                workflowElement.setAttribute("data-run-object-id", this.inputObject().id);
                workflowElement.setAttribute("data-run-object-revision", this.inputObject().revision);

                this.element.appendChild(loadedPanel);

                initialize = true;
            }
        }
        else if (listItemInfo.jobID !== undefined) {
            // Look at the given job by opening a terminal
            loadedPanel = this.element.querySelector(':scope > [data-job-id="' + listItemInfo.jobID + '"]');
            panelType = "job";

            if (!loadedPanel) {
                // Look at the given run
                var terminalTemplate = this.element.querySelector("template.terminal");
                if ('content' in terminalTemplate) {
                    loadedPanel = document.importNode(terminalTemplate.content, true);
                    loadedPanel = loadedPanel.querySelector(".job-panel");
                }
                else {
                    loadedPanel = terminalTemplate.querySelector(".job-panel").cloneNode(true);
                }

                loadedPanel.setAttribute("data-job-id", listItemInfo.jobID);

                if (listItemInfo.taskID) {
                    loadedPanel.setAttribute("data-task-id", listItemInfo.taskID);
                }

                this.element.appendChild(loadedPanel);

                initialize = true;
            }
        }
        else if (runListItem.getAttribute("data-status") == "pending") {
            panelType = "pending";
            loadedPanel = this.element.querySelector("li.tab-panel.pending");
            if (!loadedPanel) {
                // Create a pending tab
                let template = this.element.querySelector("template.pending");
                loadedPanel = Util.createElementFromTemplate(template);
                this.element.appendChild(loadedPanel);
            }
        }

        this.element.setAttribute("data-panel-type", panelType);
        this.runList.list.parentNode.setAttribute("data-panel-type", panelType);

        var link = runListItem.querySelector("a");
        if (link.getAttribute('data-pjax') === "true") {
            // Asynchronously load the panel
            link.setAttribute('data-pjax', 'complete');

            Util.get(link.getAttribute('href'), (html) => {
                loadedPanel.classList.remove('unloaded');
                loadedPanel.innerHTML = html;

                Occam.loadAll(loadedPanel);

                // Gather the run form, if it has been loaded
                let runFormElement = loadedPanel.querySelector(".task-form");
                if (runFormElement) {
                    this.runForm = RunForm.load(runFormElement);
                }

                this.bindRunFormEvents();

                this.trigger('panel-loaded', loadedPanel)
            }, "text/html");
        }
        else {
            // Already loaded
            this.trigger('panel-loaded', loadedPanel)
        }

        if (this.loadedPanel) {
            this.loadedPanel.classList.remove("active");
            this.loadedPanel.setAttribute("aria-hidden", "true");
            this.loadedPanel.setAttribute("hidden", "");
        }
        loadedPanel.removeAttribute("hidden");
        loadedPanel.setAttribute("aria-hidden", "false");
        loadedPanel.classList.add("active");
        this.loadedPanel = loadedPanel;

        if (this.tabs) {
            // Clear merged tabs
            this.tabs.mergeWith();

            // Maybe merge the panel's tabs
            if (this.loadedPanel) {
                let subTabsElement = this.loadedPanel.querySelector(".tab-bar[data-merge]");
                if (subTabsElement) {
                    let subTabs = Tabs.load(subTabsElement);
                    subTabs.merge();
                }
            }

            if (panelType === "run") {
                var sidebarButton = this.tabs.element.querySelector(".sidebar.job-list");
                if (sidebarButton) {
                    sidebarButton.removeAttribute("hidden");
                }
            }
            else {
                var sidebarButton = this.tabs.element.querySelector(".sidebar.job-list");
                if (sidebarButton) {
                    sidebarButton.setAttribute("hidden", "");
                }
            }

            if (panelType === "queue") {
                var sidebarButton = this.tabs.element.querySelector(".sidebar.configure");
                if (sidebarButton) {
                    sidebarButton.setAttribute("hidden", "");
                }
            }
            else {
                var sidebarButton = this.tabs.element.querySelector(".sidebar.configure");
                if (sidebarButton) {
                    sidebarButton.removeAttribute("hidden");
                }
            }
        }

        if (initialize) {
            if (listItemInfo.runID !== undefined) {
                // Load the workflow
                var workflow = new Workflow(loadedPanel.querySelector("occam-workflow"));
                workflow.on("change", (runInfo) => {
                    this.runList.update(runListItem, runInfo.run);
                });

                workflow.on("sidebar", () => {
                    var sidebarButton = this.tabs.element.querySelector(".sidebar.job-list");
                    if (sidebarButton) {
                        sidebarButton.classList.remove("reveal");
                    }
                });

                new Workflow(loadedPanel.querySelector("occam-workflow.mock.run"));
            }
            else if (listItemInfo.jobID !== undefined) {
                // Load the terminal
                var terminalElement = loadedPanel.querySelector(".terminal");
                terminalElement.setAttribute("data-terminal-type", "tty");
                terminalElement.setAttribute("data-job-id", listItemInfo.jobID);

                var terminal = Terminal.load(terminalElement);
                var iframe   = loadedPanel.querySelector("iframe");
                var events   = loadedPanel.querySelector("ul.events");

                terminal.reset();

                var job = Job.load(runListItem);

                // Poll for job status
                job.on("start", (event) => {
                    var conn = job.connect( (data) => {
                        terminal.write(data);
                    });
                    terminal.on("write", (data) => {
                        conn.send(data);
                    });
                    terminal.on("resize", (data) => {
                        //job.signal(28);
                    });
                });

                job.on("event", (event) => {
                    if (event.type == "port") {
                        job.networkInfo( (networkInfo) => {
                            var port = event.data.port;
                            (networkInfo.ports || []).forEach( (portInfo) => {
                                if (portInfo.bind == event.data.port) {
                                    port = portInfo.port;
                                }
                            });

                            var eventTemplate = this.element.querySelector('template.event[data-type="port"]');
                            var eventElement = null;
                            if ('content' in eventTemplate) {
                                eventElement = document.importNode(eventTemplate.content, true);
                                eventElement = eventElement.querySelector("li.event");
                            }
                            else {
                                eventElement = eventTemplate.querySelector("li.event").cloneNode(true);
                            }

                            // Update event log entry
                            var scheme = event.data.protocol || eventElement.querySelector("span.scheme").textContent.trim();
                            var host = eventElement.querySelector("span.host").textContent.trim();
                            host = window.document.location.hostname;
                            var url = scheme + "://" + host + ":" + port + event.data.url;
                            eventElement.querySelector("a").setAttribute("href", url);
                            eventElement.querySelector("a").textContent = event.data.name || url;
                            events.appendChild(eventElement);

                            // Ok, but if the url scheme is http, then we cannot open it unless we have a proxy setup
                            var proxyURL = url;
                            if (scheme == "http" && window.document.location.protocol == "https:") {
                                proxyURL = "https://" + window.document.location.hostname + ":" + (port + 10000) + event.data.url;
                            }

                            if (event.data.open == "inline") {
                                // Hide right panel
                                this.runList.showHideList(false);

                                terminalElement.setAttribute("hidden", "");
                                events.setAttribute("hidden", "");

                                iframe.removeAttribute("hidden");
                                iframe.src = proxyURL;
                            }
                        });
                    }
                });
            }
        }

        return loadedPanel;
    }

    runData(object, runID, callback) {
    }

    jobData(object, jobID, callback) {
    }

    /**
     * Returns the current object we are using as input.
     */
    inputObject() {
        if (this.withinModal) {
            return Occam.modalObject();
        }

        return Occam.object();
    }

    width(value) {
        if (value === undefined) {
            if (this.fullScreen) {
                return window.clientWidth;
            }

            var viewerPanel = this.currentPanel();
            if (viewerPanel) {
                return viewerPanel.clientWidth;
            }

            return this.element.clientWidth;
        }

        return this;
    }

    height(value) {
        if (value === undefined) {
            if (this.fullScreen) {
                return window.clientHeight;
            }
            return this.currentPanel().clientHeight;
        }

        // Set the height
        if (value == "100%") {
            this.element.style["flex-grow"]   = "1";
            this.element.style["flex-shrink"] = "1";
            this.currentPanel().style["flex-grow"]   = "1";
            this.currentPanel().style["flex-shrink"] = "1";
        }
        else {
            this.element.style["flex-grow"]   = "";
            this.element.style["flex-shrink"] = "";

            //this.element.style.height = value + "px";
        }
        return this;
    }

    maxWidth() {
        return window.clientWidth;
    }

    maxHeight() {
        return window.clientHeight;
    }

    preferredWidth() {
        return this.maxWidth();
    }

    preferredHeight() {
        if (this.fullScreen) {
            return this.maxHeight();
        }

        return "100%";
    }

    resize(height) {
        var width = this.width();
        if (this.aspectRatio) {
            height = width / this.aspectRatio;

            if (height > this.preferredHeight()) {
                height = this.preferredHeight();
                width = height * this.aspectRatio;
            }
        }

        if (height) {
            this.height(height);
        }
    }

    /*
     * This method returns whether or not there is a server-side option
     * available.
     */
    canServerSide() {
        var ret = false;
        this.backendSelector.items().forEach( (item) => {
            if (item.getAttribute('data-run-type') === "server") {
                ret = true;
            }
        });

        return ret;
    }

    /*
     * This method returns whether or not there is a client-side option
     * available.
     */
    canClientSide() {
        var ret = false;
        this.backendSelector.items().forEach( (item) => {
            if (item.getAttribute('data-run-type') === "client") {
                ret = true;
            }
        });

        return ret;
    }

    /*
     * This method returns true when "any" is selected as a backend.
     */
    isAny() {
        return this.backendSelector.selected().getAttribute('data-icon') === "any";
    }

    /*
     * This method returns true when the given run inputs imply it will run on
     * the server.
     */
    isServerSide() {
        if (this.inputObject().type == "experiment") {
            return true;
        }

        if (this.isAny()) {
            return !this.canClientSide();
        }

        return this.backendSelector.selected().getAttribute('data-run-type') === "server";
    }

    /*
     * This method returns true when the given run inputs imply it will run on
     * the client.
     */
    isClientSide() {
        if (this.isAny()) {
            return this.canClientSide();
        }

        return this.backendSelector.selected().getAttribute('data-run-type') === "client";
    }

    targetEnvironment() {
        return this.backendSelector.selected().getAttribute('data-environment');
    }

    targetArchitecture() {
        return this.backendSelector.selected().getAttribute('data-architecture');
    }

    /*
     * This method returns the environment for the running object.
     */
    environment() {
        return this.usingAutoComplete.environment();
    }

    /*
     * This method returns the architecture for the running object.
     */
    architecture() {
        return this.usingAutoComplete.architecture();
    }

    /*
     * This method returns the backend for the running object.
     */
    backend() {
        return this.usingAutoComplete.backend();
    }

    /*
     * This method returns the list of capabilities required for this task.
     */
    capabilities() {
        return this.backendSelector.selected().getAttribute('data-capabilities').split(',');
    }

    // Invokes queuing the current configured run.
    queue() {
    }

    bindEvents() {
        if (document.querySelector('.content').classList.contains('minimal')) {
            document.addEventListener('resize', (event) => {
                this.resize(this.height());
            });
        }

        if (this.tabs) {
            if (this.tabs.element.classList.contains("build-tabs") &&
                !this.tabs.element.classList.contains("runner-bound")) {
                this.tabs.element.classList.add("runner-bound");

                this.tabs.on("change", () => {
                    this.suppressEvents = true;
                    if (this.tabs.selected() == 0) {
                        // Select the run list Queue item
                        this.tabs.element.querySelector(".sidebar.build-queue").removeAttribute('hidden');
                        this.tabs.element.querySelector(".sidebar.run-queue").setAttribute('hidden', '');
                        this.runList.element.parentNode.removeAttribute('hidden');
                        this.runList.select(0);
                    }
                    else if (this.tabs.selected() == 1 || this.tabs.selected() == 2) {
                        // Select the run list item that pertains to the current build
                        this.tabs.element.querySelector(".sidebar.build-queue").removeAttribute('hidden');
                        this.tabs.element.querySelector(".sidebar.run-queue").setAttribute('hidden', '');
                        this.runList.element.parentNode.removeAttribute('hidden');

                        // Get element
                        let taskID = this.tabs.tabPanelAt(1).getAttribute("data-task-id");
                        let listItem = this.runList.element.querySelector('[data-task-id="' + taskID + '"]');
                        this.runList.select(listItem || 1);

                        this.loadTerminalPanel(this.tabs.tabPanelAt(2));
                    }
                    else {
                        // File tabs
                        this.tabs.element.querySelector(".sidebar.build-queue").setAttribute('hidden', '');
                        this.tabs.element.querySelector(".sidebar.run-queue").removeAttribute('hidden');
                        this.runList.element.parentNode.setAttribute('hidden', '');
                    }
                    this.suppressEvents = false;
                });
            }

            this.tabs.on("sidebar", (sidebarButton) => {
                if (sidebarButton.classList.contains("job-list")) {
                    if (this.loadedPanel) {
                        var sidebar = this.loadedPanel.querySelector(".jobs.sidebar");
                        if (sidebar) {
                            sidebar.classList.toggle("reveal");
                        }

                        var terminal = this.loadedPanel.querySelector(".terminal.job-viewer");
                        if (terminal) {
                            if (sidebar.classList.contains("reveal")) {
                                terminal.setAttribute("hidden", "");
                            }
                            else {
                                terminal.removeAttribute("hidden");
                            }
                        }
                    }
                }
            });
        }


        // When a new object is selected, pull out the possible backends
        // and populate the dropdowns for Backend and Dispatch.
        // TODO: Dispatch dropdown
        if (this.usingAutoComplete) {
            this.usingAutoComplete.on('change', () => {
                var selectedObject = this.usingAutoComplete.object();
                var previous = this.backendSelector.selected();
                this.backendSelector.loading();

                selectedObject.objectInfo( (info) => {
                    // Is this a javascript widget?
                    if (info.environment == "html" && info.architecture == "javascript") {
                        // Add the javascript option
                        this.backendSelector.dropdown.querySelector('*[data-architecture="javascript"]').removeAttribute('hidden');
                    }
                    else {
                        this.backendSelector.dropdown.querySelector('*[data-architecture="javascript"]').setAttribute('hidden', true);
                    }

                    // Determine other backends
                    selectedObject.backends([["html", "javascript"]], (backends) => {
                        let selected = null;
                        backends.forEach( (backend) => {
                            let entry = this.backendSelector.dropdown.querySelector('[data-backend="' + backend + '"]');
                            if (entry) {
                                selected = entry;
                                entry.removeAttribute('hidden');
                            }
                        });

                        this.backendSelector.loading(true);
                        if (selected) {
                            this.backendSelector.select(selected);
                        }
                    });
                });
            });
        }

        // When the backend is selected, we should update the object selector
        // to filter out only objects that can be used with that backend.
        if (this.backendSelector) {
            this.backendSelector.on('change', (event) => {
            });
        }

        // When the run button is pressed, invoke the run method
        this.bindRunFormEvents();

        this.runList.on("action-view", (item) => {
            var objectURL = "";
            objectURL += "/" + item.getAttribute("data-object-id");
            objectURL += "/" + item.getAttribute("data-object-revision");
            window.open(objectURL,'_blank');
        });

        // Open the configuration panel
        this.runList.on("action-configure", (item) => {
            this.configurationPanel.classList.toggle("reveal");
        });

        // Remove the entry and its viewer
        this.runList.on("action-delete", (item) => {
            // Remove the panel
            this.currentPanel().remove();

            // Go to the queue panel
            this.runList.select(0);

            // Remove the entry
            item.remove();
        });

        // Go to fullscreen
        this.runList.on("action-fullscreen", (item) => {
            if (!this.fullScreen) {
                var currentViewer = this.currentPanel();
                if (!currentViewer) {
                    return;
                }

                if (currentViewer.requestFullscreen) {
                    currentViewer.requestFullscreen();
                }
                else if (currentViewer.mozRequestFullscreen) {
                    currentViewer.mozRequestFullscreen();
                }
                else if (currentViewer.webkitRequestFullscreen) {
                    currentViewer.webkitRequestFullscreen();
                }
                else {
                    currentViewer.style.position = "fixed";
                    currentViewer.style.left = 0;
                    currentViewer.style.right = 0;
                    currentViewer.style.top = 0;
                    currentViewer.style.bottom = 0;
                    currentViewer.style.zIndex = 9999999999;
                    currentViewer.style.height = "100%";
                    currentViewer.style.width  = "100%";

                    let fullScreenCancelEvent = (event) => {
                        if (event.key == "Escape" || event.code == "Escape" || event.keyCode == 27) {
                            document.body.removeEventListener("keydown", fullScreenCancelEvent);
                            currentViewer.style.position = "";
                            currentViewer.style.left = "";
                            currentViewer.style.right = "";
                            currentViewer.style.top = "";
                            currentViewer.style.bottom = "";
                            currentViewer.style.zIndex = "";
                            currentViewer.style.height = "";
                            currentViewer.style.width = "";
                            this.fullScreen = false;
                        }
                    };

                    document.body.addEventListener("keydown", fullScreenCancelEvent);
                }
                currentViewer.focus();
            }
            this.fullScreen = !this.fullScreen;
        });
    }

    /**
     * Opens the configuration panel for the current running widget.
     */
    openConfigurationPanel() {
    }

    /**
     * Closes the configuration panel for the current running widget.
     */
    closeConfigurationPanel() {
    }

    /**
     * Opens or closes the configuration panel for the current running widget.
     */
    toggleConfigurationPanel() {
    }

    /**
     * Creates a view for the given task or partial task and queues it for run.
     */
    run(task) {
        let accepted = true;

        // Check for EULA acceptance
        if (task.eulas.length > 0) {
            for (var i = 0; i < task.eulas.length; i++) {
                let eula = task.eulas[i];

                if (!eula.accepted) {
                    accepted = false;

                    // Show modal
                    // Bring up a modal for the EULA
                    Modal.open(new OccamObject(eula.id, eula.revision).url({
                        path: "/eula"
                    }), {
                        onSubmit: (event) => {
                            event.stopPropagation();
                            event.preventDefault();

                            // Mark this EULA as accepted and continue.
                            eula.accepted = true;

                            // Close the modal.
                            Modal.close();

                            // Attempt to run this object again.
                            this.run(task);
                        },
                        onClose: () => {
                        }
                    });

                    // Don't run
                    return;
                }
            }
        }

        // If we cannot run for some reason, abort.
        if (!accepted) {
            return;
        }

        // Run
        if (task.backend === "web-browser") {
            this.runBrowserTask(task);
        }
        else {
            this.runServerTask(task);
        }
    }

    /**
     * Spawns a task for a browser-based widget.
     */
    runBrowserTask(task) {
        // We will query for a task manifest based on the given task object.

        // Hide right panel
        this.runList.showHideList(false);

        // Hide run form (if needed)
        var runForm = this.element.querySelector(".run-form");
        runForm.classList.remove('active');

        // We need to clone the viewerPanel to get a browser viewer pane
        var newViewer;
        var viewerTemplate = this.element.querySelector("template.viewer");
        if ('content' in viewerTemplate) {
            newViewer = document.importNode(viewerTemplate.content, true);
            newViewer = newViewer.querySelector("li.viewer");
        }
        else {
            newViewer = viewerTemplate.querySelector("li.viewer").cloneNode(true);
        }

        // Assign a unique viewer-id for this Runner.
        var nextViewerId = this.element.querySelectorAll(".viewer[data-viewer-id]").length;
        newViewer.setAttribute('data-viewer-id', nextViewerId);
        var iframe = newViewer.querySelector('iframe');

        // Now, we determine what will be running within this iframe

        // The object we are running is task.items[0]
        // The input to that object is in task.items[0].viewing, if it exists at all
        // If this object is running within some other known Provider, it will
        //   be task.items[1] and so on...

        const running = task.items[0];
        let input = null;
        if (task.items[0].viewing) {
            input = task.items[0].viewing;
        }
        else if (task.items[0].inputs && task.items[0].inputs[0]) {
            input = task.items[0].inputs[0] && task.items[0].inputs[0][0];
        }

        const inputId = input && input.id;
        const inputRevision = input && input.revision;
        const inputFile = input && input.file;
        const inputToken = input && input.token;
        const inputLink = input && input.link;

        if (inputId) {
            iframe.setAttribute("data-input-object-id", inputId);
        }

        if (inputRevision) {
            iframe.setAttribute("data-input-object-revision", inputRevision);
        }

        if (inputFile) {
            iframe.setAttribute("data-input-file", inputFile);
        }

        if (inputToken) {
            iframe.setAttribute("data-token", inputToken);
        }

        if (inputLink) {
            iframe.setAttribute("data-link", inputLink);
        }

        if (input && input.build && input.build.id) {
            iframe.setAttribute("data-input-build-id", input.build.id);
        }

        iframe.src = "";
        newViewer.classList.add('active');
        this.element.appendChild(newViewer);

        // Load occam general events (mostly for separator)
        Occam.loadAll(newViewer);

        // Bind panel split events
        this.bindSplitEvents(newViewer);

        // Create an entry in the run list
        var entry = this.runList.append({
            status: "pending",
            action: "viewing",
            type: "generating",
            name: "task"
        });
        entry.setAttribute("data-viewer-id", nextViewerId);
        this.runList.select(entry);

        // TODO: this should be a part of the run-form
        var path = iframe.getAttribute('data-input-file');

        var options = {};
        options["toEnvironment"]  = "html";
        options["toArchitecture"] = "javascript";

        //if (iframe.getAttribute("data-input-object-id")) {
        //    options.inputs = iframe.getAttribute("data-input-object-id") + "@" + iframe.getAttribute("data-input-object-revision");
        //}

        options.fromObject = task.items[0].id;
        options.fromRevision = task.items[0].revision;

        // TODO: make more sophisticated
        if (input) {
            options.inputs = input.fullID;
        }

        task.items.slice(1).forEach( (item) => {
            options.using = item.fullID;
        });

        var objectURL = "/task";
        Util.get(objectURL, (data) => {
            var objects = data.running[data.running.length - 1].objects;
            var object  = objects[objects.length - 1];

            // Add Tokens into the task
            let current = object;
            task.items.slice().reverse().forEach( (item) => {
                if (current) {
                    current.token = item.token;
                    current.link = item.link;

                    if (item.viewing) {
                        // TODO: obviously, fix this when the input indexes are added
                        current.inputs[0].connections[0].token = item.viewing.token;
                        current.inputs[0].connections[0].link = item.viewing.link;
                    }
                }

                if (current.running) {
                    objects = current.running[current.running.length - 1].objects;
                    current = objects[objects.length - 1];
                }
                else {
                    current = null;
                }
            });

            this.taskInfo = object;

            // Set iframe object information
            iframe.setAttribute('data-object-id',       object.id);
            iframe.setAttribute('data-object-revision', object.revision);
            iframe.setAttribute('data-object-name',     object.name);
            iframe.setAttribute('data-object-type',     object.type);

            var entryObject = task.items[0];
            let configurations = (object.inputs || []).some( (inputInfo) => inputInfo.type === "configuration" );

            this.runList.update(entry, {
                status: "viewing",
                name: entryObject.name,
                type: entryObject.type,
                id: entryObject.id,
                revision: entryObject.revision,
                configurations: configurations,
            });

            // Update iframe url
            var widgetURL = "/" + object.id + "/" + object.revision + "/raw/" + object.file;
            if (iframe.hasAttribute('data-trusted-host')) {
                widgetURL = window.document.location.protocol + "//" + iframe.getAttribute('data-trusted-host') + widgetURL;
            }
            iframe.setAttribute('src', widgetURL);
            iframe.setAttribute('tabindex', '1000');
            iframe.focus();

            // Load configurations, if any
            this.configurations = [];

            (new Promise( (resolve) => {
                if (configurations) {
                    // Load viewer configurations
                    var widgetViewURL = "/" + object.id + "/" + object.revision + "/view";
                    Util.get(widgetViewURL, (html) => {
                        let dummy = document.createElement("div");
                        dummy.innerHTML = html;
                        let configurations = dummy.querySelector(".configuration-panel");
                        this.configurationPanel = this.currentConfigurationPanel();
                        this.configurationPanel.innerHTML = configurations.innerHTML;
                        Occam.loadAll(this.configurationPanel);

                        this.loadConfigurations();

                        resolve();
                    });
                }
                else {
                    resolve();
                }
            })).then( () => {
                // XXX: fix
                var widget = Widget.load(iframe, this.configurations);
                widget.taskInfo = object;
                widget.sendUpdateTask();
            });
        }, "json", options);
    }

    /**
     * Starts a server-side task.
     */
    runServerTask(task) {
        // Hide right panel
        this.runList.showHideList(false);

        // Hide run form
        var runForm = this.element.querySelector(".run-form");
        runForm.classList.remove('active');

        // Get the phase we want to execute
        let phase = task.items[0].phase || "run";
        let link = task.items[0].link;

        // Create an entry in the run list (if needed)
        var entry = null;
        if (link && task.submit != "submit-published") {
            // For a staged run/build, we use the existing run list item
            entry = this.runList.elementFor(1);
        }
        else {
            // Otherwise, we create a list item to represent the job
            entry = this.runList.append({
                status: "pending",
                action: "running",
                phase: phase,
                type: task.items[0].type,
                name: task.items[0].name,
                id: task.items[0].id,
                revision: task.items[0].revision,
            });
        }
        this.runList.select(entry);

        var options = {};

        options.fromObject = task.items[0].id;
        options.fromRevision = task.items[0].revision;

        if (task.items[0].viewing) {
            options.inputs = task.items[0].viewing.fullID;
        }

        let path = "";

        if (path) {
            options.path = path;
        }

        let object = new OccamObject(task.items[0].id, task.items[0].revision, "", "", null, null, task.items[0].link, task.items[0].token);

        let url = object.url({path: "/runs"});
        if (phase === "build") {
            url = object.url({path: "/builds"});
        }

        // Get whether or not a commit should happen
        if (task.submit == "commit") {
            options.commit = true;
        }

        if (task.submit == "from-clean") {
            options.fromClean = true;
        }

        // Whether or not the published (as opposed to the staged) version
        // should be targeted.
        if (task.submit == "submit-published") {
            options.published = true;
        }

        if (task.dispatchTo) {
            options.target = task.dispatchTo;
        }

        if (phase === "build") {
            //this.loadPanel(entry);
        }

        // Generate a job
        Util.post(url, options, (data) => {
            var fakeNode = document.createElement("div");
            fakeNode.innerHTML = data;
            var newEntry = fakeNode.querySelector("li");
            newEntry = this.runList.replace(entry, newEntry);

            // Replace build panels
            if (phase === "build") {
                // And select the log
                this.tabs.select(2);
            }

            var info = this.runList.infoFor(newEntry);
            if (info.jobID !== undefined) {
                var job = Job.load(newEntry);

                job.on("done", (info) => {
                    if (info.job.status == "failed") {
                        newEntry.setAttribute("data-status", "failed");
                    }
                    else if (info.job.finishTime) {
                        newEntry.setAttribute("data-status", "done");
                    }

                    // If we are looking at the in-progress build and it finished,
                    // then reload the metadata and file listing containers.
                    if (phase === "build" && this.runList.selected() == this.runList.indexFor(newEntry)) {
                        // Update the build file listing and metadata sections
                        this.loadBuild(newEntry, true);
                    }
                });
            }
        });
    }

    /**
     * Establishes events for the run button.
     */
    bindRunFormEvents() {
        if (this.runForm) {
            this.runForm.on("run.runner", (data) => {
                this.run(data);
            });
        }
    }

    loadTerminalPanel(panel) {
        // Load terminal, if needed

        // Get element
        let taskID = this.tabs.tabPanelAt(1).getAttribute("data-task-id");
        let listItem = this.runList.element.querySelector('[data-task-id="' + taskID + '"]');

        // If it must be a job terminal, and not a build log,
        // adjust as needed.
        let listItemInfo = this.runList.infoFor(listItem);
        let terminalElement = panel.querySelector(".terminal")
        if (terminalElement && !terminalElement.hasAttribute("data-terminal-id")) {
            let job = null;
            if (listItemInfo.status == "running") {
                let jobID = listItemInfo.jobID;
                terminalElement.setAttribute("data-terminal-type", "tty");
                terminalElement.setAttribute("data-job-id", jobID);
                panel.setAttribute("data-job-id", jobID);

                job = Job.load(listItem);
            }

            let terminal = Terminal.load(terminalElement);

            terminal.reset();

            // Poll for job status
            if (job) {
                job.on("start", (event) => {
                    var conn = job.connect( (data) => {
                        terminal.write(data);
                    });
                    terminal.on("write", (data) => {
                        conn.send(data);
                    });
                    terminal.on("resize", (data) => {
                        //job.signal(28);
                    });
                });
            }

            terminal.open();
        }
    }

    /* Establishes events for the split panel.
    */
    bindSplitEvents(viewer) {
        var splitSelectorElement = viewer.querySelector("select.selector");
        var splitSelector = Selector.load(splitSelectorElement);
        splitSelector.clear();
    }
}

Runner._loaded = {};
Runner._count  = 0;

export default Runner;
