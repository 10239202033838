"use strict";

import AutoComplete        from './auto_complete.js';
import Dropdown            from './dropdown.js';
import Util                from './util.js';
import Card                from './card.js';
import NavigationList      from './navigation_list.js';
import Selector            from './selector.js';
import ButtonSelector      from './button_selector.js';
import Calendar            from './calendar.js';
import Markdown            from './markdown.js';
import Modal               from './modal.js';
import Header              from './header.js';
import Locator             from './locator.js';
import Tooltip             from './tooltip.js';
import Terminal            from './terminal.js';
import TagInput            from './tag_input.js';
import Tabs                from './tabs.js';
import Index               from './index.js';
import Gallery             from './gallery.js';
import Search              from './search.js';
import FileList            from './file_list.js';
import HelpPanel           from './help_panel.js';
import SocialPanel         from './social_panel.js';
import PublishPanel        from './publish_panel.js';
import Runner              from './runner.js';
import Widget              from './widget.js';
import RunList             from './run_list.js';
import Viewer              from './viewer.js';
import JobsViewer          from './jobs_viewer.js';
import Workflow            from './workflow.js';
import Configuration       from './configuration.js';
import ConfigurationPanel  from './configuration_panel.js';
import ThemeManager        from './theme.js';
import OccamObject         from './occam_object.js';
import PermissionsPanel    from './permissions_panel.js';
import Collection          from './collection.js';
import MetadataModal       from './objects/metadata_modal.js';
import EditModal           from './objects/edit_modal.js';
import NewModal            from './objects/new_modal.js';
import SelectModal         from './objects/select_modal.js';
import AddIOModal          from './objects/add_io_modal.js';
import AddDependencyModal  from './objects/add_dependency_modal.js';
import AddRequirementModal from './objects/add_requirement_modal.js';
import AddResourceModal    from './objects/add_resource_modal.js';
import ObjectTree          from './object_tree.js';
import ObjectList          from './object_list.js';
import NavigationState     from './navigation_state.js';

/**
 * This is the main class for the site.
 */
class Occam {
    static loadAll(element) {
        // Ensure Navigation State is loaded
        NavigationState.load();

        // Apply scrollbars
        var containers = element.querySelectorAll(".perfect-scrollbar");
        containers.forEach(function(container) {
            new window.PerfectScrollbar(container);
        });

        // Apply copy links
        var copyActions = window.document.querySelectorAll("a.copy");

        copyActions.forEach(function(element) {
            element.removeAttribute("hidden");

            // When we click on this, add the contents of the "data-value" to the clipboard
            element.addEventListener("click", (event) => {
                event.preventDefault();
                event.stopPropagation();
                Util.copyToClipboard(window.location.protocol + "//" + window.location.host + element.getAttribute("data-url"));
                let tooltip = Tooltip.load(element);
                if (tooltip) {
                    tooltip.show("data-copied-tooltip");
                }
            });
        });

        FileList.loadAll(element);
        AutoComplete.loadAll(element);
        Selector.loadAll(element);
        ButtonSelector.loadAll(element);
        Calendar.loadAll(element);
        Card.loadAll(element);
        Configuration.loadAll(element);
        ConfigurationPanel.loadAll(element);
        //Configurator.loadAll(element);
        Dropdown.loadAll(element);
        Header.loadAll(element);
        Locator.loadAll(element);
        HelpPanel.loadAll(element);
        Modal.loadAll(element);
        NavigationState.loadAll(element);
        ObjectTree.loadAll(element);
        ObjectList.loadAll(element);
        PermissionsPanel.loadAll(element);
        Collection.loadAll(element);
        EditModal.loadAll(element);
        MetadataModal.loadAll(element);
        NewModal.loadAll(element);
        NavigationList.loadAll(element);
        SelectModal.loadAll(element);
        AddIOModal.loadAll(element);
        AddRequirementModal.loadAll(element);
        AddDependencyModal.loadAll(element);
        AddResourceModal.loadAll(element);
        Widget.loadAll(element);
        Runner.loadAll(element);
        Viewer.loadAll(element);
        JobsViewer.loadAll(element);
        SocialPanel.loadAll(element);
        PublishPanel.loadAll(element);
        Search.loadAll(element);
        //Storage.loadAll(element);
        TagInput.loadAll(element);
        Tabs.loadAll(element);
        Index.loadAll(element);
        ThemeManager.loadAll(element);
        Tooltip.loadAll(element);
        Markdown.loadAll(element);
        Workflow.loadAll(element);
        Gallery.loadAll(element);
    }

    /**
     * Loads modules when a tab is shown for the first time.
     *
     * This is useful when such modules require being visible in order to
     * calculate metrics (width/height/text).
     *
     * @param {HTMLElement} element The tab panel element.
     */
    static loadTabPanel(element) {
        if (element.hasAttribute("data-tab-modules-loaded")) {
            return;
        }

        element.setAttribute("data-tab-modules-loaded", "");

        Terminal.loadAll(element);
    }

    static load(module, index) {
        switch(module) {
            case "Tabs":
                return Tabs.load(index);
            default:
                break;
        }

        return null;
    }

    /**
     * Event handling for when the window is resized.
     */
    static resize(element, event) {
        if (element == document.body && Occam._resizeTimer) {
            window.clearTimeout(Occam._resizeTimer);
        }

        let perform = () => {
            // Re-Apply scrollbars
            var containers = element.querySelectorAll(".perfect-scrollbar");
            containers.forEach(function(container) {
                new window.PerfectScrollbar(container);
            });
        };

        if (element == document.body) {
            Occam._resizeTimer = window.setTimeout(perform, 200);
        }
        else {
            perform();
        }
    }

    static modalObject() {
        // Is there a modal?
        let modal = document.querySelector(".modal-window");

        if (!modal) {
            // If not, there is no object.
            return null;
        }

        // Get id, revision, etc
        let id       = modal.querySelector("#modal-object-id").textContent.trim();
        let revision = modal.querySelector("#modal-object-revision").textContent.trim();
        let type     = modal.querySelector("#modal-object-type").textContent.trim();

        let fileElement = modal.querySelector("#modal-object-file");
        let file        = null;
        if (fileElement) {
            file = fileElement.textContent.trim();
        }

        let pathElement = modal.querySelector("#modal-object-path");
        let path        = null;
        if (pathElement) {
            path = pathElement.textContent.trim();
        }

        let indexElement = modal.querySelector("#modal-object-index");
        let index        = null;
        if (indexElement) {
            index = indexElement.textContent.trim();
        }

        let name     = modal.querySelector("#modal-object-name").textContent.trim();

        let ret = new OccamObject(id, revision, type, name, file, index);

        return ret;
    }

    static object() {
        if (!Occam._object) {
            // Initialize the object
            /* jshint ignore:start */
            Occam._object = new OccamObject();
            /* jshint ignore:end */
        }

        return Occam._object;
    }

    /**
     * Returns the original document title.
     */
    static title() {
        return Occam._title;
    }
}

Occam._object = null;
Occam._title  = document.title;
Occam._resizeTimer = null;

export default Occam;
