"use strict";

/**
 * This class contains useful functions mostly related to DOM exploration and
 * ajax requests.
 */
export class Util {
    /**
     * This will create a new element from a template.
     *
     * This assumes the template has exactly one root child, which will be
     * instantiated and returned.
     *
     * @param {HTMLElement} template The template element.
     *
     * @returns {HTMLElement} The new element.
     */
    static createElementFromTemplate(template) {
        let ret = null;
        if ('content' in template) {
            ret = document.importNode(template.content, true);
            ret = ret.children[0];
        }
        else {
            ret = template.children[0].cloneNode(true);
        }

        return ret;
    }

    /**
     * Decodes a "URL-Safe" base64 value.
     *
     * @param {string} value - The base64 value to decode.
     *
     * @returns {string} The decoded value.
     */
    static decodeURLSafeBase64(value) {
        return atob(value.replace(/_/g, '/').replace(/-/g, '+'));
    }

    /**
     * Encodes a "URL-Safe" base64 value.
     *
     * @param {string} value - The value to encode.
     *
     * @returns {string} The encoded value.
     */
    static encodeURLSafeBase64(value) {
        return btoa(value).replace(/[/]/g, '_').replace(/[+]/g, '-');
    }

    /**
     * This will change the tag for the given element.
     *
     * @param {HTMLElement} element The existing element.
     * @param {string} tagName The new tag.
     *
     * @returns {HTMLElement} The new element.
     */
    static replaceTag(element, tagName) {
        let newElement = document.createElement(tagName);

        Array.prototype.slice.call(element.attributes).forEach( (attribute) => {
            newElement.setAttribute(attribute.name, attribute.value);
        });

        newElement.innerHTML = element.innerHTML;

        element.parentNode.replaceChild(newElement, element);

        return newElement;
    }

    /**
     * This does string formatting.
     */
    static formatString(input, options) {
        Object.keys(options).forEach( (key) => {
            input = input.replace(new RegExp("(^|[^\\\\])[{]" + key + "[}]"), "$1" + options[key]);
        });

        return input;
    }

    /**
     * This will submit the given form.
     *
     * @param {HTMLFormElement} form The `<form>` element to submit.
     *
     * @returns {XMLHttpRequest} The ajax request object.
     */
    static submitForm(form, data_or_callback, callback, responseType) {
        var data = data_or_callback;
        if (callback === undefined) {
            callback = data_or_callback;
            data = undefined;
        }

        var formdata = new FormData(form);

        if (data) {
            data.forEach( (tuple) => {
                formdata.set(tuple[0], tuple[1]);
            });
        }

        // Sending an empty formdata triggers an error in multipart parsing in
        // rails. So here we check to see if the formdata is empty and set it
        // to null if so.
        if (formdata.entries().next().done) {
            formdata = null;
        }

        var method = form.getAttribute("method") || "GET";
        var url = form.getAttribute("action");

        if (formdata && method.toUpperCase() == "GET") {
            let params = [...formdata.entries()].map(e => encodeURIComponent(e[0]) + "=" + encodeURIComponent(e[1]));
            url = url + "?" + params.join("&");
        }

        return Util.ajax(method, url, formdata, callback, responseType);
    }

    /**
     * This will issue an ajax request. Generally, the functions get() and
     * post() are more convenient.
     *
     * @param {string} method The HTTP method ("GET", "POST") to use.
     * @param {string} url The URL to request.
     * @param {string} data The data to send.
     * @param {ajaxCallback} callback The callback function when the request is successful.
     * @param {string} responseType The type of response to ask for as the Accept parameter.
     *                              If this is "json" or "application/json", the callback
     *                              will automatically receive parsed JSON and fail if the
     *                              JSON is unacceptable.
     *
     * @returns {XMLHttpRequest} The ajax request object.
     */
    static ajax(method, url, data, callback, responseType) {
        var oReq = new XMLHttpRequest();

        // This ensures that the browser doesn't cache a partial ajax request as the
        // actual page. If you don't do this, the browser may respond to a back button
        // or page reload with the response of the ajax request if the URL is the
        // same. Kinda baffling it doesn't cache XML Request stuff separate really!!
        if (method == "GET") {
            if (url.includes("?")) {
                url = url + "&_ajax";
            }
            else {
                url = url + "?_ajax";
            }
        }

        if (callback) {
            if (callback.onprogress) {
                (oReq.upload || oReq).addEventListener("progress", (event) => {
                    callback.onprogress(event);
                });
            }
            oReq.addEventListener("load", (event) => {
                if (oReq.readyState == 4 && oReq.status == 200) {
                    var response = oReq.responseText;

                    if (responseType == "application/json" || responseType == "json") {
                        // TODO: handle errors
                        response = JSON.parse(response);
                    }

                    if (callback.onload) {
                        callback.onload(response);
                    }
                    else if (typeof callback === "function") {
                        callback(response);
                    }
                }
                else if (oReq.readyState == 4 && oReq.status == 422) {
                    var response = oReq.responseText;

                    if (responseType == "application/json" || responseType == "json") {
                        // TODO: handle errors
                        response = JSON.parse(response);
                    }

                    if (callback.onerror) {
                        callback.onerror(response);
                    }
                }
                else if (oReq.readyState == 4 && oReq.status >= 400) {
                    if (callback.onerror) {
                        callback.onerror(response);
                    }
                }
            });
        }

        oReq.open(method, url);
        oReq.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

        if (responseType == "json" || responseType == "application/json") {
            oReq.setRequestHeader('Accept', 'application/json');
        }
        else if (responseType) {
            oReq.setRequestHeader('Accept', responseType);
        }

        if (data) {
            if ((typeof data) == "string") {
                oReq.setRequestHeader('Content-Type', 'application/octet-stream');
            }
            oReq.send(data);
        }
        else {
            oReq.send();
        }

        return oReq;
    }

    /**
     * This will issue an ajax GET request.
     *
     * @param {string} url The URL to request.
     * @param {ajaxCallback} callback The callback function when the request is successful.
     * @param {string} responseType The type of response to ask for as the Accept parameter.
     *                              If this is "json" or "application/json", the callback
     *                              will automatically receive parsed JSON and fail if the
     *                              JSON is unacceptable.
     * @param {Object} options The query parameters to send along with the request.
     *
     * @returns {XMLHttpRequest} The ajax request object.
     */
    static get(url, callback, responseType, options) {
        // Decode any query parameters in the url
        var currentOptions = Util.getParameters(url);
        url = url.split('?', 1)[0];
        options = Object.assign(currentOptions, options);

        if (options) {
            let params = Object.entries(options).map(e => encodeURIComponent(e[0]) + "=" + encodeURIComponent(e[1]));
            url = url + "?" + params.join("&");
        }

        return Util.ajax("GET", url, null, callback, responseType);
    }

    /**
     * This will issue an ajax GET request and expect JSON data back.
     *
     * @param {string} url The URL to request.
     * @param {ajaxCallback} callback The callback function when the request is successful.
     * @param {Object} options The query parameters to send along with the request.
     *
     * @returns {XMLHttpRequest} The ajax request object.
     */
    static getJSON(url, callback, options) {
        return Util.get(url, callback, "json", options);
    }

    /**
     * This will issue an ajax POST request.
     *
     * @param {string} url The URL to request.
     * @param {string|Object} data The data to send. If it is an Object, then it will
     *                             send the data as a FormData.
     * @param {ajaxCallback} callback The callback function when the request is successful.
     * @param {string} responseType The type of response to ask for as the Accept parameter.
     *                              If this is "json" or "application/json", the callback
     *                              will automatically receive parsed JSON and fail if the
     *                              JSON is unacceptable.
     *
     * @returns {XMLHttpRequest} The ajax request object.
     */
    static post(url, data, callback, responseType) {
        var postData;

        if (data) {
            if ((typeof data) == "string") {
                postData = data;
            }
            else {
                postData = new FormData();
                Object.keys(data).forEach(function(key) {
                    postData.set(key, data[key]);
                });
            }
        }

        return Util.ajax("POST", url, postData, callback, responseType);
    }

    /**
     * This function retrieves the query value from the given url.
     *
     * @param {string} name The query key to look for.
     * @param {string} [url] The URL to parse. If not given, then it will use
     *                       the current location.
     * @returns {string} If found, the value for the given key is given.
     */
    static getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    /**
     * This function retrieves all query values from the given url.
     *
     * @param {string} [url] The URL to parse. If not given, then it will use
     *                       the current location.
     */
    static getParameters(url) {
        if (!url) url = window.location.href;

        var query = url.split('?')[1];
        var ret = {};
        if (query) {
            var vars = query.split('&');
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=');
                ret[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
            }
        }
        return ret;
    }

    /**
     * This function returns the position of the given element within its parent.
     *
     * Optionally, a filter can be provided, and it will get the index of the
     * element among the list of sibling elements that match that filter.
     *
     * @param {HTMLElement} element The element to query the index.
     * @param {string} [filter] The optional css selector to use to filter the
     *                           sibling elements.
     *
     * @returns {number} The index of the element or -1 if the element doesn't
     *                   exist in the given filter.
     */
    static getChildIndex(element, filter) {
        if (filter) {
            return (Array.prototype.indexOf.call(element.parentNode.querySelectorAll(":scope > " + filter), element));
        }

        return (Array.prototype.indexOf.call(element.parentNode.children, element));
    }

    /**
     * Returns an array of HTMLElement items that consist of the parents of the
     * given element. These will be the ancestors in order from closest to the
     * document root at the end.
     *
     * If a parentSelector is given, the parents will be retrieved up until the
     * ancestor that matches the given selector.
     *
     * If a filter is given, the parent list will be truncated to only those
     * that match the given filter.
     *
     * @param {HTMLElement} element The element to initialize the query.
     * @param {string} [parentSelector] The css selector to match against to stop
     *                                  the function.
     * @param {string} [filter] The css selector to match against to filter the
     *                          results.
     *
     * @returns {Array} A list containing zero or more HTMLElement items.
     */
    static getParents(element, parentSelector, filter) {
        // If no parentSelector defined will bubble up all the way to *document*
        if (parentSelector === undefined) {
            parentSelector = document;
        }

        if (filter === undefined) {
            filter = false;
        }

        var parents = [];
        var p = element.parentNode;

        while (p && p.matches && !(p.matches(parentSelector))) {
            var o = p;
            if (!filter) {
                parents.unshift(o);
            }
            p = o.parentNode;
        }

        if (p.matches) {
            parents.unshift(p);
        }

        return parents;
    }

    // From https://stackoverflow.com/questions/36721830/convert-hsl-to-rgb-and-hex

    /**
     * Converts an HSL color value to RGB. Conversion formula
     * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
     * Assumes h, s, and l are contained in the set [0, 1] and
     * returns r, g, and b in the set [0, 255].
     *
     * @param   {number} h The hue.
     * @param   {number} s The saturation.
     * @param   {number} l The lightness.
     * @return  {Array}    The RGB representation.
     */
    static hslToRgb(h, s, l) {
        var r, g, b;

        if (s == 0) {
            r = g = b = l; // achromatic
        } else {
            var hue2rgb = function hue2rgb(p, q, t) {
                if(t < 0) t += 1;
                if(t > 1) t -= 1;
                if(t < 1/6) return p + (q - p) * 6 * t;
                if(t < 1/2) return q;
                if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
                return p;
            };

            var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            var p = 2 * l - q;
            r = hue2rgb(p, q, h + 1/3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1/3);
        }

        return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    }

    /**
     * Copies the given text to the clipboard.
     *
     * @param {string} text The text to copy.
     */
    static copyToClipboard(text) {
        if (window.clipboardData && window.clipboardData.setData) {
            // IE specific code path to prevent textarea being shown while dialog is visible.
            return window.clipboardData.setData("Text", text); 

        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy");  // Security exception may be thrown by some browsers.
            } catch (ex) {
                window.console.warn("Copy to clipboard failed.", ex);
                return false;
            } finally {
                document.body.removeChild(textarea);
            }
        }
    }

    /**
     * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
     * 
     * @param {string} text The text to be rendered.
     * @param {string} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
     * 
     * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
     */
    static getTextWidth(text, font) {
        // re-use canvas object for better performance
        var canvas = Util._canvas || (Util._canvas = document.createElement("canvas"));
        var context = canvas.getContext("2d");
        context.font = font;
        var metrics = context.measureText(text);
        return metrics.width;
    }

    /**
     * Returns the standard key code string for the given event.
     *
     * @param {KeyboardEvent} event The keyboard event to pull the key information out of.
     *
     * @returns {string} The canonical key string.
     */
    static canonizeKey(event) {
        if (event.code) {
            return event.code;
        }

        if (!(Util.KEY_TRANSLATION_TABLE[event.keyCode])) {
            return "Unknown";
        }

        // Shift
        if (event.keyCode == 16 && event.location == 2) {
            return "ShiftRight";
        }

        // Control
        if (event.keyCode == 17 && event.location == 2) {
            return "ControlRight";
        }

        // Alt
        if (event.keyCode == 18 && event.location == 2) {
            return "AltRight"; // the worst key
        }

        // Numpad Enter
        if (event.keyCode == 13 && event.location == 3) {
            return "NumpadEnter";
        }

        // Panic and attempt to map it correctly
        // It generally won't, though...
        return Util.KEY_TRANSLATION_TABLE[event.keyCode];
    }

    static toFriendlyFilesize(bytes) {
        var ret = null;

        Object.entries({
            'B'   : 1024,
            'KiB' : 1024 * 1024,
            'MiB' : 1024 * 1024 * 1024,
            'GiB' : 1024 * 1024 * 1024 * 1024,
            'TiB' : 1024 * 1024 * 1024 * 1024 * 1024
        }).map(function(item) {
            var e = item[0];
            var s = item[1];
            if (!ret && bytes < s) {
                ret = {
                    value: (s == 1024 ? bytes : Math.round(bytes / (s / 1024), 2)),
                    units: e
                };
            }
        });

        if (!ret) {
            ret = {
                value: bytes,
                units: "B"
            };
        }

        return ret;
    }
}

Util.KEY_TRANSLATION_TABLE = {
    8: "Backspace",
    9: "Tab",
    13: "Enter",
    16: "ShiftLeft",
    17: "ControlLeft",
    18: "AltLeft",
    19: "Pause",
    20: "CapsLock",
    27: "Escape",
    32: "Space",
    33: "PageUp",
    34: "PageDown",
    35: "End",
    36: "Home",
    37: "ArrowLeft",
    38: "ArrowUp",
    39: "ArrowRight",
    40: "ArrowDown",
    44: "PrintScreen",
    45: "Insert",
    46: "Delete",
    48: "Digit0",
    49: "Digit1",
    50: "Digit2",
    51: "Digit3",
    52: "Digit4",
    53: "Digit5",
    54: "Digit6",
    55: "Digit7",
    56: "Digit8",
    57: "Digit9",
    65: "KeyA",
    66: "KeyB",
    67: "KeyC",
    68: "KeyD",
    69: "KeyE",
    70: "KeyF",
    71: "KeyG",
    72: "KeyH",
    73: "KeyI",
    74: "KeyJ",
    75: "KeyK",
    76: "KeyL",
    77: "KeyM",
    78: "KeyN",
    79: "KeyO",
    80: "KeyP",
    81: "KeyQ",
    82: "KeyR",
    83: "KeyS",
    84: "KeyT",
    85: "KeyU",
    86: "KeyV",
    87: "KeyW",
    88: "KeyX",
    89: "KeyY",
    90: "KeyZ",
    93: "ContextMenu",
    96: "Numpad0",
    97: "Numpad1",
    98: "Numpad2",
    99: "Numpad3",
    100: "Numpad4",
    101: "Numpad5",
    102: "Numpad6",
    103: "Numpad7",
    104: "Numpad8",
    105: "Numpad9",
    106: "NumpadMultiply",
    107: "NumpadAdd",
    109: "NumpadSubtract",
    110: "NumpadDecimal",
    111: "NumpadDivide",
    112: "F1",
    113: "F2",
    114: "F3",
    115: "F4",
    116: "F5",
    117: "F6",
    118: "F7",
    119: "F8",
    120: "F9",
    121: "F10",
    122: "F11",
    123: "F12",
    144: "NumLock",
    145: "ScrollLock",
    186: "Semicolon",
    187: "Equal",
    188: "Comma",
    189: "Minus",
    190: "Period",
    191: "Slash",
    192: "Backquote",
    219: "BracketLeft",
    220: "Backslash",
    221: "BracketRight",
    222: "Quote",
};

export default Util;
