"use strict";

import EventComponent from './event_component.js';
import Util           from './util.js';
import Occam          from './occam.js';
import Tabs           from './tabs.js';

class PublishPanel extends EventComponent {
    constructor(element) {
        super();

        // element is the .publish.sidebar on some output page
        if (element === undefined) {
            return;
        }

        this.element = element;
        this.collapse = element.parentNode.querySelector(".publish.sidebar ~ .collapse");

        let tabElement = element.parentNode.querySelector(".publish.sidebar ~ .card > .list-view > .tabs");
        console.log(tabElement);
        if (tabElement) {
            this.tabs = Tabs.load(tabElement);
        }

        PublishPanel._count++;
        this.element.setAttribute('data-publish-index', PublishPanel._count);

        PublishPanel._loaded[this.element.getAttribute('data-publish-index')] = this;

        this.bindEvents();
        this.events = {};
    }

    static loadAll(element) {
        var elements = element.querySelectorAll('.publish.sidebar');

        elements.forEach(function(element) {
            PublishPanel.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-publish-index');

        if (index) {
            return PublishPanel._loaded[index];
        }

        return new PublishPanel(element);
    }

    bindEvents() {
        if (this.tabs) {
            this.tabs.on("sidebar", () => {
                // Reveal
                this.show();
            });
        }

        // Allow for the sidebar collapse/expand
        this.collapse.addEventListener("click", (event) => {
            event.preventDefault();
            event.stopPropagation();

            if (this.element.parentNode.classList.contains("reveal")) {
                this.hide();
            }
            else {
                this.show();
            }
        });
    }

    show() {
        this.element.parentNode.classList.add("reveal");
    }

    hide() {
        this.element.parentNode.classList.remove("reveal");
        this.tabs.showSidebarButton();
    }
}

PublishPanel._count  = 0;
PublishPanel._loaded = {};

export default PublishPanel;
