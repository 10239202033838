"use strict";

import EventComponent from './event_component.js';

/**
 * This class represents a tooltip. This can display helpful text on links or
 * buttons that might not have text displayed for it.
 *
 * These are automatically created for anything that has a title attribute.
 *
 * Generally one uses the Tooltip.load() function to instantiate a tooltip,
 * or, even better, just lets it get instantiated for any element with a
 * title attribute automatically.
 *
 * @param {HTMLElement} element The element to attach the tooltip events to.
 *
 */
class Tooltip extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            throw new TypeError("element is required");
        }

        if (!element.tagName) {
            throw new TypeError("element must be an HTMLElement");
        }

        if (!Tooltip.element) {
            var tooltipElement = document.createElement("div");
            tooltipElement.setAttribute('hidden', '');
            tooltipElement.classList.add("tooltip");

            var tooltipText = document.createElement("span");
            tooltipElement.appendChild(tooltipText);

            var tooltipBeforeElement = document.createElement("div");
            tooltipBeforeElement.classList.add("before");
            tooltipElement.appendChild(tooltipBeforeElement);

            var tooltipAfterElement = document.createElement("div");
            tooltipAfterElement.classList.add("after");
            tooltipElement.appendChild(tooltipAfterElement);

            document.body.appendChild(tooltipElement);

            tooltipElement.addEventListener("mouseleave", (event) => {
                this.hide();
            });

            Tooltip.element = tooltipElement;
        }

        this.element = element;

        Tooltip._count++;
        this.element.setAttribute('data-tooltip-index', Tooltip._count);

        Tooltip._loaded[this.element.getAttribute('data-tooltip-index')] = this;

        this.bindEvents();
    }

    /**
     * Finds all elements with a title attribute and instantiates a Tooltip
     * object for each of them.
     *
     * Only looks for elements within the given element.
     *
     * @param {HTMLElement} element The element to search within.
     */
    static loadAll(element) {
        var elements = element.querySelectorAll('[title], [data-tooltip-text]');

        elements.forEach(function(element) {
            Tooltip.load(element);
        });
    }

    /**
     * Instantiates a Tooltip, if it hasn't already, for the given element.
     *
     * @param {HTMLElement} element The element to instantiate a Tooltip around.
     */
    static load(element) {
        if (element === undefined) {
            throw new TypeError("element is required");
        }

        var index = element.getAttribute('data-tooltip-index');

        if (index) {
            return Tooltip._loaded[index];
        }

        return new Tooltip(element);
    }

    /**
     * Reveals the tooltip and optionally replaces the message with the given string.
     *
     * When message is given, the normal behavior of the tooltip (to reveal on mouseover) is
     * supressed until the tooltip goes away. When message isn't given, it will pull the text
     * from the 'title' attribute.
     *
     * @param {string} message The attribute containing the tooltip message to show.
     */
    show(message) {
        if (message) {
            message = this.element.getAttribute(message);
        }

        if (Tooltip.element.classList.contains('reveal')) {
            if (Tooltip.element.getAttribute('data-tooltip-revealed') == this.element.getAttribute('data-tooltip-index')) {
                if (!message || Tooltip.element.querySelector("span").textContent == message) {
                    return;
                }
            }
        }

        message = message || this.element.getAttribute("data-tooltip-text");

        var position = this.element.getBoundingClientRect();

        // Set the text and then reveal the tooltip so that it can be measured
        Tooltip.element.querySelector("span").textContent = message;
        Tooltip.element.removeAttribute('hidden');

        var tooltipBounds = Tooltip.element.getBoundingClientRect();

        // Place it above the element with the tooltip
        var top = (position.top + parseInt(this.element.style.paddingTop || "0") - (tooltipBounds.bottom - tooltipBounds.top)) - 10;
        Tooltip.element.classList.remove("below");

        // Unless it is hitting the top of the page, then position it below the element.
        if (top < 0) {
            top = position.bottom + 10;
            Tooltip.element.classList.add("below");
        }

        Tooltip.element.style.top = top + "px";

        // Place the tooltip in the center
        var width = tooltipBounds.right - tooltipBounds.left;
        var left = position.left + ((position.right - position.left) / 2) - (width / 2);

        var arrowLeft = (width / 2) - 7;
        var arrowMaskLeftDelta = 4;

        Tooltip.element.querySelector(".after").style.borderRightWidth = "";
        Tooltip.element.querySelector(".after").style.borderLeftWidth  = "";

        // Unless it is hitting the boundary of the page, then position it at the page boundary.
        if ((left + width) > (document.body.clientWidth - 5)) {
            let delta = left - ((document.body.clientWidth - 5) - width);
            left -= delta;
            arrowLeft += delta;

            // Adjust for the extreme
            if ((left + arrowLeft) > (document.body.clientWidth - 20)) {
                arrowLeft -= 2;
                arrowMaskLeftDelta = 6;
                Tooltip.element.querySelector(".after").style.borderRightWidth = "1px";
                Tooltip.element.querySelector(".after").style.borderRightStyle = "solid";
            }
        }
        else if (left < 5) {
            let delta = (left - 5);
            left -= delta;
            arrowLeft += delta;

            if ((left + arrowLeft) < 5) {
                Tooltip.element.querySelector(".after").style.borderLeftStyle  = "solid";
                Tooltip.element.querySelector(".after").style.borderLeftWidth  = "1px";
            }
        }

        Tooltip.element.querySelector(".after").style.left = arrowLeft + "px";
        Tooltip.element.querySelector(".before").style.left = (arrowLeft + arrowMaskLeftDelta) + "px";
        Tooltip.element.style.left = left + "px";

        // Show the tooltip
        Tooltip.element.setAttribute('data-tooltip-revealed', this.element.getAttribute('data-tooltip-index'));
        Tooltip.element.classList.add('reveal');
    }

    /**
     * Clears the tooltip from view.
     */
    hide() {
        window.clearTimeout(this.timer);
        Tooltip.element.classList.remove('reveal');
        Tooltip.element.setAttribute('hidden', '');
    }

    /**
     * Binds the DOM events for this Tooltip object.
     */
    bindEvents() {
        this.tooltipText = this.element.getAttribute("data-tooltip-text") || this.element.getAttribute("title");
        this.element.setAttribute("data-tooltip-text", this.tooltipText);
        this.element.setAttribute("title", this.tooltipText);

        this.element.addEventListener("mouseenter", (event) => {
            if (this.element.getAttribute("title") !== this.element.getAttribute("data-tooltip-text")) {
                this.element.setAttribute("data-tooltip-text", this.element.getAttribute("title"));
            }
            this.element.removeAttribute("title");
            this.timer = window.setTimeout(() => {
                if (!this.element.classList.contains("disable-tooltip") &&
                    !this.element.parentNode.hasAttribute('disabled') &&
                    !this.element.hasAttribute('disabled')) {
                    this.show();
                }
            }, 500);

            this.element.addEventListener("mouseleave", (event) => {
                this.element.setAttribute("title", this.element.getAttribute("data-tooltip-text"));
                this.hide();
            });
        });

        if (!this.element.hasAttribute("data-tooltip-allow-click")) {
            this.element.addEventListener("click", (event) => {
                this.hide();
            });
        }
    }
}

Tooltip._count  = 0;
Tooltip._loaded = {};

export default Tooltip;
