"use strict";

import EventComponent from './event_component.js';

class Card extends EventComponent {
    constructor(element) {
        super();

        // element is a tagged .card
        if (element === undefined) {
            return;
        }

        this.element = element;

        Card._count++;
        this.element.setAttribute('data-card-index', Card._count);

        Card._loaded[this.element.getAttribute('data-card-index')] = this;

        this.previousElement = this.element.previousElementSibling;
        if (this.previousElement) {
            this.collapseElement = this.previousElement.querySelector(":scope > span.expand");
        }

        this.bindEvents();
        this.events = {};
    }

    static loadAll(element) {
        var elements = element.querySelectorAll('.card.collapsible');

        elements.forEach(function(element) {
            Card.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-card-index');

        if (index) {
            return Card._loaded[index];
        }

        return new Card(element);
    }

    bindEvents() {
        if (this.collapseElement) {
            this.collapseElement.parentNode.addEventListener("click", (event) => {
                if (this.collapseElement.classList.contains("shown")) {
                    this.hide();
                }
                else {
                    this.show();
                }
            });
        }
    }

    show() {
        this.collapseElement.classList.add("shown");
        this.element.removeAttribute("hidden");
    }

    hide() {
        this.collapseElement.classList.remove("shown");
        this.element.setAttribute("hidden", "");
    }
}

Card._count  = 0;
Card._loaded = {};

export default Card;
