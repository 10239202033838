"use strict";

import EventComponent from './event_component.js';

/**
 * This class represents flash popups. This can display helpful messages upon
 * actions being taken or errors occurring.
 *
 */
class Flash extends EventComponent {
    static load() {
        if (Flash.loaded) {
            return;
        }

        Flash.element = document.body.querySelectorAll("ul.flash")[0];

        Flash.loaded = true;
    }

    static push(messageType, messageText, delayTime) {
        delayTime = delayTime || 0;

        Flash.load();

        if (!Flash.element) {
            return null;
        }

        let id = Flash.nextTag;
        let template = Flash.element.querySelector("template." + messageType);
        let item = template;
        if (template) {
            if ('content' in template) {
                item = document.importNode(template.content, true);
                item = item.querySelector("li.flash");
            }
            else {
                item = template.querySelector("li.flash").cloneNode(true);
            }
        }

        if (item) {
            item.querySelector("p").textContent = messageText;
            item.setAttribute('data-flash-id', id);
            Flash.element.appendChild(item);

            if (delayTime > 0) {
                window.setTimeout( () => {
                    let opacity = 1.0;
                    var start = null;
                    let animation = (timestamp) => {
                        if (!start) start = timestamp;
                        let progress = timestamp - start;
                        opacity = Math.max(1 - (progress/500), 0);
                        item.style.opacity = opacity;
                        if (opacity > 0.0) {
                            window.requestAnimationFrame(animation);
                        }
                        else {
                            item.remove();
                        }
                    };
                    window.requestAnimationFrame(animation);
                }, delayTime);
            }
        }

        Flash.nextTag++;

        return id;
    }

    static pop(id) {
        let item = Flash.element.querySelector("li.flash[data-flash-id=\"" + id + "\"]");
        if (item) {
            item.remove();
        }
    }
}

Flash.nextTag = 0;

export default Flash;
