"use strict";

import Mover from "./mover.js";
import StaticComponent from "./static_component.js";
import EventComponent from "./event_component.js";

/**
 * This represents a visual component that can be moved.
 */
class MovableComponent extends StaticComponent {
    /**
     * Initializes the component by wrapping the given element.
     *
     * @param {HTMLElement} element The element to wrap.
     */
    constructor(element) {
        super(element);

        // Mouse Down Events
        this.element.addEventListener('mousedown', this.mouseDownEvent.bind(this));
    }

    /**
     * Whether or not the component is currently movable.
     */
    get movable() {
        return true;
    }

    /**
     * Moves the component to the specified place.
     */
    move(x, y) {
        this.element.style.left = x + "px";
        this.element.style.top  = y + "px";
    }

    /**
     * Returns the X coordinate of the component.
     */
    get x() {
        return parseInt(this.element.style.left);
    }

    /**
     * Returns the Y coordinate of the component.
     */
    get y() {
        return parseInt(this.element.style.top);
    }

    /**
     * Handles click events on the element.
     */
    mouseDownEvent(event) {
        this.trigger("mousedown", event);

        if (event.button == 0) {
            event.stopPropagation();
            event.preventDefault();

            let mover = new Mover(event);

            if (this.movable) {
                this._element.classList.add("moving");
            }

            this._startX = this.x;
            this._startY = this.y;
            mover.on("dragged", this.moveDragEvent.bind(this));
            mover.on("stopped", this.moveDragEndEvent.bind(this));
        }
    }

    /**
     * Handles when the element should be moved.
     */
    moveDragEvent(event) {
        if (!this.movable) {
            return;
        }
        event.to = {};
        event.to.x = this._startX + event.delta.x;
        event.to.y = this._startY + event.delta.y;
        this.trigger("drag", event);
    }

    /**
     * Handles when the element should be done moving.
     */
    moveDragEndEvent(event) {
        event.to = {};
        event.to.x = this._startX + event.delta.x;
        event.to.y = this._startY + event.delta.y;
        if (event.delta.x == 0 && event.delta.y == 0) {
            if (this.selectable) {
                // Select
                this.trigger("select", event);
            }
        }
        else {
            if (!this.movable) {
                return;
            }
            this.trigger("move", event);
        }
        this._element.classList.remove("moving");
    }
}

export default MovableComponent;
