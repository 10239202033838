"use strict";

import EventComponent from './event_component.js';
import Util           from './util.js';
import Occam          from './occam.js';
import Modal          from './modal.js';
import SelectModal    from './objects/select_modal.js';
import Selector       from './selector.js';
import OccamObject    from './occam_object.js';

/**
 * This class represents a VM builder widget that allows for the selection of
 * the running object and potentially each providing object and backend.
 */
class RunForm extends EventComponent {
    constructor(element) {
        super();

        this.element = element;

        this.bindEvents();
        this.bindObjectEvents();
        this.bindInputEvents();
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element;
        if (typeof element != "number") {
            index = element.getAttribute('data-run-form-index');
        }

        if (index) {
            return RunForm._loaded[index];
        }

        return new RunForm(element);
    }

    static loadAll(element) {
        var runForms = element.querySelectorAll('.task-form');
        runForms.forEach( (element) => {
            RunForm.load(element);
        });
    }

    /**
     * Runs the object as specified by the form.
     */
    run(button = null) {
        let info = this.info;

        if (button) {
            info.submit = button.getAttribute('name');
        }

        this.trigger("run", info);
    }

    /**
     * Retrieves the task information currently reflected by the form.
     */
    get info() {
        let ret = {
          items: [],
          eulas: [],
        };

        let taskItems = this.element.querySelectorAll(".task-form-cell.task-form-item");
        let viewing = null;

        taskItems.forEach( (taskItem) => {
            let item = {};

            if (taskItem.hasAttribute("data-object")) {
                item = this.dataFor(taskItem);

                if (viewing) {
                    item.viewing = viewing;
                    viewing = null;
                }

                if (taskItem.hasAttribute("data-eula-index")) {
                    ret.eulas.push({
                        id: item.id,
                        revision: item.revision,
                        index: parseInt(taskItem.getAttribute("data-eula-index"))
                    });
                }

                // Add the attached file
                if (taskItem.hasAttribute("data-file")) {
                    item.file = taskItem.getAttribute("data-file");
                }

                // Take a look at the inputs
                let inputs = taskItem.querySelectorAll(".task-form-query .input-list > li.input");
                inputs.forEach( (input) => {
                    let objects = input.querySelectorAll("li.object.input");

                    item.inputs = item.inputs || [];

                    let inputList = [];
                    objects.forEach( (inputInfo) => {
                        let type = inputInfo.querySelector("p.type").textContent;
                        let name = inputInfo.querySelector("p.name").textContent;
                        let id = inputInfo.querySelector("p.id").textContent;
                        let uid = inputInfo.querySelector("p.uid").textContent;
                        let revision = inputInfo.querySelector("p.revision").textContent;
                        let fileElement = inputInfo.querySelector("p.file");
                        let file = null;
                        if (fileElement) {
                            file = fileElement.textContent;
                        }

                        let input = {
                            type: type,
                            name: name,
                            id: id,
                            uid: uid,
                            revision: revision,
                            fullID: id + "@" + revision
                        };

                        if (file) {
                            input.file = file;
                            input.fullID = input.fullID + "/" + input.file;
                        }

                        inputList.push(input);
                    });

                    item.inputs.push(inputList);
                });

                ret.items.push(item);
            }
            else if (taskItem.hasAttribute("data-backend")) {
                ret.backend = taskItem.getAttribute("data-backend");
            }
            else if (taskItem.hasAttribute("data-dispatch-to")) {
                ret.dispatchTo = taskItem.getAttribute("data-dispatch-to");
            }
            else if (taskItem.hasAttribute("data-viewing")) {
                viewing = this.dataFor(taskItem);

                if (taskItem.hasAttribute("data-eula-index")) {
                    ret.eulas.push({
                        id: viewing.id + "@" + viewing.revision,
                        index: parseInt(taskItem.getAttribute("data-eula-index"))
                    });
                }
            }
        });

        return ret;
    }

    /**
     * Binds events to the form elements.
     */
    bindEvents() {
        let taskItems = this.element.querySelectorAll(".task-form-cell.task-form-item");

        taskItems.forEach( (taskItem) => {
            if (taskItem.classList.contains("bound")) {
                return;
            }

            taskItem.classList.add("bound");

            var collapseButton = taskItem.querySelector("button.collapse");
            if (collapseButton) {
                collapseButton.addEventListener("click", (event) => {
                    event.stopPropagation();

                    taskItem.classList.remove("active");
                    let taskItemQuery = taskItem.querySelector(".task-form-query");
                    if (taskItemQuery) {
                        taskItemQuery.classList.add("reveal");
                    }
                });
            }

            taskItem.addEventListener("click", (event) => {
                taskItem.classList.toggle("active");
                let taskItemQuery = taskItem.querySelector(".task-form-query");
                if (taskItemQuery) {
                    taskItemQuery.classList.toggle("reveal");
                    taskItemQuery.addEventListener("click", (event) => {
                        event.stopPropagation();
                    });

                    if (!taskItemQuery.hasAttribute("data-height-calculated")) {
                        let taskItemHeight = taskItemQuery.clientHeight + "px";
                        taskItemQuery.setAttribute("data-height-calculated", taskItemHeight);
                        taskItemQuery.style.transition = "none";
                        taskItemQuery.style.height = "0";

                        // Allows the transition to see the resolution of a frame at 0
                        // (Chrome only needs one of these, Firefox needs this
                        // doubled, as it is here)
                        window.requestAnimationFrame( () => {
                            window.requestAnimationFrame( () => {
                                // And then in the next frame, starts the actual animation:
                                taskItemQuery.style.transition = "";
                                taskItemQuery.style.height = taskItemHeight;
                            });
                        });
                    }
                }
            });
        });

        // The run button
        this.element.querySelectorAll("input.button.run").forEach( (button) => {
            if (button && !button.classList.contains("bound")) {
                button.classList.add("bound");
                button.addEventListener("click", (event) => {
                    // Spawn a run for this.
                    this.run(button);
                });
            }
        });
    }

    /**
     * Retrieves the data that represents the object of the given item.
     */
    dataFor(item) {
        let data = {};

        data.phase = item.getAttribute('data-phase') || 'run';
        data.type = item.querySelector(".info .type").textContent;
        data.name = item.querySelector(".info .name").textContent;
        data.icon = item.querySelector("svg.icon > use").getAttribute("xlink:href");

        data.token = item.getAttribute('data-token');
        data.fullID = item.getAttribute("data-object") || item.getAttribute("data-viewing");

        if (item.hasAttribute('data-build-id')) {
            data.build = {};
            data.build.id = item.getAttribute('data-build-id');
        }

        // Start parsing the id
        let fullID = data.fullID;

        // Look for the id
        let parts = fullID.split(/[@#%/]/);
        data.id = parts[0];
        fullID = fullID.substring(parts[0].length)

        // Look for a revision
        if (fullID[0] == '@') {
            parts = fullID.substring(1).split(/[#%/]/);
            data.revision = parts[0];
            fullID = fullID.substring(parts[0].length + 1)
        }

        // Look for a staged link
        if (fullID[0] == '#') {
            parts = fullID.substring(1).split(/[%/]/);
            data.link = parts[0];
            fullID = fullID.substring(parts[0].length + 1)
        }

        // Look for a build id
        if (fullID[0] == '%') {
            parts = fullID.substring(1).split(/[/]/);
            data.build = {}
            data.build.id = parts[0];
            fullID = fullID.substring(parts[0].length + 1)
        }

        // Look for a path
        if (fullID[0] == '/') {
            data.file = fullID.substring(1);
        }

        return data;
    }

    /**
     * Updates the given task form item with the given object.
     */
    update(item, data) {
        // Do not update if this object is already representing the given data.
        if ((item.getAttribute('data-phase') || 'run') == data.phase && (item.getAttribute('data-object') === data.id + "@" + data.revision)) {
            return;
        }

        // Update the phase for this node
        if (data.phase) {
            item.setAttribute('data-phase', data.phase);
        }

        // Update the target depicted by this node
        if (data.target) {
            item.setAttribute('data-dispatch-to', data.target);
        }

        let taskItemTypeElement = item.querySelector(".info .type");
        let taskItemNameElement = item.querySelector(".info .name");
        let taskItemIconElement = item.querySelector(".task-form-object > svg > use, .task-form-dispatch > svg > use");
        let taskItemQuery = item.querySelector(".task-form-query");

        if (taskItemTypeElement) {
            taskItemTypeElement.textContent = data.type;
        }

        if (taskItemNameElement) {
            taskItemNameElement.textContent = data.name;
        }

        if (taskItemIconElement) {
            taskItemIconElement.setAttribute("xlink:href", data.icon);
        }

        // Mark following nodes as loading
        let currentRow = item.parentNode.nextElementSibling;
        while (currentRow) {
            let nextTaskFormObject = currentRow.querySelector(".task-form-object, .task-form-dispatch");
            if (nextTaskFormObject) {
                nextTaskFormObject.classList.add("loading");
            }
            currentRow = currentRow.nextElementSibling;
        }

        let phase = item.getAttribute("data-phase") || "run"

        // Update the form
        let url = new OccamObject(data.id, data.revision).url({
            path: phase + "/queue"
        });

        // Disable the run button
        let button = this.element.querySelector("input.button.run");
        if (button) {
            button.setAttribute("disabled", "");
        }

        Util.get(url, (html) => {
            // Place the data into the DOM
            let dummy = document.createElement("div");
            dummy.setAttribute('hidden', '');
            dummy.innerHTML = html;
            document.body.appendChild(dummy);

            // Get the node we are replacing
            let currentRow = null;
            if (data.tag) {
                currentRow = dummy.querySelector(".task-form-row .task-form-item[data-object=\"" + data.tag + "\"]");
                if (currentRow) {
                    currentRow = currentRow.parentNode;
                }
            }
            else if (data.target) {
                currentRow = dummy.querySelector(".task-form-row .task-form-item.supplemental-object .task-form-dispatch");
                if (currentRow) {
                    currentRow = currentRow.parentNode.parentNode;
                }
            }

            // Get the running object (which is the one we requested)
            if (data.tag) {
                let runningObjectTaskItem = currentRow.querySelector(".task-form-item.running-object");
                item.setAttribute("data-object", runningObjectTaskItem.getAttribute("data-object"));
                if (runningObjectTaskItem.hasAttribute("data-eula-index")) {
                    item.setAttribute("data-eula-index", runningObjectTaskItem.getAttribute("data-eula-index"));
                }
            }

            // Delete the item nodes
            let existingRow = item.parentNode.nextElementSibling;
            while (existingRow) {
                let nextTaskFormObject = existingRow.querySelector(".task-form-object, .task-form-dispatch");
                let thisRow = existingRow;
                existingRow = existingRow.nextElementSibling;
                if (nextTaskFormObject) {
                    thisRow.remove();
                }
            }

            // Catch up in the dummy DOM to the currentRow
            currentRow = currentRow.nextElementSibling;

            // Append the other nodes below
            let submitRow = this.element.querySelector(".task-form-row.submit");
            while (currentRow && !currentRow.classList.contains("submit")) {
                let thisRow = currentRow;
                currentRow = currentRow.nextElementSibling;
                submitRow.parentNode.insertBefore(thisRow, submitRow);
            }

            // Re-enable button
            if (button) {
                button.removeAttribute("disabled");
            }

            // Bind Events
            this.bindEvents();
            this.bindObjectEvents();

            // Trigger event when the form is ready
            this.trigger('ready');
        });
    }

    /**
     * Retrieves the element representing the given item in the task.
     *
     * @returns {HTMLElement} The element at the given index, if it exists, null otherwise.
     */
    itemAt(index) {
        return this.element.querySelector(".task-form-row:nth-of-type(" + (index + 2) + ") .task-form-cell.task-form-item");
    }

    bindInputEvents() {
        let taskItems = this.element.querySelectorAll(".task-form-cell.task-form-item");

        taskItems.forEach( (taskItem) => {
            let taskItemQuery = taskItem.querySelector(".task-form-query.inputs");

            if (taskItemQuery) {
                if (taskItemQuery.classList.contains("input-events-bound")) {
                    return;
                }

                taskItemQuery.classList.add("input-events-bound");

                // Hook into the modal
                let addModalLinks = taskItemQuery.querySelectorAll("li.object .add.add-input.modal");
                addModalLinks.forEach( (link) => {
                    link.addEventListener('click', (e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        Modal.open(link, {
                            onLoad: () => {
                                // Get the modal
                                let selectModal = SelectModal.load(Modal.content.querySelector('h1.select-object ~ .card.select-object'));
                                selectModal.on('object', (objectInfo) => {
                                    // Append the object to the list from the template
                                    let template = taskItemQuery.querySelector("template.input.object");
                                    let object = Util.createElementFromTemplate(template);
                                    let list = link.parentNode.parentNode;
                                    let item = link.parentNode;

                                    if (!list.classList.contains("objects")) {
                                        list = list.parentNode;
                                        item = item.parentNode;
                                    }

                                    object.querySelector('p.name').textContent = objectInfo.name;
                                    object.querySelector('p.type').textContent = objectInfo.type;
                                    object.querySelector('p.id').textContent = objectInfo.id;
                                    object.querySelector('p.uid').textContent = objectInfo.uid;
                                    object.querySelector('p.revision').textContent = objectInfo.revision;
                                    object.querySelector('svg.icon > use').setAttribute('xlink:href', objectInfo.image);

                                    if (objectInfo.file) {
                                        object.querySelector('p.file').textContent = objectInfo.file;
                                    }
                                    else {
                                        object.querySelector('p.file').remove();
                                    }

                                    list.insertBefore(object, item);

                                    // Remove button
                                    object.querySelector('button.remove').addEventListener('click', (event) => {
                                        object.remove();
                                    });
                                });
                            }
                        });
                    });
                });
            }
        });
    }

    /**
     * Binds events specific to object selection.
     */
    bindObjectEvents() {
        let taskItems = this.element.querySelectorAll(".task-form-cell.task-form-item");

        taskItems.forEach( (taskItem, i) => {
            let taskItemQuery = taskItem.querySelector(".task-form-query.viewers");

            if (taskItemQuery) {
                if (taskItemQuery.classList.contains("object-events-bound")) {
                    return;
                }

                taskItemQuery.classList.add("object-events-bound");

                let objectsList = taskItemQuery.querySelector(".objects-container");
                if (objectsList) {
                    // For each item, detect when we click on an item
                    objectsList.querySelectorAll("li.object").forEach( (objectItem) => {
                        let link = objectItem.querySelector("a");
                        if (link) {
                            link.addEventListener("click", (event) => {
                                if (link.hasAttribute("disabled")) {
                                    return;
                                }
                                let data = {
                                    name: objectItem.querySelector("p.name").textContent,
                                    type: objectItem.querySelector("p.type").textContent,
                                    id: objectItem.getAttribute('data-object-id'),
                                    revision: objectItem.getAttribute('data-object-revision'),
                                    icon: link.querySelector("svg.icon use").getAttribute("xlink:href"),
                                };

                                if (data.id && data.revision) {
                                    data.tag = data.id + "@" + data.revision;
                                }

                                if (objectItem.classList.contains("target")) {
                                    data.target = data.type;
                                }

                                objectsList.querySelectorAll("li.object.active").forEach( (activeObjectItem) => {
                                    activeObjectItem.classList.remove("active");
                                });
                                objectItem.classList.add("active");

                                this.update(taskItem, data);
                            });
                        }
                    });
                }
            }
        });
    }
}

RunForm._count = 0;
RunForm._loaded = {};

export default RunForm;
