"use strict";

import EventComponent from "./event_component.js";

class Mover extends EventComponent {
    constructor(event) {
        super();

        this.state = {
            initialClick: {x: 0, y: 0},
            status: "stopped"
        };

        if (event) {
            this.start(event);
        }
    }

    start(event) {
        // Set the moving state
        this.state.initialClick = {x: event.pageX, y: event.pageY };
        this.state.button = event.button;
        this.state.status = "moving";

        // Move handler --
        //    binding this returns a new function, must save it to remove
        this.state.moveCB = (event) => { this._dragEvent(event); };
        document.addEventListener('mousemove', this.state.moveCB);

        // Cleanup --
        //    binding this returns a new function, must save it to remove
        this.state.stopCB = this._stop.bind(this);
        document.addEventListener('mouseup', this.state.stopCB);
        return this;
    }

    _stop(event) {
        document.removeEventListener('mouseup', this.state.stopCB);
        document.removeEventListener('mousemove', this.state.moveCB);
        this.state.status = "stopped";
        this.trigger("stopped", this._createEvent(event));
    }

    _dragEvent(event) {
        let mousePosition = {x: event.pageX, y: event.pageY };
        if (this.state.status === "moving") {
            this.trigger("dragged", this._createEvent(event));
        }
    }

    _createEvent(event) {
        let mousePosition = {x: event.pageX, y: event.pageY };
        let deltaX = mousePosition.x - this.state.initialClick.x;
        let deltaY = mousePosition.y - this.state.initialClick.y;

        event.origin = {
            x: this.state.initialClick.x,
            y: this.state.initialClick.y
        };

        event.delta = {
            x: deltaX,
            y: deltaY
        };

        return event;
    }
}

export default Mover;
