"use strict";

import EventComponent from "../event_component.js";
import Util           from "../util.js";
import Tabs           from "../tabs.js";

/**
 * This implements behaviors for the objects/new modal.
 */
class EditModal extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            return;
        }

        this.element = element;

        // Find the errors section
        this.errorsList = this.element.parentNode.querySelector(".errors");

        // Find the button
        this.submitButton = this.element.querySelector('input[name="submit"]');

        // Tabs
        this.tabs = Tabs.load(this.element.querySelector("ul.tab-bar"));

        // License section
        this.licensePicker = this.element.querySelector('.card.license-picker');
        this.licenseCard   = this.element.querySelector('.card.license');
        this.licenseClose  = this.element.querySelector('li.close');

        EditModal._count++;
        this.element.setAttribute('data-new-modal-index', EditModal._count);

        EditModal._loaded[this.element.getAttribute('data-new-modal-index')] = this;

        this.bindEvents();
    }

    static loadAll(element) {
        var elements = element.querySelectorAll('h1.edit ~ form.modal');

        elements.forEach(function(element) {
            EditModal.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-edit-modal-index');

        if (index) {
            return EditModal._loaded[index];
        }

        return new EditModal(element);
    }

    bindEvents() {
        if (this.tabs) {
            this.tabs.on('change', () => {
                if (this.licenseClose && this.licenseCard && this.licensePicker) {
                    this.licenseCard.setAttribute('hidden', '');
                    this.licenseClose.setAttribute('hidden', '');
                    this.licensePicker.removeAttribute('hidden');
                    this.tabs.element.classList.remove("overlay-open");
                }
            });
        }

        if (this.licenseClose) {
            this.licenseClose.addEventListener('click', (event) => {
                this.licenseCard.setAttribute('hidden', '');
                this.licenseClose.setAttribute('hidden', '');
                this.licensePicker.removeAttribute('hidden');
                this.tabs.element.classList.remove("overlay-open");
            });
        }

        if (this.licensePicker) {
            let licenseShowAll = this.licensePicker.querySelector("input.show-all");
            if (licenseShowAll) {
                licenseShowAll.addEventListener('change', (event) => {
                    this.licensePicker.querySelectorAll("li.license").forEach( (license) => {
                        if (licenseShowAll.checked && license.getAttribute('data-recommend') == 'false') {
                            license.removeAttribute('hidden');
                        }
                        else if (!licenseShowAll.checked && license.getAttribute('data-recommend') == 'false') {
                            license.setAttribute('hidden', '');
                        }
                    });
                });
            }

            let licenses = this.licensePicker.querySelectorAll("li.license");
            licenses.forEach( (license) => {
                let checkbox = license.querySelector(".checkbox input[type=\"checkbox\"]");

                let licenseButton = license.querySelector(".license a.license");
                if (licenseButton && checkbox) {
                    licenseButton.addEventListener('click', (event) => {
                        event.stopPropagation();
                        event.preventDefault();

                        checkbox.checked = !checkbox.checked;
                    });
                }

                let viewButton = license.querySelector(".actions a.view");
                if (viewButton) {
                    viewButton.addEventListener('click', (event) => {
                        event.stopPropagation();
                        event.preventDefault();

                        let url = viewButton.getAttribute('href');

                        // Pull the license text and display it in the modal
                        this.licenseCard.innerHTML = "";
                        this.licenseCard.style.height = this.licensePicker.clientHeight + "px";
                        this.licenseCard.classList.add('loading');
                        this.licenseCard.removeAttribute('hidden');
                        this.licenseClose.removeAttribute('hidden');
                        this.licensePicker.setAttribute('hidden', '');
                        this.tabs.element.classList.add("overlay-open");
                        Util.get(url, (html) => {
                            let dummy = document.createElement("div");
                            dummy.innerHTML = html;
                            dummy = dummy.querySelector('.card.license');
                            if (dummy) {
                                this.licenseCard.innerHTML = dummy.innerHTML;
                            }

                            this.licenseCard.classList.remove('loading');
                        });
                    });
                }
            });
        }
    }
}

EditModal._count  = 0;
EditModal._loaded = {};

export default EditModal;
