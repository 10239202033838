"use strict";

import EventComponent from './event_component.js';
import Util           from './util.js';

/**
 * This class manages theme editing and selection.
 *
 * @param {HTMLElement} element The element that displays the theme editor.
 *
 */
class ThemeManager extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            return;
        }

        this.element = element;

        ThemeManager._count++;
        this.element.setAttribute('data-theme-manager-index', ThemeManager._count);

        ThemeManager._loaded[this.element.getAttribute('data-theme-manager-index')] = this;

        this.bindEvents();
    }

    /**
     * Finds all elements that have the theme editor or selection panel and
     * instantiates them.
     *
     * Only looks for elements within the given element.
     *
     * @param {HTMLElement} element The element to search within.
     */
    static loadAll(element) {
        var elements = element.querySelectorAll('.theme-selection');

        elements.forEach(function(element) {
            ThemeManager.load(element);
        });
    }

    /**
     * Instantiates a ThemeManager, if it hasn't already, for the given element.
     *
     * @param {HTMLElement} element The element to instantiate a Tooltip around.
     * @returns {ThemeManager} The instantiated ThemeManager.
     */
    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-theme-manager-index');

        if (index) {
            return ThemeManager._loaded[index];
        }

        return new ThemeManager(element);
    }

    /**
     * Binds the DOM events for this ThemeManager instance.
     */
    bindEvents() {
        const buttons = this.element.querySelectorAll(".theme-selection button.color");

        buttons.forEach(function(button) {
            const selectors = JSON.parse(button.getAttribute("data-selectors"));
            const input = this.element.querySelector('input[name="' + button.getAttribute('id') + '"]');
            const style = this.element.querySelector('input[name="' + button.getAttribute('id') + '-style"]');
            const re  = /^hsl\(\s*([^,]+)\s*,\s*([^,]+)%\s*,\s*([^,]+)%\s*\)/;
            const matches = re.exec(input.value);
            const originalValue = input.value;

            const update = function() {
                // Update selectors
                selectors.forEach(function(data) {
                    const elements = document.querySelectorAll(data.selector);

                    elements.forEach(function(element) {
                        const apply = function() {
                            element._oldValue = element.style[data.key];

                            var value = "#" + input.value;
                            if (style) {
                                value = style.value + " " + value;
                                if (style.value.trim() == "") {
                                    value = "none";
                                }
                            }
                            element.style[data.key] = value;
                            if (data.hover) {
                                element.style.animation = "none";
                            }
                        };

                        const unapply = function() {
                            element.style[data.key] = element._oldValue;
                        };

                        if (data.hover) {
                            element.removeEventListener("mouseover", apply);
                            element.addEventListener("mouseover", apply);

                            element.removeEventListener("mouseout", unapply);
                            element.addEventListener("mouseout", unapply);
                        }
                        else {
                            apply();
                        }
                    });
                });
            };

            if (style) {
                style.addEventListener("change", update);
                style.addEventListener("keyup", update);
            }

            const picker = new window.jscolor(button, {
                valueElement: input,
                closable: true,
                onFineChange: update,
            });
            if (matches) {
                var rgb = null;
                try {
                    rgb = Util.hslToRgb(parseFloat(matches[1])/360, parseFloat(matches[2])/100, parseFloat(matches[3])/100);
                }
                catch (e) {
                    rgb = [0, 0, 0];
                }

                picker.fromRGB(rgb[0], rgb[1], rgb[2]);
            }
            else {
                picker.fromString(originalValue);
            }
        }, this);
    }
}

ThemeManager._count  = 0;
ThemeManager._loaded = {};

export default ThemeManager;
