"use strict";

import EventComponent from "../event_component.js";
import Search         from '../search.js';
import Util           from "../util.js";
import Tabs           from "../tabs.js";
import Modal          from "../modal.js";
import ObjectList     from "../object_list.js";
import Occam          from "../occam.js";
import FileList       from "../file_list.js";

/**
 * This implements behaviors for the objects/new modal.
 */
class AddResourceModal extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            return;
        }

        this.element = element;

        // Find the errors section
        this.errorsList = this.element.parentNode.querySelector(".errors");

        // Find the form
        this.form = this.element.parentNode.parentNode.querySelector("h1 ~ form");

        // Find the button and other form elements
        if (this.form) {
            this.messageField = this.form.querySelector('input[name="message"]');
            this.messageTemplate = this.messageField.value;
            this.nameField = this.form.querySelector('input#add-resource-name');
            this.typeField = this.form.querySelector('input#add-resource-type');
            this.subtypeField = this.form.querySelector('input#add-resource-subtype');
            this.sourceField = this.form.querySelector('input#add-resource-source');
            this.toField = this.form.querySelector('input#add-resource-to');
            this.idField = this.form.querySelector('input#add-resource-id');
            this.uidField = this.form.querySelector('input#add-resource-uid');
            this.unpackField = this.form.querySelector('input#add-resource-unpack');
            this.submitButton = this.form.querySelector('input[name="submit"]');
        }

        // Tabs
        this.tabs = Tabs.load(this.element.querySelector("ul.tab-bar"));

        AddResourceModal._count++;
        this.element.setAttribute('data-add-resource-modal-index', AddResourceModal._count);

        AddResourceModal._loaded[this.element.getAttribute('data-add-resource-modal-index')] = this;

        // Detect if part of Modal (I know... confusing, but this can be not-a-modal)
        this.withinModal = false;
        if (Util.getParents(this.element, ".modal-window", ".modal-window").length > 0) {
            this.withinModal = true;

            // Get reference to the object container we are adding to
            let resourceListId = Modal.optionFor("resource-list");
            if (resourceListId) {
                let resourceListElement = document.body.querySelector("#" + resourceListId);
                if (resourceListElement) {
                    this.resourceList = ObjectList.load(resourceListElement);
                }
            }
        }

        this.bindEvents();
    }

    static loadAll(element) {
        var elements = element.querySelectorAll('h1.new-resource ~ form .card.new-resource');

        elements.forEach(function(element) {
            AddResourceModal.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-add-resource-modal-index');

        if (index) {
            return AddResourceModal._loaded[index];
        }

        return new AddResourceModal(element);
    }

    bindEvents() {
        if (this.tabs) {
            this.tabs.on('change', () => {
                // TODO: handle sidebar changes, if needed
            });
        }

        if (this.sourceField) {
            this.sourceField.addEventListener('change', (event) => {
                // Strip whitespace
                this.sourceField.value = this.sourceField.value.trim();

                // Detect names for other fields based on URL
                let url = this.sourceField.value;
                if (url.substring(url.length - 7) == ".tar.gz" ||
                  url.substring(url.length - 4) == ".tgz") {
                    this.subtypeField.value = "application/gzip";
                    url = url.replace(".tar", "");
                    let parts = url.split('/');
                    let nameParts = parts[parts.length - 1].split('.');
                    let name = nameParts.slice(0, nameParts.length - 1).join(".");
                    this.nameField.value = name + " gzip archive";
                    this.toField.value = name + ".tar.gz";
                }
                else if (url.substring(url.length - 7) == ".tar.bz" ||
                  url.substring(url.length - 4) == ".bz2" ||
                  url.substring(url.length - 4) == ".tbz" ||
                  url.substring(url.length - 3) == ".bz") {
                    this.subtypeField.value = "application/x-bzip2";
                    url = url.replace(".tar", "");
                    let parts = url.split('/');
                    let nameParts = parts[parts.length - 1].split('.');
                    let name = nameParts.slice(0, nameParts.length - 1).join(".");
                    this.nameField.value = name + " bzip2 archive";
                    this.toField.value = name + "tar.bz";
                }
                else if (url.substring(url.length - 7) == ".tar.xz" ||
                  url.substring(url.length - 4) == ".txz" ||
                  url.substring(url.length - 3) == ".xz") {
                    this.subtypeField.value = "application/x-xz";
                    url = url.replace(".tar", "");
                    let parts = url.split('/');
                    let nameParts = parts[parts.length - 1].split('.');
                    let name = nameParts.slice(0, nameParts.length - 1).join(".");
                    this.nameField.value = name + " xz archive";
                    this.toField.value = name + ".tar.xz";
                }
                else if (url.substring(url.length - 4) == ".zip") {
                    this.subtypeField.value = "application/zip";
                    let parts = url.split('/');
                    let nameParts = parts[parts.length - 1].split('.');
                    let name = nameParts.slice(0, nameParts.length - 1).join(".");
                    this.nameField.value = name + " zip archive";
                    this.toField.value = name + ".zip";
                }
                else if (url.substring(url.length - 4) == ".git" ||
                  url.substring(0, 4) == "git:" ||
                  (url.indexOf("//github") != -1) ||
                  (url.indexOf("//gitlab") != -1)) {
                    this.subtypeField.value = "git";
                    let parts = url.split('/');
                    let nameParts = parts[parts.length - 1].split('.');
                    let name = nameParts.slice(0, nameParts.length - 1).join(".");
                    if (url.substring(url.length - 4) != ".git") {
                        name = parts[parts.length - 1];
                    }
                    this.nameField.value = name + " git repository";
                    this.toField.value = name;
                }
                else {
                    this.subtypeField.value = "file";
                    let parts = url.split('/');
                    let name = parts[parts.length - 1];
                    this.nameField.value = name + " file data";
                    this.toField.value = name;
                }
                this.submitButton.removeAttribute('disabled');
            });
        }

        if (this.withinModal) {
            Modal.onSubmit = (event) => {
                event.stopPropagation();
                event.preventDefault();

                let info = {};
                info.name = this.nameField.value;
                info.type = this.subtypeField.value;
                info.filename = this.toField.value;
                info.source = this.sourceField.value;

                if (this.subtypeField.value == "git") {
                    info.copy = true;
                    info.download = false;
                }
                else {
                    info.copy = false;
                    info.download = true;
                }

                Modal.close();

                // Add the resource to the list
                if (this.resourceList) {
                    let entry = this.resourceList.append(info);

                    let url = Occam.object().url({path: "/metadata/install/new"});

                    // Create the new resource and get an ID/Hash for it.
                    Util.post(url, {
                        name: info.name,
                        type: info.type,
                        source: info.source,
                        to: info.filename,
                    }, {
                        onload: (html) => {
                            let dummy = document.createElement("div");
                            dummy.innerHTML = html;
                            let newEntry = dummy.querySelector("li.object:first-of-type");
                            this.resourceList.replace(entry, newEntry);

                            dummy.querySelectorAll("li.object:not(:first-of-type)").forEach( (item) => {
                                // TODO: Handle multiple resources being generated (submodules in git)
                            });

                            // Get the info
                            let newInfo = this.resourceList.infoFor(newEntry);

                            // Save object metadata!
                            let metadataURL = Occam.object().url({path: "/files/object.json"});
                            let data = {
                                "data[install()][type]": 'resource',
                                "data[install()][subtype]": newInfo.type,
                                "data[install()][source]": newInfo.source,
                                "data[install()][to]": newInfo.filename,
                                "data[install()][id]": newInfo.id,
                                "data[install()][uid]": newInfo.uid,
                                "data[install()][revision]": newInfo.revision,
                                "data[install()][name]": newInfo.name,
                            };

                            if (this.unpackField.value != "") {
                                data["data[install()][actions][unpack]"] = this.unpackField.value;
                            }

                            Util.post(metadataURL, data, (html) => {
                                // Invalidate file list.
                                let fileListElement = document.body.querySelector(".file-viewer");
                                if (fileListElement) {
                                    let fileList = FileList.load(fileListElement);

                                    // TODO: also reload on removal.

                                    if (fileList) {
                                        fileList.reloadGroup();
                                    }
                                }
                            });
                        },
                        onerror: (errors) => {
                        }
                    }, 'text/html');
                }
            };
        }
    }
}

AddResourceModal._count  = 0;
AddResourceModal._loaded = {};

export default AddResourceModal;
