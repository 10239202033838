"use strict";

import Port from "./port.js";
import Util from "./util.js";
import JobDonut from "./job_donut.js";

import MovableComponent from "./movable_component.js";

// As a design goal, most components will have a static create function that
// will generate the DOM for the component, and then a more specific fromJSON
// function which fills in the DOM according to the data given.
//
// The constructor, then, pulls out the data from the DOM. That way, the DOM
// can be prepopulated and the content can work without JavaScript (styled,
// visual depiction of a graph) even if you cannot move things around or
// interact dynamically.

/**
 * This represents a node in the workflow graph.
 */
class Node extends MovableComponent {
    /**
     * Creates a Node based on the given DOM element.
     *
     * @param {HTMLElement} element The `<li>` element representing the node.
     */
    constructor(element, options) {
        super(element);

        this._options = options;
        
        // Instantiate arrays to hold port information
        this._ports = {
            "inputs": [],
            "outputs": [],
            "ports": []
        };

        // Ensure buttons exist
        ((options || {}).buttons || []).forEach( (buttonInfo, i) => {
            let button = document.createElement("div");
            (buttonInfo.classes || []).forEach(function(buttonClass) {
              button.classList.add(buttonClass);
            });

            if (options.buttons.length == 1) {
              button.classList.add("of-one");
            }

            if (options.buttons.length == 2) {
              button.classList.add("of-two");
            }

            if (options.buttons.length == 3) {
              button.classList.add("of-three");
            }

            if (options.buttons.length == 4) {
              button.classList.add("of-four");
            }

            button.classList.add("bottom-button");
            button.classList.add(["one", "two", "three", "four"][i]);

            if ((buttonInfo.inactive || {}).icon) {
                let img = document.createElement("img");
                img.src = buttonInfo.inactive.icon;
                button.appendChild(img);
            }

            if ((buttonInfo.hover || {}).icon) {
                let img = document.createElement("img");
                img.src = buttonInfo.hover.icon;
                img.classList.add("hover");
                button.appendChild(img);
            }

            element.appendChild(button);
        });

        // Attach callbacks to buttons
        let buttons = element.querySelectorAll(".bottom-button");
        buttons.forEach( (button) => {
            // Prevent this button from moving the node
            button.addEventListener("mousedown", (event) => {
                event.preventDefault();
                event.stopPropagation();
            });

            button.addEventListener("mouseup", (event) => {
                this.trigger("button-click", {
                  element: button,
                  node: this
                });
            });
        });

        let deleteButton = element.querySelector(".delete-button");
        // Prevent this button from moving the node
        if (deleteButton) {
            deleteButton.addEventListener("mousedown", (event) => {
                event.preventDefault();
                event.stopPropagation();
            });

            deleteButton.addEventListener("mouseup", (event) => {
                this.destroy();
            });
        }

        let inputAddButton  = element.querySelector(".input-add-button");
        let outputAddButton = element.querySelector(".output-add-button");
        let portAddButton   = element.querySelector(".port-add-button");

        // Prevent these buttons from moving the node
        if (inputAddButton) {
            inputAddButton.addEventListener("mousedown", (event) => {
                event.preventDefault();
                event.stopPropagation();
            });

            // Open dropdown
            inputAddButton.addEventListener("mouseup", (event) => {
                this.showInputDropdown(inputAddButton);
                event.preventDefault();
                event.stopPropagation();
            });
        }

        if (outputAddButton) {
            outputAddButton.addEventListener("mousedown", (event) => {
                event.preventDefault();
                event.stopPropagation();
            });

            // Open dropdown
            outputAddButton.addEventListener("mouseup", (event) => {
                this.showOutputDropdown(outputAddButton);
                event.preventDefault();
                event.stopPropagation();
            });
        }

        if (portAddButton) {
            portAddButton.addEventListener("mousedown", (event) => {
                event.preventDefault();
                event.stopPropagation();
            });

            // Open dropdown
            portAddButton.addEventListener("mouseup", (event) => {
                this.showPortDropdown(portAddButton);
                event.preventDefault();
                event.stopPropagation();
            });
        }

        this.updatePortAddButtons();
    }

    /**
     * Returns the index of this Node.
     *
     * @returns {Number} The index. The first node is at index 0.
     */
    get index() {
        return Util.getChildIndex(this.element, ".connection");
    }

    /**
     * Nodes can be selected.
     */
    get selectable() {
        return true;
    }

    /**
     * Whether or not the component is currently movable.
     */
    get movable() {
        return this._options.allowNodeMovement && this.visibility != "hidden";
    }

    /**
     * Nodes can be selected as part of regions.
     */
    get regionSelectable() {
        return this.visibility != "hidden";
    }

    /**
     * Nodes can be collided.
     */
    get collidable() {
        return this.visibility != "hidden";
    }

    /**
     * Get the node target information.
     */
    get targets() {
        return this._target || {};
    }

    /**
     * Sets the node target information or removes it if null.
     */
    set targets(info) {
        info = info || [];
        if (!Array.isArray(info)) {
            info = [info];
        }
        this._targets = info;

        // Remove target elements
        this.element.querySelectorAll(".target").forEach( (element) => {
            element.remove();
        });

        let aClasses = ["one", "two", "three", "four"];
        let bClasses = ["of-one", "of-two", "of-three", "of-four"];

        let count = 0;
        (this._targets || []).forEach( (target) => {
            if (target.name) {
                let element = document.createElement("div");
                element.classList.add("target");
                element.classList.add(bClasses[Math.min(this._targets.length - 1, 3)]);
                element.classList.add(aClasses[Math.min(count, 3)]);
                count++;
                this.element.appendChild(element);

                element.setAttribute("title", target.name || "Unknown Target");
                element.textContent = target.tag || "";

                if (target.color) {
                    element.style.color = target.color;
                    element.style.borderColor = target.color;
                }
            }
        });

        this.trigger("update");
    }

    /**
     * Get the node type.
     */
    get type() {
        if (!this._type) {
            let typeLabel = this.element.querySelector(":scope > span.type");
            if (typeLabel) {
                this._type = typeLabel.textContent.trim();
            }
        }

        return this._type || "";
    }

    /**
     * Update the node's type.
     */
    set type(value) {
        this._type = value;

        let typeLabel = this.element.querySelector(":scope > span.type");
        if (typeLabel) {
            typeLabel.textContent = value;
        }
    }

    /**
     * Get the node name.
     */
    get name() {
        if (!this._name) {
            let nameLabel = this.element.querySelector(":scope > span.name");
            if (nameLabel) {
                this._name = nameLabel.textContent.trim();
            }
        }

        return this._name || "";
    }

    /**
     * Update the node's name.
     */
    set name(value) {
        this._name = value;

        let nameLabel = this.element.querySelector(":scope > span.name");
        if (nameLabel) {
            nameLabel.textContent = value;
        }
    }

    /**
     * Get the node's icon.
     */
    get icon() {
        if (!this._icon) {
            let icon = this.element.querySelector(":scope > .icon");
            if (icon) {
                this._icon = icon.src.trim();
                if (this._options.imageBaseURL) {
                    this._icon = this._icon.substring(this.options.imageBaseURL.length);
                }
            }
        }

        return this._icon;
    }

    /**
     * Update the node's icon.
     */
    set icon(value) {
        this._icon = value;

        let icon = this.element.querySelector(":scope > .icon");
        if (icon) {
            if (value) {
                icon.src = (this._options.imageBaseURL || "") + value;
            }
            else {
                icon.removeAttribute("src");
            }
        }
    }

    /**
     * Returns the visibility of this Node.
     */
    get visibility() {
        return this.element.getAttribute("data-visibility");
    }

    /**
     * Sets the visibility of this Node.
     *
     * @params {string} value The visibility value, based on CSS: "visible" or "hidden".
     */
    set visibility(value) {
        value = value || "visible";

        if (value === "hidden") {
            // If we aren't attached to a visible wire, then hide this node
            let partialVisibility = "hidden";
            this.allPorts.forEach( (port) => {
                port.wires.forEach( (wire) => {
                    let portB = wire.portStart;

                    if (portB === port) {
                        portB = wire.portEnd;
                    }

                    if (portB && portB.visibility !== "hidden") {
                        if (portB.node.visibility !== "hidden") {
                            partialVisibility = "visible";
                        }
                    }
                });
            });

            if (partialVisibility === "hidden") {
                this.element.setAttribute("hidden", true);
                this.element.setAttribute("aria-hidden", "true");
                this.element.classList.remove("partially-visible");
            }
            else {
                this.element.removeAttribute("hidden");
                this.element.removeAttribute("aria-hidden");
                this.element.classList.add("partially-visible");
            }

            this.unselect();
            this.trigger("hide");
        }
        else {
            this.element.removeAttribute("hidden");
            this.element.removeAttribute("aria-hidden");
            this.trigger("show");
        }

        this.element.setAttribute("data-visibility", value);
    }

    /**
     * Returns all of the user-defined metadata for this node.
     */
    get data() {
        return this._data;
    }

    /**
     * Resets the given node metadata data to the given object.
     */
    set data(value) {
        this._data = {};
        for (let key in value) {
            this.setData(key, value[key]);
            this._data[key] = value[key];
        }
    }

    /**
     * Retrieve a particular metadata value for this node.
     */
    getData(key) {
        this._data = this._data || {};

        if (this._data[key]) {
            this._data[key] = this.element.getAttribute("data-" + key);
        }

        return this._data[key];
    }

    /**
     * Set a particular metadata value for this node.
     */
    setData(key, value) {
        this._data = this._data || {};

        this.element.setAttribute("data-" + key, value);
    }

    /**
     * Destroy this node which means disconnecting everything.
     */
    destroy() {
        this.allPorts.forEach( (port) => port.destroy() );
        this.element.remove();
        this.trigger("destroy", {
            node: this
        });
    }

    /**
     * Retrieves a comprehensive list of all Ports, including inputs, outputs,
     * and general ports.
     *
     * @returns {Array} The list of Port objects.
     */
    get allPorts() {
        return Array.prototype.concat(this._ports.inputs, this._ports.outputs).concat(this._ports.ports);
    }

    /**
     * Based on the current status, select or unselect this Node.
     */
    toggle() {
        if (this.selected) {
            this.unselect();
        }
        else {
            this.select();
        }
    }

    /**
     * Whether or not this Node is currently selected.
     */
    get selected() {
        return this._element.classList.contains("selected");
    }

    /**
     * Selects this Node.
     */
    select() {
        this.element.classList.add("selected");
        
        // Highlight wires
        if (this._options.highlightWiresOnSelect) {
            // For every wire, add its highlight class
            this.allPorts.forEach( (port) => {
                port.wires.forEach( (wire) => {
                    wire.path.classList.add("highlight");
                });
            });
        }
    }

    /**
     * Unselects this Node.
     */
    unselect() {
        this.element.classList.remove("selected");
        this.unview();
        this.hideDropdown();

        // Unhighlight wires
        if (this._options.highlightWiresOnSelect) {
            // For every wire, remove its highlight class
            this.allPorts.forEach( (port) => {
                port.wires.forEach( (wire) => {
                    wire.path.classList.remove("highlight");
                });
            });
        }
    }

    /**
     * Marks this Node as being 'viewed'.
     */
    view() {
        this.element.classList.add("viewing");
    }

    /**
     * Clears this Node as being 'viewed'.
     */
    unview() {
        this.element.classList.remove("viewing");
    }

    /**
     * Marks this Node as being 'highlighted'.
     */
    highlight() {
        this.element.classList.add("highlighted");
    }

    /**
     * Clears this Node as being 'highlighted'.
     */
    unhighlight() {
        this.element.classList.remove("highlighted");
    }

    /**
     * Reorganizes the ports on the DOM.
     */
    rearrangePorts() {
        // For each direction, arrange the port
        ["left", "top", "bottom", "right"].forEach( (direction) => {
            let ports = this.element.querySelectorAll("ol.ports li.port." + direction + ":not([hidden])");
            let portCount = ports.length;

            ports.forEach( (portElement, i) => {
                let coord = "top";
                let totalLength = this.height;
                let label = portElement.querySelector(".label");
                label.classList.remove("truncated");

                if (direction == "top" || direction == "bottom") {
                    portElement.style.top = "";
                    coord = "left";
                }
                else {
                    portElement.style.left = "";
                }

                if (direction == "top" || direction == "bottom") {
                  totalLength = this.width * 0.9;
                }

                let sliceLength = totalLength / portCount;
                portElement.style[coord] = (sliceLength * i) + (sliceLength / 2) + "px";

                // If the slice is not big enough to accommodate the label,
                // we truncate the label.
                if (coord === "left" && sliceLength < label.clientWidth) {
                    label.classList.add("truncated");
                }
                else if (coord === "top" && sliceLength < label.clientHeight) {
                    label.classList.add("truncated");
                }
                else {
                    label.classList.remove("truncated");
                }
            });
        });
    }

    /**
     * Moves the node.
     *
     * @param {number} x The x world coordinate to position the node.
     * @param {number} y The y world coordinate to position the node.
     */
    move(x, y) {
        super.move(x, y);

        this.redraw();
    }

    /**
     * Redraws the node and the attached wires.
     */
    redraw() {
        if (this._redrawing) {
            return;
        }
        this._redrawing = true;
        this.rearrangePorts();

        this.allPorts.forEach( (port) => {
            port.redraw();
        });
        this._redrawing = false;
    }

    /**
     * Retrieve the general Port at the given index.
     *
     * @returns {Port} The Port at that given index or undefined.
     */
    portAt(index) {
        return this._ports.ports[index];
    }

    /**
     * Retrieve the input Port at the given index.
     *
     * @returns {Port} The Port at that given index or undefined.
     */
    inputAt(index) {
        return this._ports.inputs[index];
    }

    /**
     * Retrieve the output Port at the given index.
     *
     * @returns {Port} The Port at that given index or undefined.
     */
    outputAt(index) {
        return this._ports.outputs[index];
    }

    /**
     * Retrieves the list of general ports.
     */
    get ports() {
        return this._ports.ports;
    }

    /**
     * Retrieves the list of input ports.
     */
    get inputs() {
        return this._ports.inputs;
    }

    /**
     * Retrieves the list of output ports.
     */
    get outputs() {
        return this._ports.outputs;
    }

    /**
     * Retrieves the list of general ports that are currently hidden.
     */
    get hiddenPorts() {
        return this._ports.ports.filter( (port) => port.visibility != "visible" );
    }

    /**
     * Retrieves the list of input ports that are currently hidden.
     */
    get hiddenInputs() {
        return this._ports.inputs.filter( (port) => port.visibility != "visible" );
    }

    /**
     * Retrieves the list of output ports that are currently hidden.
     */
    get hiddenOutputs() {
        return this._ports.outputs.filter( (port) => port.visibility != "visible" );
    }

    _fillDropdown(dropdown, ports) {
        // Clear the dropdown
        dropdown.querySelectorAll("li").forEach( (element) => element.remove() );

        // Add hidden ports
        ports.forEach( (port) => {
            let item = document.createElement("li");
            let type = document.createElement("span");
            type.classList.add('type');
            type.textContent = port.type;
            type.classList.add("type");
            item.appendChild(type);
            let name = document.createElement("span");
            name.classList.add('name');
            name.textContent = port.name;
            name.classList.add("name");
            item.appendChild(name);
            dropdown.appendChild(item);

            item.addEventListener("mousedown", (event) => {
                port.visibility = "visible";
                this.redraw();
                this.hideDropdown();
                this.updatePortAddButtons();

                // Stop the node from moving/unselecting
                event.stopPropagation();
                event.preventDefault();
            });
        });
    }

    // Show the given dropdown
    _showDropdown(dropdown, button) {
        let x = 0;
        let y = 0;

        // Determine the location of the button (middle, bottom aligned)
        y = button.offsetTop + button.offsetHeight;
        x = button.offsetLeft + (button.offsetWidth / 2);

        dropdown.removeAttribute("hidden");
        dropdown.style.left = (x - dropdown.clientWidth / 2) + "px";
        dropdown.style.top  = (y) + "px";
        dropdown.setAttribute("tabindex", "-1");

        let blurEvent = (event) => {
            if (!dropdown.contains(event.target)) {
                this.hideDropdown();
                document.removeEventListener("mousedown", blurEvent);
                dropdown.removeEventListener("blur", blurEvent);
            }
        };
        document.addEventListener("mousedown", blurEvent);
        dropdown.addEventListener("blur", blurEvent);

        dropdown.focus();
    }

    /**
     * Reveals the dropdown for any hidden general ports, of any.
     */
    showPortDropdown(button) {
        this.hideDropdown();

        let dropdown = this.element.querySelector(".wire-dropdown.ports");
        this._fillDropdown(dropdown, this.hiddenPorts);
        this._showDropdown(dropdown, button);
    }

    /**
     * Reveals the dropdown for any hidden input ports, of any.
     */
    showInputDropdown(button) {
        this.hideDropdown();

        let dropdown = this.element.querySelector(".wire-dropdown.inputs");
        this._fillDropdown(dropdown, this.hiddenInputs);
        this._showDropdown(dropdown, button);
    }

    /**
     * Reveals the dropdown for any hidden output ports, of any.
     */
    showOutputDropdown(button) {
        this.hideDropdown();

        let dropdown = this.element.querySelector(".wire-dropdown.outputs");
        this._fillDropdown(dropdown, this.hiddenOutputs);
        this._showDropdown(dropdown, button);
    }

    /**
     * Hides any dropdown that is currently displayed.
     */
    hideDropdown() {
        this.element.querySelectorAll(".wire-dropdown").forEach( (element) => {
            element.setAttribute("hidden", true);
            element.removeAttribute("tabindex");
        });
    }

    /**
     * Updates a job related to this node.
     *
     * Will add the job if the given id is unique.
     *
     * This will render the job status graphically.
     */
    updateJob(info) {
        if (!this._jobs) {
            this.element.classList.add('tracking-jobs');
            this._jobs = new JobDonut(this.element, this._options.job);
        }

        this._jobs.append(info);
    }

    /**
     * Updates the node to reflect the given serialization.
     *
     * @param {object} json The pre-parsed JSON serialization.
     */
    fromJSON(json) {
        // Sanitize the incoming json
        json.position   = json.position   || {};
        json.position.x = json.position.x || 0;
        json.position.y = json.position.y || 0;

        let element = this.element;

        this.type = json.type;
        this.name = json.name;
        this.icon = json.icon;

        this.data = json.data;

        this.targets = json.targets || json.target;

        let inputsElement  = element.querySelector(":scope > ul.inputs");
        let outputsElement = element.querySelector(":scope > ul.outputs");

        // Position Node
        element.style.left = json.position.x + "px";
        element.style.top  = json.position.y + "px";

        // Add "input"/"output"/other ports
        ["inputs", "outputs", "ports"].forEach( (portType) => {
            (json[portType] || []).forEach( (portInfo) => {
                // For each 'port' that is described, we create a Port
                // object, including its DOM representation.
                this.createPort(portType, portInfo);
            });
        });

        // Set visibility (after wires so we know if it is partially visible)
        this.visibility = json.visibility;

        // Update buttons to reflect what is visible
        this.updatePortAddButtons();

        // Put the ports in the appropriate spots
        this.rearrangePorts();
    }

    /**
     * Adds a port to this node.
     */
    createPort(portType, portInfo) {
        let element = this.element;
        let query = portType;
        if (portType == "ports") {
            query = "ports:not(.inputs):not(.outputs)";
        }
        let portCollection = element.querySelector("ol." + query);

        let port = Port.create(portInfo, this, this._options);
        if (portType == "inputs") {
            port.element.classList.add(portInfo.direction || this._options.inputOrientation);
        }
        else if (portType == "outputs") {
            port.element.classList.add(portInfo.direction || this._options.outputOrientation);
        }
        else {
            port.element.classList.add(portInfo.direction || this._options.portOrientation);
        }
        port.element.setAttribute("data-auto-orientation", this._options.portAutoOrientation);
        portCollection.appendChild(port.element);
        this._ports[portType].push(port);

        port.on("hide", (event) => {
            this.updatePortAddButtons();
            this.redraw();

            this.trigger("port-hide", port);
        });

        port.on("show", (event) => {
            this.trigger("port-show", port);
        });

        port.on("mouseenter", (event) => {
            this.trigger("port-mouseenter", port);
        });

        port.on("mouseleave", (event) => {
            this.trigger("port-mouseleave", port);
        });

        port.on("select", (event) => {
            this.trigger("port-select", port);
        });
    }

    /**
     * Ensures that the "reveal port" buttons are visible when they are valid.
     */
    updatePortAddButtons() {
        let element = this.element;

        let inputAddButton  = element.querySelector(".input-add-button");
        let outputAddButton = element.querySelector(".output-add-button");
        let portAddButton   = element.querySelector(".port-add-button");

        if (inputAddButton) {
            if (this.hiddenInputs.length == 0 || !this._options.allowNewConnections) {
                inputAddButton.setAttribute("hidden", '');
            }
            else {
                inputAddButton.removeAttribute("hidden");
            }
        }

        if (outputAddButton) {
            if (this.hiddenOutputs.length == 0 || !this._options.allowNewConnections) {
                outputAddButton.setAttribute("hidden", '');
            }
            else {
                outputAddButton.removeAttribute("hidden");
            }
        }

        if (portAddButton) {
            if (this.hiddenPorts.length == 0 || !this._options.allowNewConnections) {
                portAddButton.setAttribute("hidden", '');
            }
            else {
                portAddButton.removeAttribute("hidden");
            }
        }
    }

    /**
     * Returns a JSON-ready serialization of the node.
     *
     * @returns {object} The JSON-ready serialization of the node.
     */
    toJSON() {
        // Start with the defaults
        let ret = {
            type: this.type,
            name: this.name,
            icon: this.icon,
            position: {
                x: this.x,
                y: this.y
            }
        };

        // Include target metadata
        if (this.targets) {
            ret.targets = this.targets;
        }

        // Only include 'visibility' if it is non-default
        if (this.visibility && this.visibility != "visible") {
            ret.visibility = this.visibility;
        }

        // Encode every port
        ["inputs", "outputs", "ports"].forEach( (portType) => {
            let ports = this[portType].map( (port) => {
                return port.toJSON();
            });

            if (ports.length > 0) {
                ret[portType] = ports;
            }
        });

        // Include any other metadata
        if (this.data) {
          ret.data = this.data;
        }

        return ret;
    }

    /**
     * Creates a DOM element for a Node from the given node JSON serialization.
     *
     * @param {object} json The pre-parsed JSON serialization of the node.
     * @param {object} options The general workflow options.
     *
     * @returns {Node} The instantiated Node object.
     */
    static create(json, options, element = null) {
        if (!element) {
            element = document.createElement("li");
        }

        element.classList.add('connection');

        let icon = element.querySelector("img.icon");
        if (!icon) {
            icon = document.createElement("img");
        }
        icon.classList.add("icon");
        element.appendChild(icon);

        let typeLabel = element.querySelector("span.type");
        if (!typeLabel) {
            typeLabel = document.createElement("span");
        }
        typeLabel.classList.add("type");
        element.appendChild(typeLabel);

        let nameLabel = element.querySelector("span.name");
        if (!nameLabel) {
            nameLabel = document.createElement("span");
        }
        nameLabel.classList.add("name");
        element.appendChild(nameLabel);

        // Delete button
        if (options.allowNodeDeletion) {
            let deleteButton = element.querySelector(".delete-button");
            if (!deleteButton) {
                deleteButton = document.createElement("div");
            }
            deleteButton.classList.add("delete-button");
            element.appendChild(deleteButton);
        }

        // Port add buttons
        let inputAddButton = document.createElement("div");
        inputAddButton.classList.add("input-add-button");
        element.appendChild(inputAddButton);

        let outputAddButton = document.createElement("div");
        outputAddButton.classList.add("output-add-button");
        element.appendChild(outputAddButton);

        let portAddButton = document.createElement("div");
        portAddButton.classList.add("port-add-button");
        element.appendChild(portAddButton);

        // Create a dropdown menu (for each type of port)
        let inputsDropdown = document.createElement("div");
        inputsDropdown.classList.add("wire-dropdown");
        inputsDropdown.classList.add("inputs");
        element.appendChild(inputsDropdown);
        let outputsDropdown = document.createElement("div");
        outputsDropdown.classList.add("wire-dropdown");
        outputsDropdown.classList.add("outputs");
        element.appendChild(outputsDropdown);
        let portsDropdown = document.createElement("div");
        portsDropdown.classList.add("wire-dropdown");
        portsDropdown.classList.add("ports");
        element.appendChild(portsDropdown);

        // Add ports
        ["inputs", "outputs", "ports"].forEach( (portType) => {
            let portCollection = document.createElement("ol");
            portCollection.classList.add(portType);
            portCollection.classList.add("ports");

            element.appendChild(portCollection);
        });

        let node = new Node(element, options);
        if (json) {
            node.fromJSON(json);
        }

        return node;
    }
}

export default Node;
