"use strict";

import Quadtree from "./quadtree.js";
import Mover from "./mover.js";

import EventComponent from "./event_component.js";

/**
 * This represents a DOM surface. Each component within the World can be
 * moved, with some rules.
 *
 * Essentially, this class manages the interaction between a person and
 * the widget in terms of handling mouse and touch events. This class registers
 * event handlers and will pan, zoom, and move components around the overall
 * visualization.
 *
 * To add a component, just create a World based on an element, and then invoke
 * the add() member with an instance of a MovableComponent, which itself is
 * wrapping an element that is within the main element given to this
 * constructor.
 */
class World extends EventComponent {
    /**
     * Initializes the given element to be the container for the display.
     *
     * @param {HTMLElement} element The element containing all components.
     * @param {object} options The options governing the possible interactions.
     */
    constructor(element, options) {
        super();

        // Retain options
        this._options = options;

        // Default properties
        this._zoom = 1.0;
        this._x    = 0;
        this._y    = 0;

        // Initialize the elements
        this._element = element;

        // Get the draggable element
        this._draggable = this._element.querySelector(":scope > .draggable");

        // Get the SVG layers
        this._svg = this._element.querySelectorAll(":scope > svg");

        // Get the selection box
        this._selectionBox = this._element.querySelector(":scope > .selection");

        this.components = [];

        // Mouse Down Events
        this._element.addEventListener('mousedown',   this.mouseDownEvent.bind(this));
        this._element.addEventListener('mousewheel',  this.wheelEvent.bind(this));
        this._element.addEventListener('keydown',     this.keyDownEvent.bind(this));
        this._element.addEventListener('keypress',    this.keyEvent.bind(this));
        this._element.addEventListener('contextmenu', (event) => {
            let cur = event.target;
            while (cur && !cur.classList.contains('connections') && cur.tagName.toUpperCase() !== 'SVG') {
                cur = cur.parentNode;
            }

            if (cur) {
                event.stopPropagation();
                event.preventDefault();
            }
        });

        // Initialize a quadtree (for collidable and selectable things
        // respectively)
        this._quadtree = new Quadtree();
        this._quadtreeSelectable = new Quadtree();

        // What is currently selected
        this._selected = [];
        this._selectedSaved = [];
        this._selectedSafe = true;
    }

    /**
     * Returns the world's root element.
     */
    get element() {
        return this._element;
    }

    /**
     * Returns the world's panning element.
     */
    get draggable() {
        return this._draggable;
    }

    /**
     * The current zoom. 1.0 is the default zoom.
     */
    get zoom() {
        return this._zoom;
    }

    /**
     * Set the current zoom.
     */
    set zoom(value) {
        this._zoom = value;
        this.redraw();
    }

    /**
     * The current horizontal pan position of the world.
     */
    get x() {
        return this._x;
    }

    /**
     * The current vertical pan position of the world.
     */
    get y() {
        return this._y;
    }

    /**
     * Retrieves the world-relative X coordinate for the center.
     */
    get centerX() {
        let width  = this._element.clientWidth  / this._zoom;
        let height = this._element.clientHeight / this._zoom;

        return this.x + width/2;
    }

    /**
     * Retrieves the world-relative Y coordinate for the center.
     */
    get centerY() {
        let width  = this._element.clientWidth  / this._zoom;
        let height = this._element.clientHeight / this._zoom;

        return this.y + height/2;
    }

    /**
     * Returns a list of components that are currently selected.
     */
    get selected() {
        return this._selected.map( (selected) => { return selected.component; } );
    }

    /**
     * Adds the given component to the active display.
     *
     * @param {MovableComponent} component The component to add.
     */
    add(component) {
        this.components.push(component);

        // Place onto the plane
        this._draggable.appendChild(component.element);

        // Redraw
        if (component.redraw) {
            component.redraw();
        }

        // Add into our quadtree
        if (component.collidable) {
            this._quadtree.add(component);
        }

        if (component.selectable) {
            if (component.regionSelectable) {
                this._quadtreeSelectable.add(component);
            }

            component.on("select", (event) => {
                if (!event.shiftKey) {
                    // When shift is not held, we are selecting a single node
                    // With shift, the node is added to the selection
                    this.selectionClear();
                    component.view();
                }
                else {
                    // In this case, we want to 'unview' the first item,
                    // if our selection has a single item already
                    if (this._selected.length == 1) {
                        this._selected[0].component.unview();
                    }
                }

                if (component.selected) {
                    component.unselect();
                }
                else {
                    component.select();
                }

                this._selected.push({ component: component, x: component.x, y: component.y});
                this._selectedSaved.push({ x: component.x, y: component.y});
            });

            component.on("mouseup", (event) => {
                component.select();
            });
        }

        // Move component when it is clicked upon.
        component.on("drag", (event) => {
            if (this._selected.length > 0) {
                if (this._selected[0].component !== component &&
                    !component.element.classList.contains("selected")) {
                    this.selectionClear();
                }
                else if (this._selected[0].component === component ||
                    component.element.classList.contains("selected")) {
                    this.selectionMove(event.delta.x, event.delta.y);
                }
            }
            else {
                // Moving a single node
                this.selectionClear();
                this._selected.push({ component: component, x: component.x, y: component.y});
                this._selectedSaved.push({ x: component.x, y: component.y});
                this.selectionMove(event.delta.x, event.delta.y);
            }
        });

        // Commit the movement of this component
        // (and revert to a safe place otherwise)
        component.on("move", (event) => {
            this._selected.forEach( (selected, i) => {
                // Move the nodes to their last safe location (if needed)
                if (!this._selectedSafe) {
                    this.move(selected.component, this._selectedSaved[i].x,
                                                  this._selectedSaved[i].y);
                }

                selected.x = selected.component.x;
                selected.y = selected.component.y;
            });

            this._selectedSafe = true;
        });
    }

    /**
     * Removes the given component from the display.
     *
     * @param {MovableComponent} component The component to remove.
     */
    remove(component) {
        let index = this.components.indexOf(component);
        if (index >= 0) {
            this.components.splice(index, 1);
        }
        this._quadtree.remove(component);
    }

    /** 
     * Moves the world relative to its current position.
     *
     * @param {number} deltaX The amount to move horizontally.
     * @param {number} deltaY The amount to move vertically.
     */
    pan(deltaX, deltaY) {
        this.moveTo(this._x + deltaX, this._y + deltaY);
    }

    /**
     * Moves the world such that the given world coordinate is at the center.
     */
    center(x, y) {
        let width  = this._element.clientWidth  / this._zoom;
        let height = this._element.clientHeight / this._zoom;

        this.moveTo(x - width/2, y - height/2);
    }

    /**
     * Pans the world to the given position.
     *
     * @param {number} x The horizontal position.
     * @param {number} y The vertical position.
     */
    moveTo(x, y) {
        this._x = x;
        this._y = y;

        let width  = this._element.clientWidth  / this._zoom;
        let height = this._element.clientHeight / this._zoom;

        // Move the plane
        this._draggable.style.left = -(this._x * this._zoom) + "px";
        this._draggable.style.top  = -(this._y * this._zoom) + "px";
        this._draggable.style.transform = "scale(" + this._zoom + ")";
        this._draggable.style.transformOrigin = "top left";

        this._svg.forEach( (svg) => {
            svg.setAttribute("width", width + "px");
            svg.setAttribute("height", height + "px");
            svg.style.transform = "scale(" + this._zoom + ")";
            svg.style.transformOrigin = "top left";
            svg.setAttribute("viewBox", this._x + " " + this._y + " " + width + " " + height);
        });

        this.trigger("pan");
    }

    /**
     * Moves the given component to the given position.
     *
     * If the component is collidable, it may not be able to move since another
     * object is already located in that spot. Therefore, it will return false
     * so that the move may be reverted.
     *
     * @returns {bool} Returns true when the move is clean, and false when it collides.
     */
    move(component, x, y) {
        let ret = true;

        // Look for snap points
        let snapY;
        let snapX;

        // TODO: we could improve this by having the quadtree give us
        //       local nodes (and caching until the selection is cleared)
        // Determine the set of components that aren't in the selection
        if (this._options.verticalSnapTolerance > 0 && this._options.horizontalSnapTolerance > 0) {
            let unselected = this.components.filter( (node) => {
                return this._selected.filter( (selected) => node === selected.component ).length == 0;
            });

            unselected.forEach( (node) => {
                if (node === component) {
                    return;
                }

                if (node.visibility == "hidden") {
                    return;
                }

                if ((node.y - this._options.verticalSnapTolerance) < y &&
                    (node.y + this._options.verticalSnapTolerance) > y) {
                    snapY = Math.min(snapY || node.y, node.y);
                }

                if ((node.x - this._options.horizontalSnapTolerance) < x &&
                    (node.x + this._options.horizontalSnapTolerance) > x) {
                    snapX = Math.min(snapX || node.x, node.x);
                }
            });
        }

        // Now look at connected nodes for snapping as well.
        // TODO: make this agnostic to the node?
        if (component.allPorts) {
            component.allPorts.forEach( (port) => {
                port.wires.forEach( (wire) => {
                    let portB = wire.portStart;
                    if (portB === port) {
                        portB = wire.portEnd;
                    }
                    if (portB && portB.visibility !== "hidden") {
                        let nodeB = portB.node;
                        if (nodeB.visibility !== "hidden") {
                            // Snap to this maybe
                            // relativeX/Y is the node's Y if it were aligned to
                            // the port it is connected to.
                            let relativeX = (portB.node.x + portB.left) - port.left;
                            let relativeY = (portB.node.y + portB.top)  - port.top;

                            // Is this going to be a straight line?
                            if ((portB.direction === "left"  && port.direction === "right") ||
                                (portB.direction === "right" && port.direction === "left")) {
                                if ((relativeY - this._options.verticalSnapTolerance) < y &&
                                    (relativeY + this._options.verticalSnapTolerance) > y) {
                                    snapY = Math.min(snapY || relativeY, relativeY);
                                }
                            }
                        }
                    }
                });
            });
        }

        if (snapY !== undefined) {
            y = snapY;
        }

        if (snapX !== undefined) {
            x = snapX;
        }

        // Move the element
        component.move(x, y);

        if (component.regionSelectable) {
            this._quadtreeSelectable.update(component);
        }

        if (component.collidable) {
            this._quadtree.update(component);

            // Did we collide?
            var collisions = this._quadtree.queryOverlapping(component.x - 30,
                                                             component.y - 30,
                                                             component.width + 60,
                                                             component.height + 60);

            collisions.forEach( (subComponent) => {
                if (subComponent != component) {
                    ret = false;
                }
            });
        }

        return ret;
    }

    /**
     * Moves all components currented selected by the given relative position.
     */
    selectionMove(deltaX, deltaY) {
        // Move each element
        let safe = true;
        this._selected.forEach( (selected) => {
            safe = this.move(selected.component, selected.x + deltaX, selected.y + deltaY) && safe;
        });

        this._selectedSafe = safe;

        if (this._selectedSafe) {
            // Update safe coordinates.
            this._selectedSaved = this._selected.map( (selected) => {
                return { x: selected.component.x, y: selected.component.y };
            });
        }
    }

    /**
     * Redraws the surface.
     */
    redraw() {
        this.moveTo(this._x, this._y);
    }

    /**
     * Selects all nodes.
     */
    selectAll() {
        this.components.forEach( (component) => {
            if (component.regionSelectable) {
                component.select();
                this._selected.push({ component: component, x: component.x, y: component.y});
                this._selectedSaved.push({ x: component.x, y: component.y});
            }
        });
    }

    /**
     * Clears the current selection.
     */
    selectionClear() {
        this._selectionBox.style.width  = 0 + "px";
        this._selectionBox.style.height = 0 + "px";

        this._selected.forEach( (selected, i) => {
            // Move the nodes to their last safe location
            if (!this._selectedSafe) {
                this.move(selected.component, this._selectedSaved[i].x,
                                              this._selectedSaved[i].y);
            }
            selected.component.unselect();
            selected.component.unview();
        });

        this._selected = [];
        this._selectedSafe = true;
        this._selectedSaved = [];
    }

    /**
     * When the surface is dragged for selection.
     */
    selectionDragEvent(event) {
        // The selection box's left/top:
        let box = this._element.getBoundingClientRect();

        let origin = event.origin;
        origin.x -= box.left;
        origin.y -= box.top;

        // The selection box's width/height:
        let delta  = event.delta;

        if (delta.x < 0) {
            origin.x += delta.x;
            delta.x = -delta.x;
        }

        if (delta.y < 0) {
            origin.y += delta.y;
            delta.y = -delta.y;
        }

        // Show the selection
        this._selectionBox.style.display = "block";
        this._selectionBox.style.width   = delta.x  + "px";
        this._selectionBox.style.height  = delta.y  + "px";
        this._selectionBox.style.left    = origin.x + "px";
        this._selectionBox.style.top     = origin.y + "px";
    }

    /**
     * When the surface is no longer dragged for a selection.
     */
    selectionDragEndEvent(event) {
        // Get the bounds of the selection
        let x = parseInt(this._selectionBox.style.left) + this.x;
        let y = parseInt(this._selectionBox.style.top) + this.y;
        let width = parseInt(this._selectionBox.style.width);
        let height = parseInt(this._selectionBox.style.height);

        // Clear the selection box
        this._selectionBox.style.display = "none";

        if (width == 0 || height == 0) {
            return;
        }

        this._quadtreeSelectable.queryWithin(x, y, width, height).forEach( (component) => {
            component.select();
            this._selected.push({ component: component, x: component.x, y: component.y});
            this._selectedSaved.push({ x: component.x, y: component.y});
        });
    }

    /**
     * When the surface is dragged for a pan.
     */
    panDragEvent(event) {
        let delta = event.delta;

        // Pan the surface
        this.moveTo(this._panStartX - (delta.x / this._zoom),
                    this._panStartY - (delta.y / this._zoom));
    }

    /**
     * Handles mouse wheel events.
     */
    wheelEvent(event) {
    }

    /**
     * Handles mousedown events.
     */
    mouseDownEvent(event) {
        this.trigger("mousedown", event);

        if (event.button == 0) {
            // Clear selection (if shift isn't held)
            if (!event.shiftKey) {
                this.selectionClear();
            }

            if (this._options.allowSelections) {
                let mover = new Mover(event);

                // Handle selection drawing
                mover.on("dragged", this.selectionDragEvent.bind(this));
                mover.on("stopped", this.selectionDragEndEvent.bind(this));
            }
        }
        else if (event.button == 2) {
            if (this._options.allowPanning) {
                this._panStartX = this._x;
                this._panStartY = this._y;

                let mover = new Mover(event);
                mover.on("dragged", this.panDragEvent.bind(this));
            }
        }
    }

    /**
     * This function handles any keydown event.
     */
    keyDownEvent(event) {
        var key = String.fromCharCode(event.which).toLowerCase();

        if (event.ctrlKey || event.metaKey) {
            // CTRL+A: Select All
            if (key == "a") {
                event.preventDefault();
                this.selectAll();
            }
        }
    }

    /**
     * This function handles any keypress event.
     */
    keyEvent(event) {
        var key = event.char || event.key;

        // 0: Reset Zoom
        if (key == "0") {
            this.zoom = 1.0;

            event.stopPropagation();
            event.preventDefault();
        } // =, -: Zoom
        else if (key == "=" || key == "-") {
            let zoom = this.zoom;

            if (key == "=") {
                zoom += 0.25;
            }
            else {
                zoom -= 0.25;
            }

            if (zoom < 0.25) {
                zoom = 0.25;
            }

            this.zoom = zoom;

            event.stopPropagation();
            event.preventDefault();
        }
    }
}

export default World;
