"use strict";

import EventComponent from "../event_component.js";
import Search         from '../search.js';
import OccamObject    from '../occam_object.js';
import Util           from "../util.js";
import Tabs           from "../tabs.js";
import Selector       from "../selector.js";
import Modal          from "../modal.js";

/**
 * This implements behaviors for the objects/add-dependency modal.
 */
class AddDependencyModal extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            return;
        }

        this.element = element;

        // Find the errors section
        this.errorsList = this.element.parentNode.querySelector(".errors");

        // Find the form
        this.form = this.element.parentNode.querySelector("form#new-dependency");

        // Find the button and other form elements
        if (this.form) {
            this.messageField = this.form.querySelector('input[name="message"]');
            this.messageTemplate = this.messageField.value;
            this.nameField = this.form.querySelector('input#add-dependency-name');
            this.typeField = this.form.querySelector('input#add-dependency-type');
            this.idField = this.form.querySelector('input#add-dependency-id');
            this.uidField = this.form.querySelector('input#add-dependency-uid');
            this.submitButton = this.form.querySelector('input[name="submit"]');
            this.versionField = Selector.load(this.form.querySelector('#add-dependency-version'));
            this.modulesField = this.form.querySelector('input#add-dependency-modules');
            this.subversionField = this.form.querySelector('input#add-dependency-subversion');
            this.summaryField = this.form.querySelector('input#add-dependency-summary');

            this.previewObject = this.form.querySelector(".objects-container ul.objects li.object"); 
            this.previewLink = this.form.querySelector(".objects-container ul.objects li.object a"); 
            this.previewImage = this.previewObject.querySelector("svg.icon > use");
            this.previewName = this.previewObject.querySelector("p.name");
            this.previewType = this.previewObject.querySelector("p.type");
        }

        // Tabs
        this.tabs = Tabs.load(this.element.querySelector("ul.tab-bar"));

        // Search
        this.search = Search.load(this.element);

        AddDependencyModal._count++;
        this.element.setAttribute('data-add-dependency-modal-index', AddDependencyModal._count);

        AddDependencyModal._loaded[this.element.getAttribute('data-add-dependency-modal-index')] = this;

        // Detect if part of Modal (I know... confusing, but this can be not-a-modal)
        this.withinModal = false;
        if (Util.getParents(this.element, ".modal-window", ".modal-window").length > 0) {
            this.withinModal = true;
        }

        this.bindEvents();
    }

    static loadAll(element) {
        var elements = element.querySelectorAll('h1.new-dependency ~ .card.new-dependency');

        elements.forEach(function(element) {
            AddDependencyModal.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-add-dependency-modal-index');

        if (index) {
            return AddDependencyModal._loaded[index];
        }

        return new AddDependencyModal(element);
    }

    bindEvents() {
        if (this.tabs) {
            this.tabs.on('change', () => {
                // TODO: handle sidebar changes, if needed
            });
        }

        // Handle selection of object
        if (this.search) {
            this.search.on('change', () => {
                // Search results were updated...
                // We can bind events on the object listing.
                let list = this.search.element.querySelector(".results .search-results-preview-container");

                list.querySelectorAll("li.object a").forEach( (object) => {
                    object.addEventListener('click', (event) => {
                        event.stopPropagation();
                        event.preventDefault();

                        let node = object.parentNode;

                        if (this.form) {
                            // Update form entries
                            this.nameField.value = node.querySelector('p.name').textContent.trim();
                            this.typeField.value = node.getAttribute('data-object-type');
                            this.idField.value = node.getAttribute('data-object-id');
                            this.uidField.value = node.getAttribute('data-object-uid');
                            this.submitButton.removeAttribute('disabled');

                            let summaryValue = node.querySelector('p.summary');
                            if (summaryValue) {
                                this.summaryField.value = summaryValue.textContent;
                            }

                            // Mark the version dropdown as loading
                            this.versionField.loading();

                            // Update dummy object representation
                            let imageURL = node.querySelector("svg.icon > use").getAttribute('xlink:href');
                            this.previewImage.setAttribute('xlink:href', imageURL);
                            this.previewName.textContent = this.nameField.value;
                            this.previewType.textContent = this.typeField.value;
                            this.previewObject.classList.remove("empty");
                            this.previewLink.classList.remove("empty");

                            // Pull down version information
                            let object = new OccamObject(this.idField.value);
                            let statusURL = object.url({
                                path: "/status"
                            });

                            Util.getJSON(statusURL, (data) => {
                                Util.getJSON(object.url({ path: "/raw/object.json" }), (info) => {
                                    // Pull out a version number
                                    if (data.sortedVersions) {
                                        // We also want to filter by subversion, if there is one specified
                                        if (this.subversionField && this.subversionField.value) {
                                            // Retrieve the subversion we are filtering by
                                            let subversion = this.subversionField.value;

                                            // Filter out any versions that don't have the specified subversion
                                            // And then remove the subversion from the version tag
                                            data.sortedVersions = data.sortedVersions.filter( (tag) => {
                                                return tag.endsWith(`@${subversion}`);
                                            }).map( (tag) => {
                                                return tag.split('@')[0];
                                            });
                                        }

                                        var latest = data.sortedVersions[data.sortedVersions.length - 1];
                                        this.versionField.value = latest;

                                        // Append to the version list
                                        data.sortedVersions.forEach( (tag) => {
                                            this.versionField.append({ name: tag });
                                        });
                                    }

                                    // Un-mark the version dropdown as loading
                                    this.versionField.loaded();
                                    this.versionField.select(this.versionField.items().length - 1);

                                    // Pull out module information
                                    this.modulesField.value = "";
                                    if (info.modules) {
                                        this.modulesField.value = "JSON:" + JSON.stringify(info.modules);
                                    }
                                });
                            });

                            // Re-enable and go to Metadata tab
                            let tab = this.tabs.tabAt(1);
                            tab.removeAttribute('disabled');
                            this.tabs.select(1);

                            // Update commit message text
                            this.messageField.value = Util.formatString(this.messageTemplate, {
                                name: this.nameField.value,
                                type: this.typeField.value
                            });

                            // Submit the form and close the modal, if within one.
                            if (this.withinModal) {
                                /*Util.submitForm(this.form, (html) => {
                                    Modal.close();
                                });*/
                            }
                        }
                    });
                });
            });
        }
    }
}

AddDependencyModal._count  = 0;
AddDependencyModal._loaded = {};

export default AddDependencyModal;
