"use strict";

import EventComponent from './event_component.js';
import Util           from './util.js';

/**
 * This represents a dropdown menu used for navigation purposes or selecting
 * an action from a list of possible actions.
 *
 * Generally, you instantiate a Dropdown using Dropdown.load(element)
 * instead of invoking this directly.
 *
 * @param {HTMLElement} element The main element for the dropdown menu;
 *                              typically the `<ul>` element.
 */
class Dropdown extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            return;
        }

        this.element     = element;
        this.linkElement = this.element.querySelector("a, input, button:not([aria-hidden='true'])");
        this.menuElement = this.element.querySelector("ul.dropdown-menu-options");

        Dropdown._count++;
        this.element.setAttribute('data-loaded-index', Dropdown._count);

        Dropdown._loaded[this.element.getAttribute('data-loaded-index')] = this;

        this.bindEvents();
    }

    static loadAll(element) {
        var dropdowns = element.querySelectorAll('.dropdown-menu');

        dropdowns.forEach(function(element) {
            Dropdown.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-loaded-index');

        if (index) {
            return Dropdown._loaded[index];
        }

        return new Dropdown(element);
    }

    blurEvent(event) {
        // Detect if focus is transferred to something within ourselves
        if (event.relatedTarget && event.relatedTarget.parentNode &&
            event.relatedTarget.parentNode.parentNode &&
            event.relatedTarget.parentNode.parentNode.parentNode) {
            if (event.relatedTarget.parentNode === event.target ||
                event.relatedTarget.parentNode.parentNode === event.target ||
                event.relatedTarget.parentNode.parentNode.parentNode === event.target) {
                return;
            }
        }

        if (this.linkElement) {
            this.linkElement.classList.remove("disable-tooltip");
        }

        this.menuElement.style.display = "";
    }

    /*
     * This function initializes the dropdown and binds interactive events.
     */
    bindEvents(force) {
        if (this.element) {
            if (!force && this.element.classList.contains('bound')) {
                return;
            }
        }

        this.menuElement.setAttribute("tabindex", "1");

        if (this.linkElement) {
            this.linkElement.addEventListener('click', (event) => {
                // Do not open the dropdown when the button is disabled.
                if (this.linkElement.hasAttribute('disabled')) {
                    return;
                }

                this.linkElement.classList.add("disable-tooltip");

                this.menuElement.style.display = "block";
                this.menuElement.removeEventListener("blur", this.blurEvent.bind(this));
                this.menuElement.addEventListener("blur", this.blurEvent.bind(this));

                var items = this.menuElement.querySelectorAll("a, form input");
                items.forEach((itemElement, i) => {
                    if (!itemElement.classList.contains("bound")) {
                        itemElement.addEventListener("click", (event) => {
                            if (this.callbackFor("selected")) {
                                this.trigger("selected", {
                                    "element": itemElement,
                                    "event":   event,
                                    "index":   i
                                });
                            }
                            this.menuElement.style.display = "none";
                        });
                        itemElement.classList.add("bound");
                    }
                });

                // Focus on the element to detect when focus is removed
                this.menuElement.focus();

                // Do not go to the main link (for non-js uses)
                event.stopPropagation();
                event.preventDefault();
            });
        }

        this.element.classList.add('bound');
    }
}

Dropdown._loaded = {};
Dropdown._count  = 0;

export default Dropdown;
