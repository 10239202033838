"use strict";

import EventComponent from "./event_component.js";

import Util from "./util.js";

/**
 * This represents a connection between two Ports.
 */
class Wire extends EventComponent {
    /**
     * Instantiates an object representing the connection between two ports.
     *
     * @param {HTMLElement} elementA The `<li>` element within the starting Port.
     * @param {HTMLElement} elementB The `<li>` element within the end Port.
     * @param {Port} portA The Port that serves as the starting point.
     * @param {Port} portB The Port that serves as the endpoint.
     * @param {HTMLElement} path The `<path>` element within the SVG plane.
     * @param {object} options The workflow widget options.
     */
    constructor(elementA, elementB, portA, portB, path, options) {
        super();

        // Retain elements
        this._options   = options;
        this._elementA  = elementA;
        this._elementB  = elementB;
        this._portStart = portA;
        this._portEnd   = portB;
        this._path      = path;

        // Prevent wire clicks from becoming world clicks
        this._path.addEventListener("mousedown", (event) => {
            if (!this._path.classList.contains("hover")) {
                if (!this._path.classList.contains("dummy")) {
                    event.stopPropagation();
                    event.preventDefault();
                }
            }
        });

        // Handle wire selection.
        this._path.addEventListener("mouseup", (event) => {
            if (!this._path.classList.contains("hover")) {
                // If this wire is not connected, then destroy it
                // (This is somebody trying to select.)
                if (!this._path.classList.contains("dummy")) {
                    this.toggle();
                    event.stopPropagation();
                    event.preventDefault();
                }
            }
        });

        [this._elementA, this._elementB].forEach( (element) => {
            let deleteButton = element.querySelector(".delete-button");

            if (deleteButton) {
                deleteButton.addEventListener("mousedown", (event) => {
                    event.stopPropagation();
                    event.preventDefault();
                });
                deleteButton.addEventListener("mouseup", (event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    this.destroy();
                });
            }
        });
    }

    /**
     * Based on the current status of the Wire, select or unselect it.
     */
    toggle() {
        if (this.selected) {
            this.unselect();
        }
        else {
            this.select();
        }
    }

    /**
     * Whether or not this Wire is currently selected.
     */
    get selected() {
        return this._path.classList.contains("selected");
    }

    /**
     * Selects the Wire.
     */
    select() {
        this._path.classList.add("selected");
        this._elementA.classList.add("selected");
        this._elementB.classList.add("selected");
        this.trigger("select");

        if (this.portStart) {
            this.portStart.element.classList.add("contains-selected");
        }

        if (this.portEnd) {
            this.portEnd.element.classList.add("contains-selected");
        }
    }

    /**
     * Unselects the Wire.
     */
    unselect() {
        if (this.portStart) {
            this.portStart.element.classList.remove("contains-selected");
        }

        if (this.portEnd) {
            this.portEnd.element.classList.remove("contains-selected");
        }

        this._path.classList.remove("selected");
        this._elementA.classList.remove("selected");
        this._elementB.classList.remove("selected");
        this.trigger("unselect");
    }

    /**
     * Returns the starting Port.
     */
    get portStart() {
        return this._portStart;
    }

    /**
     * Returns the ending Port.
     */
    get portEnd() {
        return this._portEnd;
    }

    /**
     * Returns the `<li>` element that retains metadata about the initial connection.
     */
    get elementStart() {
        return this._elementA;
    }

    /**
     * Returns the `<li>` element that retains metadata about the initial connection.
     */
    get elementEnd() {
        return this._elementB;
    }

    /**
     * Returns the index of this Wire at the starting Port.
     */
    get indexStart() {
        return Util.getChildIndex(this.elementStart, ".connection");
    }

    /**
     * Returns the index of this Wire at the ending Port.
     */
    get indexEnd() {
        return Util.getChildIndex(this.elementEnd, ".connection");
    }

    /**
     * Returns the `<path>` element within the SVG plane.
     */
    get path() {
        return this._path;
    }

    // We need to get the current pan of the world so that the SVG plane respects this.
    // This is because getBoundingClientRect gets a relative offset on the page and not
    // from within the element. You wouldn't need this if you can get the relative
    // offset a different way.
    _draggableOffset() {
        let draggable = Util.getParents(this._elementA, "ul.connections", "ul.connections")[0];
        let bound = draggable.getBoundingClientRect();
        return [parseInt(bound.left || "0"), parseInt(bound.top || "0")];
    }

    /**
     * Returns the starting x coordinate.
     */
    get xStart() {
        return Math.round(this._elementA.getBoundingClientRect().x - this._draggableOffset()[0]);
    }

    /**
     * Returns the starting y coordinate.
     */
    get yStart() {
        return Math.round(this._elementA.getBoundingClientRect().y - this._draggableOffset()[1]);
    }

    /**
     * Returns the endpoint x coordinate.
     */
    get xEnd() {
        return Math.round(this._elementB.getBoundingClientRect().x - this._draggableOffset()[0]);
    }

    /**
     * Returns the endpoint y coordinate.
     */
    get yEnd() {
        return Math.round(this._elementB.getBoundingClientRect().y - this._draggableOffset()[1]);
    }

    /**
     * Returns the starting wire length.
     */
    get lengthStart() {
        let portElement = Util.getParents(this._elementA, ".port", ".port")[0];
        if (!portElement) {
            return this._options.wire.width;
        }
        let label = portElement.querySelector(".label");
        if (!label) {
            return this._options.wire.width;
        }
        return Math.max(this._options.wire.width, label.clientWidth + 30);
    }

    /**
     * Returns the endpoint wire length.
     */
    get lengthEnd() {
        let portElement = Util.getParents(this._elementB, ".port", ".port")[0];
        if (!portElement) {
            return this._options.wire.width;
        }
        let label = portElement.querySelector(".label");
        if (!label) {
            return this._options.wire.width;
        }
        return Math.max(this._options.wire.width, label.clientWidth + 30);
    }

    /**
     * The initial direction of the wire.
     */
    get directionStart() {
        return this.portStart.direction;
    }

    /**
     * The ending direction of the wire.
     */
    get directionEnd() {
        return this.portEnd.direction;
    }

    createWire() {
        // If the port is hidden, hide the wire, too
        if (this.portStart.visibility === "hidden" || this.portEnd.visibility === "hidden") {
            this.path.setAttribute("hidden", "");
            return;
        }

        // Ignore if any of the coordinates are undefined/NaN since that means
        // their ports are hidden, likely.
        if (isNaN(this.xStart) || isNaN(this.xEnd) || isNaN(this.yStart) || isNaN(this.yEnd)) {
            this.path.setAttribute("hidden", "");
            return;
        }

        this.path.removeAttribute("hidden");

        // Get the initial direction
        let direction =
            ["left", "right", "top", "bottom"].indexOf(this.directionStart);

        // Get the direction of the goal (note: the wire will head the opposite
        //   direction when it is drawn.)
        let goalDirection =
            ["left", "right", "top", "bottom"].indexOf(this.directionEnd);

        let arcRadius = this._options.wire.arcRadius;
        var horizontalThickness = this._options.wire.horizontalThickness;
        var verticalThickness = this._options.wire.verticalThickness;

        let lastX = this.xEnd;
        let lastY = this.yEnd;

        let startX = this.xStart;
        let startY = this.yStart;

        // We need to center the line on the starting coordinate
        if (direction == Wire.UP) {
            startX -= (verticalThickness - 1) / 2;
        }
        if (direction == Wire.DOWN) {
            startX += (verticalThickness - 1) / 2;
        }
        if (goalDirection == Wire.UP) {
            lastX += (verticalThickness - 1) / 2;
        }
        if (goalDirection == Wire.DOWN) {
            lastX -= (verticalThickness - 1) / 2;
        }

        if (direction == Wire.LEFT) {
            startY -= (horizontalThickness - 1) / 2;
        }
        if (direction == Wire.RIGHT) {
            startY += (horizontalThickness - 1) / 2;
        }
        if (goalDirection == Wire.LEFT) {
            lastY += (horizontalThickness - 1) / 2;
        }
        if (goalDirection == Wire.RIGHT) {
            lastY -= (horizontalThickness - 1) / 2;
        }

        let x1 = startX;
        if (direction == Wire.RIGHT) {
            x1 = startX + this.lengthStart;
        }
        else if (direction == Wire.LEFT) {
            x1 = startX - this.lengthStart;
        }

        let y1 = startY;
        if (direction == Wire.UP) {
            y1 = startY - this.lengthStart;
        }
        else if (direction == Wire.DOWN) {
            y1 = startY + this.lengthStart;
        }

        let x2 = lastX;
        if (goalDirection == Wire.RIGHT) {
            x2 = lastX + this.lengthEnd;
        }
        else if (goalDirection == Wire.LEFT) {
            x2 = lastX - this.lengthEnd;
        }

        let y2 = lastY;
        if (goalDirection == Wire.DOWN) {
            y2 = lastY + this.lengthEnd;
        }
        else if (goalDirection == Wire.UP) {
            y2 = lastY - this.lengthEnd;
        }

        let path = this._openPath(startX, startY, direction);

        //                 midWEnd   x2, y2
        //             / ----------- ------ []
        //
        //     midHEnd |
        //                 medianStart    medianEnd
        //             \ -------------- ------------- \
        //
        //                                            |
        //                                            | midHStart
        //                                            |
        //                       x1, y1   midWStart
        //                    [] ------ ------------- /

        let midWStart = 0;
        let midHStart = 0;

        let medianStart = 0;

        // Determine the midWStart, midHStart
        if ((direction == Wire.LEFT && (x2 > x1)) || (direction == Wire.RIGHT && (x1 > x2))) {
            // Goal node is behind us... curve quickly
            // Therefore midpoint should be 0, median is more important.
            midWStart = 0;
            midHStart = Math.round(Math.abs(y2 - y1) / 2);

            medianStart = Math.round(Math.abs(x2 - x1) / 2);
        }
        else if ((direction == Wire.UP && (y2 > y1)) || (direction == Wire.DOWN && (y1 > y2))) {
            // Again, Goal node is behind us... so, curve quickly
            midHStart = 0;
            midWStart = Math.round(Math.abs(x2 - x1) / 2);

            medianStart = Math.round(Math.abs(y2 - y1) / 2);
        }
        else if (direction == Wire.LEFT || direction == Wire.RIGHT) {
            // Otherwise, go halfway between the nodes.
            midWStart = Math.round(Math.abs(x2 - x1) / 2);
            midHStart = Math.round(Math.abs(y2 - y1) / 2);
        }
        else {
            midHStart = Math.round(Math.abs(y2 - y1) / 2);
            midWStart = Math.round(Math.abs(x2 - x1) / 2);
        }

        let midWEnd = 0;
        let midHEnd = 0;

        let medianEnd = 0;

        // Determine the midWEnd, midHEnd
        if ((goalDirection == Wire.LEFT && (x1 > x2)) || (goalDirection == Wire.RIGHT && (x2 > x1))) {
            // Goal node is behind us... curve quickly
            // Therefore midpoint should be 0, median is more important.
            midWEnd = 0;
            midHEnd = Math.round(Math.abs(y2 - y1) / 2) + horizontalThickness * 2;

            medianEnd = Math.round(Math.abs(x2 - x1) / 2);
        }
        else if ((goalDirection == Wire.UP && (y1 > y2)) || (goalDirection == Wire.DOWN && (y2 > y1))) {
            // Again, Goal node is behind us... so, curve quickly
            midHEnd = 0;
            midWEnd = Math.round(Math.abs(x2 - x1) / 2) + verticalThickness * 2;

            medianEnd = Math.round(Math.abs(y2 - y1) / 2);
        }
        else if (goalDirection == Wire.LEFT || goalDirection == Wire.RIGHT) {
            // Otherwise, go halfway between the nodes.
            midWEnd = Math.round(Math.abs(x2 - x1) / 2) + verticalThickness;
            midHEnd = Math.round(Math.abs(y2 - y1) / 2) + horizontalThickness;
        }
        else {
            midHEnd = Math.round(Math.abs(y2 - y1) / 2) + horizontalThickness;
            midWEnd = Math.round(Math.abs(x2 - x1) / 2) + verticalThickness;
        }

        // Course correct for different situations
        if ((Math.round(this.yStart) == Math.round(this.yEnd) &&
            (goalDirection == Wire.LEFT || goalDirection == Wire.RIGHT)) ||
            (Math.round(this.xStart) == Math.round(this.xEnd)) &&
            (goalDirection == Wire.UP || goalDirection == Wire.DOWN)) {
            midWStart = 0;
            midHStart = 0;
            medianStart = 0;
            medianEnd = 0;
            midWEnd = 0;
            midHEnd = 0;

            x1 = startX;
            x2 = lastX;

            y1 = startY;
            y2 = lastY;
        }
        else if (direction == goalDirection) {
            if (direction == Wire.LEFT || direction == Wire.RIGHT) {
                if (midWEnd == 0) {
                    midWStart += medianStart + medianEnd;
                }
                else {
                    midWEnd += medianStart + medianEnd;
                }
                if (direction == Wire.LEFT) {
                    if (y2 < y1) {
                        midHStart -= horizontalThickness;
                    }
                    else {
                        midHStart += horizontalThickness;
                    }
                }
                else if (direction == Wire.RIGHT) {
                    if (y2 < y1) {
                        midHStart += horizontalThickness;
                    }
                    else {
                        midHStart -= horizontalThickness;
                    }
                }

                if ((goalDirection == Wire.LEFT && (x1 > x2)) || (goalDirection == Wire.RIGHT && (x2 > x1))) {
                    midHEnd -= horizontalThickness;
                }
            }
            if (direction == Wire.UP || direction == Wire.DOWN) {
                if (midHEnd == 0) {
                    midHStart += medianStart + medianEnd;
                }
                else {
                    midHEnd += medianStart + medianEnd;
                }
                if (direction == Wire.DOWN) {
                    if (x2 < x1) {
                        midWStart -= verticalThickness;
                    }
                    else {
                        midWStart += verticalThickness;
                    }
                }
                else if (direction == Wire.UP) {
                    if (x2 < x1) {
                        midWStart += verticalThickness;
                    }
                    else {
                        midWStart -= verticalThickness;
                    }
                }

                if ((goalDirection == Wire.UP && (y1 > y2)) || (goalDirection == Wire.DOWN && (y2 > y1))) {
                    midWEnd -= verticalThickness;
                }
            }

            medianStart = 0;
            medianEnd = 0;
        }
        else if ((direction == Wire.LEFT || direction == Wire.RIGHT) && (goalDirection == Wire.UP || goalDirection == Wire.DOWN)) {
            if (medianStart == 0 && medianEnd == 0) {
                midWStart += midWEnd;
                midWEnd = 0;

                midHStart += midHEnd;
                midHEnd = 0;

                if (direction == Wire.RIGHT) {
                    midWStart -= verticalThickness;
                }
            }
            else if (direction == Wire.LEFT) {
                if (x1 > x2) {
                    midWEnd += medianStart;
                    medianStart = 0;
                }
                else {
                    medianStart += arcRadius - verticalThickness;
                }
            }
            else {
                if (x1 < x2) {
                    midWEnd -= medianStart;
                    medianStart = 0;
                }
                else {
                    medianStart += arcRadius;
                }

                if (y1 > y2) {
                    midWEnd -= verticalThickness;
                }
            }
        }
        else if ((goalDirection == Wire.LEFT || goalDirection == Wire.RIGHT) && (direction == Wire.UP || direction == Wire.DOWN)) {
            if (medianStart == 0 && medianEnd == 0) {
                midWStart += midWEnd;
                midWEnd = 0;

                midHStart += midHEnd;
                midHEnd = 0;

                if (goalDirection == Wire.LEFT) {
                    midHStart -= horizontalThickness;
                }
            }
            else if (goalDirection == Wire.LEFT) {
                if (x1 > x2) {
                    midWEnd += medianStart;
                    medianStart = 0;
                }
                else {
                    medianStart += arcRadius + horizontalThickness;
                }

                midHEnd -= horizontalThickness;
            }
            else {
                if (x1 < x2) {
                    midWEnd -= medianStart;
                    medianStart = 0;
                }
                else {
                    medianStart += arcRadius;
                }

                if (y1 > y2) {
                    midWEnd -= verticalThickness;
                }
            }
        }

        //console.log(x1, y1, midWStart, midHStart, medianStart, medianEnd, midWEnd, midHEnd);

        // Draw initial line
        this._drawLine(path, x1, y1);

        if (direction == Wire.RIGHT || direction == Wire.LEFT) {
            arcRadius = Math.min(this._options.wire.arcRadius, midHStart);
        }
        else {
            arcRadius = Math.min(this._options.wire.arcRadius, midWStart);
        }

        if (medianStart > 0) {
            if (direction == Wire.RIGHT || direction == Wire.LEFT) {
                arcRadius = Math.min(this._options.wire.arcRadius, (midHStart-verticalThickness)/2);
            }
            else {
                arcRadius = Math.min(this._options.wire.arcRadius, (midWStart-horizontalThickness)/2);
            }
        }

        // Draw initial middle
        if (direction == Wire.RIGHT) {
            if (midWStart >= arcRadius) {
                this._drawLine(path, path.x + midWStart - arcRadius, path.y);
            }
        }
        else if (direction == Wire.LEFT) {
            if (midWStart >= arcRadius) {
                this._drawLine(path, path.x - midWStart + arcRadius, path.y);
            }
        }
        else if (direction == Wire.DOWN) {
            if (midHStart >= arcRadius) {
                this._drawLine(path, path.x, path.y + midHStart - arcRadius);
            }
        }
        else {
            if (midHStart >= arcRadius) {
                this._drawLine(path, path.x, path.y - midHStart + arcRadius);
            }
        }

        // Curve, if needed
        if ((direction == Wire.RIGHT || direction == Wire.LEFT) && (y2 > y1)) {
            this._drawArc(path, Wire.DOWN, arcRadius);
        }
        else if ((direction == Wire.RIGHT || direction == Wire.LEFT) && (y2 < y1)) {
            this._drawArc(path, Wire.UP, arcRadius);
        }
        else if ((direction == Wire.DOWN || direction == Wire.UP) && (x2 > x1)) {
            this._drawArc(path, Wire.RIGHT, arcRadius);
        }
        else if ((direction == Wire.DOWN || direction == Wire.UP) && (x2 < x1)) {
            this._drawArc(path, Wire.LEFT, arcRadius);
        }

        // Draw second middle
        if (medianStart > 0) {
            if (path.direction == Wire.RIGHT || path.direction == Wire.LEFT) {
                arcRadius = Math.min(this._options.wire.arcRadius, Math.round(midWStart/2));
                midWStart -= arcRadius;
            }
            else {
                arcRadius = Math.min(this._options.wire.arcRadius, Math.round(midHStart/2));
                midHStart -= arcRadius;
            }
        }
        else {
            if (path.direction == Wire.RIGHT || path.direction == Wire.LEFT) {
                arcRadius = Math.min(this._options.wire.arcRadius, midWStart);
            }
            else {
                arcRadius = Math.min(this._options.wire.arcRadius, midHStart);
            }
        }

        if (path.direction == Wire.RIGHT) {
            if (midWStart > arcRadius) {
                this._drawLine(path, path.x + midWStart - arcRadius, path.y);
            }
        }
        else if (path.direction == Wire.LEFT) {
            if (midWStart > arcRadius) {
                this._drawLine(path, path.x - midWStart + arcRadius, path.y);
            }
        }
        else if (path.direction == Wire.DOWN) {
            if (midHStart > arcRadius) {
                this._drawLine(path, path.x, path.y + midHStart - arcRadius);
            }
        }
        else {
            if (midHStart > arcRadius) {
                this._drawLine(path, path.x, path.y - midHStart + arcRadius);
            }
        }

        // Draw median (maybe)
        if (medianStart > 0) {
            // Curve away from goal
            if ((path.direction == Wire.RIGHT || path.direction == Wire.LEFT) && (y2 > y1)) {
                this._drawArc(path, Wire.DOWN, arcRadius);
                if (medianStart > arcRadius) {
                    this._drawLine(path, path.x, path.y + medianStart - arcRadius);
                }
            }
            else if ((path.direction == Wire.RIGHT || path.direction == Wire.LEFT)) {
                this._drawArc(path, Wire.UP, arcRadius);
                if (medianStart > arcRadius) {
                    this._drawLine(path, path.x, path.y - medianStart + arcRadius);
                }
            }
            else if ((path.direction == Wire.DOWN || path.direction == Wire.UP) && (x2 > x1)) {
                this._drawArc(path, Wire.RIGHT, arcRadius);
                if (medianStart > arcRadius) {
                    this._drawLine(path, path.x + medianStart - arcRadius, path.y);
                }
            }
            else {
                this._drawArc(path, Wire.LEFT, arcRadius);
                if (medianStart > arcRadius) {
                    this._drawLine(path, path.x - medianStart + arcRadius, path.y);
                }
            }
        }

        if (medianEnd > 0) {
            if (path.direction == Wire.RIGHT || path.direction == Wire.LEFT) {
                arcRadius = Math.min(this._options.wire.arcRadius, Math.round(midHEnd/2));
                midHEnd -= arcRadius;
            }
            else {
                arcRadius = Math.min(this._options.wire.arcRadius, Math.round(midWEnd/2));
                midWEnd -= arcRadius;
            }
        }
        else {
            if (path.direction == Wire.RIGHT || path.direction == Wire.LEFT) {
                arcRadius = Math.min(this._options.wire.arcRadius, midHEnd);
            }
            else {
                arcRadius = Math.min(this._options.wire.arcRadius, midWEnd);
            }
        }
        if (medianEnd > 0) {
            // Draw line and curve toward goal
            if (medianEnd > arcRadius) {
                if (path.direction == Wire.LEFT) {
                    this._drawLine(path, path.x - medianEnd + arcRadius, path.y);
                }
                else if (path.direction == Wire.RIGHT) {
                    this._drawLine(path, path.x + medianEnd - arcRadius, path.y);
                }
                else if (path.direction == Wire.UP) {
                    this._drawLine(path, path.x, path.y - medianEnd + arcRadius);
                }
                else {
                    this._drawLine(path, path.x, path.y + medianEnd - arcRadius);
                }
            }

            if ((path.direction == Wire.RIGHT || path.direction == Wire.LEFT) && (y2 > y1)) {
                this._drawArc(path, Wire.DOWN, arcRadius);
            }
            else if ((path.direction == Wire.RIGHT || path.direction == Wire.LEFT)) {
                this._drawArc(path, Wire.UP, arcRadius);
            }
            else if ((path.direction == Wire.DOWN || path.direction == Wire.UP) && (x2 > x1)) {
                this._drawArc(path, Wire.RIGHT, arcRadius);
            }
            else {
                this._drawArc(path, Wire.LEFT, arcRadius);
            }
        }

        // Draw third middle
        if (path.direction == Wire.RIGHT || path.direction == Wire.LEFT) {
            arcRadius = Math.min(this._options.wire.arcRadius, midWEnd);
        }
        else {
            arcRadius = Math.min(this._options.wire.arcRadius, midHEnd);
        }

        if (path.direction == Wire.RIGHT) {
            if (midWEnd > arcRadius) {
                this._drawLine(path, path.x + midWEnd - arcRadius, path.y);
            }
        }
        else if (path.direction == Wire.LEFT) {
            if (midWEnd > arcRadius) {
                this._drawLine(path, path.x - midWEnd + arcRadius, path.y);
            }
        }
        else if (path.direction == Wire.DOWN) {
            if (midHEnd > arcRadius) {
                this._drawLine(path, path.x, path.y + midHEnd - arcRadius);
            }
        }
        else {
            if (midHEnd > arcRadius) {
                this._drawLine(path, path.x, path.y - midHEnd + arcRadius);
            }
        }

        // Draw third middle
        if (path.direction == Wire.RIGHT || path.direction == Wire.LEFT) {
            arcRadius = Math.min(this._options.wire.arcRadius, midWEnd);
        }
        else {
            arcRadius = Math.min(this._options.wire.arcRadius, midHEnd);
        }

        // Curve, if needed
        if ((path.direction == Wire.RIGHT || path.direction == Wire.LEFT) && (lastY >= path.y)) {
            if (goalDirection == Wire.UP) {
                // Do not allow arcRadius to overshoot goal
                if (path.direction == Wire.RIGHT) {
                    arcRadius = lastX - path.x;
                }
                else {
                    arcRadius = path.x - (lastX - verticalThickness);
                }
                this._drawArc(path, Wire.DOWN, arcRadius);
            }
        }
        else if ((path.direction == Wire.RIGHT || path.direction == Wire.LEFT) && (lastY <= path.y)) {
            if (goalDirection == Wire.DOWN) {
                // Do not allow arcRadius to overshoot goal
                if (path.direction == Wire.RIGHT) {
                    arcRadius = (lastX + verticalThickness) - path.x;
                }
                else {
                    arcRadius = path.x - lastX;
                }
                this._drawArc(path, Wire.UP, arcRadius);
            }
        }
        else if ((path.direction == Wire.DOWN || path.direction == Wire.UP) && (lastX >= path.x)) {
            if (goalDirection == Wire.LEFT) {
                // Do not allow arcRadius to overshoot goal
                if (path.direction == Wire.DOWN) {
                    arcRadius = (lastY + horizontalThickness) - path.y;
                }
                else {
                    arcRadius = path.y - lastY;
                }
                this._drawArc(path, Wire.RIGHT, arcRadius);
            }
        }
        else if ((path.direction == Wire.DOWN || path.direction == Wire.UP) && (lastX <= path.x)) {
            if (goalDirection == Wire.RIGHT) {
                // Do not allow arcRadius to overshoot goal
                if (path.direction == Wire.DOWN) {
                    arcRadius = lastY - path.y;
                }
                else {
                    arcRadius = path.y - (lastY - horizontalThickness);
                }
                this._drawArc(path, Wire.LEFT, arcRadius);
            }
        }

        // Draw fourth middle
        // NOTE: probably don't need to draw this segment...
        /*if (path.direction == Wire.RIGHT) {
            if (midWEnd > arcRadius) {
                this._drawLine(path, path.x + midWEnd - arcRadius, path.y);
            }
        }
        else if (path.direction == Wire.LEFT) {
            if (midWEnd > arcRadius) {
                this._drawLine(path, path.x - midWEnd + arcRadius, path.y);
            }
        }
        else if (path.direction == Wire.DOWN) {
            if (midHEnd > arcRadius) {
                this._drawLine(path, path.x, path.y + midHEnd - arcRadius);
            }
        }
        else {
            if (midHEnd > arcRadius) {
                this._drawLine(path, path.x, path.y - midHEnd + arcRadius);
            }
        }*/

        if (path.y > lastY) {
            //window.console.log("problem!");
        }

        // Draw end line
        //console.log(path.x, path.y, lastX, lastY);
        this._drawLine(path, lastX, lastY);

        this.path.setAttribute("d", this._closePath(path));
    }

    // Opens a path at the given coordinate and direction
    //
    // @param {number} x The origin X coordinate.
    // @param {number} y The origin Y coordinate.
    // @param {number} direction The direction. 0: left, 1: right, 2: up, 3: down
    //
    // @returns {object} The path state.
    _openPath(x, y, direction) {
        let start = "M " + x + " " + y;
        return {
            x: x,
            y: y,
            direction: direction,
            start: start,
            end: ""
        };
    }

    // Draws a curve in the wire with the given radius.
    //
    // @param {object} path The current path state.
    // @param {number} direction The new direction.
    // @param {number} [width] The radius. Defaults to options.wire.arcRadius.
    //
    // @returns {object} The updated state.
    _drawArc(path, direction, arcRadius) {
        // Determine line width
        var horizontalThickness = this._options.wire.horizontalThickness;
        var verticalThickness   = this._options.wire.verticalThickness;

        // Determine arc properties and radius
        if (arcRadius === undefined) {
            arcRadius = this._options.wire.arcRadius;
        }

        // Get the start/end paths
        let start = path.start;
        let end   = path.end;

        arcRadius = Math.round(arcRadius);

        let minorArcRadiusX = Math.max(arcRadius, verticalThickness) - verticalThickness;
        let minorArcRadiusY = Math.max(arcRadius, horizontalThickness) - horizontalThickness;

        let x = path.x;
        let y = path.y;

        // Probably could condense this to 4, but I'm fairly lazy about it
        if (path.direction == Wire.LEFT && direction == Wire.UP) {
            let x2 = path.x - arcRadius;
            let y2 = path.y - arcRadius;
            let sweep = 1;
            let reverseSweep = Math.abs(sweep - 1);

            start += " A " + arcRadius       + " " + arcRadius       + " 0 0 " + sweep        + " " + x2 + " " + y2;
            end    = " A " + minorArcRadiusX + " " + minorArcRadiusY + " 0 0 " + reverseSweep + " " + x  + " " + (y - horizontalThickness) + end;

            path.x = x2;
            path.y = y2;
        }
        else if (path.direction == Wire.LEFT && direction == Wire.DOWN) {
            let x2 = path.x - arcRadius + verticalThickness;
            let y2 = path.y + arcRadius - horizontalThickness;
            let sweep = 0;
            let reverseSweep = Math.abs(sweep - 1);

            start += " A " + minorArcRadiusX + " " + minorArcRadiusY + " 0 0 " + sweep        + " " + x2 + " " + y2;
            end    = " A " + arcRadius       + " " + arcRadius       + " 0 0 " + reverseSweep + " " + x  + " " + (y - horizontalThickness) + end;

            path.x = x2;
            path.y = y2;
        }
        else if (path.direction == Wire.RIGHT && direction == Wire.UP) {
            let x2 = path.x + arcRadius - verticalThickness;
            let y2 = path.y - arcRadius + horizontalThickness;
            let sweep = 0;
            let reverseSweep = Math.abs(sweep - 1);

            start += " A " + minorArcRadiusX + " " + minorArcRadiusY + " 0 0 " + sweep        + " " + x2 + " " + y2;
            end    = " A " + arcRadius       + " " + arcRadius       + " 0 0 " + reverseSweep + " " + x  + " " + (y + horizontalThickness) + end;

            path.x = x2;
            path.y = y2;
        }
        else if (path.direction == Wire.RIGHT && direction == Wire.DOWN) {
            let x2 = path.x + arcRadius;
            let y2 = path.y + arcRadius;
            let sweep = 1;
            let reverseSweep = Math.abs(sweep - 1);

            start += " A " + arcRadius       + " " + arcRadius       + " 0 0 " + sweep        + " " + x2 + " " + y2;
            end    = " A " + minorArcRadiusX + " " + minorArcRadiusY + " 0 0 " + reverseSweep + " " + x  + " " + (y + horizontalThickness) + end;

            path.x = x2;
            path.y = y2;
        }
        else if (path.direction == Wire.UP    && direction == Wire.LEFT) {
            let x2 = path.x - arcRadius + verticalThickness;
            let y2 = path.y - arcRadius + horizontalThickness;
            let sweep = 0;
            let reverseSweep = Math.abs(sweep - 1);

            start += " A " + minorArcRadiusX + " " + minorArcRadiusY + " 0 0 " + sweep        + " " + x2 + " " + y2;
            end    = " A " + arcRadius       + " " + arcRadius       + " 0 0 " + reverseSweep + " " + (x + verticalThickness) + " " + y + end;

            path.x = x2;
            path.y = y2;
        }
        else if (path.direction == Wire.UP    && direction == Wire.RIGHT) {
            let x2 = path.x + arcRadius;
            let y2 = path.y - arcRadius;
            let sweep = 1;
            let reverseSweep = Math.abs(sweep - 1);

            start += " A " + arcRadius       + " " + arcRadius       + " 0 0 " + sweep        + " " + x2 + " " + y2;
            end    = " A " + arcRadius       + " " + arcRadius       + " 0 0 " + reverseSweep + " " + (x + verticalThickness) + " " + y + end;

            path.x = x2;
            path.y = y2;
        }
        else if (path.direction == Wire.DOWN  && direction == Wire.LEFT) {
            let x2 = path.x - arcRadius;
            let y2 = path.y + arcRadius;
            let sweep = 1;
            let reverseSweep = Math.abs(sweep - 1);

            start += " A " + arcRadius       + " " + arcRadius       + " 0 0 " + sweep        + " " + x2 + " " + y2;
            end    = " A " + arcRadius       + " " + arcRadius       + " 0 0 " + reverseSweep + " " + (x - verticalThickness) + " " + y + end;

            path.x = x2;
            path.y = y2;
        }
        else if (path.direction == Wire.DOWN  && direction == Wire.RIGHT) {
            let x2 = path.x + arcRadius - verticalThickness;
            let y2 = path.y + arcRadius - horizontalThickness;
            let sweep = 0;
            let reverseSweep = Math.abs(sweep - 1);

            start += " A " + minorArcRadiusX + " " + minorArcRadiusY + " 0 0 " + sweep        + " " + x2 + " " + y2;
            end    = " A " + arcRadius       + " " + arcRadius       + " 0 0 " + reverseSweep + " " + (x - verticalThickness) + " " + y + end;

            path.x = x2;
            path.y = y2;
        }

        path.direction = direction;
        path.start = start;
        path.end   = end;

        return path;
    }

    // Draws a line in the wire with the given length.
    //
    // @param path {Array} The [start, end] where start and end are strings
    //                     representing the current path. These are mutated.
    _drawLine(path, x, y) {
        // Do not draw if the line's magnitude would be 0
        if (path.x == x && path.y == y) {
            return;
        }

        // Get the start/end paths
        let start = path.start;
        let end   = path.end;

        // Determine line width
        var horizontalThickness = this._options.wire.horizontalThickness;
        var verticalThickness   = this._options.wire.verticalThickness;

        // The wire is governed by the following:
        // "o" is the current path position
        // The other line is the return line

        // Direction: Wire.RIGHT
        //
        // path.x, path.y o -------------> x, y
        //
        // path.x, path.y + hT <----------

        // Direction: Wire.LEFT
        //
        // ----------------> path.x, path.y - hT
        //
        // x, y <--------- o path.x, path.y

        // Direction: Wire.UP
        //
        //     x , y  |
        //       ^    |
        //       |    |
        //       |    |
        //       o    v path.x + vT, path.y
        // path.x, path.y

        // Direction: Wire.DOWN
        //
        //                    path.x, path.y
        // path.x - vT, path.y ^    o
        //                     |    |
        //                     |    |
        //                     |    v
        //                     |  x , y

        // Default: vertical line
        var width  = verticalThickness;
        var height = 0;
        if (path.y == y) { // horizontal line
            width  = 0;
            height = horizontalThickness;
        }

        // Make sure the return line is on the correct side
        if (path.direction == Wire.LEFT) {
            height = -height;
        }
        if (path.direction == Wire.DOWN) {
            width = -width;
        }

        // Append to path
        start += " L " + x + " " + y;
        end    = " L " + (path.x + width) + " " + (path.y + height) + end;

        path.start = start;
        path.end   = end;

        path.x     = x;
        path.y     = y;

        return path;
    }

    // Simply closes the given path tuple.
    //
    // @param path {Array} The [start, end] where start and end are strings
    //                     representing the current path.
    // @returns {string} The completed path.
    _closePath(path) {
        // Determine line width
        var horizontalThickness = this._options.wire.horizontalThickness;
        var verticalThickness   = this._options.wire.verticalThickness;

        if (path.direction == Wire.LEFT) {
            path.start += " L " + path.x + " " + (path.y - horizontalThickness);
        }
        else if (path.direction == Wire.RIGHT) {
            path.start += " L " + path.x + " " + (path.y + horizontalThickness);
        }
        else if (path.direction == Wire.UP) {
            path.start += " L " + (path.x + verticalThickness) + " " + path.y;
        }
        else {
            path.start += " L " + (path.x - verticalThickness) + " " + path.y;
        }

        return path.start + path.end + " Z";
    }

    /**
     * Redraws the wire.
     */
    redraw() {
        this.createWire();
    }

    /**
     * Disconnects this wire.
     */
    disconnect() {
        this.trigger("disconnect");
    }

    /**
     * Destroys this wire.
     */
    destroy() {
        // We may recurse, since disconnecting triggers a destroy
        // and destroying triggers a disconnect.
        // So we must prevent that:
        if (this._destroying) {
            return;
        }
        this._destroying = true;

        // Disconnect (just in case)
        this.disconnect();

        // Destroy DOM and SVG path elements
        this.path.remove();
        this.elementStart.remove();
        this.elementEnd.remove();

        this.portStart.remove(this);
        this.portEnd.remove(this);
    }

    /**
     * Creates the DOM/SVG representation of this connection.
     *
     * @param {object} options The general options for the workflow widget.
     */
    static create(options, portA, portB) {
        // Create a <path> for this wire based on its properties
        let SVGNS = "http://www.w3.org/2000/svg";
        let path = document.createElementNS(SVGNS, "path");

        if (portB.element.classList.contains("dummy")) {
            path.classList.add("dummy");
        }

        if (portA.connectionType === "port" || (!portB.element.classList.contains("dummy") && portB.connectionType === "port")) {
            path.classList.add("port");
        }

        // Create the <li> that represents each connection in each port.
        let elementA = document.createElement("li");
        elementA.classList.add("connection");
        let elementB = elementA.cloneNode(true);

        let deleteButton = document.createElement("div");
        deleteButton.classList.add("delete-button");
        elementA.appendChild(deleteButton.cloneNode(true));
        elementB.appendChild(deleteButton);

        // Append the wire's DOM representation to each port

        let wire = new Wire(elementA, elementB, portA, portB, path, options);
        if (portA) {
            portA.appendStart(wire);
        }

        if (portB) {
            portB.appendEnd(wire);
        }

        return wire;
    }
}

/**
 * The direction denoting right to left. As in, heading left.
 */
Wire.LEFT  = 0;

/**
 * The direction denoting left to right. As in, heading right.
 */
Wire.RIGHT = 1;

/**
 * The direction denoting down to up. As in, heading upward.
 */
Wire.UP    = 2;

/**
 * The direction denoting up to down. As in, heading downward.
 */
Wire.DOWN  = 3;

export default Wire;
