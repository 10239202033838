"use strict";

import EventComponent from './event_component.js';
import Util           from './util.js';
import Tabs           from './tabs.js';
import Occam          from './occam.js';

// I'm thankful for the following codepen:
// https://codepen.io/anon/pen/gBJrOP

// I'm the opposite of thankful for:
// 1. webkit's idea of how to do directory traversal

/**
 * This class handles the file listing and navigation.
 *
 * It also handles file input configuration management for things
 * such as selecting which files to use as input.
 */
class FileList extends EventComponent {
    constructor(element) {
        super();

        this.element = element;

        this.fileListingSidebar = this.element.querySelector(".file-listing-container");
        this.fileListing    = this.element.querySelector(".card.file-list-panel:not(.pending)");
        this.pendingListing = this.element.querySelector(".card.pending.file-list-panel");

        // Attach counter
        FileList._count++;
        this.element.setAttribute('data-file-viewer-index', FileList._count);

        FileList._loaded[this.element.getAttribute('data-file-viewer-index')] = this;

        // Find the file tabs
        let tabElement = this.element.querySelector("ul.file-tabs.tabs");
        this.fileTabs = Tabs.load(tabElement);

        // Get initial title (default given here, overriden by looking at tabs)
        this.title = Occam.title() + " :: Files";

        if (!this.fileListing) {
            return;
        }

        // Retain URL we are initially viewing
        let header = this.fileListing.querySelector("h2");
        this._viewing = {
            'url':  header.getAttribute("data-url"),
            'name': header.getAttribute("data-name"),
            'path': header.getAttribute("data-path")
        };

        if (tabElement) {
            let mainElement = Util.getParents(tabElement, ".content", ".content")[0];
            let filePanel   = Util.getParents(tabElement, ".tab-panel", ".tab-panel")[0];

            if (mainElement && filePanel) {
                this.filePanelIndex = Util.getChildIndex(filePanel, "li");
                let mainTabElement = mainElement.querySelector(".card-tabs");
                if (mainTabElement) {
                    this.mainTabs = Tabs.load(mainTabElement);
                    let mainTab = this.mainTabs.tabAt(this.filePanelIndex);
                    if (mainTab) {
                        let mainLink = mainTab.querySelector("a, button");
                        if (mainLink) {
                            // Override default title by using main tab content.
                            // This will use the proper localization.
                            this.title = Occam.title() + " :: " + mainLink.textContent;
                        }
                    }
                    this.mainTabs.on("change", (index) => {
                        if (index == this.filePanelIndex) {
                            this.updateDocumentTitle();
                        }
                    });
                    if (this.mainTabs.selected() == this.filePanelIndex) {
                        this.updateDocumentTitle();
                    }
                }
            }
        }

        this.bindEvents();
    }

    static loadAll(element) {
        var fileLists = element.querySelectorAll('.file-viewer');

        fileLists.forEach( (subElement) => {
            var fileList = FileList.load(subElement);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-file-viewer-index');

        if (index) {
            return FileList._loaded[index];
        }

        return new FileList(element);
    }

    /**
     * Loads the given directory by URL.
     *
     * This will load and replace the file listing panel.
     *
     */
    loadGroup(url, name) {
        // Remove current file list viewer
        var viewer = this.fileListingSidebar.querySelector(":scope > .card");
        if (viewer) {
            viewer.remove();
        }

        // Show loading graphic
        var pending = this.fileListingSidebar.querySelector(":scope > .pending");
        if (pending) {
            pending.removeAttribute("hidden");
        }

        // Retain URL we are viewing
        this._viewing = {
            url:  url,
            name: name,
        };

        Util.get(url, (html) => {
            var fakeNode = document.createElement("div");
            fakeNode.innerHTML = html;

            var newCard = fakeNode.querySelector(".card.file-list-panel");
            this.fileListingSidebar.insertBefore(newCard, this.fileListingSidebar.children[0]);

            if (pending) {
                pending.setAttribute("hidden", "");
            }

            this.rebind();
        });
    }

    /**
     * Rebinds the file listing events if the file list changes.
     */
    rebind() {
        var newCard = this.element.querySelector(".card.file-list-panel");

        // Rebind the events
        this.bindFileListEvents(newCard);

        // Ensure the pending is hidden
        var pending = this.fileListingSidebar.querySelector(":scope > .pending");
        if (pending) {
            pending.setAttribute("hidden", "");
        }

        // Retain useful information
        let header = this.fileListing.querySelector("h2");
        this._viewing = {
            'url':  header.getAttribute("data-url"),
            'name': header.getAttribute("data-name"),
            'path': header.getAttribute("data-path")
        };

        Occam.loadAll(newCard);
    }

    reloadGroup() {
        if (this._viewing) {
            return this.loadGroup(this._viewing.url, this._viewing.name);
        }
    }

    /**
     * Loads the given file by URL.
     *
     * The type and name fields will be used to generate the appropriately labeled
     * tab in the file browser.
     *
     */
    loadFile(url, type, name, path, image) {
        if (!this.fileTabs) {
            this.trigger('file', {
                url: url,
                name: name,
                type: type,
                file: path,
                image: image
            });

            return;
        }

        // Look for the existing tab, and simply select it
        var existingTab = this.element.querySelector("ul.file-tabs.tabs > li.file-tab > a[href=\"" + url + "\"]");
        if (existingTab) {
            this.fileTabs.select(existingTab.parentNode);
            return;
        }

        // Add a pending tab
        var fileTabTemplate = this.element.querySelector("ul.file-tabs.tabs > template");

        var pendingTab = null;
        if ('content' in fileTabTemplate) {
            pendingTab = document.importNode(fileTabTemplate.content, true);
            pendingTab = pendingTab.querySelector("li");
        }
        else {
            pendingTab = fileTabTemplate.querySelector("li").cloneNode(true);
        }

        var filePanelTemplate = this.element.querySelector("ul.tab-panels > template.file-tab");

        var pendingPanel = null;
        if ('content' in filePanelTemplate) {
            pendingPanel = document.importNode(filePanelTemplate.content, true);
            pendingPanel = pendingPanel.querySelector("li");
        }
        else {
            pendingPanel = filePanelTemplate.querySelector("li").cloneNode(true);
        }

        // Set the label
        pendingTab.querySelector(".label span.name").textContent = name;
        pendingTab.querySelector(".label span.type").textContent = type;

        // Set the link
        pendingTab.querySelector("a").setAttribute('href', url);

        this.fileTabs.addTab([pendingTab, pendingPanel]);
        this.fileTabs.select(pendingTab);

        // If this is a mobile view... then let's collapse the file listing to show
        // the file widget pane.
        if (window.innerWidth - this.fileListing.offsetWidth < 100) {
            this.fileTabs.revealTabEvent(this.fileTabs.element.querySelector("li.sidebar.tab:first-of-type"));
        }

        Util.get(url, (html) => {
            var fakeNode = document.createElement("div");
            fakeNode.innerHTML = html;

            var newTab = fakeNode.querySelector(".file-viewer li.file-tab.tab");

            // Pull the file panel and add that panel
            var newPanel = fakeNode.querySelector(".file-viewer li.file-tab.tab-panel:first-of-type");

            this.fileTabs.replaceTab(pendingTab, [newTab, newPanel]);

            // Pull the file tab and add the tab
            this.bindTabEvents(newTab);

            this.bindDataPanelEvents(newPanel);

            // Apply events on the new card
            Occam.loadAll(newTab);
            Occam.loadAll(newPanel);
        }, null, { type: type });
    }

    /**
     * Binds interactive events to the component.
     */
    bindEvents() {
        this.bindFileListEvents(this.element);
        this.bindDataPanelEvents(this.element);

        this.element.querySelectorAll("ul.file-tabs > li.file-tab.tab:not(.sidebar)").forEach((tabElement) => {
            this.bindTabEvents(tabElement);
        });

        if (this.fileTabs) {
            this.fileTabs.on("change", (selected) => {
                // Update document title
                this.updateDocumentTitle();

                // Un-highlight the current selected file row
                this.element.querySelectorAll("table.file-listing tr.active").forEach( (row) => {
                    row.classList.remove("active");
                });;

                // Mark the list item
                var item = this.fileTabs.tabAt(selected);
                if (item) {
                    var link = item.querySelector("a");
                    if (link) {
                        var url = link.getAttribute('href');

                        // Find file entry with the same url (if it exists)
                        var cell = this.element.querySelector("table.file-listing tr:not(.upload) td.name a[href=\"" + url + "\"]");

                        if (cell) {
                            cell.parentNode.parentNode.classList.add("active");
                        }
                    }
                }

                // Make sure the sidebar button reflects the right panel status
                var panel = this.fileTabs.tabPanelAt(selected);

                // If there is a panel
                if (panel) {
                    var sidebar = panel.querySelector(".sidebar.right");

                    // And that panel has a sidebar (for the run list)
                    if (sidebar) {
                        // The button should not be disabled
                        let sidebarButton = this.element.querySelector("li.tab.sidebar.run-queue");
                        if (sidebarButton) {
                            sidebarButton.removeAttribute("disabled");
                        }
                    }
                }
            });
        }
    }

    bindTabEvents(tabElement) {
        // Close on middle mouse click
        tabElement.addEventListener('mouseup', (event) => {
            if (event.button == 1) {
                event.preventDefault();
                this.fileTabs.removeTab(tabElement);
            }
        });

        // Handle the close event
        tabElement.querySelector("button.delete").addEventListener('click', (event) => {
            event.stopPropagation();
            event.preventDefault();

            this.fileTabs.removeTab(tabElement);

            // Should the sidebar be disabled?
            if (this.fileTabs.count() == 0) {
                this.element.querySelector("li.tab.sidebar").classList.add("disabled");
            }
        });
    }

    bindDataPanelEvents(element) {
        // Apply collapse event
        const collapseButtons = element.querySelectorAll("h2 > .collapse, ul.file-tabs > .collapse");
        collapseButtons.forEach( (collapseButton) => {
            if (!collapseButton.classList.contains("bound")) {
                collapseButton.addEventListener("mousedown", (event) => {
                    this.fileListing.classList.toggle("reveal");
                    this.pendingListing.classList.toggle("reveal");
                });
                collapseButton.classList.add("bound");
            }
        });
    }

    handleDrop(event) {
        var dataTransfer = event.dataTransfer;
        var files = dataTransfer.files;
        var items = dataTransfer.items || files;

        let dropArea = this.fileListing;

        var uploadEntry = dropArea.querySelector(".upload-bar");
        var uploadFileEntry = uploadEntry.querySelector('input[type="file"]');

        if (!uploadFileEntry.hasAttribute("disabled")) {
            // Start uploading each file
            this.uploadFileList(items);
        }
        else {
            // When we do not have the ability to upload files
            // (When the object is read-only)
            // TODO: Present an error.
            let error = this.element.querySelector(".error.upload-no-write-access-error");
            if (error) {
                error.removeAttribute("hidden");
            }
            else {
                console.error("Cannot find upload-no-write-access-error error text.");
            }
        }
    }

    /**
     * Queues an upload of the given file entry, interpreted as a directory.
     *
     * The entry will be a FileSystemDirectoryEntry which is, as of this
     * implementation of this function, non-standard.
     */
    uploadDirectory(info, path) {
        return new Promise( async (resolve, reject) => {
            let reader = info.createReader();

            let entries = 0;

            // Creates a directory entry in the list to represent this directory.
            this.createDirectory(path + info.name, info.name).then( (newRow) => {
                if (path) {
                    window.setTimeout( () => {
                        newRow.remove();
                    }, 500);
                }
            });

            // Keep reading entries until it returns an empty list.
            do {
                let readEntries = await new Promise( (resolve, reject) => { reader.readEntries(resolve, reject); });
                entries = readEntries.length;

                // For each entry, recursively consume the file list.
                this.uploadFileList(readEntries, path + info.name + "/");
            } while(entries > 0);
        });
    }

    /**
     * Queues an upload of the given File.
     */
    uploadFile(file, path) {
        return new Promise( async (resolve, reject) => {
            let dropArea = this.fileListing;

            var table = dropArea.querySelector("table.file-listing tbody");
            var uploadEntry = dropArea.querySelector(".upload-bar");
            var uploadFileEntry = uploadEntry.querySelector('input[type="file"]');

            var table = this.fileListing.querySelector("table.file-listing tbody");
            var form = uploadEntry.querySelector("form");

            // Ensure upload fields are now hidden
            uploadEntry.setAttribute("hidden", '');

            // Create a list entry for this uploaded file
            var fileEntryTemplate = this.fileListing.querySelector(".file-listing-table template.file");

            var newRow;
            if ('content' in fileEntryTemplate) {
                newRow = document.importNode(fileEntryTemplate.content, true);
                newRow = newRow.querySelector("tr");
            }
            else {
                newRow = fileEntryTemplate.querySelector("tr").cloneNode(true);
            }

            var sizeTuple = Util.toFriendlyFilesize(file.size);

            newRow.classList.add("upload");
            newRow.querySelector("td.name span.name").textContent = path + file.name;
            newRow.querySelector("td.name span.revision").remove();
            newRow.querySelector("td.size.value").textContent = sizeTuple.value;
            newRow.querySelector("td.size.units").textContent = sizeTuple.units;

            if (newRow) {
                let firstRow = table.querySelector("tr:not(.main):first-of-type, tr.main + tr");
                if (firstRow) {
                    table.insertBefore(newRow, firstRow);
                }
                else {
                    table.appendChild(newRow);
                }
            }

            newRow.style.backgroundSize = "0% 100%";

            // TODO: Place it in a queue to be uploaded at some point...


            // A file is created when you post 0 data to the file's route.
            // A directory is created when you post 0 data to a .gitignore in that directory.
            let query = {};

            let basePath = this._viewing.path;
            if (basePath[0] == "/") {
                basePath = basePath.substring(1);
            }
            if (basePath && basePath[basePath.length - 1] != "/") {
                basePath = basePath + "/";
            }
            let url = Occam.object().url({"path": "/files/" + basePath + path, query: query});

            Util.post(url, {
                fileToUpload: file
            }, {
                onload: (html) => {
                    // Replace row
                    let dummy = document.createElement('table');
                    dummy.innerHTML = html;
                    dummy = dummy.querySelector("tr");
                    if (dummy) {
                        newRow.parentElement.replaceChild(dummy, newRow);
                    }

                    this.bindFileRowEvents(dummy);

                    if (path) {
                        window.setTimeout( () => {
                            dummy.remove();
                        }, 500);
                    }

                    resolve();
                },
                onprogress: (event) => {
                    if (event.lengthComputable) {
                        newRow.style.backgroundSize = Math.round((event.loaded / event.total) * 100, 2) + "% 100%";
                    }
                }
            }, "text/html");
        });
    }

    /**
     * Consumes and uploads the file provided by the given file metadata.
     *
     * This can either be a File, a DataTransferItem, or a FileSystemEntry.
     * Browser support dictates that fallbacks are used which include each of
     * these potential types of file metadata structures.
     *
     * It attempts to eventually get a standard File structure before invoking
     * uploadFile. Also, for directory support, it will call uploadDirectory
     * which will recursively call this function indirectly through calls to
     * uploadFileList for entries in that directory.
     */
    uploadFileListItem(file, path = "") {
        // Detect the type of item
        if (!file.getAsEntry) {
            file.getAsEntry = file.webkitGetAsEntry;
        }

        // If it is a DataTransferItem, we can potentially get a FileSystemEntry
        if (file.getAsEntry) {
            let info = file.getAsEntry();
            if (info.isDirectory) {
                this.uploadDirectory(info, path);
            }
            else {
                this.uploadFile(file.getAsFile(), path);
            }
        }
        else {
            // Now, it can be a FileSystemFileEntry or a normal File
            if (file.isDirectory) {
                this.uploadDirectory(file, path);
            }
            else if (file.file) {
                file.file( (f) => {
                    this.uploadFile(f, path);
                });
            }
            else {
                // Treat it as a File type
                this.uploadFile(file, path);
            }
        }
    }

    /**
     * Consume and upload the files represented in the given list.
     *
     * The list is assumed to be either an array of FileSystemEntry structions
     * or a DataTransferItemList of some kind.
     *
     * The uploadFileListItem function will disambiguate for each potential type
     * of file entry and appropriately fallback when some functionality is not
     * available.
     */
    uploadFileList(files, path = "") {
        return new Promise( (resolve, reject) => {
            Array.prototype.slice.call(files).forEach( (file) => {
                this.uploadFileListItem(file, path);
            });
        });
    }

    bindDragDropEvent() {
        if (!this.fileListing) {
            return;
        }

        let dropArea = this.fileListing;

        var table = dropArea.querySelector("table.file-listing tbody");
        var uploadEntry = dropArea.querySelector(".upload-bar");
        var uploadFileEntry = uploadEntry.querySelector('input[type="file"]');

        // Prevent defaults helper.
        let preventDefaults = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };

        // React to when something is dragged on and then off the body.
        // These events sometimes chain in surprising ways that no human can comprehend.
        let dragCount = 0;

        // Associate events
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach( (eventName) => {
            dropArea.addEventListener(eventName, preventDefaults, false);
            document.body.addEventListener(eventName, preventDefaults, false);
        });

        // Associate an event on the body for dragenter/leave so they know where things go
        document.body.addEventListener('dragenter', (event) => {
            dragCount++;
            if (dragCount == 1) {
                document.body.classList.add("dragover");
            }
        });

        // Attempt to handle iframes that get dragged over in some way...
        // Likely won't do much.
        document.body.querySelectorAll('iframe').forEach( (iframe) => {
            ['dragenter', 'dragover', 'dragleave', 'drop'].forEach( (eventName) => {
                dropArea.addEventListener(eventName, preventDefaults, false);
                document.body.addEventListener(eventName, preventDefaults, false);
            });
        });

        document.body.addEventListener('dragleave', (event) => {
            dragCount--;
            if (dragCount == 0) {
                document.body.classList.remove("dragover");
            }
        });

        document.body.addEventListener('drop', (event) => {
            dragCount = 0;
            document.body.classList.remove("dragover");
        });

        // Handle dropped files
        dropArea.addEventListener('drop', this.handleDrop.bind(this), false);
    }

    bindFileListEvents(element) {
        this.fileListing = this.element.querySelector(".card.file-list-panel:not(.pending)");

        // Handle drag-n-drop of file uploads.
        this.bindDragDropEvent();

        // Bind new-actions bar
        let showUploadButton = element.querySelector(".new-actions-bar button.new-action.show-upload-file");
        if (showUploadButton) {
            showUploadButton.addEventListener("click", (event) => {
                let uploadRow = element.querySelector(".upload-bar");
                if (uploadRow) {
                    uploadRow.removeAttribute('hidden');
                }
            });
            showUploadButton.classList.add("bound");
        }

        let newGroupButton = element.querySelector(".new-actions-bar button.new-action.new-group");
        if (newGroupButton) {
            // TODO: add a new group and focus on it
            newGroupButton.addEventListener("click", (event) => {
                let newRowTemplate = element.querySelector("template.new-group");
                if (newRowTemplate) {
                    let newRow = null;
                    if ('content' in newRowTemplate) {
                        newRow = document.importNode(newRowTemplate.content, true);
                        newRow = newRow.querySelector("tr");
                    }
                    else {
                        newRow = newRowTemplate.querySelector("tr").cloneNode(true);
                    }

                    if (newRow) {
                        let firstRow = element.querySelector("table tbody tr:not(.main):first-of-type, table tbody tr.main + tr");
                        if (firstRow) {
                            element.querySelector("table tbody").insertBefore(newRow, firstRow);
                        }
                        else {
                            element.querySelector("table tbody").appendChild(newRow);
                        }
                        this.bindNewRowEvents(newRow);
                    }
                }
            });
        }

        let newFileButton = element.querySelector(".new-actions-bar button.new-action.new-file");
        if (newFileButton) {
            // TODO: add a new file and focus on it
            newFileButton.addEventListener("click", (event) => {
                let newRowTemplate = element.querySelector("template.new-file");
                if (newRowTemplate) {
                    let newRow = null;
                    if ('content' in newRowTemplate) {
                        newRow = document.importNode(newRowTemplate.content, true);
                        newRow = newRow.querySelector("tr");
                    }
                    else {
                        newRow = newRowTemplate.querySelector("tr").cloneNode(true);
                    }

                    if (newRow) {
                        let firstRow = element.querySelector("table tbody tr:not(.main):first-of-type, table tbody tr.main + tr");
                        if (firstRow) {
                            element.querySelector("table tbody").insertBefore(newRow, firstRow);
                        }
                        else {
                            element.querySelector("table tbody").appendChild(newRow);
                        }
                        this.bindNewRowEvents(newRow);
                    }
                }
            });
        }

        var rows = element.querySelectorAll("tr:not(.upload)");
        rows.forEach( (row) => {
            this.bindFileRowEvents(row);
        });
    }

    bindFileRowEvents(row) {
        // Bind click events on each file row
        var cells = row.querySelectorAll("td:not(.actions)");

        cells.forEach( (cell) => {
            cell.style.cursor = "pointer";

            // Ensure hover indicates that any cell can be clicked visually
            cell.addEventListener("mouseenter", (event) => {
                cell.parentNode.classList.add("hover");
            });

            // Ditto, but for when the cell is not hovered
            cell.addEventListener("mouseleave", (event) => {
                cell.parentNode.classList.remove("hover");
            });

            // Clicking on any cell will open the file/directory
            cell.addEventListener("click", (event) => {
                event.preventDefault();
                event.stopPropagation();

                // Get the corresponding link
                var tr = cell.parentNode;
                var link = tr.querySelector("td.name a");

                var url = link.getAttribute("href");

                var isGroup = false;

                if (link.parentNode.parentNode.getAttribute("data-type") == "tree") {
                    this.loadGroup(url, link.textContent);
                    isGroup = true;
                }
                else {
                    this.loadFile(url, link.parentNode.parentNode.querySelector("td.type").textContent, link.querySelector("span.name").textContent, link.parentNode.parentNode.getAttribute('data-path'), link.parentNode.parentNode.querySelector("svg > use").getAttribute('xlink:href'));
                }
            });
        });
    }

    /**
     * Asynchronously creates a new file.
     */
    create(path, name, isFile = true, existingRow = null) {
        return new Promise( (resolve, reject) => {
            // Make the row a spinner
            var loadingRowTemplate = this.element.querySelector("template.loading");

            var loadingRow = null;
            if ('content' in loadingRowTemplate) {
                loadingRow = document.importNode(loadingRowTemplate.content, true);
                loadingRow = loadingRow.querySelector("tr");
            }
            else {
                loadingRow = loadingRowTemplate.querySelector("tr").cloneNode(true);
            }
            loadingRow.querySelector("span.name").textContent = name;

            if (existingRow) {
                existingRow.parentElement.replaceChild(loadingRow, existingRow);
            }
            else {
                let firstRow = this.element.querySelector("table tbody tr:not(.main):first-of-type, table tbody tr.main + tr");
                if (firstRow) {
                    this.element.querySelector("table tbody").insertBefore(loadingRow, firstRow);
                }
                else {
                    this.element.querySelector("table tbody").appendChild(loadingRow);
                }
            }

            // A file is created when you post 0 data to the file's route.
            // A directory is created when you post 0 data to a .gitignore in that directory.
            let query = null;
            if (!isFile) {
                query = {};
                query.responsePath = path;
                path = path + "/.gitignore";
            }

            let url = Occam.object().url({"path": "/files/" + path, query: query});
            Util.post(url, "", (html) => {
                // Replace the row
                let dummy = document.createElement('table');
                dummy.innerHTML = html;
                dummy = dummy.querySelector("tr");
                if (dummy) {
                    loadingRow.parentElement.replaceChild(dummy, loadingRow);
                }

                this.bindFileRowEvents(dummy);

                resolve(dummy);
            }, 'text/html');
        });
    }

    /**
     * Asynchronously creates a new directory.
     */
    createDirectory(path, name) {
        return this.create(path, name, false);
    }

    /**
     * This method binds events for "new" file/group rows in the file list.
     */
    bindNewRowEvents(element) {
        let newRow = element;

        // Focus on name input
        let inputElement = newRow.querySelector("input.name");
        if (inputElement) {
            inputElement.focus();

            let create = (event) => {
                inputElement.removeEventListener("blur", create);
                if (inputElement.value.trim() == "") {
                    newRow.remove();
                }
                else {
                    let isFile = element.classList.contains("file");

                    // Submit the new row and get back the rendered item
                    let path = this._viewing.path;
                    if (path[path.length - 1] != "/") {
                        path = path + "/";
                    }
                    path = path + inputElement.value;
                    name = inputElement.value;
                    this.create(path, name, isFile, newRow);
                }
            };

            inputElement.addEventListener("blur", create);
            inputElement.addEventListener("keydown", (event) => {
                if (event.code == "Enter" && inputElement && inputElement.value.trim() != "") {
                    create(event);
                }
            });
        }
    }

    /**
     * This method updates the document title to reflect the current tab.
     */
    updateDocumentTitle() {
        if (this.mainTabs && this.mainTabs.selected() == this.filePanelIndex) {
            let selected = this.fileTabs.selected();
            if (selected >= 0) {
                let tab = this.fileTabs.tabAt(selected);
                if (tab && tab.classList.contains('file-tab')) {
                    let name = tab.querySelector(".label span.name").textContent;
                    document.title = this.title + " :: " + name;
                }
            }
        }
    }
}

FileList._count = 0;
FileList._loaded = {};

export default FileList;
