"use strict";

import EventComponent from './event_component.js';
import Util           from './util.js';
import Tabs           from './tabs.js';
import Occam          from './occam.js';

/**
 * This class represents a run list, which is the list of queued or running
 * jobs or tasks.
 *
 * This panel appears on the "View" or "Run" and usually has an entry for
 * "Queue" which can queue a new task. It also appears on each file tab.
 *
 * This list is also reused for the job viewer to list the jobs for a node
 * in a workflow.
 */
class RunList extends EventComponent {
    constructor(element) {
        super();

        if (element.getAttribute('data-run-list-index') == null) {
            RunList._count++;

            element.setAttribute('data-run-list-index', RunList._count);
            RunList._loaded[element.getAttribute('data-run-list-index')] = this;
        }

        // Get "Builds" header
        this.header = element.querySelector("h3");

        // Get the sidebar
        this.sidebar = element.parentNode;

        // Get the tabs that hold the sidebar button
        this.tabs = Tabs.tabsFor(this.sidebar);

        this.bindCollapseEvents();

        // Get the item template
        this.template = element.parentNode.querySelector("template.run-item:not(.viewing)");
        this.viewingTemplate = element.parentNode.querySelector("template.run-item.viewing");
        this.runningTemplate = element.parentNode.querySelector("template.run-item.running");

        this.list = element;

        // Ensure the selected option is the tabindex and the rest are not
        this.select(this.selected());

        // Attach events to each existing list item
        this.list.querySelectorAll(":scope > li").forEach( (item) => {
            this.bindEvents(item);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element;
        if (typeof element != "number") {
            index = element.getAttribute('data-run-list-index');
        }

        if (index) {
            return RunList._loaded[index];
        }

        return new RunList(element);
    }

    /**
     * The HTMLElement that represents this list.
     */
    get element() {
        return this.list;
    }

    /**
     * Hides the run list.
     */
    hide() {
        this.sidebar.classList.add("reveal");
        this.trigger("hidden");

        // Handle tab sidebar button
        this.tabs.hideSidebarButton(1);
    }

    /**
     * Shows the run list.
     */
    show() {
        this.sidebar.classList.remove("reveal");
        this.trigger("shown");

        // Handle tab sidebar button
        if (this.tabs) {
            this.tabs.showSidebarButton(1);
        }
    }

    /**
     * Shows/Hides the run list.
     */
    showHideList(show) {
        if (this.sidebar) {
            if (show == true || (show != false && this.sidebar.classList.contains("reveal"))) {
                // Show
                this.show();
            }
            else {
                // Hide
                this.hide();
            }
        }
    }

    /* Attaches events to the collapse bar to show/hide the list.
    */
    bindCollapseEvents() {
        if (this.collapse) {
            this.collapse.addEventListener("click", (event) => {
                this.showHideList();
            });
        }
    }

    /* Attaches events to the given item element.
    */
    bindEvents(item) {
        // Actions
        var actions = item.querySelector("ul.actions");
        if (actions) {
            var buttons = actions.querySelectorAll("li > *:not(form), li > form > button");
            buttons.forEach( (button) => {
                var form = null;
                var actionItem = button.parentNode;
                if (actionItem.tagName.toUpperCase() == "FORM") {
                    form = actionItem;
                    actionItem = actionItem.parentNode;
                }

                var action = actionItem.getAttribute("data-action");
                if (action) {
                    button.addEventListener("click", (event) => {
                        event.stopPropagation();
                        event.preventDefault();

                        this.trigger("action-" + action, item);
                    });
                }
                else if (form) {
                    // Just submit the form asynchronously
                    button.addEventListener("click", (event) => {
                        event.stopPropagation();
                        event.preventDefault();

                        Util.submitForm(form);
                    });
                }
            });
        }

        var link = item.querySelector("a");

        link.addEventListener("mousedown", (event) => {
            link.classList.add("clicked");
        });

        link.addEventListener("click", (event) => {
            event.preventDefault();
            event.stopPropagation();

            // Select the correct item
            this.select(this.indexFor(item));
        });

        link.addEventListener('blur', (event) => {
            link.classList.remove("clicked");
        });

        link.addEventListener("focus", (event) => {
            this.showHideList(true);
        });

        link.addEventListener("keydown", (event) => {
            var toggle = null;
            if (event.keyCode == 38) { // up arrow
                toggle = item.previousElementSibling;
            }
            else if (event.keyCode == 40) { // down arrow
                toggle = item.nextElementSibling;
            }
            else if (event.keyCode == 39 || event.keyCode == 37) { // left/right arrow
                // Unfocus the run list and go to content
                event.preventDefault();
                event.stopPropagation();

                this.trigger("focus", item);

                return;
            }

            if (toggle) {
                this.select(this.indexFor(toggle));
                toggle.querySelector("a").focus();
            }
        });
    }

    /**
     * Selects the given item in the list.
     */
    select(index_or_element) {
        var selectedIndex = this.selected();
        var item = this.elementFor(index_or_element);

        if (item) {
            var index = this.indexFor(item);

            if (selectedIndex == index) {
                return item;
            }
        }

        // De-select the currently selected item
        this.list.querySelectorAll(':scope > .active').forEach( (child) => {
            child.classList.remove('active');
            var link = child.querySelector("a");
            link.classList.remove("clicked");
            link.setAttribute('tabindex', '-1');
            link.setAttribute('aria-selected', 'false');
        });

        if (!item) {
            return null;
        }

        var link = item.querySelector("a");
        item.classList.add('active');
        link.setAttribute('tabindex', '0');
        link.setAttribute('aria-selected', 'true');

        // Trigger an event
        this.trigger('change', item);

        // Return the element
        return item;
    }

    indexFor(index_or_element) {
        var item = this.elementFor(index_or_element);
        return Util.getChildIndex(item, "li");
    };

    /**
     * Returns the index of the currently selected item.
     */
    selected() {
        var item = this.list.querySelector(':scope > li.active');

        if (!item) {
            return -1;
        }

        return this.indexFor(item);
    }

    /* Updates the item element to reflect the given information.
    */
    update(entry, options) {
        // Set type/name/date etc
        ['date', 'name', 'type'].forEach(function(key) {
            if (options[key]) {
                var field = entry.querySelector("span." + key);
                if (field) {
                    field.textContent = options[key];
                }
            }

            if (key == 'type') {
                let useEntry = entry.querySelector("svg use");

                if (useEntry) {
                    useEntry.setAttribute("xlink:href", "/images/symbol/objects.svg#" + options.type);
                }
            }
        });

        var status = options.status;

        if (options.finishTime) {
            status = "done";
        }

        if (options.failureTime) {
            status = "failed";
        }

        // status
        if (options.status) {
            if (options.status == "finished") {
                status = "done";
            }
            if (options.status == "started") {
                status = "running";
            }
            if (options.status == "queued") {
                status = "pending";
            }
        }

        if (options.configurations) {
            entry.setAttribute("data-configurable", "");
        }
        else {
            entry.removeAttribute("data-configurable");
        }

        if (status) {
            entry.setAttribute("data-status", status);

            if (status == "done" || status == "failed") {
                // Remove 'cancel' button
                var deleteButton = entry.querySelector("ul.actions > li.cancel, ul.actions > li.delete");
                if (deleteButton) {
                    deleteButton.setAttribute('hidden', "");
                }
            }
        }

        // id/revision
        if (options.id) {
            if (this.list.classList.contains("jobs")) {
                entry.setAttribute("data-job-id", options.id);
            }
            else {
                entry.setAttribute("data-object-id", options.id);
            }
        }

        if (options.revision) {
            entry.setAttribute("data-object-revision", options.revision);
        }

        if (this.elementFor(this.selected()) === entry) {
            this.trigger('change', entry);
        }
    }

    /* Removes all entries in the list.
    */
    clear() {
        this.list.querySelectorAll("li").forEach(function(entry) {
            entry.remove();
        });
    }

    /* Appends the given item to the run list at the given location.
    */
    append(options, atIndex) {
        var runStatus = options.action || options.status || "pending";

        if (atIndex === undefined) {
            atIndex = 1;
        }

        var template = this.template;
        if (runStatus == "viewing") {
            template = this.viewingTemplate;
        }
        if (runStatus == "running") {
            template = this.runningTemplate;
        }

        var newItem = null;
        if (template && ('content' in template)) {
            newItem = document.importNode(template.content, true);
            newItem = newItem.querySelector("li");
        }
        else {
            newItem = template.querySelector("li").cloneNode(true);
        }

        var lastItem = this.list.querySelector("li:nth-of-type(" + (atIndex + 1) + ")");
        if (lastItem && lastItem.hasAttribute('data-staged')) {
            lastItem = lastItem.nextElementSibling;
        }
        if (this.header && runStatus == "running") {
            lastItem = this.header;
            if (options.phase === 'build') {
                lastItem = lastItem.nextElementSibling;
            }
        }
        if (!lastItem) {
            // If we can't find the item at the index, then find the last known list item
            lastItem = this.list.querySelector("li:last-of-type");

            // And we will add it *after*, so we need to add it before the next one
            if (lastItem) {
                lastItem = lastItem.nextElementSibling;
            }
        }

        if (!lastItem) {
            // There are no items in the list, so we just append
            this.list.appendChild(newItem);
        }
        else {
            // We have a reference item, so add it before this one
            this.list.insertBefore(newItem, lastItem);
        }

        // Set the class name
        newItem.setAttribute("data-status", options.status || "pending");

        this.update(newItem, options);

        // Put all events on this item
        Occam.loadAll(newItem);

        // Attach events
        this.bindEvents(newItem);

        // Return the element representing the item
        return newItem;
    }

    /* Replaces the job list with the given HTML content.
     *
     * Should be a set of <li> elements.
     */
    loadHTML(html) {
        this.list.innerHTML = html;
        this.list.querySelectorAll(":scope > li").forEach(function(item) {
            this.bindEvents(item);
        }.bind(this));
    }

    /* Replaces a list item with the given element.
    */
    replace(index_or_element, newEntry) {
        var oldEntry = this.elementFor(index_or_element);
        oldEntry.parentNode.replaceChild(newEntry, oldEntry);
        this.bindEvents(newEntry);
        this.select(newEntry);

        Occam.loadAll(newEntry);

        return newEntry;
    }

    /* Removes the list item by either index or element.
    */
    remove(index_or_element) {
        var element = this.elementFor(index_or_element);

        if (!element) {
            return;
        }

        var index = this.indexFor(element);

        var reselect = this.selected() == index;

        element.remove();

        if (reselect) {
            this.select(index - 1);
        }
    }

    /* Retrieves the list element for the given index.
     *
     * Returns null when the item cannot be found.
     */
    elementFor(index_or_element) {
        // Presume the passed argument is the element
        var item = index_or_element;

        // Passing an element?
        if (index_or_element && !!index_or_element.tagName) {
            // We are done
        }
        else if (typeof index_or_element == "object") {
            // We have a dictionary to match items against
            let data = index_or_element;

            if (data) {
                item = null;

                // Go through each item
                let items = this.list.querySelectorAll('li');

                items.forEach( (listItem) => {
                    if ("" + data.viewerID) {
                        if (listItem.getAttribute('data-viewer-id') == ("" + data.viewerID)) {
                            item = listItem;
                            return false;
                        }
                    }
                });
            }
        }
        else {
            // Try really hard to get a number
            index_or_element = parseInt(index_or_element);

            // However, if we are passed an index...
            if (index_or_element < 0) {
                index_or_element = 0;
            }

            // Find the item to select
            item = this.list.querySelector(':scope > li:nth-of-type(' + (index_or_element + 1) + ')');
        }

        // If the item does not exist, return null.
        if (!item) {
            return null;
        }

        return item;
    }

    /**
     * Retrieves information about the given item in the list.
     */
    infoFor(index_or_element) {
        var item = this.elementFor(index_or_element);

        // Ready the return object.
        var ret = {};

        // Gather the status
        ret.status = item.getAttribute("data-status");
        if (item.hasAttribute("data-run-id")) {
            ret.runID = item.getAttribute("data-run-id");
        }
        if (item.hasAttribute("data-job-id")) {
            ret.jobID = item.getAttribute("data-job-id");
        }
        if (item.hasAttribute("data-viewer-id")) {
            ret.viewerID = item.getAttribute("data-viewer-id");
        }
        if (item.hasAttribute("data-task-id")) {
            ret.taskID = item.getAttribute("data-task-id");
        }
        if (item.hasAttribute("data-staged")) {
            ret.staged = item.getAttribute("data-staged");
        }
        let name = item.querySelector('.name');
        if (name) {
            ret.name = name.textContent;
        }
        let type = item.querySelector('.type');
        if (type) {
            ret.type = type.textContent;
        }

        return ret;
    }
}

RunList._count = 0;
RunList._loaded = {};

export default RunList;
