"use strict";

import EventComponent from "./event_component.js";
import WebSocket      from "./web_socket.js";

/**
 * This class instantiates a terminal emulator at the given element.
 */
class Terminal extends EventComponent {
    constructor(name, type, data, element, link) {
        super();

        // Determine a unique id
        this.terminalId = name + Math.floor(Math.random() * (1000 + 1));

        // Remember this terminal
        Terminal._terminals[this.terminalId] = this;

        // The terminal data to send upon open
        this.data = data || {};

        // Initialize the vt100 context
        this.context = {};

        // Pull out link text from element
        if (element.getAttribute('data-link-text') != undefined) {
            link = element.getAttribute('data-link-text');
        }

        this.name    = name;
        this.link    = link || "Run Terminal";
        this.type    = type;
        this.element = element;
        this.element.setAttribute('data-terminal-id', this.terminalId);
        //this.element.appendChild(this.context.caret);

        // Create an XTerm
        let termStyle = window.getComputedStyle(this.element, null);
        this.xTerm = new window.XTerm({
          //allowTransparency: true,    // Allows transparent backgrounds
          theme: {
            background: "#222"
          },
          screenReaderMode: true,     // Enables screen-reader support
          cursorBlink:      true,     // Whether or not the cursor caret blinks
          cursorStyle:      "block",  // Cursor style: block, underline, or bar
          tabStopWidth:     8,        // Default tab stop width (in spaces)
          convertEol:       true,     // Turn any '\n' into '\r\n'
        });
        this.xTermFit = new window.XTerm.FitAddon();
        this.xTerm.loadAddon(this.xTermFit);
        var xTermPane = document.createElement("div");
        xTermPane.classList.add("xterm");
        xTermPane.classList.add("card");
        xTermPane.classList.add("transparent");
        xTermPane.classList.add("filled");
        //xTermPane.classList.add("flex");
        this.element.appendChild(xTermPane);
        var loadingPane = document.createElement("div");
        loadingPane.classList.add("card");
        loadingPane.classList.add("overlay");
        loadingPane.classList.add("white");
        loadingPane.classList.add("filled");
        loadingPane.classList.add("transparent");
        loadingPane.classList.add("loading");
        this.element.appendChild(loadingPane);
        this.xTerm.onData( (bytes) => {
            this.trigger("write", bytes);
        });
        this.xTerm.open(xTermPane);           // create the XTerm
        this.xTermFit.fit();                  // fit the container, please
        xTermPane.querySelector(".xterm").classList.add("filled");

        this.resizeTimer = null;

        window.elementResizeEvent(this.element, (event) => {
            // Prevents flickering when redrawing the terminal
            window.setTimeout( () => {
                this.resizeEvent();
            }, 0);
        });

        this.jobID = this.element.getAttribute("data-job-id");

        this.pendingLogItem = "";

        this.element.addEventListener('keydown', (event) => {
            event.stopPropagation();
            event.preventDefault();
        });

        // Open websocket route.
        this.ws = WebSocket.route(this.terminalId, this.write, this);

        return this;
    }

    static loadAll(element) {
        // Find the 'terminal' element
        element.querySelectorAll(".terminal:not(.xterm):not(template)").forEach( (subElement) => {
            Terminal.load(subElement);
        });
    }

    static load(element) {
        if (!element) {
            return null;
        }

        var id = element.getAttribute('data-terminal-id');

        if (!id) {
            var object_id        = element.getAttribute('data-object-id');
            var object_revision  = element.getAttribute('data-object-revision');
            var object_link      = element.getAttribute('data-object-link');
            var workset_id       = element.getAttribute('data-workset-id');
            var workset_revision = element.getAttribute('data-workset-revision');
            var input_id         = element.getAttribute('data-input-id');
            var input_revision   = element.getAttribute('data-input-revision');
            var job_id           = element.getAttribute('data-job-id');
            var build_id         = element.getAttribute('data-build-id');
            var build_revision   = element.getAttribute('data-build-revision');
            var token            = element.getAttribute('data-account-token');
            var type             = element.getAttribute('data-terminal-type');
            var logType          = "tty";
            if (type === "run") {
                //logType = "log";
            }

            new Terminal(type, logType, {
                "object_id":        object_id,
                "object_revision":  object_revision,
                "object_link":      object_link,
                "workset_id":       workset_id,
                "workset_revision": workset_revision,
                "input_id":         input_id,
                "input_revision":   input_revision,
                "build_id":         build_id,
                "build_revision":   build_revision,
                "job_id":           job_id,
                "token":            token,
            }, element);
        }

        return Terminal.retrieve(element);
    }

    static retrieve(element_or_terminalId) {
        var id = element_or_terminalId;
        if (id.getAttribute !== undefined) {
            id = id.getAttribute('data-terminal-id');
        }
        return Terminal._terminals[id];
    }

    calculateSize() {
        var computedStyle = window.getComputedStyle(this.element);

        var elementHeight = this.element.clientHeight;
        var elementWidth = this.element.clientWidth;

        elementHeight -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
        elementWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);

        this.context.height = elementHeight / this.context.charHeight;
        this.context.height = Math.round(this.context.height - 0.5);

        this.context.width  = elementWidth / this.context.charWidth;
        this.context.width  = Math.round(this.context.width  - 0.5);
    }

    reset() {
        this.xTerm.reset();
    }

    resizeEvent() {
        var oldWidth  = this.context.width;
        var oldHeight = this.context.height;

        this.calculateSize();

        var width  = this.context.width;
        var height = this.context.height;

        this.xTermFit.fit();

        // Send the signal

        this.trigger('resize', {
          "oldWidth": oldWidth,
          "oldHeight": oldHeight,
          "width": width,
          "height": height
        });
    }

    write(data) {
        var terminal = this;

        if (data.base64 || data.base64 == "") {
            data.output = atob(data.base64) || "";
        }

        if (terminal.type == "tty") {
            /*
            let maxScroll = terminal.element.scrollHeight - terminal.element.clientHeight;
            let autoScroll = (terminal.element.scrollTop >= maxScroll - 0.5);
            let response = VT100.parse(data.output, terminal.element, terminal.context);

            if (response.length > 0) {
                var message = {
                    "request": "write",
                    "terminal": terminal.terminalId,
                    "input":    response,
                    "data": {"job_id": terminal.jobID}
                };
                terminal.ws.send(message);
            }
            if (autoScroll) {
                terminal.element.scrollTop = terminal.element.scrollHeight - terminal.element.clientHeight;
            }*/
            let loadingPane = this.element.querySelector(".loading.card");
            if (loadingPane) {
                loadingPane.remove();
            }
            this.xTerm.write(data.output);
        }
        else {
            var inputs = data.output.split('\n');

            let maxScroll = terminal.logRootElement.scrollHeight - terminal.logRootElement.clientHeight;
            let autoScroll = (terminal.logRootElement.scrollTop >= maxScroll - 0.5);

            if (inputs.length > 0) {
                inputs[0] = terminal.pendingLogItem + inputs[0];
                terminal.pendingLogItem = "";
            }

            for (var i = 0; i < inputs.length; i++) {
                var logItem = {};

                inputs[i] = inputs[i].trim();
                if (inputs[i].length == 0) {
                    continue;
                }

                try {
                    logItem = JSON.parse(inputs[i]);
                }
                catch(e) {
                    if (i == inputs.length-1) {
                        terminal.pendingLogItem = inputs[i];
                    }
                    continue;
                }

                if (logItem.params === undefined) {
                    logItem.params = {};
                }
                logItem.params = logItem.params || {};

                if (logItem.type == "event") {
                    // Handle the event
                    if (logItem.message == "video") {
                        // Switch tabs and start the VNC client
                        /*
                        var videoTab = $(terminal.logTabs.children('.tab').get(3));
                        videoTab.attr('aria-hidden', 'false').trigger('click');
                        var button = $(terminal.logTabPanels.children('.tab-panel').get(3)).find('#stream_connect_button');
                        button.data('port', logItem.params.port);
                        button.trigger('click');*/
                        window.console.log("video event");
                    }
                    continue;
                }

                var logElement = document.createElement("li");
                logElement.classList.add(logItem.type);
                logElement.textContent = logItem.message;
                logElement.style.opacity = 0;

                logItem.params = logItem.params || {};
                if (logItem.params.source) {
                    logElement.setAttribute('data-source', logItem.params.source);
                }

                if (logItem.params.source && (terminal.lastLogElement.getAttribute('data-source') == logElement.getAttribute('data-source'))) {
                    // Combine elements with the same source
                    terminal.lastLogElement.appendChild(document.createElement('br'));
                    var section = document.createElement("span");
                    section.textContent = logItem.message;
                    terminal.lastLogElement.appendChild(section);
                }
                else {
                    terminal.logListElement.appendChild(logElement);
                    terminal.lastLogElement = logElement;

                    logElement.style.opacity = 1.0;
                }

                if (autoScroll) {
                    terminal.logRootElement.scrollTop = terminal.logRootElement.scrollHeight - terminal.logRootElement.clientHeight;
                }
            }
        }
    }

    /*
     * Sends the key to the server.
     */
    sendKey(key) {
        this.trigger("write", key);
    }

    /*
     * Runs the terminal.
     */
    open() {
        var terminal = this;

        var job_id = this.element.getAttribute('data-job-id');
        var build_id = this.element.getAttribute('data-build-id');

        terminal.data.job_id = job_id;
        terminal.data.build_id = build_id;

        var message = {
            "request":  "open",
            "spawning": terminal.name,
            "data": terminal.data,
            "rows": terminal.context.height,
            "cols": terminal.context.width,
            "terminal": terminal.terminalId
        };

        this.ws.send(message);
    }
}

// Contains a list of all active terminals
Terminal._terminals = {};

export default Terminal;
