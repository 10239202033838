"use strict";

import EventComponent from './event_component.js';
import Util from './util.js';

class HelpPanel extends EventComponent {
    constructor(element) {
        super();

        if (element === undefined) {
            return;
        }

        // Turn this into a <BUTTON>
        this.element = Util.replaceTag(element, "button");

        if (HelpPanel.fakeCard === undefined) {
            // Fake card
            HelpPanel.fakeCard = document.createElement("div");
            HelpPanel.fakeCard.setAttribute("role", "presentation");
            HelpPanel.fakeCard.setAttribute("aria-hidden", "true");
            HelpPanel.fakeCard.classList.add("card");
            HelpPanel.fakeCard.style.opacity = 0;
            HelpPanel.fakeCard.style.position = "absolute";
            // Ensure that the help bubble isn't the first element so
            // the correct styling is applied (except for when the help bubble
            // *IS* the first element)
            HelpPanel.fakeCard.appendChild(document.createElement("div"));
            document.querySelector(".content").appendChild(HelpPanel.fakeCard);
        }

        HelpPanel._count++;
        this.element.setAttribute('data-help-index', HelpPanel._count);

        HelpPanel._loaded[this.element.getAttribute('data-help-index')] = this;

        this.bindEvents();
    }

    static loadAll(element) {
        var elements = element.querySelectorAll('.help-bubble');

        elements.forEach(function(element) {
            HelpPanel.load(element);
        });
    }

    static load(element) {
        if (element === undefined) {
            return null;
        }

        var index = element.getAttribute('data-help-index');

        if (index) {
            return HelpPanel._loaded[index];
        }

        return new HelpPanel(element);
    }

    get helpTextElement() {
        var helpText = this.element.nextElementSibling;
        if (helpText === null) {
            helpText = this.element.parentNode.nextElementSibling;
        }
        if (helpText === null) {
            helpText = this.element.parentNode.parentNode.nextElementSibling;
        }
        return helpText;
    }

    bindEvents() {
        // Help bubble expansion
        let helpText = this.helpTextElement;

        // Measure help text
        var dup = helpText.cloneNode(true);
        HelpPanel.fakeCard.appendChild(dup);

        dup.removeAttribute("hidden");
        dup.style.height = "";
        dup.style.minHeight = "";

        // Get the height
        var height = dup.offsetHeight;

        dup.remove();

        helpText.style.minHeight = "0px";
        var initiallyHidden = helpText.getAttribute("hidden") !== null;
        if (initiallyHidden) {
            helpText.style.height = "0px";
            helpText.style.opacity = 0;
            helpText.style.borderWidth = 0;
            helpText.classList.add("closed");
        }
        else {
            var computedStyle = window.getComputedStyle(helpText);
            if (this.isFullHeight()) {
                helpText.style.height = "calc(100% - " + computedStyle.top + ")";
            }
            else {
                helpText.style.height = height + "px";
            }
            helpText.style.opacity = 1;
            helpText.classList.add("open");
        }
        helpText.removeAttribute("hidden");

        // Add 'delete' button to help bubbles
        var deleteNode = document.createElement("div");
        deleteNode.classList.add("delete");
        helpText.insertBefore(deleteNode, helpText.children[0]);

        var openCloseHandler = (event) => {
            event.stopPropagation();
            event.preventDefault();

            if (!helpText.classList.contains("bound")) {
                // Make sure the help text has a static height to begin with

                helpText.style.minHeight = "0px";
                helpText.style.position = "";
                if (initiallyHidden) {
                    helpText.style.height = "0px";
                    helpText.classList.add("closed");
                }
                else {
                    var computedStyle = window.getComputedStyle(helpText);
                    if (this.isFullHeight()) {
                        helpText.style.height = "calc(100% - " + computedStyle.top + ")";
                    }
                    else {
                        helpText.style.height = height + "px";
                    }
                    helpText.style.opacity = 1;
                    helpText.classList.add("open");
                }

                helpText.classList.add("bound");
            }

            if (helpText.classList.contains("closed")) {
                // Show the help text
                // Special-case tab-bar help fields to be full height
                var computedStyle = window.getComputedStyle(helpText);
                if (this.isFullHeight()) {
                    helpText.style.height = "calc(100% - " + computedStyle.top + ")";
                    helpText.style.borderWidth = "";
                }
                else {
                    helpText.style.height = height + "px";
                    helpText.style.borderTopWidth = "3px";
                    helpText.style.borderBottomWidth = "1px";
                }
                helpText.style.opacity = 1;

                helpText.classList.remove("closed");
                helpText.classList.add("open");
                helpText.setAttribute("aria-hidden", "false");
                this.element.setAttribute("aria-expanded", "true");
                this.element.parentNode.classList.add("open");
            }
            else {
                // Hide the help text
                helpText.style.height = "0px";
                helpText.style.opacity = 0;
                helpText.style.borderWidth = 0;

                helpText.classList.remove("open");
                helpText.classList.add("closed");
                helpText.setAttribute("aria-hidden", "true");
                this.element.setAttribute("aria-expanded", "false");
                this.element.parentNode.classList.remove("open");
            }
        };

        this.element.addEventListener("click", openCloseHandler);
        deleteNode.addEventListener("click", openCloseHandler);
    }

    isFullHeight() {
        let helpText = this.helpTextElement;

        // We are full height if we are in a sidebar or we are the first help
        // button on the first card on a tabbed card.
        return helpText.parentNode.parentNode.classList.contains("sidebar") ||
               (helpText.nextElementSibling.classList.contains("tab-bar") &&
                Util.getChildIndex(helpText.parentNode, "div.card:not(.errors)") == 0);
    }
}

HelpPanel._loaded = {};
HelpPanel._count  = 0;

export default HelpPanel;
