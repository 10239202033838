"use strict";

import EventComponent  from './event_component.js';
import Util            from './util.js';
import Occam           from './occam.js';
import NavigationState from './navigation_state.js';

/**
 * This class handles any tab strip on the website.
 */
class Tabs extends EventComponent {
    constructor(element) {
        super();

        this.nextTabID = 0;

        if (element) {
            this.element = element;
            if (element.hasAttribute("data-panels")) {
                this.panels = document.querySelector(".tab-panels#" + element.getAttribute("data-panels"));
            }
            if (!(this.panels)) {
                this.panels = element.parentNode.querySelector('.tabs + .tab-panels');
            }
            if (!(this.panels)) {
                this.panels = element.parentNode.parentNode.querySelector('* > .tab-panels');
            }
            if (this.element.getAttribute('data-tabs-index') == null) {
                Tabs._count++;

                this.element.setAttribute('data-tabs-index', "tabs-" + Tabs._count);
                Tabs._loaded[this.element.getAttribute('data-tabs-index')] = this;
            }

            // Whether or not these tabs exist within a modal
            this.withinModal = false;
            if (Util.getParents(this.element, ".modal-window", ".modal-window").length > 0) {
                this.withinModal = true;
            }

            // Whether or not this is within another tabstrip
            var parentTabPanels = Util.getParents(this.element, ".tab-panels", ".tab-panels");
            if (parentTabPanels && parentTabPanels[0]) {
                // Get the tab strip associated with this
                var parentTabPanel = parentTabPanels[0];
                var parentTabStrip = null;
                if (parentTabPanel.hasAttribute("id")) {
                    parentTabStrip = document.querySelector('ul.tabs[data-panels="' + parentTabPanel.getAttribute('id') + '"]');
                }

                if (!parentTabStrip) {
                    parentTabStrip = parentTabPanel.parentNode.querySelector("ul.tabs");
                }

                if (parentTabStrip) {
                    this.parentTabs = Tabs.load(parentTabStrip);
                }
            }

            // Whether or not there is a sidebar reveal button
            this.containsSidebarButton = !!element.querySelector(".tab.sidebar:not(:last-of-type)");
            this.containsRightSidebarButton = !!element.querySelector(".tab.sidebar:last-of-type");

            // Detect a dropdown menu
            // Note: The events to open/close dropdown menus are already handled by the Occam.Dropdown class.
            // The dropdown menu will be visible when tabs are obscured due to the maximum width.
            this.dropdownMenuButton = element.querySelector("li.dropdown-menu");
            if (!this.dropdownMenuButton) {
                // We can add one...
                let menuButton = document.createElement("li");
                menuButton.classList.add("dropdown-menu");

                // ARIA: do not show... they can see the whole tabstrip
                menuButton.setAttribute("role", "presentation");
                menuButton.setAttribute("aria-hidden", "true");

                // Make sure it is before the sidebar
                if (this.containsRightSidebarButton) {
                    let sidebarButton = this.element.querySelector(".tab.sidebar:last-of-type");
                    while(sidebarButton.previousElementSibling &&
                          sidebarButton.previousElementSibling.classList.contains("sidebar")) {
                        sidebarButton = sidebarButton.previousElementSibling;
                    }
                    this.element.insertBefore(menuButton, sidebarButton);
                }
                else {
                    this.element.appendChild(menuButton);
                }

                // Creating the actual show/hide button
                let button = document.createElement("button");
                button.classList.add("dropdown");
                menuButton.appendChild(button);

                let menu = document.createElement("ul");
                menu.classList.add("dropdown-menu-options");
                menu.classList.add("options");
                menuButton.appendChild(menu);

                this.dropdownMenuButton = menuButton;
            }

            // Preload the dropdown menu
            var menu = this.dropdownMenuButton.querySelector(".dropdown-menu-options");
            if (menu) {
                this.element.querySelectorAll(":scope > li.tab:not(.sidebar)").forEach( (item) => {
                    let menuItem = item.cloneNode(true);
                    menu.appendChild(menuItem);
                    this.bindDropdownEvents(menuItem);
                });
            }

            // Bind event handlers
            this.bindEvents();

            // Stimulate the dropdown button
            this.checkDropdownVisibility();

            // Update the document title
            this.updateDocumentTitle();

            // Freeze tab widths
            this.element.querySelectorAll(":scope > li.tab").forEach( (tab) => {
                this.freezeTabWidth(tab);
            });

            // Select the selected tab by default
            this.select(this.selected());

            // Merge tabs (if needed)
            this.merge();

            // Push and preserve the initial tab state (if desired)
            if (!this.withinModal && this.element.hasAttribute("data-push-navigation")) {
                NavigationState.updateState("Tabs", this.index(), {'index': this.selected()});
            }
        }
        else {
            throw("Requires an element to be passed");
        }
    }

    static loadAll(element) {
        element.querySelectorAll('ul.tabs:not(.documentation)').forEach( (tabElement) => {
            Tabs.load(tabElement);
        });
    }

    static load(element) {
        if (element === undefined || element === null) {
            return null;
        }

        let index = element;
        if (typeof element != "number" && typeof element != "string") {
            index = element.getAttribute('data-tabs-index');
        }

        if (index) {
            return Tabs._loaded[index];
        }

        return new Tabs(element);
    }

    /**
     * This function will give you the Tabs component that represents the
     * current element.
     *
     * If you give it an element that is within a tab-panel, it will return
     * the Tabs instance for the tabs element that corresponds to that tab-panel
     * element.
     *
     * Returns null if there is no such tabstrip.
     */
    static tabsFor(element) {
        // Find the parent tab-panels list for this element (if it isn't one)
        let tabPanels = element;
        if (!tabPanels.classList.contains("tab-panels")) {
            tabPanels = Util.getParents(element, ".tab-panels", ".tab-panels")[0];
        }

        if (!tabPanels) {
            return null;
        }

        // Find the corresponding tab strip element for this panel
        let tabPanelsId = tabPanels.getAttribute('id');
        var tabStrip = null;
        if (tabPanelsId) {
            tabStrip = document.body.querySelector('.tabs[data-panels="' + tabPanelsId + '"]');
        }

        if (!tabStrip) {
            // Look at the previous sibling
            var possibleElement = tabPanels.previousElementSibling;
            if (possibleElement && possibleElement.classList.contains("tabs")) {
                tabStrip = possibleElement;
            }
            else if (possibleElement) {
                possibleElement = possibleElement.previousElementSibling;
                if (possibleElement && possibleElement.classList.contains("tabs")) {
                    tabStrip = possibleElement;
                }
            }
        }

        if (!tabStrip) {
            // Look up the chain a little bit
            var possibleElement = tabPanels.parentNode.previousElementSibling;
            if (possibleElement && possibleElement.classList.contains("tabs")) {
                tabStrip = possibleElement;
            }
            else if (possibleElement) {
                possibleElement = possibleElement.previousElementSibling;
                if (possibleElement && possibleElement.classList.contains("tabs")) {
                    tabStrip = possibleElement;
                }
            }
        }

        if (!tabStrip) {
            // Cannot find it
            return null;
        }

        return Tabs.load(tabStrip);
    }

    /**
     * Returns the visible width of the tab-strip.
     *
     * This does not include the dropdown button, if it is visible, nor any
     * sidebar buttons.
     */
    get tabStripWidth() {
        // Determine the maximum width for this tabstrip (subtract the sidebar)
        let width = this.element.clientWidth;

        if (this.containsRightSidebarButton) {
            let sidebarButton = this.element.querySelector(".tab.sidebar:last-of-type");
            while(sidebarButton.previousElementSibling.classList.contains("sidebar")) {
                sidebarButton = sidebarButton.previousElementSibling;
            }
            width -= sidebarButton.offsetWidth;
        }

        if (this.dropdownMenuButton && !this.dropdownMenuButton.getAttribute("hidden")) {
            width -= this.dropdownMenuButton.offsetWidth;
        }

        return width;
    }

    /**
     * Determines if (and acts to make sure) the dropdown button is visible.
     */
    checkDropdownVisibility() {
        if (this.element.hasAttribute("data-always-show-dropdown-button")) {
            this.showDropdownMenuButton();
            return;
        }

        let width = this.tabStripWidth;

        // Determine the acting width
        let currentWidth = 0;
        this.element.querySelectorAll(".tab:not(.sidebar)").forEach( (tab) => {
            currentWidth += tab.offsetWidth;
        });

        if (currentWidth > width && width > 0) {
            this.showDropdownMenuButton();
        }
        else {
            this.hideDropdownMenuButton();
        }
    }

    /**
     * Ensures that the dropdown button, if it exists, is shown.
     */
    showDropdownMenuButton(event) {
        if (this.dropdownMenuButton) {
            this.dropdownMenuButton.removeAttribute("hidden");
        }
    }

    /**
     * Ensures that the dropdown button, if it exists, is hidden.
     */
    hideDropdownMenuButton(event) {
        if (this.dropdownMenuButton) {
            this.dropdownMenuButton.setAttribute("hidden", "");
        }
    }

    /**
     * Ensures that the dropdown menu, if it exists, is shown.
     */
    showDropdownMenu(event) {
        if (this.dropdownMenuButton) {
            let dropdown = this.dropdownMenuButton.querySelector(".dropdown-menu-options");
            if (dropdown) {
                dropdown.style.display = "block";
            }
        }
    }

    /**
     * Ensures that the dropdown menu, if it exists, is hidden.
     */
    hideDropdownMenu(event) {
        if (this.dropdownMenuButton) {
            let dropdown = this.dropdownMenuButton.querySelector(".dropdown-menu-options");
            if (dropdown) {
                dropdown.style.display = "";
            }
        }
    }

    /**
     * Ensures that the sidebar button, if it exists, is shown.
     */
    showSidebarButton(index) {
        var sidebarButton = this.element.querySelectorAll(".tab.sidebar")[index || 0];
        if (sidebarButton) {
            sidebarButton.classList.remove("reveal");
        }
    }

    /**
     * Ensures that the sidebar button, if it exists, is depressed.
     */
    hideSidebarButton(index) {
        var sidebarButton = this.element.querySelectorAll(".tab.sidebar")[index || 0];
        if (sidebarButton) {
            sidebarButton.classList.add("reveal");
        }
    }

    /**
     * Shows the given sidebar.
     */
    showSidebar(index) {
        var sidebarButton = this.element.querySelectorAll(".tab.sidebar")[index || 0];
        if (sidebarButton) {
            if (sidebarButton.classList.contains("reveal")) {
                this.revealSidebarTabEvent(sidebarButton, null);
            }
        }
    }

    /**
     * Hides the given sidebar.
     */
    hideSidebar(index) {
        var sidebarButton = this.element.querySelectorAll(".tab.sidebar")[index || 0];
        if (sidebarButton) {
            if (!sidebarButton.classList.contains("reveal")) {
                this.revealSidebarTabEvent(sidebarButton, null);
            }
        }
    }

    /**
     * This method is the event callback that will reveal the bound tab.
     */
    revealTabEvent(element, event) {
        if (element.classList.contains("disabled") || element.hasAttribute("disabled")) {
            return;
        }

        // Get the index of the tab that invoked the event
        var index = Util.getChildIndex(element, "li");
        if (this.containsSidebarButton) {
            index = index - 1;

            if (index == -1) {
                // Sidebar button pressed
                this.revealSidebarTabEvent(element, event);
                return;
            }
        }

        if (this.containsRightSidebarButton) {
            if (element.classList.contains("sidebar")) {
                // Sidebar button pressed
                this.revealSidebarTabEvent(element, event);
                return;
            }
        }

        // Select that tab
        this.select(index);
    }

    revealSidebarTabEvent(element, event) {
        // Reveal!
        element.classList.toggle("reveal");

        // Trigger sidebar event
        this.trigger('sidebar', element);

        if (element.hasAttribute("data-sidebar-reveal")) {
            var sidebar = this.element.parentNode.querySelector(element.getAttribute("data-sidebar-reveal"));

            if (sidebar) {
                sidebar.classList.toggle("reveal");

                let link = element.querySelector("a");
                if (link) {
                    // Asynchronous loading of sidebar content
                    if (link.getAttribute("data-pjax") == "true") {
                        this.asynchronousLoad(link, sidebar, -1);
                    }
                }
            }
        }
    }

    /**
     * Invalidates an asynchronously loadable tab.
     *
     * When this is called on the index or element of the tab, when the tab is
     * switched to next, the content will refresh. If `force` is set to `true`,
     * the tab will be refreshed even if it is currently selected.
     *
     * @param {number} index - The index of the tab.
     * @param {bool} force - When `true`, refresh if the tab is active.
     */
    invalidate(index, force = false) {
        if (typeof index == "object") {
            index = Util.getChildIndex(index, "li");
            if (this.containsSidebarButton) {
                index = index - 1;
            }
        }
        else {
            index = parseInt(index);
        }

        let tab = this.tabAt(index);
        let link = null;
        let loadingTemplate = window.document.body.querySelector("template.loading");

        if (tab) {
            link = tab.querySelector('a');

            if (link) {
                link.setAttribute('data-pjax', 'true');
            }
            else {
                return;
            }
        }

        let panel = this.tabPanelAt(index);

        if (panel) {
            panel.innerHTML = "";

            // Add loading card
            let loadingCard = Util.createElementFromTemplate(loadingTemplate);
            panel.appendChild(loadingCard);
        }

        if (force && this.selected() == index) {
            this.asynchronousLoad(link, panel, index);
        }
    }

    asynchronousLoad(link, tabPanel, index) {
        let request = Util.get(link.getAttribute('href'), {
            onload: (html) => {
                tabPanel.classList.remove('loading');
                tabPanel.classList.remove('unloaded');
                tabPanel.innerHTML = html;

                Occam.loadAll(tabPanel);
                // TODO: needs to Occam.loadTabPanel if still visible

                this.trigger("loaded", index)

                this.detectSidebarState();
            },
            onerror: (response) => {
                if (request.status >= 500) {
                    let card = tabPanel.querySelector(".card.loading");
                    if (card) {
                        card.classList.add("error-500");
                        let p = card.querySelector("p.loading-500");
                        if (p) {
                            p.removeAttribute('hidden');
                            p.removeAttribute('aria-hidden');
                        }
                    }
                }
                else if (request.status >= 400) {
                    let card = tabPanel.querySelector(".card.loading");
                    if (card) {
                        card.classList.add("error-400");
                        let p = card.querySelector("p.loading-400");
                        if (p) {
                            p.removeAttribute('hidden');
                            p.removeAttribute('aria-hidden');
                        }
                    }
                }
            }
        }, "text/html");
    }

    updateState(state) {
        this.select(state.index, true);
    }

    /**
     * This method returns the unique index for this tab strip.
     */
    index() {
        return this.element.getAttribute('data-tabs-index');
    }

    /**
     * This function selects the given tab by its index or the element.
     *
     * The first tab is at index 0.
     */
    select(index, doNotPushState=false, force=false) {
        if (typeof index == "object") {
            index = Util.getChildIndex(index, "li");
            if (this.containsSidebarButton) {
                index = index - 1;
            }
        }
        else {
            index = parseInt(index);
        }

        var oldIndex = this.selected();

        if (oldIndex == index && !force) {
            return;
        }

        var tabIndex = index;
        if (this.containsSidebarButton) {
            tabIndex = index + 1;
        }

        var tab = this.element.querySelector(':scope > li.tab:nth-of-type(' + (tabIndex + 1) + ')');
        if (!tab) {
            // Select last
            tab = this.element.querySelector(':scope > li.tab:last-of-type');
        }
        if (tab && tab.classList.contains("sidebar")) {
            tab = tab.previousElementSibling;
            if (tab && !tab.matches("li")) {
                tab = null;
            }
        }
        while (tab && tab.classList.contains("sidebar")) {
            tab = tab.previousElementSibling;
        }
        while (tab && !tab.classList.contains("tab")) {
            tab = tab.previousElementSibling;
        }
        if (!tab) {
            return;
        }

        if (tab.classList.contains("disabled")) {
            return;
        }

        tab.parentNode.querySelectorAll(':scope > .active').forEach( (item) => {
            this.freezeTabWidth(item);

            let link = item.querySelector("a");

            item.classList.remove('active');
            link.classList.remove("clicked");
            link.setAttribute("tabindex", "-1");
            link.setAttribute("aria-selected", "false");
        });

        var link = tab.querySelector('a');
        link.setAttribute("aria-selected", "true");
        link.setAttribute("tabindex", "0");

        this.freezeTabWidth(tab);

        tab.classList.add('active');

        // Check to make sure the tab is visible by scrolling to it when necessary
        // We use margin-left instead of scrollLeft because we cannot have the
        // element have an 'overflow: hidden' since the dropdown needs to be
        // drawn.

        // Get the current scroll-left (which is a margin-left) to accurately
        // know the tab's current position
        let currentScrollLeft = parseInt(tab.parentNode.querySelector(":scope > li.tab:first-of-type").style.marginLeft) || 0;
        // Get the tab's left position
        let tabLeft = tab.offsetLeft - currentScrollLeft;
        // Get the content width of the tabstrip
        let tabStripWidth = this.tabStripWidth;
        // Get the width of the tab
        let tabWidth = tab.offsetWidth;
        // Determine the scroll position
        let tabStripScrollLeft = Math.max(0, (tabLeft + tabWidth) - tabStripWidth);
        // Set (as long as the tabs are properly visible)
        if (tabStripWidth > 0) {
            tab.parentNode.querySelector(":scope > li.tab:first-of-type").style.marginLeft = (-tabStripScrollLeft) + "px";
        }

        // Hide tab
        if (this.panels) {
            var currentTab = this.panels.querySelector(':scope > .tab-panel.active');
            if (currentTab) {
                currentTab.classList.remove('active');
                currentTab.setAttribute('aria-hidden', 'true');
            }
        }

        // When the tab is 'merged', it actually belongs to another tabstrip.
        // We do this when we want to have some tabs get inherited when there
        //   are tab-panels with tabstrips.
        if (tab && tab.classList.contains('merged')) {
            // Ah, just select the secondary tabstrip.
            let subTabs = Tabs.load(tab.getAttribute('data-merge-target-tabstrip'));
            let subTabIndex = parseInt(tab.getAttribute('data-merge-target-tab'))
            return subTabs.select(subTabIndex);
        }

        // Reveal tab
        if (this.panels) {
            var tabPanel = this.panels.querySelector(':scope > .tab-panel:nth-of-type(' + (index + 1) + ')');
            if (!tabPanel) {
                tabPanel = this.panels.querySelector(':scope > .tab-panel:last-of-type');
            }
            if (tabPanel) {
                tabPanel.classList.add('active');
                tabPanel.setAttribute('aria-hidden', 'false');
            }
        }

        // Dynamic load, if necessary
        if (link.getAttribute('data-pjax') === "true") {
            link.setAttribute('data-pjax', 'complete');

            this.asynchronousLoad(link, tabPanel, index);
        }

        // Update the browser url / title
        if (!this.withinModal) {
            if (this.element.hasAttribute("data-replace-navigation")) {
                if (!doNotPushState) {
                    NavigationState.updateLocation(tab.querySelector('a').getAttribute('href'));
                }
            }

            if (this.element.hasAttribute("data-push-navigation")) {
                if (!doNotPushState) {
                    NavigationState.updateState("Tabs", this.index(), {'index': this.selected()});
                }
            }

            this.updateDocumentTitle();
        }

        if (tabPanel) {
            // Call resize
            Occam.resize(tabPanel, null);
        }

        // Press/depress sidebar button if sidebar is there
        this.detectSidebarState();

        // Fire event
        this.trigger("change", index);
    }

    detectSidebarState() {
        this.element.querySelectorAll(":scope > li.sidebar.tab").forEach( (sidebarTab) => {
            if (sidebarTab.hasAttribute("data-sidebar-reveal")) {
                var sidebar = this.element.parentNode.querySelector(sidebarTab.getAttribute("data-sidebar-reveal"));

                if (sidebar) {
                    sidebarTab.removeAttribute('disabled');
                    if (sidebar.classList.contains("reveal")) {
                        sidebarTab.classList.add("reveal");
                    }
                    else {
                        sidebarTab.classList.remove("reveal");
                    }
                }
                else {
                    // Sidebar isn't found... so disable the button
                    sidebarTab.setAttribute('disabled', '');
                }
            }
        });
    }

    /**
     * This method sets the width of the tab to a static value based on its current form.
     */
    freezeTabWidth(tab) {
        let link = tab.querySelector("a");

        if (link.offsetWidth == 0) {
            return;
        }

        // Ensure each tab retains its (prior to active) width
        if (!tab.classList.contains("sidebar") && !tab.hasAttribute("data-tab-adjusted") && !tab.hasAttribute('data-no-tab-adjustment')) {
            tab.setAttribute("data-tab-adjusted", "");
            let linkStyle = window.getComputedStyle(link, null);
            let paddingLeft = parseFloat(linkStyle.getPropertyValue('padding-left'));
            let paddingRight = parseFloat(linkStyle.getPropertyValue('padding-right'));

            if (paddingLeft > paddingRight) {
                paddingLeft = paddingLeft - paddingRight;
                paddingRight = 0;
            }
            else {
                paddingRight = paddingRight - paddingLeft;
                paddingLeft = 0;
            }

            link.style.width = Math.ceil(link.offsetWidth - paddingLeft - paddingRight) + "px";
            link.style.paddingLeft  = paddingLeft + "px";
            link.style.paddingRight = paddingRight + "px";
        }
    }

    /**
     * This method retrieves the document title that should be used for the current tab.
     */
    get documentTitle() {
        let ret = Occam.title();

        let tab = this.tabAt(this.selected());
        if (tab) {
            let link = tab.querySelector('a');
            if (link && this.element.hasAttribute("data-append-title")) {
                if (this.parentTabs) {
                    ret = this.parentTabs.documentTitle;
                }
                ret = ret + " :: " + link.textContent;
            }
        }

        return ret
    }

    /**
     * This method updates the document title to reflect the current tab.
     */
    updateDocumentTitle() {
        document.title = this.documentTitle;
    }
    
    /**
     * This method returns the number of tabs (not including sidebar tabs.)
     */
    count() {
        return this.element.querySelectorAll(':scope > li.tab:not(.sidebar)').length;
    }

    /**
     * This method will return the index of the currently selected tab.
     */
    selected() {
        var currentTab = this.element.querySelector(':scope > li.tab.active');

        if (!currentTab) {
            return -1;
        }

        var index = Util.getChildIndex(currentTab, "li");

        if (this.containsSidebarButton) {
            index = index - 1;
        }

        return index;
    }

    /**
     * This method will ensure that tab events are bound to this tabstrip.
     */
    bindEvents(tab, tabPanel) {
        if (tab) {
            if (tab.classList.contains("tab-bound")) {
                return;
            }
            tab.classList.add("tab-bound");

            if (!tab.getAttribute("data-tab-id")) {
                tab.setAttribute("data-tab-id", this.nextTabID);
                this.nextTabID++;
            }

            var link = tab.querySelector(':scope > a, :scope > button');

            tab.addEventListener('mousedown', (event) => {
                // Do not outline it for a mouse click
                tab.style.outline = "none";
                link.classList.add("clicked");
            });

            link.addEventListener('blur', (event) => {
                link.classList.remove("clicked");
            });

            tab.addEventListener('click', (event) => { this.revealTabEvent(tab, event); });

            link.addEventListener('click', function(event) {
                event.preventDefault();
            });

            if (!tabPanel) {
                tabPanel = this.tabPanelAt(tab);
            }

            // ARIA (attach panel with tab link)
            if (tabPanel && !tab.getAttribute("aria-controls")) {
                let tabPanelID = tabPanel.getAttribute('id');
                if (!tabPanelID) {
                    tabPanelID = this.element.getAttribute('data-tabs-index') + "-panel-" + tab.getAttribute("data-tab-id");
                }
                link.setAttribute("aria-controls", tabPanelID);
                tabPanel.setAttribute("id", tabPanelID);
            }

            // Do not allow it to be tabbed to
            link.setAttribute("tabindex", "-1");

            // Allow the current tab to be tabbed to
            var selected = this.element.querySelector(':scope > li.active a');
            if (selected) {
                selected.setAttribute("tabindex", "0");
            }

            // Allow the navigation of the tabstrip via keyboard
            link.addEventListener("keydown", (event) => {
                var toggle = null;
                if (event.keyCode == 37) { // left arrow
                    toggle = tab.previousElementSibling;
                }
                else if (event.keyCode == 39) { // right arrow
                    toggle = tab.nextElementSibling;
                }
                else if (event.keyCode == 40) { // down arrow
                    // Unfocus the tabstrip and go to content
                    event.preventDefault();
                    event.stopPropagation();

                    var panel = this.tabPanelAt(this.selected());
                    if (panel) {
                        panel.setAttribute("tabindex", "0");
                        panel.focus();
                        panel.setAttribute("tabindex", "-1");
                    }

                    return;
                }

                if (toggle) {
                    var index = Util.getChildIndex(toggle, "li");
                    if (this.containsSidebarButton) {
                        index = index - 1;
                    }

                    this.select(index);
                    toggle.querySelector("a").focus();
                }
            });
        }
        else {
            window.addEventListener("resize", (event) => {
                // When we might change base font sizes, (e.g., the window is
                // resized to a larger scale) the tabs will reset their widths.
                this.element.querySelectorAll(':scope > li.tab').forEach( (tab) => {
                    tab.removeAttribute("data-tab-adjusted");

                    var link = tab.querySelector("a");
                    link.style.width = "";
                    link.style.paddingLeft = "";
                    link.style.paddingRight = "";
                });
            });

            this.element.querySelectorAll(':scope > li.tab').forEach( (tab) => {
                this.bindEvents(tab);
            });

            // But also, make sure we have a resize event
            if (!this.resizeEvent) {
                this.resizeEvent = window.addEventListener("resize", (event) => {
                    this.checkDropdownVisibility();
                });
            }

            // Select the element to load the panel
            this.select(this.selected(), true, true);
        }

        return this;
    }
    
    /**
     * This method adds events to the dropdown menu item.
     */
    bindDropdownEvents(tab) {
        tab.addEventListener("click", (event) => {
            event.preventDefault();
            event.stopPropagation();

            // Get the index of this item and select that tab
            var index = Util.getChildIndex(tab, "li");

            this.select(index);
        });
    }

    /**
     * This method adds a tab at the given index with the given name. If no index
     * is given, the tab is appended to the end of the tabstrip (the right-hand
     * side assuming a left-to-right rendering)
     */
    addTab(name_or_element, b, c) {
        var callback = b;
        var atIndex = b;

        if (arguments.length > 2) {
            callback = c;
        }
        else {
            callback = null;
        }

        var index = atIndex || this.element.children.length;
        if (atIndex == 0) {
            index = 0;
        }

        var panelIndex = index;
        if (this.containsSidebarButton) {
            index++;
        }

        var tab = name_or_element;
        if (typeof tab == "string") {
            tab = document.createElement("li");
            tab.classList.add("tab");
            var newLink = document.createElement("a");
            newLink.textContent = name_or_element;
            tab.appendChild(newLink);
        }

        var tabPanel = null;
        if (Array.isArray(name_or_element)) {
            tab = name_or_element[0];
            tabPanel = name_or_element[1];
        }
        else {
            tabPanel = document.createElement("li");
            tabPanel.classList.add("tab-panel");
        }

        tab.removeAttribute("data-tab-adjusted");
        tab.querySelector("a").removeAttribute('style');
        tab.classList.remove("tab-bound");
        tab.classList.remove("active");
        let link = tab.querySelector(':scope > a, :scope > button');
        if (link) {
            link.classList.remove("clicked");
        }
        tabPanel.classList.remove("active");

        var referenceTab = this.element.querySelector(':scope > li.tab:nth-of-type(' + (index + 1) + ')');

        var referencePanel = this.panels.querySelector(':scope > li.tab-panel:nth-of-type(' + (panelIndex + 1) + ')');

        if (!referenceTab) {
            // We have to find where the tab should go. To the right of all
            // existing tabs (and left of any nonsense that might be there)
            let lastTab = this.element.querySelector(':scope > li.tab.sidebar:last-of-type');
            if (!lastTab) {
                lastTab = this.element.querySelector(':scope > li:last-of-type');
            }
            referenceTab = lastTab.previousElementSibling;
            while(referenceTab && referenceTab.classList.contains("sidebar")) {
                referenceTab = referenceTab.previousElementSibling;
            }
            referencePanel = null;
            while (referenceTab && !referenceTab.classList.contains("tab")) {
                lastTab = referenceTab;
                referenceTab = referenceTab.previousElementSibling;
            }
            if (!referenceTab) {
                referenceTab = lastTab;
            }
            else {
                referenceTab = referenceTab.nextElementSibling;
            }
        }

        if (!referenceTab) {
            this.element.appendChild(tab);
            this.panels.appendChild(tabPanel);
        }
        else {
            this.element.insertBefore(tab, referenceTab);

            if (referencePanel) {
                this.panels.insertBefore(tabPanel, referencePanel);
            }
            else {
                this.panels.appendChild(tabPanel);
            }
        }

        // We need to add the item to the dropdown at the correct index as well.
        if (this.dropdownMenuButton) {
            let dropdown = this.dropdownMenuButton.querySelector(".dropdown-menu-options");
            if (dropdown) {
                var referenceItem = dropdown.querySelector(':scope > li:nth-of-type(' + (panelIndex + 1) + ')');
                var clonedTab = tab.cloneNode(true);
                if (referenceItem) {
                    dropdown.insertBefore(clonedTab, referenceItem);
                }
                else {
                    dropdown.appendChild(clonedTab);
                }
                this.bindDropdownEvents(clonedTab);
            }
        }

        // Bind events on this tab element
        this.bindEvents(tab);

        // Stimulate the dropdown button
        this.checkDropdownVisibility();

        if (callback) {
            callback(tabPanel);
        }

        return tab;
    }

    /**
     * This method replaces the tab at the given index with the one passed in.
     */
    replaceTab(atIndexOrElement, newTab) {
        // What we will do is remove this tab and then add the given elements at this index.
        var atIndex = atIndexOrElement;
        if (typeof atIndex == "object") {
            atIndex = Util.getChildIndex(atIndex, "li");
            if (this.containsSidebarButton) {
                atIndex = atIndex - 1;
            }
        }

        var selected = this.selected();

        this.removeTab(atIndexOrElement);
        this.addTab(newTab, atIndex);

        if (selected == atIndex) {
            this.select(atIndex);
        }

        return this;
    }

    /**
     * This method removes the tab at the given index or via the passed in element.
     */
    removeTab(atIndexOrElement) {
        var atIndex = atIndexOrElement;
        if (typeof atIndex == "object") {
            atIndex = Util.getChildIndex(atIndex, "li");
            if (this.containsSidebarButton) {
                atIndex--;
            }
        }

        var selected = this.selected();

        var panelIndex = atIndex;
        if (this.containsSidebarButton) {
            atIndex++;
        }

        this.element.querySelector(':scope > li.tab:nth-of-type(' + (atIndex + 1) + ')').remove();
        this.panels.querySelector(':scope > li.tab-panel:nth-of-type(' + (panelIndex + 1) + ')').remove();

        // We must remove this from the dropdown menu
        if (this.dropdownMenuButton) {
            let dropdown = this.dropdownMenuButton.querySelector(".dropdown-menu-options");
            if (dropdown) {
                var item = dropdown.querySelector(':scope > li:nth-of-type(' + (panelIndex + 1) + ')');
                if (item) {
                    item.remove();
                }
            }
        }

        // If this tab was the selected tab, select the next index.
        if (selected == panelIndex) {
            this.select(panelIndex);
        }

        // Stimulate the dropdown button
        this.checkDropdownVisibility();

        return this;
    }

    /**
     * This method returns the element of the tab at the given index.
     */
    tabAt(atIndex) {
        if (typeof atIndex == "object") {
            atIndex = Util.getChildIndex(atIndex, "li");
        }
        else {
            atIndex = parseInt(atIndex);
        }

        if (this.containsSidebarButton) {
            atIndex += 1;
        }

        return this.element.querySelector(':scope > li.tab:nth-of-type(' + (atIndex + 1) + ')');
    }

    /**
     * This method returns a reference to the tab panels element.
     */
    tabPanels() {
        return this.panels;
    }

    /**
     * This method returns the element of the tab-panel at the given index.
     */
    tabPanelAt(atIndex) {
        if (typeof atIndex == "object") {
            atIndex = Util.getChildIndex(atIndex, "li");

            if (this.containsSidebarButton) {
                atIndex -= 1;
            }
        }
        else {
            atIndex = parseInt(atIndex);
        }

        if (!this.panels) {
            return null;
        }

        return this.panels.querySelector(':scope > li.tab-panel:nth-of-type(' + (atIndex + 1) + ')');
    }

    /**
     * This method will merge these tabs to a parent tabstrip.
     *
     * It will merge with a tabstrip that is up the chain with the attribute
     * 'data-allow-merge'.
     *
     * Generally, this will be done automatically when it sees a 'data-merge'
     * attribute.
     */
    merge() {
        if (this.element.hasAttribute('data-merge')) {
            // Find the parent tab-panel for this element
            let tabPanel = Util.getParents(this.element, ".tab-panel, .task-panel", ".tab-panel, .task-panel")[0];

            // If we cannot find our tab-panel (or it isn't active), bail out
            if (!tabPanel || !tabPanel.classList.contains('active')) {
                return;
            }

            // Find the first Tabs instance that our tabstrip is within
            let parentTabs = Tabs.tabsFor(this.element);

            if (parentTabs) {
                // Merge with that tabstrip
                parentTabs.mergeWith(this);

                // Select the currectly active tab
                parentTabs.select(this.selected());
            }

            // Hide our tabstrip
            this.element.setAttribute('hidden', '');
        }
    }

    /**
     * This method merges the tabs from the given Tabs instance with the
     * current one.
     *
     * The previously merged items will be cleared.
     *
     * If you pass null, the previously merged items will be cleared only.
     */
    mergeWith(subTabs) {
        if (this.element.hasAttribute("data-allow-merge")) {
            // Remove all previously merged tabs
            this.element.querySelectorAll(":scope > li.tab.merged").forEach( (mergedTab) => {
                mergedTab.remove();
            });

            // Add the subtabs
            if (subTabs) {
                var tabIndex = 0;
                subTabs.element.querySelectorAll(":scope > li.tab").forEach( (subTab) => {
                    let newTab = subTab.cloneNode(true);
                    newTab.classList.add("merged");
                    newTab.setAttribute('data-merge-target-tabstrip', subTabs.index());
                    newTab.setAttribute('data-merge-target-tab', tabIndex);
                    newTab.removeAttribute('data-tab-id');
                    this.addTab(newTab);
                    tabIndex++;
                });
            }
        }
    }
}

Tabs._count = 0;
Tabs._loaded = {};

export default Tabs;
